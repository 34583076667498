import { Box, Button, Alert, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React, { useEffect, useState } from 'react';
import axios from 'axios';


function DetailDiff(props) {

     const [compareDiv, setCompareDiv] = useState(false);
     const [compareError, setCompareError] = useState(false);
     const [compareErrorResponse, setCompareErrorResponse] = useState('');

     useEffect(() => {
          if (props.servicetree.hasOwnProperty('serviceDomainID') && props.feedtree.hasOwnProperty('serviceDomainID')) {
               setCompareDiv(true)
          }
     }, [props.servicetree, props.feedtree])

     function handleCompareDomain(event) {
          setCompareError(false);
          var servicetree = props.servicetree
          var feedbacktree = props.feedtree
          var getToken = localStorage.getItem('jwtToken')
          var jwtToken = "Bearer " + getToken
          if (getToken == null) {
               setCompareErrorResponse('Unable To Compare Domains, Authorization Failed');
               setCompareError(true);
          }
          else if (servicetree != null && feedbacktree != null && getToken != null) {
               setCompareDiv(true)
               axios({
                    method: 'post',
                    url: 'https://bian-functions-staging.azurewebsites.net/ServiceDomains',
                    data: [feedbacktree, servicetree],
                    headers: {
                         'Authorization': jwtToken,
                    }
               })
                    .then(function (response) {
                         setCompareError(false);
                         console.log("response is", response);
                         props.callbackFunction(response.data)
                    })
                    .catch(function (error) {
                         // console.log("catch response is",error);


                         // Error
                         if (error.response) {
                              console.log("error.response => ", error.response);
                              setCompareErrorResponse(error?.response?.data[0]?.description ? error?.response?.data[0]?.description : 'Unable To Compare Domains');
                              setCompareError(true);

                         } else if (error.request) {
                              console.log("error.request => ", error.request);
                              console.log(error.request);
                         } else {
                              console.log('error.message => ', error.message);
                         }
                         console.log(error.config);
                    });
          } else {
               setCompareErrorResponse('Unable To Compare Domains');
               setCompareError(true);
          }
     }

     return <>
          {compareDiv && <Box style={{ margin: '8px 0', display: 'flex', justifyContent: 'center' }}>
               <Button variant="contained" color="primary" endIcon={<SendIcon />} onClick={handleCompareDomain}>
                    Compare
               </Button>
          </Box>}
          {compareError && <Stack sx={{ width: '100%' }} spacing={2}><Alert severity="error">{compareErrorResponse}</Alert></Stack>}
     </>;
}

export default DetailDiff;
