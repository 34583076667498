import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Typography, Skeleton } from "@mui/material";
import { green, blue, red, purple, orange, brown } from "@mui/material/colors";
import { useSelector, useDispatch } from "react-redux";
import DashboardCard from "./DashboardCard";
import ApiIcon from "../../assets/images/image001.png";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useNavigate, useNavigate as useHistory } from "react-router-dom";
// import useCustomEvent from '@microsoft/applicationinsights-react-js/types/useTrackEvent';
import useCustomEvent from "../../hooks/useCustomTrack";
import { reactPlugin } from "../../AppInsights";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";

function DashboardContent() {
  const [allData, setAllData] = useState([]);
  let history = useHistory();
  const appInsights = reactPlugin.getAppInsights();

  const allCollection = useSelector((state) => state?.common?.searchVal);
  const searchText = useSelector((state) => state?.common?.searchText);
  const searchField = useSelector((state) => state?.common?.searchField);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectVal = (selectedDataType) => {
    appInsights.trackEvent({
      name: "Selected Card in Dashboard",
      properties: selectedDataType.artefactType,
    });
    if (selectedDataType.artefactType === "ServiceDomain") {
      dispatch({ type: "SELECT_TAB", payload: "business-scenario-services" });
      dispatch({
        type: "SET_FEEDBACK_SERVIES_DOMAIN",
        payload: selectedDataType,
      });
      dispatch({
        type: "BUSINESS_SCENARIO_SERVICE_SELECTED",
        payload: selectedDataType.uid,
      });
      history(`/dashboard/business-scenario-services/services`);
    } else if (selectedDataType.artefactType === "BusinessScenario") {
      dispatch({ type: "SELECT_TAB", payload: "business-scenario" });
      dispatch({
        type: "SELECTED_BUSINESS_SCENARIO",
        payload: selectedDataType,
      });
      history(`/dashboard/business-scenario-services/business-scenario`);
    } else if (selectedDataType.artefactType === "PrimitiveType") {
      selectedDataType.dataType = selectedDataType.name;
      dispatch({ type: "SELECT_TAB", payload: "primitive-type" });
      dispatch({ type: "PRIMITIVE_DATA_TYPE_VAL", payload: selectedDataType });
      history(`/dashboard/business-scenario-services/primitive-type`);
    } else if (selectedDataType.artefactType === "Class") {
      selectedDataType.dataType = selectedDataType.name;
      dispatch({ type: "SELECT_TAB", payload: "classes" });
      dispatch({ type: "CLASS_VAL", payload: selectedDataType });
      history(`/dashboard/business-scenario-services/classes`);
    } else if (selectedDataType.artefactType === "Enumeration") {
      selectedDataType.dataType = selectedDataType.name;
      dispatch({ type: "SELECT_TAB", payload: "enumerations" });
      dispatch({ type: "ENUMRATION_VAL", payload: selectedDataType });
      history(`/dashboard/business-scenario-services/enumerations`);
    } else {
      selectedDataType.dataType = selectedDataType.name;
      dispatch({ type: "SELECT_TAB", payload: "data-type" });
      dispatch({ type: "DATA_TYPE_VAL", payload: selectedDataType });
      history(`/dashboard/business-scenario-services/data-type`);
    }
    // navigate('/dashboard/business-scenario-services');
  };

  const radioSkeletons = [];
  for (let i = 1; i <= 10; i++) {
    radioSkeletons.push(i);
  }

  useEffect(() => {
    if (searchText && searchText !== "") {
      var data = JSON.stringify(searchText);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_MODEL_ACCESS_S1}Search`,
        headers: {
          "Content-Type": "application/json",
        },
        // headers: {
        //     Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        // },
        data: data,
      }).then((res) => {
        if (searchField && searchField != "all") {
          const allSearchVal = res.data.filter(
            ({ artefactType }) => artefactType === searchField
          );
          dispatch({ type: "SEARCH_VAL", payload: allSearchVal });
        } else {
          dispatch({ type: "SEARCH_VAL", payload: res.data });
        }
      });
    }
  }, [searchText, searchField]);

  useEffect(() => {
    setAllData(allCollection);
  }, [allCollection]);

  var listOfCardItem = [
    {
      bgcolor: brown[500],
      avatarText: "BS",
      title: "Business Scenario | Wireframe",
      subheader: "Use cases with connected Service Domains",
      to: "business-scenario-services",
      redirect: "business-scenario",
      toText: "View Business Scenarios | Wireframe",
    },
    {
      bgcolor: green[500],
      avatarText: "SD",
      title: "Service Domain",
      redirect: "services",
      subheader: (
        <div>
          <p>Details & Semantic APIs </p>{" "}
          <img
            src={ApiIcon}
            style={{
              width: "26%",
              display: "block",
              marginTop: "-16px",
              marginBottom: "-16px",
            }}
          />
        </div>
      ),
      to: "services",
      toText: "View Service Domains",
    },
    // {
    //     bgcolor: blue[500],
    //     avatarText: 'BO',
    //     title: 'Busines Objects',
    //     subheader: "Add, Compare, Edit and Delete Busines Objects Attributes",
    //     to: "business-object",
    //     toText: "View Busines Objectss",
    // },
    {
      bgcolor: red[500],
      avatarText: "BC",
      title: "Class",
      redirect: "classes",
      subheader: "Business Object Class",
      to: "business-classes",
      toText: "View Class",
    },
    {
      bgcolor: purple[500],
      avatarText: "DT",
      title: "Data Types",
      redirect: "data-type",
      subheader: "Business Object Data Types",
      to: "business-data-types",
      toText: "View Data Types",
    },
    {
      bgcolor: orange[500],
      avatarText: "EM",
      title: "Enumeration",
      redirect: "enumerations",
      subheader: "Business Object Enumeration",
      to: "business-enumeration",
      toText: "View Enumeration",
    },
    {
      bgcolor: brown[500],
      avatarText: "PT",
      title: "Primitive Types",
      redirect: "primitive-type",
      subheader: "Business Object Primitive Types",
      to: "business-primitive-datatypes",
      toText: "View Primitive Types",
    },
  ];

  // <>
  //     {listOfCardItem.map(mapData => (
  //         <Box sx={{ m: 2, p: 2, mb: 0, mt: 0 }}>
  //             <Box sx={{ mb: 2 }}>
  //                 <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>#{mapData.title}</Typography>
  //                 <Grid container sx={{ width: "auto" }}>
  //                     <Grid item xs={12} md={6} lg={3} sx={{ p: 1 }}>
  //                         <DashboardCard
  //                             cardHeader={{
  //                                 bgcolor: mapData.bgcolor,
  //                                 avatarText: mapData.avatarText,
  //                                 title: mapData.title,
  //                                 subheader: mapData.subheader
  //                             }}
  //                             cardAction={{
  //                                 to: mapData.to,
  //                                 toText: mapData.toText
  //                             }}
  //                         />
  //                     </Grid>
  //                     <Grid item xs={12} md={6} lg={3} sx={{ p: 1 }}>
  //                         <Link to={"create-new/" + mapData.to}>
  //                             <Button variant="outlined" startIcon={<AddIcon />} sx={{ border: "2px dashed grey", height: "100%", width: "100%", borderRadius: "5px" }}>Add</Button>
  //                         </Link>
  //                     </Grid>
  //                 </Grid>
  //             </Box>
  //             <Divider sx={{ borderBottomWidth: "2px" }} />
  //         </Box>
  //     ))}
  // </>
  console.log("allData, ", allData);
  return (
    <>
      {/* <Grid container spacing={2}> */}
      <Grid
        xs={12}
        style={{ overflow: "auto", marginTop: "20px", display: "inline-flex" }}
      >
        {listOfCardItem.map((mapData) => (
          <Grid xs={6} sx={{ padding: "20px" }} key={mapData.to}>
            <DashboardCard
              cardHeader={{
                bgcolor: mapData.bgcolor,
                avatarText: mapData.avatarText,
                title: mapData.title,
                subheader: mapData.subheader,
              }}
              cardAction={{
                to: mapData.to,
                toText: mapData.toText,
                redirect: mapData.redirect,
              }}
            />
          </Grid>
        ))}
      </Grid>
      {searchText && allData.length === 0 ? (
        <Grid xs={8} ml={40}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <Skeleton
              sx={{ flexGrow: "1" }}
              height={40}
              variant="rectangular"
            />
            <Divider style={{ margin: "8px 0" }} />
          </Box>
          <Box sx={{ padding: "10px 15px" }}>
            {radioSkeletons.map((index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
                key={"skeletonKey" + index}
              >
                <Skeleton
                  variant="circular"
                  width={20}
                  height={20}
                  sx={{ marginRight: "7px" }}
                />
                <Skeleton
                  sx={{ flexGrow: "1" }}
                  height={20}
                  variant="rectangular"
                />
              </Box>
            ))}
          </Box>
        </Grid>
      ) : null}
      <Grid
        xs={12}
        ml={36}
        mr={34}
        style={{ overflow: "scroll", height: "800px" }}
      >
        {allData.map((item) => (
          <div
            className="g Ww4FFb vt6azd tF2Cxc"
            lang="en"
            style={{ marginLeft: "40px" }}
            data-hveid="CC4QAA"
            data-ved="2ahUKEwi6j_O459r5AhXEdd4KHURPBxoQFSgAegQILhAA"
          >
            <div
              className="kvH3mc BToiNc UK95Uc"
              data-sokoban-container="ih6Jnb_PVDDpb"
            >
              <div
                className="Z26q7c UK95Uc uUuwM jGGQ5e"
                data-header-feature={0}
              >
                <div className="yuRUbf">
                  <a
                    onClick={() => selectVal(item)}
                    style={{ color: "blue", cursor: "pointer" }}
                    data-ved="2ahUKEwi6j_O459r5AhXEdd4KHURPBxoQFnoECCEQAQ"
                  >
                    <br />
                    <h3 className="LC20lb MBeuO DKV0Md">{item.name}</h3>
                    <div className="TbwUpd NJjxre">
                      <cite className="iUh30 qLRx3b tjvcx" role="text">
                        {item.artefactType}
                      </cite>
                    </div>
                  </a>
                </div>
              </div>
              <div className="Z26q7c UK95Uc uUuwM" data-content-feature={1}>
                <div
                  className="VwiC3b yXK7lf MUxGbd yDYNvb lyLwlc lEBKkf"
                  style={{ WebkitLineClamp: 2 }}
                >
                  <span
                    className="MUxGbd wuQ4Ob WZ8Tjf"
                    style={{ marginRight: "20px" }}
                  >
                    <span>
                      {item?.lastModified &&
                        (item.lastModified ?? "")
                          ?.replaceAll("T", " ")
                          .split(":")[0] +
                          `:` +
                          (item.lastModified ?? "")
                            ?.replaceAll("T", " ")
                            .split(":")[1]}
                    </span>
                  </span>
                  <span>
                    {item?.description &&
                      (item?.description ?? "").slice(0, 400) +
                        (item?.description?.length > 400 ? "..." : "")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Grid>
      {/* </Grid> */}

      {/* <Grid >
                <Grid sx={6} style={{ margin: "20px 10px", width: "auto" }}>
                    {listOfCardItem.map(mapData => (
                        <Grid item xs={12} md={6} lg={3} sx={{ padding: "10px", height: '100%' }} key={mapData.to}>
                            <DashboardCard
                                cardHeader={{
                                    bgcolor: mapData.bgcolor,
                                    avatarText: mapData.avatarText,
                                    title: mapData.title,
                                    subheader: mapData.subheader
                                }}
                                cardAction={{
                                    to: mapData.to,
                                    toText: mapData.toText
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid sx={6} style={{ height: '500px', overflow: 'auto' }}>
                    {
                        allCollection.map((item) =>
                            <div
                                className="g Ww4FFb vt6azd tF2Cxc"
                                lang="en"
                                style={{ width: 600, marginLeft: '40px' }}
                                data-hveid="CC4QAA"
                                data-ved="2ahUKEwi6j_O459r5AhXEdd4KHURPBxoQFSgAegQILhAA"
                                onClick={() => selectVal(item)}
                            >
                                <div className="kvH3mc BToiNc UK95Uc" data-sokoban-container="ih6Jnb_PVDDpb">
                                    <div className="Z26q7c UK95Uc uUuwM jGGQ5e" data-header-feature={0}>
                                        <div className="yuRUbf">
                                            <a
                                                // href=""
                                                style={{ color: "blue" }}
                                                data-ved="2ahUKEwi6j_O459r5AhXEdd4KHURPBxoQFnoECCEQAQ"
                                            >
                                                <br />
                                                <h3 className="LC20lb MBeuO DKV0Md">
                                                    {item.name}
                                                </h3>
                                                <div className="TbwUpd NJjxre">
                                                    <cite className="iUh30 qLRx3b tjvcx" role="text">
                                                        {item.artefactType}
                                                    </cite>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="Z26q7c UK95Uc uUuwM" data-content-feature={1}>
                                        <div
                                            className="VwiC3b yXK7lf MUxGbd yDYNvb lyLwlc lEBKkf"
                                            style={{ WebkitLineClamp: 2 }}
                                        >
                                            <span className="MUxGbd wuQ4Ob WZ8Tjf">
                                                <span>{item.lastModified}</span> —{" "}
                                            </span>
                                            <span>
                                                {item.description}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Grid>
            </Grid> */}
    </>
  );
}

export default DashboardContent;
