import { createTheme } from "@mui/material/styles";

export const dashboard = createTheme({
     palette: {
          primary: {
               main: "#10414a"
          },
          secondary: {
               main: "#c1cd29"
          },
          dark: {
               main: "#5f6062"
          },
          light: {
               main: "#ffffff"
          },
          danger: {
               main: "#eb0014",
               color: "#ffffff"
          },
     },
     custombutton: {
          primary: {
               color: "#fff",
               backgroundColor: "#c1cd29"
          },
          secondary: {
               color: "#fff",
               backgroundColor: "#10414a"
          },
     }
});