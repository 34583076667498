import React, { useEffect } from "react";
import {
	Avatar,
	Card,
	CardActions,
	CardHeader,
	Divider,
	Typography,
} from "@mui/material";
import { Link, useNavigate as useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
	useAppInsightsContext,
	useTrackMetric,
	useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import useCustomEvent from "../../hooks/useCustomTrack";
import { reactPlugin } from "../../AppInsights";

function DashboardCard(props) {
	const dispatch = useDispatch();
	let history = useHistory();
	const appInsights = useAppInsightsContext();
	const trackCustomEvents = useTrackEvent(reactPlugin, "Selected Card is");
	const trackCustomMatric = useTrackMetric(
		reactPlugin,
		"Select Metric data Card"
	);

	useEffect(() => {
		trackCustomEvents({ name: props.cardAction.to });
		// trackCustomMatric(props.cardAction.to);
	}, []);

	const onChangeLink = () => {
		console.log("propertis to track", props.cardAction.to);
		trackCustomEvents({ name: props.cardAction.to });
		trackCustomMatric({ name: props.cardAction.to, average: 10 });
		// appInsights.trackEvent({
		// 	name: "Selected Card in Dashboard",
		// 	properties: props.cardAction.to,
		// });
		if (props.cardAction.to == "business-scenario-services") {
			dispatch({ type: "SELECT_TAB", payload: "business-scenario" });
			history(`/dashboard/business-scenario-services/business-scenario`);
		} else if (props.cardAction.to == "services") {
			dispatch({ type: "SELECT_TAB", payload: "business-scenario-services" });
			history(`/dashboard/business-scenario-services/services`);
		} else if (props.cardAction.to == "business-classes") {
			dispatch({ type: "SELECT_TAB", payload: "classes" });
			history(`/dashboard/business-scenario-services/classes`);
		} else if (props.cardAction.to == "business-data-types") {
			dispatch({ type: "SELECT_TAB", payload: "data-type" });
			history(`/dashboard/business-scenario-services/data-type`);
		} else if (props.cardAction.to == "business-enumeration") {
			dispatch({ type: "SELECT_TAB", payload: "enumerations" });
			history(`/dashboard/business-scenario-services/enumerations`);
		} else if (props.cardAction.to == "business-primitive-datatypes") {
			dispatch({ type: "SELECT_TAB", payload: "primitive-type" });
			history(`/dashboard/business-scenario-services/primitive-type`);
		}
	};

	const selectTab = () => {
		if (props.cardAction.to == "business-scenario-services") {
			return `/dashboard/business-scenario-services/business-scenario`;
		} else if (props.cardAction.to == "services") {
			return `/dashboard/business-scenario-services/services`;
		} else if (props.cardAction.to == "business-classes") {
			return `/dashboard/business-scenario-services/classes`;
		} else if (props.cardAction.to == "business-data-types") {
			return `/dashboard/business-scenario-services/data-type`;
		} else if (props.cardAction.to == "business-enumeration") {
			return `/dashboard/business-scenario-services/enumerations`;
		} else if (props.cardAction.to == "business-primitive-datatypes") {
			return `/dashboard/business-scenario-services/primitive-type`;
		}
	};

	return (
		<Card style={{ boxShadow: "0 4px 24px 0 rgba(34,41,47,.1)" }}>
			<CardHeader
				style={{ minHeight: "74px" }}
				avatar={
					<Avatar
						sx={{ bgcolor: props.cardHeader.bgcolor }}
						aria-label={props.cardHeader.title}>
						{props.cardHeader.avatarText}
					</Avatar>
				}
				title={props.cardHeader.title}
				subheader={props.cardHeader.subheader}
			/>
			<Divider />
			<CardActions disableSpacing>
				{/* {
                    props.cardAction.to == 'services' ?
                        dispatch({ type: "SELECT_TAB", payload: 'business-scenario-services' })
                        : null

                } */}
				<Link
					onClick={() => onChangeLink()}
					// to={props.cardAction.to != 'business-object' ? 'business-scenario-services' : props.cardAction.to}
					to={`/dashboard/business-scenario-services/${props.cardAction.redirect}`}
					style={{ display: "flex", color: "grey", alignItems: "center" }}>
					<Typography style={{ fontSize: "14px" }}>
						{props.cardAction.toText}
					</Typography>
					<ArrowForwardIcon style={{ fontSize: "16px", marginLeft: "10px" }} />
				</Link>
			</CardActions>
		</Card>
	);
}

export default DashboardCard;
