import axios from "axios";
import { SECURITY_END_POINT, FUNCTION_END_POINT } from "./endpoints";

async function getUserPasswordToken(userloginDetail, jwtToken) {
  return await axios
    .post(SECURITY_END_POINT + "Users/PasswordResetToken", userloginDetail, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwtToken,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        var response = res.data;

        return {
          status: true,
          response,
        };
      }
    })
    .catch(function (error) {
      try {
        if (error?.response?.status === 400) {
          return {
            status: false,
            error: error.response.data.message,
            message: error.response.data.message,
            code: error.response.status,
            messageCode: 1,
          };
        }
      } catch {}
      return {
        status: false,
        error: "Token Authentication Failed",
        message: "Token Authentication Failed",
        code: 500,
        messageCode: 2,
      };
    });
}

async function passwordResetMail(userData, jwtToken) {
  return await axios
    .post(
      SECURITY_END_POINT + "SendPasswordResetMail",
      { ...userData, appName: "Certification" },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwtToken,
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        var response = res.data;
        return {
          status: true,
          response,
        };
      }
    })
    .catch(function (error) {
      try {
        if (error?.response?.status === 400) {
          return {
            status: false,
            code: error.response.status,
            message: error.response.data.message,
            messageCode: 1,
          };
        }
      } catch {}
      return {
        status: false,
        code: 500,
        message: "User Send Password Reset Mail Failed",
        messageCode: 2,
      };
    });
}

export { getUserPasswordToken, passwordResetMail };
