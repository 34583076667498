import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";

import { useNavigate as useHistory } from "react-router-dom";
import {
  getUserPasswordToken,
  passwordResetMail,
} from "../../utility/api/authentication";
import { LoadingButton } from "@mui/lab";

function AddUser() {
  const [organisation, setOrganisation] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    password: "",
    emailAddress: "",
    phoneNumber: "",
    organisation: "",
    role: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState("");
  let history = useHistory();
  const [alertEmail, setAlertEmail] = useState({
    status: false,
    severity: "",
    msg: "",
  });
  const [alertPhone, setAlertPhone] = useState({
    status: false,
    severity: "",
    msg: "",
  });

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOrgChange = (event) => {
    setUser({ ...user, organisation: event.target.value });
  };

  const handleRoleChange = (event) => {
    setUser({ ...user, role: event.target.value });
  };

  const handleEmailChange = (event) => {
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let Email = event.target.value.trimLeft();
    Email != ""
      ? Email.match(mailformat)
        ? setAlertEmail({
            severity: "success",
            status: true,
            msg: "Email format is correct",
          })
        : setAlertEmail({
            severity: "error",
            status: true,
            msg: "Enter Correct Mail!",
          })
      : setAlertEmail({
          severity: "error",
          status: true,
          msg: "Fill email first!",
        });
    setUser({
      ...user,
      emailAddress: event.target.value,
      username: event.target.value,
    });
  };

  const getOrganizationById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "OrganisationByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "OrganisationByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        return response.data;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoleById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "RoleByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "RoleByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        var array = [];
        array.push(response.data);
        console.log("array is", array);
        return array;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  const getUserData = async () => {
    var userData = {
      userName: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      organisation: await getOrganizationById(user.organisation),
      roles: await getRoleById(user.role),
      status: "New",
      origin: "ApprovalApp",
    };
    return userData;
  };

  async function passwordResetMethod(user_detail) {
    // Password reset method functionality and then send generated format to passwordResetMail
    const fetched_user_detail = await getUserPasswordToken(
      {
        username: user_detail.userName,
        password: user_detail.password,
      },
      localStorage.getItem("jwtToken")
    );

    if (fetched_user_detail.status === true) {
      const passwordResetResponse = await passwordResetMail(
        {
          fromDisplayName: "BIAN Approval App",
          to: fetched_user_detail.response.emailAddress,
          subject: "Password Reset Request",
          userUID: fetched_user_detail.response.uid,
          resetToken: fetched_user_detail.response.passwordResetToken.token,
          new: true,
          hostUrl: `${window.location.protocol}//${window.location.host}`,
        },
        localStorage.getItem("jwtToken")
      );
      console.log(`window.location.hostname ${window.location.host}`);

      return { ...passwordResetResponse, responseCode: "mail" };
    } else {
      return { ...fetched_user_detail, responseCode: "token" };
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSuccessText("");
    setSuccess(false);
    console.log(event);
    const userData = await getUserData();
    console.log("userData is ", userData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "User/");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "User/");

    await axios
      .post(axiosUrl, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(async (response) => {
        // If request is good...

        console.log("response.data {register}", response.data);
        if (response.data.includes("already")) {
          setError(true);
          setErrorText(response.data);
        } else {
          setError(false);

          // Send reset password token
          const passwordResetMethodResponse = await passwordResetMethod(
            userData
          );
          if (passwordResetMethodResponse.status) {
            setSuccessText("Registration successful.");
            setSuccess(true);
            // send BIAN request
            await axios
              .post(
                process.env.REACT_APP_NODE_CONFLUENCE_API +
                  "api/user/invite-by-emails",
                JSON.stringify({
                  emails: [userData.emailAddress],
                }),
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Basic ${process.env.REACT_APP_CONFLUENCE_API_KEY}`,
                  },
                }
              )
              .then((res) => {
                setIsLoading(false);
                setSuccessText("Request sent");
                setSuccess(true);
                setError(false);
                history("/manage/users");
              })
              .catch((error) => {
                setError(true);
                setIsLoading(false);
                setErrorText("Unable to send confluence request ");
                console.log(" error.response :", error.response);
                console.log(" error.response.data :", error.response.data);
              });
          } else {
            setError(true);
            setIsLoading(false);
            setErrorText("Unable to send reset password mail " + error.message);
          }

          // end of  Send reset password token

          //To get user details from organization to send approval email
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          // Request made and server responded
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.data +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add User " + error.message);
        }
      });
  };

  const getOrganization = async () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Organisations");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Organisations");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setOrganisation(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoles = async () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Roles");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Roles");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setRoles(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  useEffect(() => {
    getOrganization();
    getRoles();
    setUserDetails(JSON.parse(localStorage.getItem("tokenDetails")));
  }, []);

  return (
    <Box style={{ overflow: "auto", height: "65vh" }}>
      <Container sx={{ pt: 4 }}>
        <Card>
          <CardHeader subheader="* Fill the required fields" title="Add User" />
          <Divider />
          {organisation.length > 0 ? (
            <form noValidate onSubmit={handleSubmit}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <Box style={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="username"
                    label="User Name"
                    name="userName"
                    autoFocus
                    value={user.userName}
                    onChange={handleInputChange}
                  />
                </Box> */}
                <Box sx={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="emailAddress"
                    label="Email Address"
                    name="emailAddress"
                    autoComplete="emailAddress"
                    value={user.emailAddress}
                    onChange={handleEmailChange}
                  />
                  {alertEmail.status && (
                    <Alert severity={alertEmail.severity}>
                      {alertEmail.msg}
                    </Alert>
                  )}
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoFocus
                    value={user.firstName}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                    value={user.lastName}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={user.password}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    name="phoneNumber"
                    label="Phone Number"
                    type="text"
                    id="phoneNumber"
                    value={user.phoneNumber}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "20px 10px", flex: "1" }}>
                  <FormControl fullWidth>
                    <InputLabel id="selectOrg">Organisation</InputLabel>
                    <Select
                      labelId="selectOrg"
                      id="demo-simple-select-org"
                      value={user.organisation}
                      label="Organisation"
                      onChange={handleOrgChange}
                    >
                      {organisation.map((org) => (
                        <MenuItem value={org.uid} key={org.uid}>
                          <strong>{org.name}</strong>{" "}
                          {org.phoneNumber && (
                            <p style={{ margin: "0px 4px" }}>
                              {" "}
                              Phone: {org.phoneNumber}
                            </p>
                          )}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ padding: "20px 10px", flex: "1" }}>
                  <FormControl fullWidth>
                    <InputLabel id="selectRole">Role</InputLabel>
                    <Select
                      labelId="selectRole"
                      id="demo-simple-select-role"
                      value={user.role}
                      label="Role"
                      onChange={handleRoleChange}
                    >
                      {roles.map((role) => (
                        <MenuItem value={role.uid} key={role.uid}>
                          <strong>{role.name}</strong>{" "}
                          {role.phoneNumber && (
                            <p style={{ margin: "0px 4px" }}>
                              {" "}
                              Phone: {role.phoneNumber}
                            </p>
                          )}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              {isLoading ? (
                <LoadingButton
                  loading
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  Submit
                </LoadingButton>
              ) : (
                  userDetails == null ? (
                    <></>
                  ) : (
                    userDetails != null &&
                    userDetails.OrganisationName == "BIAN"
                  )
                ) ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ padding: "10px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      Register User
                    </Button>
                  </Box>
                </Box>
              ) : userDetails.Role == "ToolAdministrator" ||
                userDetails.Role == "UserAdministrator" ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ padding: "10px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      Register User
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ padding: "10px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      disabled={true}
                    >
                      Register User
                    </Button>
                  </Box>
                </Box>
              )}

              {/* <FormControlLabel
                                   control={<Checkbox value="remember" color="primary" />}
                                   label="Remember me"
                              /> */}
            </form>
          ) : (
            <LinearProgress color="primary" />
          )}
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorText}
            </Alert>
          )}
          {success && (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {successText}
            </Alert>
          )}
        </Card>
      </Container>
    </Box>
  );
}

export default AddUser;
