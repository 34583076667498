import { Box, Typography } from "@mui/material";
import React from "react";

const QnAFooter = () => {
	return (
		<Box
			sx={{
				width: "100%",
				height: "100px",
				bgcolor: "black",
				p: "1.5rem",
			}}>
			<Typography variant="h6" color={"secondary.light"}>
				QnA{" "}
			</Typography>
		</Box>
	);
};

export default QnAFooter;
