import React, { useState, useEffect } from "react";
import {
	Container,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Divider,
	Skeleton,
	Stack,
	Box,
	Typography,
	Chip,
	Button,
	IconButton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import Add from "@mui/icons-material/Add";

import { makeStyles } from "@mui/styles";
import Searcharea from "../../utils/Searcharea";

import { reactPlugin } from "../../../AppInsights";

import {
	useAppInsightsContext,
	useTrackMetric,
	useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "calc(100vh - 150px)",
		overflow: "hidden",
		overflowY: "scroll",
	},
	item: {
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		padding: "15px 0px",
	},
	icons: {},
	path: {
		paddingLeft: "10px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	formControl: { width: "100%", height: "" },
}));

function Feedbacklist(props) {
	const classes = useStyles();
	const [searchText, setSearchText] = useState();
	const [applyFilter, setApplyFilter] = useState(false);
	const [activeChip, setActiveChip] = useState("All");
	const [allServiceFeedback, setAllServiceFeedback] = useState([]);
	const [serviceFeedback, setServiceFeedback] = useState([]);

	const trackCustomEvents = useTrackEvent(
		reactPlugin,
		"Selected Feedback list Bussiness Senario"
	);
	const trackCustomMatric = useTrackMetric(
		reactPlugin,
		"Select Feedback list Bussiness Senario Matric Data"
	);

	const domainStatus = ["Extended Definition", "All"];
	var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
	var showSkeleton = false;
	var radioValue = "";

	(props.skeleton === undefined || props.skeleton === true) &&
		(showSkeleton = true);

	const radioSkeletons = [];
	for (let i = 1; i <= 20; i++) {
		radioSkeletons.push(i);
	}

	function handlesearchText(text) {
		// if(text.target.value.length > 3){
		// 	console.log(">3");
		// 	setSearchText(text.target.value)
		// }
		// else if(text.target.value.length === 0){
		// 	console.log("===0");
		// 	setSearchText(text.target.value)
		// }
		// else{
		// 	console.log("else");
		// 	return
		// }
		setSearchText(text.target.value);
	}

	useEffect(() => {
		setAllServiceFeedback(props.serviceItems);
		setServiceFeedback(props.serviceItems);
	}, [props.serviceItems]);

	const handleChipChange = (passed) => {
		if (passed === "All") {
			setServiceFeedback(allServiceFeedback);
		} else {
			const extedDef = allServiceFeedback.filter(
				({ extendedDefinition }) => extendedDefinition === true
			);
			setServiceFeedback(extedDef);
		}
		// passed === "" && setApplyFilter(false);
		setActiveChip(passed);
		// props.handleChipChange(passed);
	};

	return (
		<Container className={combinedClasses}>
			{applyFilter ? (
				<Box
					style={{
						padding: "10px",
					}}>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<Typography varinat="small">Apply filter: </Typography>
						<Button
							variant="text"
							size="small"
							onClick={() => setApplyFilter(false)}>
							Hide
						</Button>
					</Box>
					<Divider style={{ margin: "6px 0" }} />
					<Box
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "start",
							flexDirection: "column",
						}}>
						{domainStatus.map((cur) => {
							return (
								<Chip
									size="small"
									style={{ margin: "10px 5px" }}
									variant={activeChip == cur ? "filled" : "outlined"}
									color="primary"
									label={cur}
									key={cur}
									onClick={() => handleChipChange(cur)}
								/>
							);
						})}
						{activeChip !== "" && (
							<Chip
								size="small"
								style={{ margin: "10px 5px" }}
								variant="filled"
								color="primary"
								label="Clear"
								onClick={() => handleChipChange("")}
								onDelete={() => handleChipChange("")}
							/>
						)}
					</Box>
				</Box>
			) : (
				<Box>
					<FormControl className={classes.formControl}>
						<div className="searchcontainer">
							<Searcharea searchText={handlesearchText} text={searchText} />
						</div>
						<Divider style={{ margin: "15px 0" }} />

						<div style={{ display: "inline" }}>
							<FormLabel id="demo-service-radio-buttons-group-label">
								Service Domain
							</FormLabel>
							<IconButton
								aria-label="filter"
								size="small"
								color={activeChip !== "" ? "danger" : "primary"}
								onClick={() => setApplyFilter(true)}
								style={{ float: "right", textAlign: "right" }}>
								<FilterListIcon size="small" />
							</IconButton>
						</div>
						<RadioGroup
							aria-labelledby="demo-service-radio-buttons-group-label"
							defaultValue="female"
							name="service-radio-buttons-group"
							onChange={(event, value) => {
								const selectedItem = serviceFeedback.filter(
									(item) => item.uid === value
								);

								trackCustomEvents({ name: selectedItem[0].name });
								trackCustomMatric({ name: selectedItem[0].name, average: 60 });
								props.handleRadioChange(value);
								radioValue = value;
								props.selectedService(
									serviceFeedback.filter(({ uid }) => uid === value)
								);
							}}>
							{serviceFeedback?.length > 0 &&
								!showSkeleton &&
								serviceFeedback.map((loop) => {
									if (searchText === undefined || searchText?.length === 0) {
										return (
											<FormControlLabel
												value={loop.uid}
												key={loop.uid}
												control={<Radio />}
												label={loop.name}
											/>
										);
									} else if (searchText) {
										return (
											<FormControlLabel
												value={loop.uid}
												key={loop.uid}
												control={<Radio />}
												label={loop.name}
												style={{
													display: loop.name
														.toLowerCase()
														.includes(searchText.toLowerCase())
														? "block"
														: "none",
												}}
											/>
										);
									} else {
										return (
											<Typography variant="label" component="label">
												No results found
											</Typography>
										);
									}
								})}
							{(serviceFeedback?.length === 0 ||
								serviceFeedback?.length === undefined ||
								serviceFeedback?.length === null ||
								showSkeleton) &&
								radioSkeletons.map((loopSkeleton, index) => (
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											paddingTop: "10px",
										}}
										key={"skeletonKey" + index}>
										<Skeleton
											variant="circular"
											width={20}
											height={20}
											sx={{ marginRight: "7px" }}
										/>
										<Skeleton
											sx={{ flexGrow: "1" }}
											height={20}
											variant="rectangular"
										/>
									</Box>
								))}
						</RadioGroup>
					</FormControl>
				</Box>
			)}
		</Container>
	);
}

export default Feedbacklist;
