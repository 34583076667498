import { AppBar, Box, Button, Toolbar } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LogoBar from '../dashboard/Header/LogoBar'
import GoBack from '../utils/GoBack'

function NavigationBar() {

    var defaultUser = {
        username: process.env.REACT_APP_DEFAULT_USERNAME,
        password: process.env.REACT_APP_DEFAULT_PASSWORD,
    };
    const [admin, setAdmin] = useState(false);
    const [jwtToken, setJwtToken] = useState("");

    const getAuthenticate = async (loginUser) => {
        localStorage.setItem("jwtToken", "");
        localStorage.setItem("firstName", "");

        let axiosUrl = "";
        process.env.REACT_APP_VERSION === "S1" &&
            (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
        process.env.REACT_APP_VERSION === "S2" &&
            (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

        await axios
            .post(axiosUrl, loginUser)
            .then((res) => {
                if (res.status === 200) {
                    var response = res.data;
                    localStorage.setItem("jwtToken", response.jwtToken);
                    localStorage.setItem("firstName", response.firstName);
                    setJwtToken(response.jwtToken);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(1);
                    console.log(error.response.data.message);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(2);
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log(3);
                    console.log("Error", error.message);
                }
            });
    };

    const getUserDetail = async () => {
        let axiosUrl = "";
        process.env.REACT_APP_VERSION === "S1" &&
            (axiosUrl =
                process.env.REACT_APP_SECURITY_S1 +
                "UserByUid/" +
                localStorage.getItem("cacheToken"));
        process.env.REACT_APP_VERSION === "S2" &&
            (axiosUrl =
                process.env.REACT_APP_SECURITY_S2 +
                "UserByUid/" +
                localStorage.getItem("cacheToken"));

        await axios
            .get(axiosUrl, {
                headers: {
                    Authorization: "Bearer " + jwtToken,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    var response = res.data;
                    if (response.roles) {
                        if (response.roles.length > 0) {
                            var crawler = 0;
                            // old admin true based on role
                            // response.roles.map((filterData) => (filterData.name == "UserAdministrator" || filterData.name == "ToolAdministrator" || filterData.name == "Administrator") && crawler++ );
                            // new admin true based on org-bian and status active
                            if (response.status != null) {
                                response.status == "active" &&
                                    response?.organisation?.name == "BIAN" &&
                                    crawler++;
                            } else {
                                crawler = 0;
                            }
                            localStorage.setItem("crawler", crawler > 0);
                            setAdmin(crawler > 0);
                        }
                    }
                }
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log(1);
                    console.log(error.response.data.message);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(2);
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log(3);
                    console.log("Error", error.message);
                }
            });
    };

    useEffect(() => {
        localStorage.setItem("crawler", false)
        // getAuthenticate(defaultUser);
    }, []);

    useEffect(() => {
        jwtToken != "" && getUserDetail();
    }, [jwtToken]);

    const pages = [
        {
            name: "Dashboard",
            path: "/dashboard",
            status: true,
        },
        // {
        //     name: "Certification",
        //     path: "/certification",
        //     status: true,
        // },
        {
            name: "Manage",
            path: "/manage/",
            status: admin,
        }
        // {
        //   name: "Users",
        //   path: "/manage/users/",
        //   status: admin,
        // },
        // {
        //   name: "Organisation",
        //   path: "/manage/organisation/",
        //   status: admin,
        // },
        // {
        //   name: "Role",
        //   path: "/manage/role/",
        //   status: admin,
        // },
    ];

    return (
        <>
            <LogoBar />
            <AppBar position="static" className="navbar__container">
                <Toolbar className="toolbar">
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {pages.map((page) => (
                            <Link to={page.path} key={page.path}>
                                <Button
                                    sx={{
                                        my: 2,
                                        color: "white",
                                        display: page.status ? "block" : "none",
                                    }}
                                >
                                    {page.name}
                                </Button>
                            </Link>
                        ))}
                    </Box>
                </Toolbar>
            </AppBar>
            <GoBack display={window.location.pathname === "/home" ? "none" : "block"} />
        </>
    )
}

export default NavigationBar