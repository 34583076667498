import { GET_ORGANISATION } from "./type";

const initialState = {
  organisationData: [],
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANISATION:
      return {
        ...state,
        organisationData: action.payload,
      };
    default:
      return state;
  }
}
