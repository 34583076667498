export function hideHeader(path, pathConsist) {
  if (
    path.length > 0 &&
    path.trim().length > 0 &&
    pathConsist.length > 0 &&
    pathConsist.trim().length > 0
  ) {
    if (document.getElementsByClassName("navbar__container").length > 0) {
      path.includes(pathConsist) &&
        (document.getElementsByClassName("navbar__container")[0].style.display =
          "none");
    }

    if (document.getElementsByClassName("search-container").length > 0) {
      path.includes(pathConsist) &&
        (document.getElementsByClassName("search-container")[0].style.display =
          "none");
    }
  }
}

export function generateRandomNumber(range, withHyphen) {
  let selectAnyOne = [];
  if (withHyphen) {
    selectAnyOne = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-"];
  } else {
    selectAnyOne = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
  }
  let returnString = "";
  for (let i = 0; i < range; i++) {
    let randomNum = Math.floor(Math.random() * selectAnyOne.length);
    returnString += selectAnyOne[randomNum];
  }
  return returnString;
}

// ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
export function letters() {
  const caps = [...Array(26)].map((val, i) => String.fromCharCode(i + 65));
  return caps.concat(caps.map((letter) => letter.toLowerCase()));
}

export function generateRandomString(range, withHyphen) {
  let selectAnyOne = letters();
  withHyphen && selectAnyOne.push("-");
  let returnString = "";
  for (let i = 0; i < range; i++) {
    let randomNum = Math.floor(Math.random() * selectAnyOne.length);
    returnString += selectAnyOne[randomNum];
  }
  return returnString;
}
