import React, { useEffect, useState } from "react";
import { useNavigate as useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function EditUser() {
  const [organisation, setOrganisation] = useState([]);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState({
    uid: "",
    userName: "",
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phoneNumber: "",
    organisation: "",
    role: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let history = useHistory();
  let { userId } = useParams();

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOrgChange = (event) => {
    setUser({ ...user, organisation: event.target.value });
  };

  const handleRoleChange = (event) => {
    setUser({ ...user, role: event.target.value });
  };

  const loadUser = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserByUid/" + uid);

    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log("If request is good... ", response.data);
        setError(false);
        setUser(setUserData(response.data));
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };
  useEffect(() => {
    loadUser(userId);
  }, []);

  const getOrganizationById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "OrganisationByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "OrganisationByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        return response.data;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoleById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "RoleByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "RoleByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        return response.data;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  const getUserData = async () => {
    var userData = {
      uid: userId,
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      email: user.email,
      phoneNumber: user.phoneNumber,
      organisation: await getOrganizationById(user.organisation),
      roles: [await getRoleById(user.role)],
    };
    return userData;
  };

  const setUserData = (userDetail) => {
    return {
      uid:
        userDetail.uid !== "string" &&
          userDetail.uid !== null &&
          userDetail.uid !== undefined
          ? userDetail.uid
          : "",
      userName:
        userDetail.userName !== "string" &&
          userDetail.userName !== null &&
          userDetail.userName !== undefined
          ? userDetail.userName
          : "",
      firstName:
        userDetail.firstName !== "string" &&
          userDetail.firstName !== null &&
          userDetail.firstName !== undefined
          ? userDetail.firstName
          : "",
      lastName:
        userDetail.lastName !== "string" &&
          userDetail.lastName !== null &&
          userDetail.lastName !== undefined
          ? userDetail.lastName
          : "",
      email:
        userDetail.emailAddress !== "string" &&
          userDetail.emailAddress !== null &&
          userDetail.emailAddress !== undefined
          ? userDetail.emailAddress
          : "",
      phoneNumber:
        userDetail.phoneNumber !== "string" &&
          userDetail.phoneNumber !== null &&
          userDetail.phoneNumber !== undefined
          ? userDetail.phoneNumber
          : "",
      password:
        userDetail.password !== "string" &&
          userDetail.password !== null &&
          userDetail.password !== undefined
          ? userDetail.password
          : "",
      organisation:
        userDetail.organisation !== "string" &&
          userDetail.organisation?.uid !== null &&
          userDetail.organisation?.uid !== undefined
          ? userDetail.organisation.uid
          : "",
      role: [
        userDetail.roles[0]?.uid !== "string" &&
          userDetail.roles[0]?.uid !== null &&
          userDetail.roles[0]?.uid !== undefined
          ? userDetail.roles[0].uid
          : "",
      ],
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    const userData = await getUserData();
    console.log("userData is ", userData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserUpdate/" + userData);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserUpdate/" + userData);

    await axios
      .put(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        history("/users");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(" error.response :", error.response);
          console.log(" error.response.data :", error.response.data);
          console.log(" error.response.status :", error.response.status);
          console.log(" error.response.headers :", error.response.headers);
          setError(true);
          setErrorText(
            error.response.status +
            " " +
            error.response.statusText +
            ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add User " + error.message);
        }
      });
  };

  const getOrganization = async () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Organisations");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Organisations");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setOrganisation(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoles = async () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Roles");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Roles");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setRoles(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  useEffect(() => {
    getOrganization();
    getRoles();
  }, []);

  return (
    <Box>
      <Container style={{ paddingTop: "30px" }}>
        <Card style={{ padding: "15px" }}>
          <Typography>Edit User {user.firstName}</Typography>
          <Divider style={{ margin: "8px 0" }} />
          {organisation.length > 0 ? (
            <form noValidate onSubmit={handleSubmit}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="username"
                    label="User Name"
                    name="userName"
                    autoFocus
                    value={user.userName}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={user.email}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoFocus
                    value={user.firstName}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                    value={user.lastName}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={user.password}
                    onChange={handleInputChange}
                  />
                </Box>
                <Box sx={{ padding: "10px", flex: "1" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    name="phoneNumber"
                    label="Phone Number"
                    type="text"
                    id="phoneNumber"
                    value={user.phoneNumber}
                    onChange={handleInputChange}
                  />
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "20px 10px", flex: "1" }}>
                  <FormControl fullWidth>
                    <InputLabel id="selectOrg">Organisation</InputLabel>
                    <Select
                      labelId="selectOrg"
                      id="demo-simple-select-org"
                      value={user.organisation}
                      label="Organisation"
                      onChange={handleOrgChange}
                    >
                      {organisation.map((org) => (
                        <MenuItem value={org.uid} key={org.uid}>
                          <strong>{org.name}</strong>{" "}
                          {org.phoneNumber && (
                            <p style={{ margin: "0px 4px" }}>
                              {" "}
                              Phone: {org.phoneNumber}
                            </p>
                          )}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ padding: "20px 10px", flex: "1" }}>
                  <FormControl fullWidth>
                    <InputLabel id="selectRole">Role</InputLabel>
                    <Select
                      labelId="selectRole"
                      id="demo-simple-select-role"
                      value={user.role}
                      label="Role"
                      onChange={handleRoleChange}
                    >
                      {roles.map((role) => (
                        <MenuItem value={role.uid} key={role.uid}>
                          <strong>{role.name}</strong>{" "}
                          {role.phoneNumber && (
                            <p style={{ margin: "0px 4px" }}>
                              {" "}
                              Phone: {role.phoneNumber}
                            </p>
                          )}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Update
                  </Button>
                </Box>
              </Box>

              {/* <FormControlLabel
                                   control={<Checkbox value="remember" color="primary" />}
                                   label="Remember me"
                              /> */}
            </form>
          ) : (
            <LinearProgress color="primary" />
          )}
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorText}
            </Alert>
          )}
        </Card>
      </Container>
    </Box>
  );
}

export default EditUser;
