import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";

const AccountProfile = ({ details, callbackFunction }) => {
  const [user, setUser] = useState({
    uid: "",
    userName: "",
    firstName: "",
    lastName: "",
    password: "",
    description: "",
    emailAddress: "",
    phoneNumber: "",
    organisation: "",
    role: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let { userId } = useParams();

  const setUserData = (userDetail) => {
    return {
      uid:
        userDetail.uid !== "string" &&
          userDetail.uid !== null &&
          userDetail.uid !== undefined
          ? userDetail.uid
          : "",
      userName:
        userDetail.userName !== "string" &&
          userDetail.userName !== null &&
          userDetail.userName !== undefined
          ? userDetail.userName
          : "",
      firstName:
        userDetail.firstName !== "string" &&
          userDetail.firstName !== null &&
          userDetail.firstName !== undefined
          ? userDetail.firstName
          : "",
      lastName:
        userDetail.lastName !== "string" &&
          userDetail.lastName !== null &&
          userDetail.lastName !== undefined
          ? userDetail.lastName
          : "",
      emailAddress:
        userDetail.emailAddress !== "string" &&
          userDetail.emailAddress !== null &&
          userDetail.emailAddress !== undefined
          ? userDetail.emailAddress
          : "",
      phoneNumber:
        userDetail.phoneNumber !== "string" &&
          userDetail.phoneNumber !== null &&
          userDetail.phoneNumber !== undefined
          ? userDetail.phoneNumber
          : "",
      password:
        userDetail.password !== "string" &&
          userDetail.password !== null &&
          userDetail.password !== undefined
          ? userDetail.password
          : "",
      description:
        userDetail.description !== "string" &&
          userDetail.description !== null &&
          userDetail.description !== undefined
          ? userDetail.description
          : "",
      organisation:
        userDetail.organisation !== "string" &&
          userDetail.organisation?.uid !== null &&
          userDetail.organisation?.uid !== undefined
          ? userDetail.organisation.uid
          : "",
      role: [
        userDetail.roles[0]?.uid !== "string" &&
          userDetail.roles[0]?.uid !== null &&
          userDetail.roles[0]?.uid !== undefined
          ? userDetail.roles[0].uid
          : "",
      ],
    };
  };

  const loadUser = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserByUid/" + uid);
    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        setError(false);
        setUser(setUserData(response.data));
        callbackFunction();
      })
      .catch((error) => {
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };
  useEffect(() => {
    loadUser(userId);
    console.log(
      "If request is good... callbackFunction is executed and useEffect is executed",
      details
    );
    console.log("user  =>", user);
    console.log("user.emailAddress  =>", user.emailAddress);
    console.log("user.emailAddress  =>", user.emailAddress);
  }, [details]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            src={user.avatar}
            sx={{
              height: 64,
              mb: 2,
              width: 64,
            }}
          />

          {user.firstName && user.firstName ? (
            <Typography color="textPrimary" gutterBottom variant="h5">
              {user.firstName} {user.lastName}
            </Typography>
          ) : (
            <Typography color="textPrimary" gutterBottom variant="h5">
              {user.userName}
            </Typography>
          )}
          {user.description && (
            <Typography color="textSecondary" variant="body2">
              {user.description}
            </Typography>
          )}
          {user.emailAddress !== undefined && (
            <Typography color="textSecondary" variant="body2">
              {user.emailAddress}
            </Typography>
          )}
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};

export default AccountProfile;
