import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import ".././../../assets/css/form.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

function ModelFormEdit(props) {
  var trimData = "";
  if (props.data?.hasOwnProperty("title")) {
    trimData = props.data.title.trim();
  } else {
    trimData = "";
  }

  const [formdata, setFormdata] = useState({});
  const [commentCount, setCommentCount] = useState(0);
  const [commentInput, setCommentInput] = useState("");
  const [commentEdit, setCommentEdit] = useState({
    cid: "",
    content: "",
    status: false,
  });
  const [handleComment, setHandleComment] = useState([]);
  useEffect(() => {
    setFormdata({
      id: props.data.id,
      uid: props.data.editId,
      editId: props.data.editId,
      buid: props.data.buid,
      title: props.data.title,
      description: props.data.description,
      status: props.data.status,
      data_type: props.data.data_type,
      type1: props.data.type1,
      type2: props.data.type2,
      type3: props.data.type3,
      level: props.data.level,
      comments: props.data.comments != null ? props.data.comments : [],
    });
    props.data.comments != null && setCommentCount(props.data.comments.length);
    props.data.comments != null && setHandleComment(props.data.comments);
  }, [props.data]);

  function handleformChange(event) {
    var newDta = { ...formdata };
    var inputName = event.target.name;
    if (inputName === "title") {
      newDta["title"] = event.target.value;
    } else if (inputName === "description") {
      newDta["description"] = event.target.value;
    } else if (inputName === "status") {
      newDta["status"] = event.target.value;
    } else if (inputName === "data_type") {
      newDta["data_type"] = event.target.value;
    } else {
    }
    setFormdata(newDta);
  }

  function handleformSubmit(e) {
    e.preventDefault();
    var getLocalStorage = JSON.parse(localStorage.getItem("feedbacktree"));
    console.log("getLocalStorage starts is", getLocalStorage);
    console.log("formdata is", formdata);
    if (formdata.level === "level1") {
      if (formdata.type1 === "characteristics") {
        getLocalStorage.characteristics.name = formdata.title;
        getLocalStorage.characteristics.description = formdata.description;
        getLocalStorage.characteristics.status = formdata.status;
        getLocalStorage.characteristics.data_type = formdata.data_type;
        if (getLocalStorage.characteristics?.hasOwnProperty("metaLight")) {
          if (
            getLocalStorage.characteristics.metaLight?.hasOwnProperty(
              "comments"
            )
          ) {
            getLocalStorage.characteristics.metaLight.comments =
              formdata.comments;
          } else {
            getLocalStorage.characteristics.metaLight = {
              comments: formdata.comments,
            };
          }
        } else {
          getLocalStorage.characteristics["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else if (formdata.type1 === "businessArea") {
        getLocalStorage.businessArea.name = formdata.title;
        getLocalStorage.businessArea.description = formdata.description;
        getLocalStorage.businessArea.status = formdata.status;
        getLocalStorage.businessArea.data_type = formdata.data_type;
        if (getLocalStorage.businessArea?.hasOwnProperty("metaLight")) {
          if (
            getLocalStorage.businessArea.metaLight?.hasOwnProperty("comments")
          ) {
            getLocalStorage.businessArea.metaLight.comments = formdata.comments;
          } else {
            getLocalStorage.businessArea.metaLight = {
              comments: formdata.comments,
            };
          }
        } else {
          getLocalStorage.businessArea["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else if (formdata.type1 === "businessDomain") {
        getLocalStorage.businessDomain.name = formdata.title;
        getLocalStorage.businessDomain.description = formdata.description;
        getLocalStorage.businessDomain.status = formdata.status;
        getLocalStorage.businessDomain.data_type = formdata.data_type;
        if (getLocalStorage.businessDomain?.hasOwnProperty("metaLight")) {
          if (
            getLocalStorage.businessDomain.metaLight?.hasOwnProperty("comments")
          ) {
            getLocalStorage.businessDomain.metaLight.comments =
              formdata.comments;
          } else {
            getLocalStorage.businessDomain.metaLight = {
              comments: formdata.comments,
            };
          }
        } else {
          getLocalStorage.businessDomain["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else if (formdata.type1 === "controlRecord") {
        getLocalStorage.controlRecord.name = formdata.title;
        getLocalStorage.controlRecord.description = formdata.description;
        getLocalStorage.controlRecord.status = formdata.status;
        getLocalStorage.controlRecord.data_type = formdata.data_type;
        if (getLocalStorage.controlRecord?.hasOwnProperty("metaLight")) {
          if (
            getLocalStorage.controlRecord.metaLight?.hasOwnProperty("comments")
          ) {
            getLocalStorage.controlRecord.metaLight.comments =
              formdata.comments;
          } else {
            getLocalStorage.controlRecord.metaLight = {
              comments: formdata.comments,
            };
          }
        } else {
          getLocalStorage.controlRecord["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else {
        console.log("level1 type1 not matching", formdata.type1);
      }
    } else if (formdata.level === "level2") {
      if (formdata.type1 === "controlRecord") {
        var objIndex = getLocalStorage.controlRecord.attributes.findIndex(
          (obj) => obj.uid == formdata.uid
        );
        console.log("objIndex is", objIndex);
        getLocalStorage.controlRecord.attributes[objIndex].name =
          formdata.title;
        getLocalStorage.controlRecord.attributes[objIndex].description =
          formdata.description;
        getLocalStorage.controlRecord.attributes[objIndex].status =
          formdata.status;
        getLocalStorage.controlRecord.attributes[objIndex].data_type =
          formdata.data_type;
        if (
          getLocalStorage.controlRecord.attributes[objIndex]?.hasOwnProperty(
            "metaLight"
          )
        ) {
          console.log("metalight is there");
          if (
            getLocalStorage.controlRecord.attributes[objIndex].metaLight != null
          ) {
            if (
              getLocalStorage.controlRecord.attributes[
                objIndex
              ].metaLight?.hasOwnProperty("comments")
            ) {
              console.log("metalight and comment is there");
              getLocalStorage.controlRecord.attributes[
                objIndex
              ].metaLight.comments = formdata.comments;
            } else {
              console.log("metalight and comment is not there");
              getLocalStorage.controlRecord.attributes[objIndex].metaLight[
                "comments"
              ] = formdata.comments;
            }
          } else {
            console.log("metalight is null");
            getLocalStorage.controlRecord.attributes[objIndex]["metaLight"] = {
              comments: formdata.comments,
            };
          }
        } else {
          console.log("metalight is not there");
          getLocalStorage.controlRecord.attributes[objIndex]["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else if (formdata.type1 === "behaviourQualifiers") {
        var objIndex = getLocalStorage.behaviourQualifiers.findIndex(
          (obj) => obj.uid == formdata.uid
        );
        console.log("objIndex is", objIndex);
        getLocalStorage.behaviourQualifiers[objIndex].name = formdata.title;
        getLocalStorage.behaviourQualifiers[objIndex].description =
          formdata.description;
        getLocalStorage.behaviourQualifiers[objIndex].status = formdata.status;
        getLocalStorage.behaviourQualifiers[objIndex].data_type =
          formdata.data_type;
        if (
          getLocalStorage.behaviourQualifiers[objIndex]?.hasOwnProperty(
            "metaLight"
          )
        ) {
          if (
            getLocalStorage.behaviourQualifiers[
              objIndex
            ].metaLight?.hasOwnProperty("comments")
          ) {
            getLocalStorage.behaviourQualifiers[objIndex].metaLight.comments =
              formdata.comments;
          } else {
            getLocalStorage.behaviourQualifiers[objIndex].metaLight[
              "comments"
            ] = formdata.comments;
          }
        } else {
          getLocalStorage.behaviourQualifiers[objIndex]["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else if (formdata.type1 === "serviceOperations") {
        var objIndex = getLocalStorage.serviceOperations.findIndex(
          (obj) => obj.uid == formdata.uid
        );
        console.log("objIndex is", objIndex);
        getLocalStorage.serviceOperations[objIndex].name = formdata.title;
        getLocalStorage.serviceOperations[objIndex].description =
          formdata.description;
        getLocalStorage.serviceOperations[objIndex].status = formdata.status;
        getLocalStorage.serviceOperations[objIndex].data_type =
          formdata.data_type;
        if (
          getLocalStorage.serviceOperations[objIndex]?.hasOwnProperty(
            "metaLight"
          )
        ) {
          if (
            getLocalStorage.serviceOperations[
              objIndex
            ].metaLight?.hasOwnProperty("comments")
          ) {
            getLocalStorage.serviceOperations[objIndex].metaLight.comments =
              formdata.comments;
          } else {
            getLocalStorage.serviceOperations[objIndex].metaLight["comments"] =
              formdata.comments;
          }
        } else {
          getLocalStorage.serviceOperations[objIndex]["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else if (formdata.type1 === "businessScenarios") {
        var objIndex = getLocalStorage.businessScenarios.findIndex(
          (obj) => obj.uid == formdata.uid
        );
        console.log("objIndex is", objIndex);
        getLocalStorage.businessScenarios[objIndex].name = formdata.title;
        getLocalStorage.businessScenarios[objIndex].description =
          formdata.description;
        getLocalStorage.businessScenarios[objIndex].status = formdata.status;
        getLocalStorage.businessScenarios[objIndex].data_type =
          formdata.data_type;
        if (
          getLocalStorage.businessScenarios[objIndex]?.hasOwnProperty(
            "metaLight"
          )
        ) {
          if (
            getLocalStorage.businessScenarios[
              objIndex
            ].metaLight?.hasOwnProperty("comments")
          ) {
            getLocalStorage.businessScenarios[objIndex].metaLight.comments =
              formdata.comments;
          } else {
            getLocalStorage.businessScenarios[objIndex].metaLight = {
              comments: formdata.comments,
            };
          }
        } else {
          getLocalStorage.businessScenarios[objIndex]["metaLight"] = {
            comments: formdata.comments,
          };
        }
      } else {
        console.log("level2 type not found", formdata.type1);
      }
    } else if (formdata.level === "level3") {
      if (formdata.type1 === "serviceOperations") {
        if (formdata.type2 === "inputAttributes") {
          var objIndex1 = getLocalStorage.serviceOperations.findIndex(
            (obj) => obj.uid == formdata.type3
          );
          var objIndex = getLocalStorage.serviceOperations[
            objIndex1
          ].inputAttributes.findIndex((obj) => obj.uid == formdata.editId);
          console.log("objIndex is", objIndex);
          console.log("objIndex1 is", objIndex1);
          getLocalStorage.serviceOperations[objIndex1].inputAttributes[
            objIndex
          ].name = formdata.title;
          getLocalStorage.serviceOperations[objIndex1].inputAttributes[
            objIndex
          ].description = formdata.description;
          getLocalStorage.serviceOperations[objIndex1].inputAttributes[
            objIndex
          ].status = formdata.status;
          getLocalStorage.serviceOperations[objIndex1].inputAttributes[
            objIndex
          ].data_type = formdata.data_type;
          if (
            getLocalStorage.serviceOperations[objIndex1].inputAttributes[
              objIndex
            ]?.hasOwnProperty("metaLight")
          ) {
            if (
              getLocalStorage.serviceOperations[objIndex1].inputAttributes[
                objIndex
              ].metaLight != null
            ) {
              if (
                getLocalStorage.serviceOperations[objIndex1].inputAttributes[
                  objIndex
                ].metaLight?.hasOwnProperty("comments")
              ) {
                getLocalStorage.serviceOperations[objIndex1].inputAttributes[
                  objIndex
                ].metaLight.comments = formdata.comments;
              } else {
                getLocalStorage.serviceOperations[objIndex1].inputAttributes[
                  objIndex
                ]["metaLight"].comments = formdata.comments;
              }
            } else {
              getLocalStorage.serviceOperations[objIndex1].inputAttributes[
                objIndex
              ]["metaLight"] = {
                comments: formdata.comments,
              };
            }
          } else {
            getLocalStorage.serviceOperations[objIndex1].inputAttributes[
              objIndex
            ]["metaLight"] = {
              comments: formdata.comments,
            };
          }
        } else if (formdata.type2 === "outputAttributes") {
          var objIndex1 = getLocalStorage.serviceOperations.findIndex(
            (obj) => obj.uid == formdata.type3
          );
          var objIndex = getLocalStorage.serviceOperations[
            objIndex1
          ].outputAttributes.findIndex((obj) => obj.uid == formdata.editId);
          console.log("objIndex is", objIndex);
          getLocalStorage.serviceOperations[objIndex1].outputAttributes[
            objIndex
          ].name = formdata.title;
          getLocalStorage.serviceOperations[objIndex1].outputAttributes[
            objIndex
          ].description = formdata.description;
          getLocalStorage.serviceOperations[objIndex1].outputAttributes[
            objIndex
          ].status = formdata.status;
          getLocalStorage.serviceOperations[objIndex1].outputAttributes[
            objIndex
          ].data_type = formdata.data_type;
          if (
            getLocalStorage.serviceOperations[objIndex1].outputAttributes[
              objIndex
            ]?.hasOwnProperty("metaLight")
          ) {
            if (
              getLocalStorage.serviceOperations[objIndex1].outputAttributes[
                objIndex
              ].metaLight != null
            ) {
              if (
                getLocalStorage.serviceOperations[objIndex1].outputAttributes[
                  objIndex
                ].metaLight?.hasOwnProperty("comments")
              ) {
                getLocalStorage.serviceOperations[objIndex1].outputAttributes[
                  objIndex
                ].metaLight.comments = formdata.comments;
              } else {
                getLocalStorage.serviceOperations[objIndex1].outputAttributes[
                  objIndex
                ]["metaLight"].comments = formdata.comments;
              }
            } else {
              getLocalStorage.serviceOperations[objIndex1].outputAttributes[
                objIndex
              ]["metaLight"] = {
                comments: formdata.comments,
              };
            }
          } else {
            getLocalStorage.serviceOperations[objIndex1].outputAttributes[
              objIndex
            ]["metaLight"] = {
              comments: formdata.comments,
            };
          }
        } else {
          console.log("type2 is unknown", formdata.type2);
        }
      } else if (formdata.type1 === "behaviourQualifiers") {
        console.log("formdata.type1", formdata.type1);
        console.log("formdata.type3", formdata.type3);
        console.log("formdata.editId", formdata.editId);
        var objIndex1 = getLocalStorage.behaviourQualifiers.findIndex(
          (obj) => obj.uid == formdata.type3
        );
        var objIndex = getLocalStorage.behaviourQualifiers[
          objIndex1
        ].attributes.findIndex((obj) => obj.uid == formdata.editId);
        console.log("objIndex is", objIndex);
        getLocalStorage.behaviourQualifiers[objIndex1].attributes[
          objIndex
        ].name = formdata.title;
        getLocalStorage.behaviourQualifiers[objIndex1].attributes[
          objIndex
        ].description = formdata.description;
        getLocalStorage.behaviourQualifiers[objIndex1].attributes[
          objIndex
        ].status = formdata.status;
        getLocalStorage.behaviourQualifiers[objIndex1].attributes[
          objIndex
        ].data_type = formdata.data_type;
        if (
          getLocalStorage.behaviourQualifiers[objIndex1].attributes[
            objIndex
          ]?.hasOwnProperty("metaLight")
        ) {
          if (
            getLocalStorage.behaviourQualifiers[objIndex1].attributes[objIndex]
              .metaLight != null
          ) {
            if (
              getLocalStorage.behaviourQualifiers[objIndex1].attributes[
                objIndex
              ].metaLight?.hasOwnProperty("comments")
            ) {
              getLocalStorage.behaviourQualifiers[objIndex1].attributes[
                objIndex
              ].metaLight.comments = formdata.comments;
            } else {
              getLocalStorage.behaviourQualifiers[objIndex1].attributes[
                objIndex
              ]["metaLight"].comments = formdata.comments;
            }
          } else {
            getLocalStorage.behaviourQualifiers[objIndex1].attributes[objIndex][
              "metaLight"
            ] = {
              comments: formdata.comments,
            };
          }
        } else {
          getLocalStorage.behaviourQualifiers[objIndex1].attributes[objIndex][
            "metaLight"
          ] = {
            comments: formdata.comments,
          };
        }
      } else {
        return;
      }
    } else {
      return;
    }

    localStorage.setItem("feedbacktree", JSON.stringify(getLocalStorage));
    console.log("getLocalStorage ends is", getLocalStorage);
    props.handleSubmit();
  }

  function handleCommentAdd(e) {
    var getOlderComments = formdata.comments;
    console.log("before getOlderComments =>", getOlderComments);
    getOlderComments.push({
      cid: "cid_" + String(Math.random()).split(".")[1],
      uid: JSON.parse(localStorage.getItem("feedbacktree")).uid,
      userId: localStorage.getItem("cacheToken"),
      content: commentInput,
      date: new Date(),
    });
    console.log("after getOlderComments =>", getOlderComments);
    setFormdata((prevState) => ({
      ...prevState,
      ["comments"]: getOlderComments,
    }));
    console.log("formdata =>", formdata);
    setCommentInput("");
    setCommentCount(formdata.comments.length);
    setHandleComment(formdata.comments);
  }

  function handleCommentChange(event) {
    setCommentInput(event.target.value);
  }

  function handleCommentEdit(cid, content) {
    if (cid && content) {
      setCommentInput(content);
      setCommentEdit({
        cid: cid,
        content: content,
        status: true,
        date: new Date(),
      });
    }
  }

  function handleCommentSave() {
    var getOlderComments = formdata.comments;
    console.log("getOlderComments =>", getOlderComments);
    var objIndex = getOlderComments.findIndex(
      (obj) => obj.cid == commentEdit.cid
    );
    console.log("objIndex =>", objIndex);
    console.log(
      " before getOlderComments[objIndex].content =>",
      getOlderComments[objIndex].content
    );
    getOlderComments[objIndex].content = commentInput;
    getOlderComments[objIndex].date = new Date();
    console.log("commentInput =>", commentInput);
    console.log(
      "afterf getOlderComments[objIndex].content =>",
      getOlderComments[objIndex].content
    );
    setFormdata((prevState) => ({
      ...prevState,
      ["comments"]: getOlderComments,
    }));
    console.log("formdata =>", formdata);
    setCommentInput("");
    setCommentEdit({
      cid: "",
      content: "",
      status: false,
      date: "",
    });
    setCommentCount(formdata.comments.length);
    setHandleComment(formdata.comments);
  }

  function handleCommentDelete(cid) {
    if (cid) {
      var getOlderComments = formdata.comments;
      var objIndex = getOlderComments.findIndex((obj) => obj.cid == cid);
      getOlderComments.splice(objIndex, 1);
      setFormdata((prevState) => ({
        ...prevState,
        ["comments"]: getOlderComments,
      }));
      setCommentCount(formdata.comments.length);
      setHandleComment(formdata.comments);
    }
  }

  useEffect(() => {
    console.log(formdata, handleComment, commentCount);
  }, [formdata, handleComment, commentCount]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        className="dialog-form"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby={"alert-dialog-slide-" + trimData}
      >
        <DialogTitle
          className="alert-dialog-title"
          style={{ padding: "16px 24px 0 24px" }}
        >
          Edit {props.data.title}
        </DialogTitle>
        {props.data.description && (
          <DialogContentText
            className="alert-dialog-text"
            style={{ padding: "0 24px 10px 24px" }}
          >
            {props.data.description}
          </DialogContentText>
        )}
        <Divider />
        <form
          onSubmit={(fsubmit) => handleformSubmit(fsubmit)}
          action=""
          id={"alert-dialog-slide-" + trimData}
        >
          <DialogContent>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Box style={{ flex: "1", padding: "10px" }}>
                <Box className="form-control">
                  <TextField
                    className="form-input"
                    fullWidth
                    label="Name"
                    margin="dense"
                    id={"outlined-title" + trimData}
                    onChange={(e) => handleformChange(e)}
                    name="title"
                    value={formdata.title ? formdata.title : ""}
                  />
                </Box>
                <Box className="form-control">
                  <TextField
                    className="form-input"
                    fullWidth
                    margin="dense"
                    multiline
                    rows={4}
                    label="Description"
                    id={"outlined-description" + trimData}
                    onChange={(e) => handleformChange(e)}
                    name="description"
                    value={formdata.description ? formdata.description : ""}
                  />
                </Box>
                <Box className="form-control">
                  <TextField
                    className="form-input"
                    fullWidth
                    label="Data Type"
                    margin="dense"
                    id={"outlined-type" + trimData}
                    onChange={(e) => handleformChange(e)}
                    name="data_type"
                    value={formdata.data_type ? formdata.data_type : ""}
                  />
                </Box>
              </Box>
              <Box style={{ flex: "1", padding: "10px" }}>
                {commentCount > 0 && (
                  <Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        sx={{ mt: 4, mb: 2 }}
                        variant="h6"
                        component="div"
                      >
                        User Comments
                      </Typography>
                      <Demo>
                        <List dense>
                          {handleComment.map((mapData) => {
                            return (
                              <ListItem key={mapData.cid}>
                                {/* props.commentUser?.length > 0 && (props.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.firstName + " h " + props.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.emailAddress) */}
                                <ListItemText primary={mapData.content} secondary={props.data?.commentUser?.length > 0 && props.data?.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.firstName ? props.data?.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.firstName : localStorage.getItem("firstName")}/>
                                <ListItemIcon>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                      handleCommentEdit(
                                        mapData.cid,
                                        mapData.content
                                      )
                                    }
                                  >
                                    <EditIcon size="small" color="info" />
                                  </IconButton>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                      handleCommentDelete(mapData.cid)
                                    }
                                  >
                                    <DeleteIcon size="small" color="danger" />
                                  </IconButton>
                                </ListItemIcon>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Demo>
                    </Grid>
                  </Grid>
                )}
                <Box className="form-control">
                  <TextField
                    className="form-input"
                    fullWidth
                    label="Comment"
                    margin="dense"
                    id={"outlined-type" + trimData}
                    onChange={(e) => handleCommentChange(e)}
                    name="comment"
                    value={commentInput}
                    size="small"
                    variant="standard"
                    color="success"
                  />
                </Box>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  {commentEdit.status ? (
                    <Button
                      size="small"
                      variant="outlined"
                      color="success"
                      endIcon={<CheckCircleIcon />}
                      onClick={handleCommentSave}
                    >
                      Save Comment
                    </Button>
                  ) : (
                    commentInput && (
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<AddIcon />}
                        onClick={handleCommentAdd}
                      >
                        Add Comment
                      </Button>
                    )
                  )}
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Alert severity="info">
                <AlertTitle>Note</AlertTitle>
                Please ensure you click the Save button on the top right —{" "}
                <strong>if you would like to save your changes!</strong>
              </Alert>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button type="submit" onClick={handleformSubmit}>
              Close
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ModelFormEdit;
