// File: ApiDocumentation.js

import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const ApiDocumentation = () => {
  const openApiSpec = require("./asyncapi.yaml"); // Adjust the path accordingly
  return (
    <div style={{ overflow: "auto", height: "100vh" }}>
      {/* <RedocStandalone specUrl={openApiSpec}
      options={{
        nativeScrollbars: true,
        scrollYOffset: 50,
        hideDownloadButton: false,
        hideHostname: false,
        expandResponses: 'all',
        requiredPropsFirst: false,
        noAutoAuth: false,
      }}
      /> */}
      <SwaggerUI url={openApiSpec} />
    </div>
  );
};

export default ApiDocumentation;
