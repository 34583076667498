import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import approvalImage from "../../assets/images/aproval.jpg";

function Unapprove() {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          marginTop: "20px",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" color="success" variant="h1">
              User Created
            </Typography>
            <Typography align="center" color="textPrimary" variant="subtitle2">
              You will be able to access the restricted functionality once the
              Primary Contact of your organisation has approved your access.
            </Typography>
            <Typography align="center" color="textPrimary" variant="subtitle2">
              {`The Primary Contact for ${localStorage.getItem(
                "org_name"
              )} is ${localStorage.getItem(
                "org_admin_name"
              )}. Their email address is: ${localStorage.getItem(
                "org_admin_email"
              )}`}
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <img
                alt="User Approval"
                src={approvalImage}
                style={{
                  marginTop: 50,
                  display: "inline-block",
                  maxWidth: "100%",
                  width: "100%",
                }}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Unapprove;
