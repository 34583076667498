import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Menu, MenuItem, TextField, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DehazeIcon from '@mui/icons-material/Dehaze';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";


function ShowMore(props) {
    const [showMoreStatus, setShowMoreStatus] = useState(true)
    const [modifyButtonAppear, setModifyButtonAppear] = useState({
        edit: false,
        delete: false,
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [showMoreData, setShowMoreData] = useState([])

    const clickHandler = (e) => {
        if (e.target !== e.currentTarget) return
        setShowMoreStatus(!showMoreStatus)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const saveItem = () => {
        setModifyButtonAppear({
            edit: false,
            delete: false,
        });
        setShowMoreStatus(false)
    };

    const handleInputChange = (event, val) => {
        console.log("handleInputChange event", event, " | and val", val)
    }

    useEffect(() => {
        var showMoreDataArray = []
        for (var property in props.otherDetails) {
            showMoreDataArray.push({
                'key': property,
                'value': props?.otherDetails[property]
            })
        }
        setShowMoreData([...showMoreDataArray])
    }, [props.otherDetails])

    return (<>
        <Divider style={{ margin: "8px 0" }} />
        <Box
            sx={{ background: 'rgb(2 136 210 / 5%)', margin: "5px", cursor: "pointer" }}
            onClick={clickHandler}
        >
            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", border: "3px solid #fff", padding: "10px" }} onClick={clickHandler}>
                <Typography variant="text" sx={{ fontSize: "14px" }} onClick={clickHandler}>
                    Show More...
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                    {props.serOrFeed === 'feed' && props.editButtonEnable && props.editButtonEnable && <Box>
                        {!modifyButtonAppear.edit ?
                            <Button size='small'
                                onClick={() => {
                                    localStorage.setItem("modifyStatus", "edit-feedback");
                                    setModifyButtonAppear({
                                        ...modifyButtonAppear,
                                        edit: true
                                    })
                                    setShowMoreStatus(false)
                                }}>Edit</Button>
                            :
                            <Button size='small' onClick={saveItem}>Save</Button>
                        }
                    </Box>}

                    {showMoreStatus
                        ? <ArrowDropDownIcon fontSize="small" sx={{ mr: 1 }} color="primary" onClick={clickHandler} />
                        : <ArrowDropUpIcon fontSize="small" sx={{ mr: 1 }} color="primary" onClick={clickHandler} />
                    }
                </Box>
            </Box>
        </Box>
        {!showMoreStatus && <Box>
            <Box
                sx={{ padding: "15px 15px 0 15px" }}
            >
                {showMoreData.map(mapData => typeof mapData.value !== 'object' && <Box
                    sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "10px", borderLeft: '1px solid #d6d6d6', gap: '10px' }}
                >
                    <Typography variant="text" sx={{ fontSize: "14px" }}>{mapData.key}</Typography>
                    <Box
                        sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                    >
                        {!modifyButtonAppear.edit ? <Typography variant="text" sx={{ fontSize: "14px" }}>{mapData?.value}</Typography>
                            :
                            <Box
                                sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center" }}
                            >
                                <TextField size='small' label={mapData?.value} variant="standard" value={mapData?.value} onChange={event => { handleInputChange(event, mapData.value) }} />
                            </Box>}

                        {/* <Box
                            sx={{ lineHeight: '1', marginLeft: '15px' }}
                        >
                            <DehazeIcon
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={event => {
                                    console.log("modal open and comments is null", props);
                                    localStorage.setItem(
                                        "selectToModify",
                                        JSON.stringify({
                                            title: props.title,
                                            value: props.value,
                                        })
                                    );
                                    handleClick(event);
                                }}
                                fontSize="small"
                                sx={{ cursor: "pointer" }}
                            />
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        localStorage.setItem("modifyStatus", "edit-feedback");
                                        setModifyButtonAppear({
                                            ...modifyButtonAppear,
                                            edit: true
                                        })
                                        handleClose();
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
                                        <EditIcon fontSize="small" sx={{ mr: 1 }} color="info" />
                                        <Typography variant="span" component="spam">Edit</Typography>
                                    </Box>
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        setModifyButtonAppear({
                                            ...modifyButtonAppear,
                                            delete: true
                                        })
                                        handleClose();
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
                                        <DeleteIcon fontSize="small" sx={{ mr: 1 }} color="error" />
                                        <Typography variant="span" component="spam">Delete</Typography>
                                    </Box>
                                </MenuItem>
                            </Menu>
                        </Box> */}
                    </Box>
                </Box>)}
                <Divider style={{ margin: "8px 0" }} />
            </Box>
        </Box>}
    </>
    )
}

export default ShowMore