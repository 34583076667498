import { Box, Breadcrumbs, Button, Chip, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, List, ListItem, ListItemText, Slide, TextField, Typography } from '@mui/material'
import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ServiceDomain from "../../components/common/ServiceDomain"


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CertificateTestModal(props) {
    console.log("props data in certificate_modal =>", props);

    const [selectedModule, setSeletedModule] = useState({})
    const [questionContent, setQuestionContent] = useState({
        breadCrumb: [],
        question: "",
        questionType: props.rolesType,
        helperText: "",
        questionId: "",
        answers: "",
        subject: "",
        subjectId: "",
        secondarySubject: "",
        secondarySubjectId: ""
    })


    const skipQuestion = () => {
        props.handleAnswered("skip", questionContent)
        moveToNextQuestion()
    }

    const nextQuestion = () => {
        props.handleAnswered("next", questionContent)
        moveToNextQuestion()
    }

    const clearQuestionVar = () => {
        setQuestionContent({
            breadCrumb: [],
            question: "",
            questionType: props.rolesType,
            helperText: "",
            questionId: "",
            answers: "",
            subject: "",
            subjectId: "",
            secondarySubject: "",
            secondarySubjectId: ""
        })
    }

    const moveToNextQuestion = () => {
        clearQuestionVar()
    }

    let navigate = useNavigate();
    const submitCertificationModal = () => {
        props.handleClose()
        navigate('/certification')
    }


    useEffect(() => {
        setSeletedModule(props.certificate_module)
    }, [props.certificate_module])

    const certificationData = useSelector(state => state.certification)
    const selectedCourse = certificationData.selected_course
    console.log("selectedCourse", selectedCourse)
    const selectedDomain = certificationData.selected_domain
    console.log("selectedDomain", selectedDomain)
    const currentDomain = certificationData.current_domain
    console.log("currentDomain", currentDomain)

    return (
        <Box>
            <Dialog
                fullScreen
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Transition}
                className="cerificate_dialog_container"
            >
                <Box sx={{ p: 2, height: "100vh", overflow: "hidden" }}>
                    <Box className="cerificate_dialog_wrapper">
                        <Box className="cerificate_dialog_wrapper_item cerificate_dialog_wrapper_item1">
                            <Box className='cerificate_dialog_wrapper_item1_wrapper'>
                                {selectedModule?.subjects?.length > 0 && <List dense={false}>
                                    {selectedModule.subjects.map(mapData => (
                                        <ListItem class="smallListItems_first">
                                            <ListItemText size="small" primary={mapData.subject} />
                                            {mapData.secondarySubject.length > 0 && <List dense={false} className="smallListItems_second_container">
                                                {mapData.secondarySubject.map(mapData2 => (
                                                    <ListItem class="smallListItems_second">
                                                        <ListItemText size="small" primary={mapData2.name} />
                                                        {mapData2.test.length > 0 && <List dense={false} className="smallListItems_third_container">
                                                            {mapData2.test.map(mapData3 => (
                                                                <ListItem class="smallListItems_third">
                                                                    <ListItemText size="small" primary={mapData3.compliance} />
                                                                </ListItem>
                                                            ))}
                                                        </List>}
                                                    </ListItem>
                                                ))}
                                            </List>}
                                        </ListItem>
                                    ))}
                                </List>}
                            </Box>
                        </Box>
                        <Box className="cerificate_dialog_wrapper_item cerificate_dialog_wrapper_item2">
                            {/* Breadcrumb:Start */}
                            <Box role="presentation" sx={{ padding: "10px 5px" }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    {questionContent.breadCrumb.map((bread, idx) => <Typography fontSize="small" color={bread.status === 'Active' ? "text.primary" : "text.secoandry"} status={bread.status} key={idx}>{bread.name} </Typography>)}
                                </Breadcrumbs>
                                <Divider sx={{ mt: 1 }} />
                            </Box>
                            {/* Breadcrumb:End */}
                            {/* Main Section:Start */}
                            <Box className='dialogQuestionDomainContainer'>
                                <Box className='dialogQuestionContainer'>
                                    <Box className='dialogQuestionWrapper'>
                                        <DialogTitle>
                                            Question
                                            <Chip size='small' label={questionContent.questionType} color="secondary" sx={{ ml: 1 }} />
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>{questionContent.question}</DialogContentText>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="Answer"
                                                type="text"
                                                fullWidth
                                                variant="standard"
                                                helperText={questionContent.helperText}
                                                value={questionContent.answers}
                                                onChange={e => setQuestionContent({
                                                    ...questionContent,
                                                    answers: e.currentTarget.value
                                                })}
                                            />
                                        </DialogContent>
                                    </Box>
                                </Box>
                                <Box className='dialogDomainContainer'>
                                    <ServiceDomain serviceUid={currentDomain.uid} />
                                </Box>
                            </Box>
                            {/* Main Section:End */}
                            {/* Action Section:Start */}
                            <Box>
                                <Divider />
                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px 10px" }} >
                                    <Button color="secondary" onClick={skipQuestion} >Skip</Button>
                                    <Button variant="contained" onClick={nextQuestion} >Next</Button>

                                    {/* To Close Modal */}
                                    <Button variant='contained' onClick={submitCertificationModal} >Submit</Button>
                                </Box>
                            </Box>
                            {/* Action Section:End */}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </Box>
    )
}

export default CertificateTestModal