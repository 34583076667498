import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import ApiIcon from "../../../assets/images/image001.png";
import IsoIcon from "../../../assets/images/ISO_API_logo.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  SvgIcon,
  Collapse,
  Typography,
  Divider,
  Box,
  Button,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RadioGroup,
  Alert,
  Snackbar,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import FeedbackLabel from "./FeedbackLabel";
import CheckIcon from "@mui/icons-material/Check";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import ModelFormAdd from "./ModelFormAdd";
import ModelFormEdit from "./ModelFormEdit";
import ModelFormDelete from "./ModelFormDelete";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function Feedback(props) {
  const [admin, setAdmin] = useState(
    JSON.parse(localStorage.getItem("crawler"))
  );
  const [treeItem, setTreeItem] = useState({});
  const [jwtTokenApprove, setJwtTokenApprove] = useState(false);
  const [userTreeEdit, setUserTreeEdit] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [open, setOpen] = useState(false);
  const [servsArr, setServsArr] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modifyDomain, setModifyDomain] = useState({
    add: false,
    edit: false,
    delete: false,
  });
  const [oldstatus, setOldStatus] = useState("");
  const [commentUser, setCommentUser] = useState([]);

  // ------------------ Loading Button State
  const [loadingButtons, setLoadingButons] = useState({
    sendBack: false,
    publish: false,
    approve: false,
  });
  // ------------------ Loading Button State

  const [modalOpenData, setModalOpenData] = useState({
    title: "Title",
    description: "Description",
    status: "Status",
    data_type: "String",
  });
  const [alertMsg, setAlertMsg] = useState({
    status: false,
    severity: "",
    message: "",
  });
  const [snackopen, setSnackopen] = useState({
    status: false,
    severity: "error",
    message: "",
    duration: 0,
    vertical: "top",
    horizontal: "right",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const snackHandleClose = () => {
    setSnackopen({
      status: false,
      severity: "",
      message: "",
      duration: 0,
      vertical: "top",
      horizontal: "right",
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClickOpen = () => {
    var getItem = JSON.parse(localStorage.getItem("selectToModify"));
    var modifyStatus = localStorage.getItem("modifyStatus");
    if (modifyStatus === "add-feedback") {
      console.log("modifyStatus is add-feedback");
      setModalOpen(true);
      setModifyDomain({
        ...modifyDomain,
        add: true,
      });
    } else if (modifyStatus === "edit-feedback") {
      console.log("modifyStatus is edit-feedback");
      setModalOpenData(getItem);
      setModalOpen(true);
      setModifyDomain({
        ...modifyDomain,
        edit: true,
      });
    } else if (modifyStatus === "delete-feedback") {
      console.log("modifyStatus is delete-feedback");
      setModalOpen(true);
      setModifyDomain({
        ...modifyDomain,
        delete: true,
      });
    } else {
      return;
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModifyDomain({
      add: false,
      edit: false,
      delete: false,
    });
  };

  const handleModalSubmit = () => {
    setModalOpen(false);
    setModifyDomain({
      add: false,
      edit: false,
      delete: false,
    });
    setTreeItem(JSON.parse(localStorage.getItem("feedbacktree")));
    setOldStatus(JSON.parse(localStorage.getItem("feedbacktree")).status);
  };

  const handleTreeSubmit = () => {
    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + localStorage.getItem("jwtToken");
    submitDta.status = "Feedback-Rework";

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainUpdate");
    setLoadingButons({ ...loadingButtons, sendBack: true });
    axios({
      method: "put",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    }).then((res) => {
      const response = res.data;
      setLoadingButons({ ...loadingButtons, sendBack: false });
      console.log("response is from axios api ", response);
      notifyButtonClicked("Feedback-Rework", submitDta.uid);
    });
    setLoadingButons({ ...loadingButtons, sendBack: false });
    setOpen(false);
  };

  var defaultUser = {
    username: process.env.REACT_APP_DEFAULT_USERNAME,
    password: process.env.REACT_APP_DEFAULT_PASSWORD,
  };

  const getAuthenticate = async (loginUser) => {
    let axiosUrl = "";

    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

    await axios
      .post(axiosUrl, loginUser)
      .then((res) => {
        if (res.status === 200) {
          var response = res.data;
          setJwtTokenApprove(response.jwtToken);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
      });
  };

  useEffect(() => {
    getAuthenticate(defaultUser);
  }, []);

  const notifyButtonClicked = (notifyStatus, feedbackUid) => {
    let axiosUrl = "";
    axiosUrl = process.env.REACT_APP_NOTIFY_URL;

    axios({
      method: "PUT",
      url: axiosUrl,
      // url: 'http://api-modeler.bian.org/project/external/feedback/notify',
      // url: 'http://api-modeller.bian.org/project/external/feedback/notify',
      data: {
        id: 1,
        // "comment":"This has been "+notifyStatus+" by BIAN",
        comment: notifyStatus,
        status: notifyStatus,
        feedbackUid: feedbackUid,
      },
    })
      .then((res) => {
        const response = res.data;
        console.log("response is from axios api ", response);
        setSnackopen({
          status: true,
          severity: "success",
          message: "The submitter has been notified of this change",
          duration: 4000,
          vertical: "top",
          horizontal: "right",
        });
      })
      .catch((err) => {
        setSnackopen({
          status: true,
          severity: "error",
          message: "Failed to notify the user",
          duration: 4000,
          vertical: "top",
          horizontal: "right",
        });
      });
  };

  const handleTreePublish = () => {
    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + jwtTokenApprove;
    submitDta.status = "Published";
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainUpdate");
    setLoadingButons({ ...loadingButtons, publish: false });
    axios({
      method: "PUT",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    }).then((res) => {
      const response = res.data;
      console.log("response is from axios api ", response);
      setLoadingButons({ ...loadingButtons, publish: false });
      notifyButtonClicked("Published", submitDta.uid);
    });
    setLoadingButons({ ...loadingButtons, publish: false });
    setOpen(false);
  };

  const UploadToDataBase = () => {
    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + jwtTokenApprove;
    submitDta.status = "Approved";
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainUpdate");
    setLoadingButons({ ...loadingButtons, approve: true });
    axios({
      method: "PUT",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    })
      .then((res) => {
        const response = res.data;
        console.log("response is from axios api ", response);
        setLoadingButons({ ...loadingButtons, approve: false });
        notifyButtonClicked("Approved", submitDta.uid);
      })
      .catch((error) => {
        console.log("response is from axios api ", error);
        setLoadingButons({ ...loadingButtons, approve: false });
      });
    setLoadingButons({ ...loadingButtons, approve: false });
    setOpen(false);
  };

  const UploadToGitHub = () => {
    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + jwtTokenApprove;
    submitDta.status = "Approved";
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_CONTENT_MANAGER_S1 + "ServiceDomain");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_CONTENT_MANAGER_S2 + "ServiceDomain");
    setLoadingButons({ ...loadingButtons, approve: true });
    axios({
      method: "POST",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    })
      .then((res) => {
        const response = res.data;
        console.log("response is from axios api ", response);
        setLoadingButons({ ...loadingButtons, approve: false });
      })
      .catch((error) => {
        console.log("response is from axios api ", error);
        setLoadingButons({ ...loadingButtons, approve: false });
      });
    setLoadingButons({ ...loadingButtons, approve: false });
    setOpen(false);
  };

  const handleTreeApprove = () => {
    setLoadingButons({ ...loadingButtons, approve: true });
    UploadToDataBase();
    UploadToGitHub();
    setLoadingButons({ ...loadingButtons, approve: false });
  };

  const userEditFeedbackTree = () => {
    console.log("userEditFeedbackTree func get called ");
    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + jwtTokenApprove;
    // use PATCH ​/ServiceDomain​/{serviceDomainUID}​/UserAssignment/{userUID}
    submitDta.status = "Feedback-InProcess";
    var serviceDomainUID = submitDta.uid;
    let userUid = localStorage.getItem("cacheToken");
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 +
        "ServiceDomain/" +
        serviceDomainUID +
        "/Status/Feedback-InProcess/UserAssignment/" +
        userUid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 +
        "ServiceDomain/" +
        serviceDomainUID +
        "/Status/Feedback-InProcess/UserAssignment/" +
        userUid);

    axios({
      method: "PATCH",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    })
      .then((res) => {
        const response = res.data;
        console.log("response is from axios api ", response);
        setAlertMsg({
          status: true,
          severity: "success",
          message: "Service Domain Edited Enable",
        });
        clearAlertMsg(4000);
        setUserTreeEdit(!userTreeEdit);
      })
      .catch(() => {
        setAlertMsg({
          status: true,
          severity: "success",
          message: "Unable to Enable Edit Of Service Domain",
        });
        clearAlertMsg(4000);
      });
  };

  const handleUserTreeCancel = () => {
    console.log("userEditFeedbackTree func get called ");
    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + jwtTokenApprove;
    // use PATCH ​/ServiceDomain​/{serviceDomainUID}​/UserAssignment/{userUID}
    submitDta.status = oldstatus;
    var serviceDomainUID = submitDta.uid;
    let userUid = localStorage.getItem("cacheToken");
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 +
        "ServiceDomain/" +
        serviceDomainUID +
        "/Status/Feedback-Created/UserAssignmentRemove/" +
        userUid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 +
        "ServiceDomain/" +
        serviceDomainUID +
        "/Status/Feedback-Created/UserAssignmentRemove/" +
        userUid);

    axios({
      method: "PATCH",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    }).then((res) => {
      const response = res.data;
      console.log("response is from axios api ", response);
      setOpen(false);
      setUserTreeEdit(false);
    });
  };

  const userSaveFeedbackTree = () => {
    console.log("userEditFeedbackTree func get called ");

    var submitDta = JSON.parse(localStorage.getItem("feedbacktree"));
    var submitToken = "Bearer " + jwtTokenApprove;

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainUpdate");

    submitDta.status = "Feedback-InProcess";
    // var getUid = submitDta.uid;
    // var newUid = submitDta.uid;
    // if (getUid.lastIndexOf("_") != -1) {
    //   var foundAt = Number(getUid.lastIndexOf("_"));
    //   var getVer = foundAt + 1;
    //   var getRest = getUid.slice(0, foundAt);
    //   var incnum = Number(getUid.slice(getVer)) + 1;
    //   newUid = getRest + "_" + incnum;
    // }
    // submitDta.uid = newUid;

    axios({
      method: "PUT",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    })
      .then((res) => {
        const response = res.data;
        console.log("response is from axios api ", response);
        setAlertMsg({
          status: true,
          severity: "success",
          message: "Service Domain Edited Successfully",
        });
        clearAlertMsg(4000);
        setUserTreeEdit(!userTreeEdit);
      })
      .catch(() => {
        setAlertMsg({
          status: true,
          severity: "success",
          message: "Service Domain Edited Successfully",
        });
        clearAlertMsg(4000);
      });

    setOpen(false);
  };

  function clearAlertMsg(timeProvided) {
    setTimeout(() => {
      setAlertMsg({
        status: false,
        severity: "",
        message: "",
      });
    }, timeProvided);
  }

  console.log("selected feedback id is ", props.feedUid);

  useEffect(() => {
    var submitToken = "Bearer " + localStorage.getItem("jwtToken");
    setIsLoading(true);
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 +
        "ServiceDomainByUID/" +
        props.feedUid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 +
        "ServiceDomainByUID/" +
        props.feedUid);

    if (props.comparedata.hasOwnProperty("serviceDomainID")) {
      setTreeItem(props.comparedata);
      setOldStatus(props.comparedata.status);
      props.comparedata.status == "Feedback-InProcess" && setUserTreeEdit(true);
    } else if (
      props.feedUid !== undefined &&
      props.feedUid !== null &&
      props.feedUid !== ""
    ) {
      console.log(2);
      axios({
        method: "get",
        url: axiosUrl,
        headers: {
          Authorization: submitToken,
        },
      }).then((res) => {
        setTreeItem(res.data);
        setOldStatus(res.data.status);
        setIsLoading(false);
        // TODO feedback comment for indexdb
        // localStorage.setItem("feedbacktree", JSON.stringify(res.data));
        props.callbackFunction(res.data);
        setSkeleton(false);
        res.data.status == "Feedback-InProcess" && setUserTreeEdit(true);
      });
    } else {
      return;
    }

    // Get Comment User
    fetchCommentUserFromDomain();
  }, [props.feedUid, props.comparedata]);

  const radioSkeletons = [];
  for (let i = 1; i <= 10; i++) {
    radioSkeletons.push(i);
  }

  const getUserDetailsForComment = (userUID) => {
    var submitToken = "Bearer " + localStorage.getItem("jwtToken");
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserByUid/" + userUID);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserByUid/" + userUID);

    axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: submitToken,
        },
      })
      .then((response) => {
        setCommentUser([
          ...commentUser,
          {
            firstName: response.data.firstName,
            emailAddress: response.data.emailAddress,
            uid: response.data.uid,
            status: response.data.status,
          },
        ]);
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };

  function fetchCommentUserFromDomain() {
    console.log("-------- fetchCommentUserFromDomain Start ---------");

    // Characteristics
    if (treeItem?.characteristics?.metaLight?.comments?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain characteristics ---------"
      );

      treeItem?.characteristics?.metaLight?.comments.map((loopData) => {
        console.log(
          " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
          commentUser.filter((fil) => fil.uid == loopData.userId).length
        );
        if (
          commentUser.filter((fil) => fil.uid == loopData.userId).length === 0
        ) {
          console.log("----- requirement setisfied characteristics");
          getUserDetailsForComment(loopData.userId);
        }
      });
    }

    // Business Area
    if (treeItem?.businessArea?.metaLight?.comments?.length > 0) {
      console.log("-------- fetchCommentUserFromDomain businessArea ---------");

      treeItem?.businessArea?.metaLight?.comments.map((loopData) => {
        console.log(
          " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
          commentUser.filter((fil) => fil.uid == loopData.userId).length
        );
        if (
          commentUser.filter((fil) => fil.uid == loopData.userId).length === 0
        ) {
          console.log("----- requirement setisfied businessArea");
          getUserDetailsForComment(loopData.userId);
        }
      });
    }

    // Business Domain
    if (treeItem?.businessDomain?.metaLight?.comments?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain businessDomain ---------"
      );

      treeItem?.businessDomain?.metaLight?.comments.map((loopData) => {
        console.log(
          " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
          commentUser.filter((fil) => fil.uid == loopData.userId).length
        );
        if (
          commentUser.filter((fil) => fil.uid == loopData.userId).length === 0
        ) {
          console.log("----- requirement setisfied businessDomain");
          getUserDetailsForComment(loopData.userId);
        }
      });
    }

    // Control Record Level 1
    if (treeItem?.controlRecord?.metaLight?.comments?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain businessDomain Level 1 ---------"
      );

      treeItem?.controlRecord?.metaLight?.comments.map((loopData) => {
        console.log(
          " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
          commentUser.filter((fil) => fil.uid == loopData.userId).length
        );
        if (
          commentUser.filter((fil) => fil.uid == loopData.userId).length === 0
        ) {
          console.log("----- requirement setisfied Control Record Level 1");
          getUserDetailsForComment(loopData.userId);
        }
      });
    }

    // Control Record Level 2
    if (treeItem?.controlRecord?.attributes?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain businessDomain Level 2 ---------"
      );

      treeItem?.controlRecord?.attributes.map((arrayLoop) => {
        if (arrayLoop?.metaLight?.comments?.length > 0) {
          arrayLoop?.metaLight?.comments.map((loopData) => {
            console.log(
              " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
              commentUser.filter((fil) => fil.uid == loopData.userId).length
            );
            if (
              commentUser.filter((fil) => fil.uid == loopData.userId).length ===
              0
            ) {
              console.log("----- requirement setisfied Control Record Level 2");
              getUserDetailsForComment(loopData.userId);
            }
          });
        }
      });
    }

    // Behaviour Qualifiers Level 2 and Level 3
    if (treeItem?.behaviourQualifiers?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain behaviourQualifiers ---------"
      );

      treeItem?.behaviourQualifiers?.map((arrayLoop) => {
        // Level 2
        if (arrayLoop?.metaLight?.comments?.length > 0) {
          console.log(
            "-------- fetchCommentUserFromDomain behaviourQualifiers Level 2 ---------"
          );

          arrayLoop?.metaLight?.comments.map((loopData) => {
            console.log(
              " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
              commentUser.filter((fil) => fil.uid == loopData.userId).length
            );
            if (
              commentUser.filter((fil) => fil.uid == loopData.userId).length ===
              0
            ) {
              console.log(
                "----- requirement setisfied Behaviour Qualifiers Level 2"
              );
              getUserDetailsForComment(loopData.userId);
            }
          });
        }

        // Level 3
        if (arrayLoop?.attributes?.length > 0) {
          console.log(
            "-------- fetchCommentUserFromDomain behaviourQualifiers Level 3 ---------"
          );

          arrayLoop?.attributes.map((arrayLoop2) => {
            if (arrayLoop2?.metaLight?.comments?.length > 0) {
              arrayLoop2?.metaLight?.comments.map((loopData) => {
                console.log(
                  " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
                  commentUser.filter((fil) => fil.uid == loopData.userId).length
                );
                if (
                  commentUser.filter((fil) => fil.uid == loopData.userId)
                    .length === 0
                ) {
                  console.log(
                    "----- requirement setisfied Behaviour Qualifiers Level 3"
                  );
                  getUserDetailsForComment(loopData.userId);
                }
              });
            }
          });
        }
      });
    }

    // Service Operations No Level 2 and Only Level 3
    if (treeItem?.serviceOperations?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain serviceOperations ---------"
      );

      treeItem?.serviceOperations?.map((arrayLoop) => {
        // Level 2
        if (arrayLoop?.metaLight?.comments?.length > 0) {
          console.log(
            "-------- fetchCommentUserFromDomain serviceOperations Level 2 ---------"
          );

          arrayLoop?.metaLight?.comments.map((loopData) => {
            console.log(
              " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
              commentUser.filter((fil) => fil.uid == loopData.userId).length
            );
            if (
              commentUser.filter((fil) => fil.uid == loopData.userId).length ===
              0
            ) {
              console.log(
                "----- requirement setisfied Service Operations Level 2"
              );
              getUserDetailsForComment(loopData.userId);
            }
          });
        }
        // Level 3 Input Attributes
        if (arrayLoop?.inputAttributes?.length > 0) {
          console.log(
            "-------- fetchCommentUserFromDomain serviceOperations Level 3 inputAttributes ---------"
          );

          arrayLoop?.inputAttributes.map((arrayLoop2) => {
            if (arrayLoop2?.metaLight?.comments?.length > 0) {
              arrayLoop2?.metaLight?.comments.map((loopData) => {
                console.log(
                  " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
                  commentUser.filter((fil) => fil.uid == loopData.userId).length
                );
                if (
                  commentUser.filter((fil) => fil.uid == loopData.userId)
                    .length === 0
                ) {
                  console.log(
                    "----- requirement setisfied Service Operations Level 3 inputAttributes"
                  );
                  getUserDetailsForComment(loopData.userId);
                }
              });
            }
          });
        }

        // Level 3 Output Attributes
        if (arrayLoop?.outputAttributes?.length > 0) {
          console.log(
            "-------- fetchCommentUserFromDomain serviceOperations Level 3 outputAttributes ---------"
          );

          arrayLoop?.outputAttributes.map((arrayLoop2) => {
            if (arrayLoop2?.metaLight?.comments?.length > 0) {
              arrayLoop2?.metaLight?.comments.map((loopData) => {
                console.log(
                  " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
                  commentUser.filter((fil) => fil.uid == loopData.userId).length
                );
                if (
                  commentUser.filter((fil) => fil.uid == loopData.userId)
                    .length === 0
                ) {
                  console.log(
                    "----- requirement setisfied Service Operations Level 3 outputAttributes"
                  );
                  getUserDetailsForComment(loopData.userId);
                }
              });
            }
          });
        }
      });
    }

    // Business Scenarios No Level 1 and Only Level 2
    if (treeItem?.businessScenarios?.length > 0) {
      console.log(
        "-------- fetchCommentUserFromDomain businessScenarios ---------"
      );

      treeItem?.businessScenarios.map((arrayLoop) => {
        if (arrayLoop?.metaLight?.comments?.length > 0) {
          arrayLoop?.metaLight?.comments.map((loopData) => {
            console.log(
              " commentUser.filter(fil => fil.uid == loopData.userId).length => ",
              commentUser.filter((fil) => fil.uid == loopData.userId).length
            );
            if (
              commentUser.filter((fil) => fil.uid == loopData.userId).length ===
              0
            ) {
              console.log(
                "----- requirement setisfied Business Scenarios Level 2"
              );
              getUserDetailsForComment(loopData.userId);
            }
          });
        }
      });
    }

    console.log("-------- fetchCommentUserFromDomain End ---------");
  }

  const callSymanticUI = (item) => {
    if (item?.apiConsole) {
      window.open(item?.apiConsole, "_blank");
    }
  };

  const callISOUI = () => {
    var urlData = localStorage.getItem("serviceFeedRadioId");
    const match = urlData.match(/[^-]+$/);
    const result = match ? match[0] : "AccountRecovery";
    window.open(
      `https://app.swaggerhub.com/apis/BIAN-3/${result}/12.0.1`,
      "_blank"
    );
  };

  useEffect(() => {
    if (treeItem?.serves) {
      const mainArr = [];
      Object.keys(treeItem.serves).map((key) => {
        if (key.indexOf("level") > -1) {
          if (treeItem.serves[key].length > 0) {
            mainArr.push(treeItem.serves[key][0]);
          }
        }
        // [Number(key), treeItem.serves[key]]
      });
      setServsArr(mainArr);
    }
  }, [treeItem.serves]);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#353535",
      color: "#d4d4d4",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: "10px",
    },
  }));

  return (
    <>
      {!skeleton && !isLoading && (
        <Box>
          <Box>
            <Box
              style={{
                padding: "5px 10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  style={{ marginBottom: "24px" }}
                >
                  {treeItem.name}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  <div
                    style={{
                      padding: "5px 10px",
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "-60px",
                      float: "right",
                    }}
                  >
                    <img
                      src={IsoIcon}
                      style={{
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                        paddingTop: "4px",
                        paddingRight: "10px",
                        display: treeItem.apiConsole ? "block" : "none",
                      }}
                      onClick={() => callISOUI()}
                    />
                    <img
                      src={ApiIcon}
                      style={{
                        height: "35px",
                        cursor: "pointer",
                        display: treeItem.apiConsole ? "block" : "none",
                      }}
                      onClick={() => callSymanticUI(treeItem)}
                    />
                  </div>
                </Typography>
                {treeItem?.meta?.organisationInstance != null && (
                  <Typography variant="body1" color="#3a77837d">
                    {treeItem?.meta?.organisationInstance}
                  </Typography>
                )}
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {treeItem.executiveSummary}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {treeItem.roleDefinition}
                </Typography>
                {/* <Typography variant="subtitle2" color="text.secondary" >
                  <ExpandMoreIcon style={{ marginTop: "20px" }} /> <p onClick={() => setExampleOfUse(!exampleOfUse)}> Example of Use </p>
                </Typography>

                <Typography variant="body2" color="text.secondary">
                  <p style={{ display: exampleOfUse ? "block" : "none" }}>{treeItem.examplesOfUse}</p>
                </Typography> */}

                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
                  <TreeItem nodeId="1" label="Example of Use">
                    <TreeItem nodeId="2" label={treeItem.examplesOfUse} />
                  </TreeItem>
                  <TreeItem nodeId="3" label="Role Definition">
                    <TreeItem nodeId="4" label={treeItem.roleDefinition} />
                  </TreeItem>
                  <TreeItem nodeId="5" label="Key Features">
                    <TreeItem nodeId="6" label={treeItem.keyFeatures} />
                  </TreeItem>
                </TreeView>
                {treeItem.description != null && (
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ mt: 1 }}
                  >
                    {treeItem.description}
                  </Typography>
                )}
              </Box>
              <Box>
                {userTreeEdit && (
                  <Tooltip
                    title={
                      "By cancel status will be moved to older which is " +
                      oldstatus
                    }
                  >
                    <Button onClick={handleUserTreeCancel} variant="text">
                      Cancel
                    </Button>
                  </Tooltip>
                )}
                {admin && (
                  <>
                    {userTreeEdit ? (
                      <Tooltip
                        title={
                          "By Click on Save Button it will save the updated service domain tree with status of Published"
                        }
                      >
                        <Button onClick={userSaveFeedbackTree} variant="text">
                          Save
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          "By Click on Edit Button it will update the service domain tree with status of Feedback-InProcess"
                        }
                      >
                        <Button onClick={userEditFeedbackTree} variant="text">
                          Edit
                        </Button>
                      </Tooltip>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <Divider style={{ margin: "0 0 8px 0" }} />
          </Box>

          <DragDropContext>
            <TreeView
              aria-label="customized"
              defaultExpanded={["1"]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
            >
              {/* <Droppable>
                              {(provided, snapshot) => ( */}
              <div>
                {/* 1st Item:Start */}
                <StyledTreeItem
                  style={{
                    border:
                      treeItem?.characteristics?.metaLight?.differenceLights
                        ?.length > 0
                        ? "3px solid green"
                        : "3px solid #fff",
                  }}
                  nodeId="1"
                  key="1"
                  label={
                    <FeedbackLabel
                      commentUser={commentUser}
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        treeItem?.characteristics?.functionalPattern
                          ? treeItem?.characteristics?.functionalPattern
                          : "Characteristics"
                      }
                      description={treeItem?.characteristics?.assetType}
                      sec-desc={treeItem?.characteristics?.genericArtefactType}
                      status={treeItem?.characteristics?.status}
                      data_type={treeItem?.characteristics?.dataType}
                      data_type2={
                        treeItem?.characteristics?.boType ? (
                          <>
                            <div style={{ cursor: "pointer" }}>
                              {treeItem?.characteristics?.boType}
                            </div>
                          </>
                        ) : null
                      }
                      type1="characteristics"
                      type2=""
                      type3=""
                      serORfeed="feedback"
                      metaLight={treeItem?.characteristics?.metaLight}
                      showCompareRadio="true"
                      group_name="radio-buttons-group-feedback"
                      level="level1"
                      editId={treeItem?.characteristics?.uid}
                      parentId=""
                      editEnabled="true"
                      editButtonEnable={userTreeEdit && admin}
                      showSubTitle="true"
                      changeStatusText={
                        treeItem?.characteristics?.metaLight?.differenceLights
                          ?.length > 0
                          ? "Change"
                          : "Dont"
                      }
                      meta={treeItem?.characteristics?.meta}
                    />
                  }
                />
                {/* 1st Item:End */}

                <LightTooltip
                  style={{ backgroundColor: "red" }}
                  title={
                    "Assets can be tangible things like computers and buildings, or they can be far less tangible things such as relationships, knowledge and know how"
                  }
                >
                  <span>
                    <StyledTreeItem
                      style={{ border: "3px solid #fff" }}
                      nodeId="1"
                      key="1"
                      label={
                        <FeedbackLabel
                          modalOpen={handleModalClickOpen}
                          modalClose={handleModalClose}
                          title={
                            <div style={{ display: "inline-flex" }}>
                              {treeItem?.characteristics?.assetType
                                ? treeItem?.characteristics?.assetType
                                : ""}
                              <div
                                style={{
                                  paddingLeft: "10px",
                                  color: "#c2bebe",
                                }}
                              >
                                assetType
                              </div>
                            </div>
                          }
                          // description={treeItem?.characteristics?.assetType}
                          // sec-desc={treeItem?.characteristics?.genericArtefactType}
                          // status={treeItem?.characteristics?.status}
                          // data_type={treeItem?.characteristics?.dataType}
                          type1="characteristics"
                          type2=""
                          type3=""
                          changeStatusText="Dont"
                          serORfeed="service"
                          group_name="radio-buttons-group-service"
                          level="level1"
                          editId={treeItem?.characteristics?.uid}
                          editEnabled="false"
                          showCompareRadio="false"
                          // meta={treeItem?.characteristics?.meta}
                        />
                      }
                    />
                  </span>
                </LightTooltip>
                <LightTooltip
                  style={{ backgroundColor: "red" }}
                  title={
                    "The type of artifact/document that is used/produced when tracking the actions of the service domain as it completes its execution from start to finish. Simply translates the action of executing the behavior into something more concrete (basically converting the behavior from verb to noun form)"
                  }
                >
                  <span>
                    <StyledTreeItem
                      style={{ border: "3px solid #fff" }}
                      nodeId="1"
                      key="1"
                      label={
                        <FeedbackLabel
                          modalOpen={handleModalClickOpen}
                          modalClose={handleModalClose}
                          title={
                            <div style={{ display: "inline-flex" }}>
                              {treeItem?.characteristics?.genericArtefactType
                                ? treeItem?.characteristics?.genericArtefactType
                                : ""}
                              <div
                                style={{
                                  paddingLeft: "10px",
                                  color: "#c2bebe",
                                }}
                              >
                                genericArtefactType
                              </div>
                            </div>
                          }
                          // description={treeItem?.characteristics?.genericArtefactType}
                          // sec-desc={treeItem?.characteristics?.genericArtefactType}
                          // status={treeItem?.characteristics?.status}
                          // data_type={treeItem?.characteristics?.dataType}
                          type1="characteristics"
                          type2=""
                          type3=""
                          changeStatusText={"Dont"}
                          serORfeed="service"
                          group_name="radio-buttons-group-service"
                          level="level1"
                          editId={treeItem?.characteristics?.uid}
                          editEnabled="false"
                          showCompareRadio="false"
                          // meta={treeItem?.characteristics?.meta}
                        />
                      }
                    />
                  </span>
                </LightTooltip>

                {/* 2nd Item:Start */}
                <StyledTreeItem
                  style={{
                    border:
                      treeItem?.businessArea?.metaLight?.differenceLights
                        ?.length > 0
                        ? "3px solid green"
                        : "3px solid #fff",
                  }}
                  nodeId="2"
                  key="2"
                  label={
                    <FeedbackLabel
                      commentUser={commentUser}
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        treeItem?.businessArea?.name
                          ? treeItem?.businessArea?.name
                          : "Business Area"
                      }
                      description={treeItem?.businessArea?.description}
                      sec-desc=""
                      status={treeItem?.businessArea?.status}
                      data_type={treeItem?.businessArea?.dataType}
                      data_type2={
                        treeItem?.businessArea?.boType ? (
                          <>
                            <div style={{ cursor: "pointer" }}>
                              {treeItem?.businessArea?.boType}
                            </div>
                          </>
                        ) : null
                      }
                      type1="businessArea"
                      type2=""
                      type3=""
                      serORfeed="feedback"
                      metaLight={treeItem?.businessArea?.metaLight}
                      showCompareRadio="true"
                      group_name="radio-buttons-group-feedback"
                      level="level1"
                      editId={treeItem?.businessArea?.uid}
                      parentId=""
                      editEnabled="true"
                      editButtonEnable={userTreeEdit && admin}
                      showSubTitle="true"
                      changeStatusText={
                        treeItem?.businessArea?.metaLight?.differenceLights
                          ?.length > 0
                          ? "Change"
                          : "Dont"
                      }
                      meta={treeItem?.businessArea?.meta}
                    />
                  }
                />
                {/* 2nd Item:End */}

                {/* 3rd Item:Start */}
                <StyledTreeItem
                  style={{
                    border:
                      treeItem?.businessDomain?.metaLight?.differenceLights
                        ?.length > 0
                        ? "3px solid green"
                        : "3px solid #fff",
                  }}
                  nodeId="3"
                  key="3"
                  label={
                    <FeedbackLabel
                      commentUser={commentUser}
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        treeItem?.businessDomain?.name
                          ? treeItem?.businessDomain?.name
                          : "Business Domain"
                      }
                      description={treeItem?.businessDomain?.description}
                      sec-desc=""
                      status={treeItem?.businessDomain?.status}
                      data_type={treeItem?.businessDomain?.dataType}
                      data_type2={
                        treeItem?.businessDomain?.boType ? (
                          <>
                            <div style={{ cursor: "pointer" }}>
                              {treeItem?.businessDomain?.boType}
                            </div>
                          </>
                        ) : null
                      }
                      type1="businessDomain"
                      type2=""
                      type3=""
                      serORfeed="feedback"
                      metaLight={treeItem?.businessDomain?.metaLight}
                      showCompareRadio="true"
                      group_name="radio-buttons-group-feedback"
                      level="level1"
                      editId={treeItem?.businessDomain?.uid}
                      parentId=""
                      editEnabled="true"
                      editButtonEnable={userTreeEdit && admin}
                      showSubTitle="true"
                      changeStatusText={
                        treeItem?.businessDomain?.metaLight?.differenceLights
                          ?.length > 0
                          ? "Change"
                          : "Dont"
                      }
                      meta={treeItem?.businessDomain?.meta}
                    />
                  }
                />
                {/* 3rd Item:End */}

                {/* 4th Item:Start */}
                {/* <StyledTreeItem
                  style={{
                    border:
                      treeItem?.controlRecord?.metaLight?.differenceLights
                        ?.length > 0
                        ? "3px solid green"
                        : "3px solid #fff",
                  }}
                  nodeId="4"
                  key="4"
                  label={
                    <FeedbackLabel
                      commentUser={commentUser}
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        treeItem.controlRecord?.name
                          ? treeItem.controlRecord?.name
                          : "Control Record"
                      }
                      description={treeItem?.controlRecord?.description}
                      sec-desc=""
                      status={treeItem?.controlRecord?.status}
                      data_type={treeItem?.controlRecord?.dataType}
                      data_type2={treeItem?.controlRecord?.boType ? <><div style={{ cursor: 'pointer' }}>{treeItem?.controlRecord?.boType}</div></> : null}

                      type1="controlRecord"
                      type2=""
                      type3=""
                      serORfeed="feedback"
                      metaLight={treeItem?.controlRecord?.metaLight}
                      showCompareRadio="false"
                      group_name="radio-buttons-group-feedback"
                      level="level1"
                      editId={treeItem?.controlRecord?.uid}
                      parentId=""
                      editEnabled="true"
                      editButtonEnable={userTreeEdit && admin}
                      showSubTitle="true"
                      changeStatusText={
                        treeItem?.controlRecord?.attributes.filter(
                          (filterLoop) =>
                            filterLoop?.metaLight?.differenceLights?.length > 0
                        ).length > 0
                          ? "Change"
                          : "Dont"
                      }
                      meta={treeItem?.controlRecord?.meta}
                    />
                  }
                >
                  {treeItem?.controlRecord?.attributes?.length > 0 &&
                    treeItem?.controlRecord?.attributes.map(
                      (attrmap, index) => (
                        <StyledTreeItem
                          style={{
                            border:
                              attrmap?.metaLight?.differenceLights?.length > 0
                                ? "3px solid green"
                                : "3px solid #fff",
                          }}
                          nodeId={"4-" + index}
                          key={"4-" + index}
                          label={
                            <FeedbackLabel
                              commentUser={commentUser}
                              modalOpen={handleModalClickOpen}
                              modalClose={handleModalClose}
                              title={attrmap.name}
                              description={attrmap.description}
                              sec-desc={attrmap.dataType}
                              status={attrmap?.status}
                              data_type={attrmap?.dataType}
                              data_type2={attrmap?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap?.boType}</div></> : null}

                              type1="controlRecord"
                              type2=""
                              type3=""
                              serORfeed="feedback"
                              metaLight={attrmap?.metaLight}
                              showCompareRadio="false"
                              group_name="radio-buttons-group-feedback"
                              level="level2"
                              editId={attrmap?.uid}
                              parentId=""
                              editEnabled="true"
                              editButtonEnable={userTreeEdit && admin}
                              showSubTitle="false"
                              changeStatusText="Dont"
                              meta={attrmap?.meta}
                            />
                          }
                        />
                      )
                    )}
                </StyledTreeItem> */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="4"
                  key="4"
                  label={
                    <LightTooltip
                      style={{ backgroundColor: "red" }}
                      title={
                        "A set of business information that reflects all information needed to support the fulfilment of the role of Service Domain on a single or multiple instances of AssetType."
                      }
                    >
                      <span>
                        <FeedbackLabel
                          modalOpen={handleModalClickOpen}
                          modalClose={handleModalClose}
                          changeStatusText="Dont"
                          title={
                            // treeItem.controlRecord?.name
                            //   ? treeItem.controlRecord?.name
                            //   : "Control Record"

                            <div style={{ display: "inline-flex" }}>
                              {treeItem?.controlRecord?.name
                                ? treeItem?.controlRecord?.name
                                : ""}
                              <div
                                style={{
                                  paddingLeft: "10px",
                                  color: "#c2bebe",
                                }}
                              >
                                controlRecord
                              </div>
                            </div>
                          }
                          description={treeItem?.controlRecord?.description}
                          sec-desc=""
                          status={treeItem?.controlRecord?.status}
                          data_type={treeItem?.controlRecord?.dataType}
                          type1="controlRecord"
                          type2="22222222222222"
                          type3="33333333333333333333"
                          serORfeed="service"
                          group_name="radio-buttons-group-service"
                          level="level1"
                          editId={treeItem?.controlRecord?.uid}
                          editEnabled="false"
                          showCompareRadio="false"
                          meta={treeItem?.controlRecord?.meta}
                        />
                      </span>
                    </LightTooltip>
                  }
                >
                  {treeItem?.controlRecord?.attributes?.length > 0 &&
                    treeItem?.controlRecord?.attributes.map((attrmap) => (
                      <StyledTreeItem
                        style={{ border: "3px solid #fff", cursor: "pointer" }}
                        nodeId={"4" + attrmap?.id}
                        key={"4" + attrmap?.id}
                        // onClick={() => selectDataType(attrmap)}

                        label={
                          <>
                            <FeedbackLabel
                              changeStatusText="Dont"
                              modalOpen={handleModalClickOpen}
                              modalClose={handleModalClose}
                              title={
                                <div style={{ cursor: "pointer" }}>
                                  {" "}
                                  {attrmap.name}
                                </div>
                              }
                              // title={attrmap.name}
                              description={<div>{attrmap.description}</div>}
                              sec-desc={attrmap.dataType}
                              status={attrmap?.status}
                              data_type={
                                <>
                                  <div style={{ cursor: "pointer" }}>
                                    {attrmap?.dataType}
                                  </div>
                                </>
                              }
                              data_type2={
                                attrmap?.boType ? (
                                  <>
                                    <div style={{ cursor: "pointer" }}>
                                      {attrmap?.boType}
                                    </div>
                                  </>
                                ) : null
                              }
                              type1="controlRecord"
                              type2=""
                              type3=""
                              serORfeed="service"
                              group_name="radio-buttons-group-service"
                              level="level2"
                              editId={attrmap?.uid}
                              editEnabled="false"
                              showCompareRadio="false"
                              meta={attrmap?.meta}
                            />
                          </>
                        }
                      />
                    ))}
                </StyledTreeItem>
                {/* 4th Item:End */}

                {/* 5th Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="5"
                  key="5"
                  label={
                    <FeedbackLabel
                      commentUser={commentUser}
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title="Behaviour Qualifiers"
                      description=""
                      sec-desc=""
                      status=""
                      data_type=""
                      type1="behaviourQualifiers"
                      type2=""
                      type3=""
                      serORfeed="feedback"
                      metaLight=""
                      showCompareRadio="false"
                      group_name="radio-buttons-group-feedback"
                      level="level1"
                      editId=""
                      parentId=""
                      editEnabled="false"
                      editButtonEnable={userTreeEdit && admin}
                      showSubTitle="true"
                      changeStatusText={
                        treeItem?.behaviourQualifiers?.attributes?.filter(
                          (filterLoop) =>
                            filterLoop?.metaLight?.differenceLights?.length > 0
                        ).length > 0
                          ? "Change"
                          : "Dont"
                      }
                      meta={treeItem?.behaviourQualifiers?.meta}
                    />
                  }
                >
                  {treeItem?.behaviourQualifiers?.length > 0 &&
                    treeItem?.behaviourQualifiers?.map((attrmap) => (
                      <StyledTreeItem
                        style={{
                          border:
                            attrmap?.metaLight?.differenceLights?.length > 0
                              ? "3px solid green"
                              : "3px solid #fff",
                        }}
                        nodeId={"5-" + attrmap?.id}
                        key={"5-" + attrmap?.id}
                        label={
                          <FeedbackLabel
                            commentUser={commentUser}
                            modalOpen={handleModalClickOpen}
                            modalClose={handleModalClose}
                            title={attrmap.name}
                            description={attrmap.description}
                            sec-desc=""
                            status={attrmap?.status}
                            data_type={attrmap?.dataType}
                            data_type2={
                              attrmap?.boType ? (
                                <>
                                  <div style={{ cursor: "pointer" }}>
                                    {attrmap?.boType}
                                  </div>
                                </>
                              ) : null
                            }
                            type1="behaviourQualifiers"
                            type2=""
                            type3=""
                            serORfeed="feedback"
                            metaLight={attrmap?.metaLight}
                            showCompareRadio="false"
                            group_name="radio-buttons-group-feedback"
                            level="level2"
                            editId={attrmap?.uid}
                            parentId=""
                            editEnabled="true"
                            editButtonEnable={userTreeEdit && admin}
                            showSubTitle="false"
                            changeStatusText={
                              treeItem?.behaviourQualifiers?.attributes?.filter(
                                (filterLoop) =>
                                  filterLoop?.metaLight?.differenceLights
                                    ?.length > 0
                              ).length > 0
                                ? "Change"
                                : "Dont"
                            }
                            meta={attrmap?.meta}
                          />
                        }
                      >
                        {attrmap.attributes?.length > 0 &&
                          attrmap.attributes.map((attrmap2) => (
                            <StyledTreeItem
                              style={{
                                border:
                                  attrmap2?.metaLight?.differenceLights
                                    ?.length > 0
                                    ? "3px solid green"
                                    : "3px solid #fff",
                              }}
                              nodeId={"5-" + attrmap?.id + "-" + attrmap2?.id}
                              key={"5-" + attrmap?.id + "-" + attrmap2?.id}
                              label={
                                <FeedbackLabel
                                  commentUser={commentUser}
                                  modalOpen={handleModalClickOpen}
                                  modalClose={handleModalClose}
                                  title={attrmap2.name}
                                  description={attrmap2.description}
                                  sec-desc=""
                                  status={attrmap2?.status}
                                  data_type={attrmap2?.dataType}
                                  data_type2={
                                    attrmap2?.boType ? (
                                      <>
                                        <div style={{ cursor: "pointer" }}>
                                          {attrmap2?.boType}
                                        </div>
                                      </>
                                    ) : null
                                  }
                                  type1="behaviourQualifiers"
                                  type2=""
                                  type3={attrmap?.uid}
                                  serORfeed="feedback"
                                  metaLight={attrmap2?.metaLight}
                                  showCompareRadio="false"
                                  group_name="radio-buttons-group-feedback"
                                  level="level3"
                                  editId={attrmap2?.uid}
                                  parentId={attrmap?.uid}
                                  editEnabled="true"
                                  editButtonEnable={userTreeEdit && admin}
                                  showSubTitle="false"
                                  changeStatusText={
                                    treeItem?.behaviourQualifiers?.attributes?.filter(
                                      (filterLoop) =>
                                        filterLoop?.metaLight?.differenceLights
                                          ?.length > 0
                                    ).length > 0
                                      ? "Change"
                                      : "Dont"
                                  }
                                  meta={attrmap2?.meta}
                                />
                              }
                            />
                          ))}
                      </StyledTreeItem>
                    ))}
                </StyledTreeItem>
                {/* 5th Item:End */}

                {/* 6th Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="6"
                  key="6"
                  label={
                    <FeedbackLabel
                      commentUser={commentUser}
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title="Service Operations"
                      description=""
                      sec-desc=""
                      status=""
                      data_type=""
                      type1="serviceOperations"
                      type2=""
                      type3=""
                      serORfeed="feedback"
                      metaLight=""
                      showCompareRadio="false"
                      group_name="radio-buttons-group-feedback"
                      level="level1"
                      editId=""
                      parentId=""
                      editEnabled="false"
                      editButtonEnable={userTreeEdit && admin}
                      showSubTitle="true"
                      changeStatusText={
                        treeItem?.serviceOperations
                          ?.map((filterLoop1) =>
                            filterLoop1.inputAttributes.filter(
                              (filterloop2) =>
                                filterloop2.metaLight?.differenceLights
                                  ?.length > 0
                            )
                          )
                          .filter((filterLoop) => filterLoop.length > 0)
                          .length > 0 ||
                        treeItem?.serviceOperations
                          ?.map((filterLoop1) =>
                            filterLoop1.outputAttributes.filter(
                              (filterloop2) =>
                                filterloop2.metaLight?.differenceLights
                                  ?.length > 0
                            )
                          )
                          .filter((filterLoop) => filterLoop.length > 0)
                          .length > 0
                          ? "Change"
                          : "Dont"
                      }
                      meta={treeItem?.serviceOperations?.meta}
                    />
                  }
                >
                  {treeItem?.serviceOperations?.length > 0 &&
                    treeItem?.serviceOperations?.map((attrmap) => (
                      <StyledTreeItem
                        style={{
                          border:
                            attrmap?.metaLight?.differenceLights?.length > 0
                              ? "3px solid green"
                              : "3px solid #fff",
                        }}
                        nodeId={"6-" + attrmap?.id}
                        key={"6-" + attrmap?.id}
                        label={
                          <FeedbackLabel
                            commentUser={commentUser}
                            modalOpen={handleModalClickOpen}
                            modalClose={handleModalClose}
                            title={attrmap.name}
                            description={attrmap.description}
                            sec-desc=""
                            status={attrmap?.status}
                            data_type={attrmap?.dataType}
                            data_type2={
                              attrmap?.boType ? (
                                <>
                                  <div style={{ cursor: "pointer" }}>
                                    {attrmap?.boType}
                                  </div>
                                </>
                              ) : null
                            }
                            type1="serviceOperations"
                            type2=""
                            type3=""
                            serORfeed="feedback"
                            metaLight={attrmap?.metaLight}
                            showCompareRadio="false"
                            group_name="radio-buttons-group-feedback"
                            level="level2"
                            editId={attrmap?.uid}
                            parentId=""
                            editEnabled="true"
                            editButtonEnable={userTreeEdit && admin}
                            showSubTitle="false"
                            changeStatusText={
                              attrmap.inputAttributes.filter(
                                (filt) =>
                                  filt.metaLight?.differenceLights?.length > 0
                              ).length > 0 ||
                              attrmap.outputAttributes.filter(
                                (filt) =>
                                  filt.metaLight?.differenceLights?.length > 0
                              ).length > 0
                                ? "Change"
                                : "Dont"
                            }
                            meta={attrmap?.meta}
                          />
                        }
                      >
                        {attrmap.inputAttributes?.length > 0 && (
                          <Box
                            style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                          >
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h6"
                              style={{ textAlign: "center" }}
                            >
                              Input Attributes
                            </Typography>
                            <Divider style={{ margin: "8px 0" }} />

                            {attrmap.inputAttributes?.length > 0 &&
                              attrmap.inputAttributes.map((attrmap2) => (
                                <StyledTreeItem
                                  style={{
                                    border:
                                      attrmap2?.metaLight?.differenceLights
                                        ?.length > 0
                                        ? "3px solid green"
                                        : "3px solid #fff",
                                  }}
                                  nodeId={"6-1-" + attrmap2?.id}
                                  key={"6-1-" + attrmap2?.id}
                                  label={
                                    <FeedbackLabel
                                      commentUser={commentUser}
                                      modalOpen={handleModalClickOpen}
                                      modalClose={handleModalClose}
                                      title={attrmap2.name}
                                      description={attrmap2.description}
                                      sec-desc=""
                                      status={attrmap2?.status}
                                      data_type={attrmap2?.dataType}
                                      data_type2={
                                        attrmap2?.boType ? (
                                          <>
                                            <div style={{ cursor: "pointer" }}>
                                              {attrmap2?.boType}
                                            </div>
                                          </>
                                        ) : null
                                      }
                                      type1="serviceOperations"
                                      type2="inputAttributes"
                                      type3={attrmap?.uid}
                                      serORfeed="feedback"
                                      metaLight={attrmap2?.metaLight}
                                      showCompareRadio="false"
                                      group_name="radio-buttons-group-feedback"
                                      level="level3"
                                      editId={attrmap2?.uid}
                                      parentId={attrmap?.uid}
                                      editEnabled="true"
                                      editButtonEnable={userTreeEdit && admin}
                                      showSubTitle="false"
                                      changeStatusText="Dont"
                                      meta={attrmap2?.meta}
                                    />
                                  }
                                />
                              ))}
                          </Box>
                        )}
                        {attrmap.outputAttributes?.length > 0 && (
                          <Box
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                              marginTop: "5px",
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h6"
                              style={{ textAlign: "center" }}
                            >
                              Output Attributes
                            </Typography>
                            <Divider style={{ margin: "8px 0" }} />

                            {attrmap.outputAttributes?.length > 0 &&
                              attrmap.outputAttributes.map((attrmap2) => (
                                <StyledTreeItem
                                  style={{
                                    border:
                                      attrmap2?.metaLight?.differenceLights
                                        ?.length > 0
                                        ? "3px solid green"
                                        : "3px solid #fff",
                                  }}
                                  nodeId={"6-2-" + attrmap2?.id}
                                  key={"6-2-" + attrmap2?.id}
                                  label={
                                    <FeedbackLabel
                                      commentUser={commentUser}
                                      modalOpen={handleModalClickOpen}
                                      modalClose={handleModalClose}
                                      title={attrmap2.name}
                                      description={attrmap2.description}
                                      sec-desc=""
                                      status={attrmap2?.status}
                                      data_type={attrmap2?.dataType}
                                      data_type2={
                                        attrmap2?.boType ? (
                                          <>
                                            <div style={{ cursor: "pointer" }}>
                                              {attrmap2?.boType}
                                            </div>
                                          </>
                                        ) : null
                                      }
                                      type1="serviceOperations"
                                      type2="outputAttributes"
                                      type3={attrmap?.uid}
                                      serORfeed="feedback"
                                      metaLight={attrmap2?.metaLight}
                                      showCompareRadio="false"
                                      group_name="radio-buttons-group-feedback"
                                      level="level3"
                                      editId={attrmap2?.uid}
                                      parentId={attrmap?.uid}
                                      editEnabled="true"
                                      editButtonEnable={userTreeEdit && admin}
                                      showSubTitle="false"
                                      changeStatusText="Dont"
                                      meta={attrmap2?.meta}
                                    />
                                  }
                                />
                              ))}
                          </Box>
                        )}
                      </StyledTreeItem>
                    ))}
                </StyledTreeItem>
                {/* 6th Item:End */}

                {/* 7rd Item:Start */}
                {treeItem.businessScenarios?.length !== 0 && (
                  <StyledTreeItem
                    style={{
                      border:
                        treeItem?.businessScenarios?.metaLight?.differenceLights
                          ?.length > 0
                          ? "3px solid green"
                          : "3px solid #fff",
                    }}
                    nodeId="7"
                    key="7"
                    label={
                      <FeedbackLabel
                        commentUser={commentUser}
                        modalOpen={handleModalClickOpen}
                        modalClose={handleModalClose}
                        title="Business Scenarios"
                        description=""
                        sec-desc=""
                        status=""
                        data_type=""
                        type1="businessScenarios"
                        type2=""
                        type3=""
                        serORfeed="feedback"
                        metaLight=""
                        showCompareRadio="false"
                        group_name="radio-buttons-group-feedback"
                        level="level1"
                        editId=""
                        parentId=""
                        editEnabled="false"
                        editButtonEnable={userTreeEdit && admin}
                        showSubTitle="true"
                        changeStatusText={
                          treeItem?.businessScenarios?.filter(
                            (filterLoop) =>
                              filterLoop?.metaLight?.differenceLights?.length >
                              0
                          ).length > 0
                            ? "Change"
                            : "Dont"
                        }
                        meta={treeItem?.businessScenarios?.meta}
                      />
                    }
                  >
                    {treeItem?.businessScenarios?.length > 0 &&
                      treeItem?.businessScenarios?.map((attrmap) => (
                        <StyledTreeItem
                          style={{
                            border:
                              treeItem?.businessScenarios?.metaLight
                                ?.differenceLights?.length > 0
                                ? "3px solid green"
                                : "3px solid #fff",
                          }}
                          nodeId={"7" + attrmap?.id}
                          key={"7" + attrmap?.id}
                          label={
                            <FeedbackLabel
                              commentUser={commentUser}
                              modalOpen={handleModalClickOpen}
                              modalClose={handleModalClose}
                              title={attrmap.name}
                              description={attrmap.description}
                              sec-desc={attrmap.dataType}
                              status={attrmap?.status}
                              data_type={attrmap?.dataType}
                              data_type2={
                                attrmap?.boType ? (
                                  <>
                                    <div style={{ cursor: "pointer" }}>
                                      {attrmap?.boType}
                                    </div>
                                  </>
                                ) : null
                              }
                              type1="businessScenarios"
                              type2=""
                              type3=""
                              serORfeed="feedback"
                              metaLight={attrmap?.metaLight}
                              showCompareRadio="false"
                              group_name="radio-buttons-group-feedback"
                              level="level2"
                              editId={attrmap?.uid}
                              parentId=""
                              editEnabled="true"
                              editButtonEnable={userTreeEdit && admin}
                              showSubTitle="false"
                              changeStatusText="Dont"
                              meta={attrmap?.meta}
                            />
                          }
                        />
                      ))}
                  </StyledTreeItem>
                )}

                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="8"
                  key="8"
                  label={
                    <FeedbackLabel
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        <div style={{ display: "inline-flex" }}>
                          Serves BusinessCapabilities
                          {/* <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>serviceOperations</div> */}
                        </div>
                      }
                      description=""
                      sec-desc=""
                      status=""
                      data_type=""
                      changeStatusText={"Dont"}
                      type1="serviceOperations"
                      type2=""
                      type3=""
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId=""
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.serviceOperations?.meta}
                    />
                  }
                >
                  {servsArr?.length > 0 &&
                    servsArr?.map((attrmap) => (
                      <StyledTreeItem
                        style={{ border: "3px solid #fff", cursor: "pointer" }}
                        nodeId={"6-" + attrmap?.id}
                        key={"6-" + attrmap?.id}
                        changeStatusText={"Dont"}
                        // onClick={() => selectDataType(attrmap)}
                        label={
                          <>
                            <FeedbackLabel
                              modalOpen={handleModalClickOpen}
                              changeStatusText={"Dont"}
                              modalClose={handleModalClose}
                              title={attrmap.name}
                              description={attrmap.description}
                              sec-desc=""
                              status={attrmap?.status}
                              data_type={attrmap?.dataType}
                              data_type2={
                                attrmap?.boType ? (
                                  <>
                                    <div style={{ cursor: "pointer" }}>
                                      {attrmap?.boType}
                                    </div>
                                  </>
                                ) : null
                              }
                              type1="serviceOperations"
                              type2=""
                              type3=""
                              serORfeed="service"
                              group_name="radio-buttons-group-service"
                              level="level2"
                              editId={attrmap?.uid}
                              editEnabled="false"
                              showCompareRadio="false"
                              meta={attrmap?.meta}
                            />
                          </>
                        }
                      ></StyledTreeItem>
                    ))}
                </StyledTreeItem>
                {/* 7rd Item:End */}
              </div>
              {/* )}
                         </Droppable> */}
            </TreeView>
          </DragDropContext>

          {/* Modify Modal */}
          <Box>
            <ModelFormAdd
              data={modalOpenData}
              open={modalOpen && modifyDomain.add}
              handleClose={handleModalClose}
              handleSubmit={handleModalSubmit}
            />
            <ModelFormEdit
              data={modalOpenData}
              open={modalOpen && modifyDomain.edit}
              handleClose={handleModalClose}
              handleSubmit={handleModalSubmit}
            />
            <ModelFormDelete
              data={modalOpenData}
              open={modalOpen && modifyDomain.delete}
              handleClose={handleModalClose}
              handleSubmit={handleModalSubmit}
            />
          </Box>

          <Divider style={{ margin: "8px 0" }} />
          {admin && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "8px 0",
              }}
            >
              {loadingButtons.sendBack ? (
                <LoadingButton loading variant="outlined">
                  Submit
                </LoadingButton>
              ) : (
                <Tooltip
                  title={
                    "By choosing this option, the Feedback will be sent back to the submitter"
                  }
                >
                  <Button
                    variant="contained"
                    color="info"
                    startIcon={<AutorenewIcon />}
                    style={{
                      marginRight: "5px",
                      minWidth: "130px",
                      marginTop: "5px",
                    }}
                    onClick={handleClickOpen}
                  >
                    Send Back
                  </Button>
                </Tooltip>
              )}
              {loadingButtons.publish ? (
                <LoadingButton loading variant="outlined">
                  Submit
                </LoadingButton>
              ) : (
                <Tooltip
                  title={
                    " By choosing this option, the Feedback will be published as a reference implementation. It will not be integrated into BiZZdesign"
                  }
                >
                  <Button
                    variant="contained"
                    color="success"
                    endIcon={<CheckIcon />}
                    style={{
                      marginLeft: "5px",
                      minWidth: "130px",
                      marginTop: "5px",
                    }}
                    onClick={handleTreePublish}
                  >
                    Publish
                  </Button>
                </Tooltip>
              )}
              {loadingButtons.approve ? (
                <LoadingButton loading variant="outlined">
                  Submit
                </LoadingButton>
              ) : (
                <Tooltip
                  title={
                    "By choosing this option, the feedback will be sent over to BiZZdesign, from where a new Patch version of this Service Domain will be generated"
                  }
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<AddTaskIcon />}
                    style={{
                      marginLeft: "5px",
                      minWidth: "130px",
                      marginTop: "5px",
                    }}
                    onClick={handleTreeApprove}
                  >
                    Approve
                  </Button>
                </Tooltip>
              )}
            </Box>
          )}
          <Box>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{treeItem.name}</DialogTitle>
              <Divider />
              <DialogContent>
                <DialogContentText>
                  Are you sure you you want to sumbit this changes for{" "}
                  {treeItem.name} in database
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleTreeSubmit}>Confirm</Button>
              </DialogActions>
            </Dialog>
          </Box>
          <Box>
            {alertMsg.status && (
              <Alert severity={alertMsg.severity}>{alertMsg.message}</Alert>
            )}
            <Snackbar
              open={snackopen.status}
              autoHideDuration={snackopen.duration}
              onClose={snackHandleClose}
              anchorOrigin={{
                vertical: snackopen.vertical,
                horizontal: snackopen.horizontal,
              }}
              key={snackopen.vertical + snackopen.horizontal}
            >
              <Alert
                onClose={snackHandleClose}
                severity={snackopen.severity}
                sx={{ width: "100%" }}
              >
                {snackopen.message}
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      )}

      {skeleton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <Skeleton sx={{ flexGrow: "1" }} height={40} variant="rectangular" />
          <Divider style={{ margin: "8px 0" }} />
        </Box>
      )}
      {skeleton && (
        <Box sx={{ padding: "10px 15px" }}>
          {radioSkeletons.map((index) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
              }}
              key={"skeletonKey" + index}
            >
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={{ marginRight: "7px" }}
              />
              <Skeleton
                sx={{ flexGrow: "1" }}
                height={20}
                variant="rectangular"
              />
            </Box>
          ))}
        </Box>
      )}

      {isLoading && (
        <Box sx={{ padding: "10px 15px" }}>
          {radioSkeletons.map((index) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
              }}
              key={"skeletonKey" + index}
            >
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={{ marginRight: "7px" }}
              />
              <Skeleton
                sx={{ flexGrow: "1" }}
                height={20}
                variant="rectangular"
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

export default Feedback;
