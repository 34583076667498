const getAutoGeneratedPassword = async (prefix = "", length = 10) => {
	const charset =
		"abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	var genPass = prefix;

	await [...Array(length)].forEach(
		(i, n): void =>
			(genPass += charset.charAt(Math.floor(Math.random() * n)))
	);

	return genPass;
};

export { getAutoGeneratedPassword };
