import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
    SvgIcon,
    Collapse,
    Typography,
    Divider,
    Box,
    Skeleton,
} from "@mui/material";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import ServiceDomainLabel from "./ServiceDomainLabel";
import axios from "axios";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: "translate3d(20px,0,0)",
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

function ServiceDomain(props) {
    const [treeItem, setTreeItem] = useState({});
    const [skeleton, setSkeleton] = useState(true);
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenData, setModalOpenData] = useState({
        title: "Title",
        description: "Description",
        status: "Status",
        data_type: "String",
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleModalClickOpen = () => {
        var getItem = JSON.parse(localStorage.getItem("selectToModify"));
        setModalOpenData(getItem);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        console.log("props data is",props);
        if (
            props.serviceUid !== undefined &&
            props.serviceUid !== null &&
            props.serviceUid !== ""
        ) {
            let axiosUrl = "";
            process.env.REACT_APP_VERSION === "S1" &&
                (axiosUrl =
                    process.env.REACT_APP_MODEL_ACCESS_S1 +
                    "ServiceDomainByUID/" +
                    props.serviceUid);
            process.env.REACT_APP_VERSION === "S2" &&
                (axiosUrl =
                    process.env.REACT_APP_MODEL_ACCESS_S2 +
                    "ServiceDomainByUID/" +
                    props.serviceUid);

            axios({
                method: "get",
                url: axiosUrl,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                },
            }).then((res) => {
                setTreeItem(res.data);
                localStorage.setItem("servicetree", JSON.stringify(res.data));
                // props.callbackFunction(res.data);
                setSkeleton(false);
            });
        }
    }, [props]);

    const radioSkeletons = [];
    for (let i = 1; i <= 10; i++) {
        radioSkeletons.push(i);
    }

    return (
        <>
            {!skeleton && (
                <Box>
                    <Box>
                        <Box
                            style={{
                                padding: "5px 10px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box>
                                <Typography gutterBottom variant="h5" component="div">
                                    {treeItem.name}
                                </Typography>
                                {treeItem.description != null && (
                                    <Typography variant="body2" color="text.secondary">
                                        {treeItem.description}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Divider style={{ margin: "0 0 8px 0" }} />
                    </Box>

                    <TreeView
                        aria-label="customized"
                        defaultExpanded={["1"]}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                    >
                        {/* <Droppable>
                              {(provided, snapshot) => ( */}
                        <div>
                            {/* 1st Item:Start */}
                            <StyledTreeItem
                                style={{ border: "3px solid #fff" }}
                                nodeId="1"
                                key="1"
                                label={
                                    <ServiceDomainLabel
                                        modalOpen={handleModalClickOpen}
                                        modalClose={handleModalClose}
                                        title={
                                            treeItem?.characteristics?.functionalPattern
                                                ? treeItem?.characteristics?.functionalPattern
                                                : "Characteristics"
                                        }
                                        description={treeItem?.characteristics?.assetType}
                                        sec-desc={treeItem?.characteristics?.genericArtefactType}
                                        status={treeItem?.characteristics?.status}
                                        data_type={treeItem?.characteristics?.dataType}
                                        type1="characteristics"
                                        type2=""
                                        type3=""
                                        serORfeed="service"
                                        group_name="radio-buttons-group-service"
                                        level="level1"
                                        editId={treeItem?.characteristics?.uid}
                                        editEnabled="false"
                                        showCompareRadio="false"
                                        meta={treeItem?.characteristics?.meta}
                                    />
                                }
                            />
                            {/* 1st Item:End */}

                            {/* 2nd Item:Start */}
                            <StyledTreeItem
                                style={{ border: "3px solid #fff" }}
                                nodeId="2"
                                key="2"
                                label={
                                    <ServiceDomainLabel
                                        modalOpen={handleModalClickOpen}
                                        modalClose={handleModalClose}
                                        title={
                                            treeItem?.businessArea?.name
                                                ? treeItem?.businessArea?.name
                                                : "Business Domain"
                                        }
                                        description={treeItem?.businessArea?.description}
                                        sec-desc=""
                                        status={treeItem?.businessArea?.status}
                                        data_type={treeItem?.businessArea?.dataType}
                                        type1="businessArea"
                                        type2=""
                                        type3=""
                                        serORfeed="service"
                                        group_name="radio-buttons-group-service"
                                        level="level1"
                                        editId={treeItem?.businessArea?.uid}
                                        editEnabled="false"
                                        showCompareRadio="false"
                                        meta={treeItem?.businessArea?.meta}
                                    />
                                }
                            />
                            {/* 2nd Item:End */}

                            {/* 3rd Item:Start */}
                            <StyledTreeItem
                                style={{ border: "3px solid #fff" }}
                                nodeId="3"
                                key="3"
                                label={
                                    <ServiceDomainLabel
                                        modalOpen={handleModalClickOpen}
                                        modalClose={handleModalClose}
                                        title={
                                            treeItem?.businessDomain?.name
                                                ? treeItem?.businessDomain?.name
                                                : "Business Domain"
                                        }
                                        description={treeItem?.businessDomain?.description}
                                        sec-desc=""
                                        status={treeItem?.businessDomain?.status}
                                        data_type={treeItem?.businessDomain?.dataType}
                                        type1="businessDomain"
                                        type2=""
                                        type3=""
                                        serORfeed="service"
                                        group_name="radio-buttons-group-service"
                                        level="level1"
                                        editId={treeItem?.businessDomain?.uid}
                                        editEnabled="false"
                                        showCompareRadio="false"
                                        meta={treeItem?.businessDomain?.meta}
                                    />
                                }
                            />
                            {/* 3rd Item:End */}

                            {/* 4th Item:Start */}
                            <StyledTreeItem
                                style={{ border: "3px solid #fff" }}
                                nodeId="4"
                                key="4"
                                label={
                                    <ServiceDomainLabel
                                        modalOpen={handleModalClickOpen}
                                        modalClose={handleModalClose}
                                        title={
                                            treeItem.controlRecord?.name
                                                ? treeItem.controlRecord?.name
                                                : "Control Record"
                                        }
                                        description={treeItem?.controlRecord?.description}
                                        sec-desc=""
                                        status={treeItem?.controlRecord?.status}
                                        data_type={treeItem?.controlRecord?.dataType}
                                        type1="controlRecord"
                                        type2=""
                                        type3=""
                                        serORfeed="service"
                                        group_name="radio-buttons-group-service"
                                        level="level1"
                                        editId={treeItem?.controlRecord?.uid}
                                        editEnabled="false"
                                        showCompareRadio="false"
                                        meta={treeItem?.controlRecord?.meta}
                                    />
                                }
                            >
                                {treeItem?.controlRecord?.attributes?.length > 0 &&
                                    treeItem?.controlRecord?.attributes.map((attrmap) => (
                                        <StyledTreeItem
                                            style={{ border: "3px solid #fff" }}
                                            nodeId={"4" + attrmap?.id}
                                            key={"4" + attrmap?.id}
                                            label={
                                                <ServiceDomainLabel
                                                    modalOpen={handleModalClickOpen}
                                                    modalClose={handleModalClose}
                                                    title={attrmap.name}
                                                    description={attrmap.description}
                                                    sec-desc={attrmap.dataType}
                                                    status={attrmap?.status}
                                                    data_type={attrmap?.dataType}
                                                    type1="controlRecord"
                                                    type2=""
                                                    type3=""
                                                    serORfeed="service"
                                                    group_name="radio-buttons-group-service"
                                                    level="level2"
                                                    editId={attrmap?.uid}
                                                    editEnabled="false"
                                                    showCompareRadio="false"
                                                    meta={attrmap?.meta}
                                                />
                                            }
                                        />
                                    ))}
                            </StyledTreeItem>
                            {/* 4th Item:End */}

                            {/* 5th Item:Start */}
                            <StyledTreeItem
                                style={{ border: "3px solid #fff" }}
                                nodeId="5"
                                key="5"
                                label={
                                    <ServiceDomainLabel
                                        modalOpen={handleModalClickOpen}
                                        modalClose={handleModalClose}
                                        title="Behaviour Qualifiers"
                                        description=""
                                        sec-desc=""
                                        status=""
                                        data_type=""
                                        type1="behaviourQualifiers"
                                        type2=""
                                        type3=""
                                        serORfeed="service"
                                        group_name="radio-buttons-group-service"
                                        level="level1"
                                        editId=""
                                        editEnabled="false"
                                        showCompareRadio="false"
                                        meta={treeItem?.behaviourQualifiers?.meta}
                                    />
                                }
                            >
                                {treeItem?.behaviourQualifiers?.length > 0 &&
                                    treeItem?.behaviourQualifiers?.map((attrmap) => (
                                        <StyledTreeItem
                                            style={{ border: "3px solid #fff" }}
                                            nodeId={"5-" + attrmap?._id}
                                            key={"5-" + attrmap?._id}
                                            label={
                                                <ServiceDomainLabel
                                                    modalOpen={handleModalClickOpen}
                                                    modalClose={handleModalClose}
                                                    title={attrmap.name}
                                                    description={attrmap.description}
                                                    sec-desc=""
                                                    status={attrmap?.status}
                                                    data_type={attrmap?.dataType}
                                                    type1="behaviourQualifiers"
                                                    type2=""
                                                    type3=""
                                                    serORfeed="service"
                                                    group_name="radio-buttons-group-service"
                                                    level="level2"
                                                    editId={attrmap?.uid}
                                                    editEnabled="false"
                                                    showCompareRadio="false"
                                                    meta={attrmap?.meta}
                                                />
                                            }
                                        >
                                            {attrmap.attributes?.length > 0 &&
                                                attrmap.attributes.map((attrmap2) => (
                                                    <StyledTreeItem
                                                        style={{ border: "3px solid #fff" }}
                                                        nodeId={"5-" + "attrmap?.id" + "-" + "attrmap2?.id"}
                                                        key={"5-" + attrmap?.id + "-" + attrmap2?.id}
                                                        label={
                                                            <ServiceDomainLabel
                                                                modalOpen={handleModalClickOpen}
                                                                modalClose={handleModalClose}
                                                                title={attrmap2.name}
                                                                description={attrmap2.description}
                                                                sec-desc=""
                                                                status={attrmap2?.status}
                                                                data_type={attrmap2?.dataType}
                                                                type1="behaviourQualifiers"
                                                                type2=""
                                                                type3=""
                                                                serORfeed="service"
                                                                group_name="radio-buttons-group-service"
                                                                level="level3"
                                                                editId={attrmap2?.uid}
                                                                editEnabled="false"
                                                                showCompareRadio="false"
                                                                meta={attrmap2?.meta}
                                                            />
                                                        }
                                                    />
                                                ))}
                                        </StyledTreeItem>
                                    ))}
                            </StyledTreeItem>
                            {/* 5th Item:End */}

                            {/* 6th Item:Start */}
                            <StyledTreeItem
                                style={{ border: "3px solid #fff" }}
                                nodeId="6"
                                key="6"
                                label={
                                    <ServiceDomainLabel
                                        modalOpen={handleModalClickOpen}
                                        modalClose={handleModalClose}
                                        title="Service Operations"
                                        description=""
                                        sec-desc=""
                                        status=""
                                        data_type=""
                                        type1="serviceOperations"
                                        type2=""
                                        type3=""
                                        serORfeed="service"
                                        group_name="radio-buttons-group-service"
                                        level="level1"
                                        editId=""
                                        editEnabled="false"
                                        showCompareRadio="false"
                                        meta={treeItem?.serviceOperations?.meta}
                                    />
                                }
                            >
                                {treeItem?.serviceOperations?.length > 0 &&
                                    treeItem?.serviceOperations?.map((attrmap) => (
                                        <StyledTreeItem
                                            style={{ border: "3px solid #fff" }}
                                            nodeId={"6-" + attrmap?.id}
                                            key={"6-" + attrmap?.id}
                                            label={
                                                <ServiceDomainLabel
                                                    modalOpen={handleModalClickOpen}
                                                    modalClose={handleModalClose}
                                                    title={attrmap.name}
                                                    description={attrmap.description}
                                                    sec-desc=""
                                                    status={attrmap?.status}
                                                    data_type={attrmap?.dataType}
                                                    type1="serviceOperations"
                                                    type2=""
                                                    type3=""
                                                    serORfeed="service"
                                                    group_name="radio-buttons-group-service"
                                                    level="level2"
                                                    editId={attrmap?.uid}
                                                    editEnabled="false"
                                                    showCompareRadio="false"
                                                    meta={attrmap?.meta}
                                                />
                                            }
                                        >
                                            {attrmap.inputAttributes?.length > 0 && (
                                                <Box
                                                    style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="h6"
                                                        component="h6"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        Input Attributes
                                                    </Typography>
                                                    <Divider style={{ margin: "8px 0" }} />

                                                    {attrmap.inputAttributes?.length > 0 &&
                                                        attrmap.inputAttributes.map((attrmap2) => (
                                                            <StyledTreeItem
                                                                style={{ border: "3px solid #fff" }}
                                                                nodeId={"6-1-" + attrmap2?.id}
                                                                key={"6-1-" + attrmap2?.id}
                                                                label={
                                                                    <ServiceDomainLabel
                                                                        modalOpen={handleModalClickOpen}
                                                                        modalClose={handleModalClose}
                                                                        title={attrmap2.name}
                                                                        description={attrmap2.description}
                                                                        sec-desc=""
                                                                        status={attrmap2?.status}
                                                                        data_type={attrmap2?.dataType}
                                                                        type1="serviceOperations"
                                                                        type2="inputAttributes"
                                                                        type3=""
                                                                        serORfeed="service"
                                                                        group_name="radio-buttons-group-service"
                                                                        level="level3"
                                                                        editId={attrmap2?.uid}
                                                                        editEnabled="false"
                                                                        showCompareRadio="false"
                                                                        meta={attrmap2?.meta}
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                </Box>
                                            )}
                                            {attrmap.outputAttributes?.length > 0 && (
                                                <Box
                                                    style={{
                                                        border: "1px solid rgba(0, 0, 0, 0.12)",
                                                        marginTop: "5px",
                                                    }}
                                                >
                                                    <Typography
                                                        gutterBottom
                                                        variant="h6"
                                                        component="h6"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        Output Attributes
                                                    </Typography>
                                                    <Divider style={{ margin: "8px 0" }} />

                                                    {attrmap.outputAttributes?.length > 0 &&
                                                        attrmap.outputAttributes.map((attrmap2) => (
                                                            <StyledTreeItem
                                                                style={{ border: "3px solid #fff" }}
                                                                nodeId={"6-2-" + attrmap2?.id}
                                                                key={"6-2-" + attrmap2?.id}
                                                                label={
                                                                    <ServiceDomainLabel
                                                                        modalOpen={handleModalClickOpen}
                                                                        modalClose={handleModalClose}
                                                                        title={attrmap2.name}
                                                                        description={attrmap2.description}
                                                                        sec-desc=""
                                                                        status={attrmap2?.status}
                                                                        data_type={attrmap2?.dataType}
                                                                        type1="serviceOperations"
                                                                        type2="outputAttributes"
                                                                        type3=""
                                                                        serORfeed="service"
                                                                        group_name="radio-buttons-group-service"
                                                                        level="level3"
                                                                        editId={attrmap2?.uid}
                                                                        editEnabled="false"
                                                                        showCompareRadio="false"
                                                                        meta={attrmap2?.meta}
                                                                    />
                                                                }
                                                            />
                                                        ))}
                                                </Box>
                                            )}
                                        </StyledTreeItem>
                                    ))}
                            </StyledTreeItem>
                            {/* 6th Item:End */}

                            {/* 7rd Item:Start */}
                            {treeItem.businessScenarios?.length !== 0 && (
                                <StyledTreeItem
                                    style={{ border: "3px solid #fff" }}
                                    nodeId="7"
                                    key="7"
                                    label={
                                        <ServiceDomainLabel
                                            modalOpen={handleModalClickOpen}
                                            modalClose={handleModalClose}
                                            title="Business Scenarios"
                                            description=""
                                            sec-desc=""
                                            status=""
                                            data_type=""
                                            type1="businessScenarios"
                                            type2=""
                                            type3=""
                                            serORfeed="service"
                                            group_name="radio-buttons-group-service"
                                            level="level1"
                                            editId=""
                                            editEnabled="false"
                                            showCompareRadio="false"
                                            meta={treeItem?.businessScenarios?.meta}
                                        />
                                    }
                                >
                                    {treeItem?.businessScenarios?.length > 0 &&
                                        treeItem?.businessScenarios?.map((attrmap) => (
                                            <StyledTreeItem
                                                style={{ border: "3px solid #fff" }}
                                                nodeId={"7" + attrmap?.id}
                                                key={"7" + attrmap?.id}
                                                label={
                                                    <ServiceDomainLabel
                                                        modalOpen={handleModalClickOpen}
                                                        modalClose={handleModalClose}
                                                        title={attrmap.name}
                                                        description={attrmap.description}
                                                        sec-desc={attrmap.dataType}
                                                        status={attrmap?.status}
                                                        data_type={attrmap?.dataType}
                                                        type1="businessScenarios"
                                                        type2=""
                                                        type3=""
                                                        serORfeed="service"
                                                        group_name="radio-buttons-group-service"
                                                        level="level2"
                                                        editId={attrmap?.uid}
                                                        editEnabled="false"
                                                        showCompareRadio="false"
                                                        meta={attrmap?.meta}
                                                    />
                                                }
                                            />
                                        ))}
                                </StyledTreeItem>
                            )}
                            {/* 7rd Item:End */}
                        </div>
                        {/* )}
                         </Droppable> */}
                    </TreeView>

                    {/* <ModelFormEdit data={modalOpenData} open={modalOpen} handleClose={handleModalClose} />
               <Divider style={{ margin: '8px 0' }} />

               <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '8px 0' }}>
                    <Button variant="contained" color="info" endIcon={<CheckIcon />} style={{ marginLeft: '5px' }} onClick={handleClickOpen}>
                         Submit
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                         <DialogTitle>{treeItem.name}</DialogTitle>
                         <Divider />
                         <DialogContent>
                              <DialogContentText>
                                   Are you sure you you want to sumbit this changes for {treeItem.name} in database
                              </DialogContentText>
                         </DialogContent>
                         <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={handleTreeSubmit}>Confirm</Button>
                         </DialogActions>
                    </Dialog>
               </Box> */}
                </Box>
            )}

            {skeleton && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "15px",
                    }}
                >
                    <Skeleton sx={{ flexGrow: "1" }} height={40} variant="rectangular" />
                    <Divider style={{ margin: "8px 0" }} />
                </Box>
            )}

            {skeleton && (
                <Box sx={{ padding: "10px 15px" }}>
                    {radioSkeletons.map((index) => (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingTop: "10px",
                            }}
                            key={"skeletonKey" + index}
                        >
                            <Skeleton
                                variant="circular"
                                width={20}
                                height={20}
                                sx={{ marginRight: "7px" }}
                            />
                            <Skeleton
                                sx={{ flexGrow: "1" }}
                                height={20}
                                variant="rectangular"
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
}

export default ServiceDomain;
