import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'

function OptionList({ listOption, handleOnchnge }) {
    const [defaultValue, setDefaultValue] = useState("")
    const [serviceBank, setServiceBank] = useState("")
    const [value, setValue] = useState("")
    const [orgList, setOrgList] = useState([]);

    useEffect(() => {
        var n = []
        listOption?.map((item) =>
            n.push({
                label: item.organisation + " v" + item.version,
                name: item.organisation,
                organisation: item.organisation,
                version: item.version,
                id: item.uid,
            })
        );
        setOrgList([...n])
        let defaultOrgSelected = n?.filter(filterArray => filterArray.organisation === "BIAN")
        defaultOrgSelected.length > 0 && setDefaultValue(defaultOrgSelected[0]);
    }, [listOption])


    return (
        <>
            <Autocomplete
                value={value}
                defaultValue={defaultValue}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    console.log(newValue, "onChange");
                    handleOnchnge(newValue)
                }}
                inputValue={serviceBank}
                onInputChange={(event, newInputValue) => {
                    setServiceBank(newInputValue);
                }}
                disablePortal
                autoSelect
                id="autocomplete_onload_bank_service"
                options={orgList}
                sx={{ width: 300, my: 2 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Select your Company"
                        variant="outlined"
                        className="auto_complete"
                    />
                )}
            />
        </>
    )
}

export default OptionList