import { Button, Chip, Divider, Typography, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import certificate_test from '../../format/certificate_test.json'
import { currentDomainForCertification } from '../../redux/certification/action';
import CertificateTestModal from './CertificateTestModal';
import SubjectCard from './SubjectCard';



function CertTest() {

    const [slugTestData, setSlugTestData] = useState({})
    const [certificateRolesType, setCertificateRolesType] = useState('')
    let { certTestId } = useParams();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAnswered = (type, response) => {
        console.log("selected answer response is =>", response, " | with type of =>", type);
    };

    useEffect(() => {
        console.log("certTestId is ==>", certTestId);

        var conSlugText = certTestId.replaceAll("-", " ").toUpperCase()
        var data = {}
        data = certificate_test.filter(fil => fil.test === conSlugText)
        data.length > 0 && setSlugTestData(data[0])

    }, [slugTestData, certTestId])

    const selectedDomainlist = useSelector(state => state.certification.selected_domain)
    const selectedCourselist = useSelector(state => state.certification.selected_course)

    console.log(selectedDomainlist, selectedCourselist);

    const dispatch = useDispatch()
    selectedDomainlist?.length > 0 && dispatch(currentDomainForCertification(selectedDomainlist[0]))

    return (
        <>
            <Box>
                <Box sx={{ padding: "10px" }}>
                    <Box sx={{ boxShadow: '0 4px 24px 0 rgba(34,41,47,.1)', padding: "20px 10px" }}>
                        {/* Heading Section */}
                        <Box className="headingSection">
                            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", mb: 0 }}>
                                <Typography variant="h5" component="span">{slugTestData?.name}</Typography>
                                <Chip label={slugTestData?.test} color="primary" size="small" sx={{ fontSize: 10, ml: 2 }} />
                            </Box>
                            <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>{slugTestData?.description}</Typography>
                        </Box>
                        {/* Subject Section with Chapter */}
                        <Divider sx={{ m: 1 }} />
                        <Box className="SubjectSection">
                            <Typography variant="h6" component="div" sx={{ mb: 2 }}>{slugTestData?.name}</Typography>
                            {/* Subjects */}
                            {slugTestData?.subjects?.length > 0 && slugTestData?.subjects.map(mapData => <SubjectCard key={mapData.key} data={mapData} makeDefaultOpen={true} />)}
                        </Box>
                        {/* Start Test Button */}
                        <Box sx={{ textAlign: "center" }}>
                            <Button
                                key="Proxy"
                                size='small'
                                color="primary"
                                variant='outlined'
                                onClick={() => {
                                    setCertificateRolesType('Proxy')
                                    handleClickOpen()
                                }}
                            >
                                Start
                            </Button>
                        </Box>
                        {/* Modal For Test */}
                        {open && <CertificateTestModal open={open} handleClose={handleClose} handleAnswered={handleAnswered} certificate_module={slugTestData} rolesType={certificateRolesType} />}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default CertTest