import React, { useEffect, useState } from "react";
import Servicelist from "./Servicelist";
import Feedbacklist from "./Feedbacklist";
import MainContent from "./MainContent";
import { makeStyles } from "@mui/styles";
import "./service.css";
import axios from "axios";
import withAppInsights from './../../../AppInsights';

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  left_bar: {
    margin: "20px 10px 0 20px",
    borderRadius: "10px",
    width: "290px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
  },
  right_bar: {
    margin: "20px 20px 0 10px",
    borderRadius: "10px",
    width: "290px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
  },

  maincontent: {
    width: "calc(100% - 620px)",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 240px)",
    },
  },
}));

function Service(props) {
  const classes = useStyles();
  const [serviceItems, setserviceItems] = useState("");
  const [feedItems, setfeedItems] = useState("");
  const [skeletonSer, setSkeletonSer] = useState(false);
  const [skeletonFer, setSkeletonFeed] = useState(false);
  const [suid, setsuid] = useState("");
  const [fuid, setfuid] = useState("");

  // BIAN v10.0.0

  // Service
  useEffect(() => {
    if (props.serviceOrg.organisation !== undefined) {
      setSkeletonSer(true);

      let axiosUrl = "";
      process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "BOPrimitiveTypesBasic/" + props.serviceOrg.organisation + "/" + props.serviceOrg.version + "/");
      process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "BOPrimitiveTypesBasic/" + props.serviceOrg.organisation + "/" + props.serviceOrg.version + "/");

      axios
        .get(axiosUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        })
        .then(
          (response) => {
            var newOrgService = [];
            response.data.length > 0 &&
              response.data.map((item) =>
                newOrgService.push({
                  name: item.name,
                  id: item.id,
                  bianId: item.bianId,
                  uid: item.uid,
                  status: item.status,
                  meta: item.meta,
                })
              );
            setserviceItems(newOrgService);
            setSkeletonSer(false);
          },
          (error) => console.log(error)
        );
    }
  }, [props.serviceOrg]);

  // Feedback
  useEffect(() => {
    if (props.feedOrg.organisation !== undefined) {
      setSkeletonFeed(true);

      let axiosUrl = "";

      process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "BOPrimitiveTypesBasic/" + props.feedOrg.organisation + "/" + props.feedOrg.version + "/");
      process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "BOPrimitiveTypesBasic/" + props.feedOrg.organisation + "/" + props.feedOrg.version + "/");

      axios
        .get(axiosUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        })
        .then(
          (response) => {
            var newOrgService = [];
            response.data.length > 0 &&
              response.data.map((item) =>
                newOrgService.push({
                  name: item.name,
                  id: item.id,
                  bianId: item.bianId,
                  uid: item.uid,
                  status: item.status,
                  meta: item.meta,
                })
              );
            setfeedItems(newOrgService);
            setSkeletonFeed(false);
          },
          (error) => console.log(error)
        );
    }
  }, [props.feedOrg]);

  function handleServiceRadioChange(uid) {
    setsuid(uid);
    localStorage.setItem("serviceRadioId", uid);
  }

  function handleFeedRadioChange(uid) {
    setfuid(uid);
    localStorage.setItem("serviceFeedRadioId", uid);
  }

  return (
    <>
      <div className={classes.maincontainer}>
        <div className={classes.left_bar}>
          <Servicelist
            skeleton={skeletonSer}
            serviceItems={serviceItems}
            currentOrganisation={props.serviceOrg.organisation}
            currentVersion={props.serviceOrg.version}
            handleRadioChange={handleServiceRadioChange}
          />
        </div>
        <div className={classes.maincontent}>
          <MainContent serviceUid={suid} feedUid={fuid} />
        </div>
        <div className={classes.right_bar}>
          <Feedbacklist
            skeleton={skeletonFer}
            serviceItems={feedItems}
            currentOrganisation={props.feedOrg.organisation}
            currentVersion={props.feedOrg.version}
            handleRadioChange={handleFeedRadioChange}
          />
        </div>
      </div>
    </>
  );
}

export default withAppInsights(Service);
