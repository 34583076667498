import {
  SELECT_DOMAIN_FOR_CERTIFICATION,
  CURRENT_DOMAIN_FOR_CERTIFICATION,
  SELECT_COURSE_FOR_CERTIFICATION,
} from "./type";

const initialState = {
  selected_domain: [],
  current_domain: {},
  selected_course: [],
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_DOMAIN_FOR_CERTIFICATION:
      return {
        ...state,
        selected_domain: action.payload,
      };
    case CURRENT_DOMAIN_FOR_CERTIFICATION:
      return {
        ...state,
        current_domain: action.payload,
      };
    case SELECT_COURSE_FOR_CERTIFICATION:
      return {
        ...state,
        selected_course: action.payload,
      };
    default:
      return state;
  }
}
