import React from 'react';

function Sample() {
    return (
        <div style={{
            maxHeight: '100vh',
            overflowY: 'scroll'
        }}>
            {/* <link
                href="https://bian.org/wp-content/themes/bian/styles/lib/bootstrap.min.css"
                rel="stylesheet"
            />
            <link
                href="https://bian.org/wp-content/themes/bian/style.css"
                rel="stylesheet"
            /> */}
            <link
                href="https://bian.org/wp-content/themes/bian/styles/lib/bootstrap.min.css"
                rel="stylesheet"
            />
            <link
                href="https://bian.org/wp-content/themes/bian/style.css"
                rel="stylesheet"
            />
            <link
                rel="shortcut icon"
                href="https://bian.org/wp-content/themes/bian/img/favicon.png"
                type="image/x-icon"
            />
            {/* HTML5 shim and Respond.js for IE8 support of HTML5 elements and media queries */}
            {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
            {/*[if lt IE 9]>
      
      
  <![endif]*/}
            {/* This site is optimized with the Yoast SEO plugin v4.7.1 - https://yoast.com/wordpress/plugins/seo/ */}
            <link
                rel="canonical"
                href="https://bian.org/deliverables/bian-portal/model-api-sandbox-help/"
            />

            <div className="container">
                <main>
                    <section
                        className="hero-banner offset-double"
                        style={{
                            backgroundImage:
                                "url(https://bian.org/wp-content/uploads/2017/03/people2.jpg)"
                        }}
                    >
                        <aside className="left">
                            <div className="row">
                                <div className="col-xs-12">
                                    <h3>BIAN Model API</h3>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-xs-12">
                                    <h1>How to use the Model API Sandbox?</h1>
                                </div>
                            </div>
                        </aside>
                    </section>
                    <section className="body-content offset">
                        <div className="row">
                            <div className="col-xs-12 col-md-9 col-md-offset-1">
                                <div className="body-content-border">
                                    <h1 className="p1">
                                        How to Use TheÂ&nbsp;Model APIÂ&nbsp;Sandbox
                                    </h1>
                                    <p>
                                        The BIAN Model APIs are meant to provide access to the various
                                        artefacts in the BIAN Model.
                                    </p>
                                    <p>
                                        The BIAN Model API Sandbox can be accessed Â&nbsp;here:{" "}
                                        <a
                                            href="https://bian-modelapisandbox.azurewebsites.net/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://bian-modelapisandbox.azurewebsites.net/
                                        </a>
                                        .Â&nbsp;(Please note that https://api.bian.org:4432/Â&nbsp;is
                                        now defunct and will be decommissioned soon).
                                    </p>
                                    <p>
                                        The BIAN Model API Sandbox Postman Collection can be accessed
                                        here:Â&nbsp;
                                        <a href="https://bian.org/wp-content/uploads/2021/12/BIAN-Model-Access-API-Sandbox_v1.postman_collection.json_.zip">
                                            BIAN Model Access API Sandbox_v1.postman_collection.json
                                        </a>
                                    </p>
                                    <p>
                                        We recommend getting an understanding of the BIAN model before
                                        starting to use these APIs. A comprehensive guide to the BIAN
                                        model can be found here:{" "}
                                        <a href="https://bian.org/wp-content/uploads/2020/10/BIAN-Semantic-API-Pactitioner-Guide-V8.1-FINAL.pdf">
                                            Practitioner Guide
                                        </a>
                                        .
                                    </p>
                                    <p className="p1">
                                        PleaseÂ&nbsp;
                                        <em>
                                            <strong>DO NOT</strong>
                                        </em>
                                        Â&nbsp;post any confidential data using this API . Any data
                                        posted via this endpoint is accessible to everyone.
                                    </p>
                                    <p className="p1">
                                        The{" "}
                                        <strong>
                                            <a
                                                href="https://github.com/bian-official/public/blob/main/LICENSE"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <em>Terms of Service</em>
                                            </a>
                                        </strong>{" "}
                                        for using this service can be found{" "}
                                        <strong>
                                            <a
                                                href="https://github.com/bian-official/public/blob/main/LICENSE"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <em>here</em>
                                            </a>
                                        </strong>
                                        .
                                    </p>
                                    <p>The BIAN artefacts are:</p>
                                    <ol>
                                        <li>
                                            Metas – Meta data on the model data managed by this API –
                                            specifically the Organisation and Version.
                                        </li>
                                        <li>Business Areas</li>
                                        <li>Business Domains</li>
                                        <li>Service Domains</li>
                                        <li>Business Objects</li>
                                        <li>Business Object Classes</li>
                                        <li>Business Object Enumerations</li>
                                        <li>Business Object Data Types</li>
                                        <li>Business Object Primitive Types</li>
                                        <li>Business Scenarios</li>
                                        <li>
                                            Scenario Groups – Groups of functionally related business
                                            scenarios
                                        </li>
                                        <li>Service Domain APIs</li>
                                        <li>Service Domain Information Html Pages (as in bian.org)</li>
                                    </ol>
                                    <p>
                                        We provide endpoints to access the above information, as well as
                                        to provide (POST) BIAN compliant artefacts that you might have
                                        implemented to be hosted as ‘Reference Implementations’ within
                                        BIAN.
                                    </p>
                                    <p>
                                        We will also review any reference implementations and add it
                                        back to the BIAN model if we find that it enriches the BIAN
                                        model.
                                    </p>
                                    <ul>
                                        <li>
                                            <h2>Token</h2>
                                            <p>
                                                The POST /Token endpoint is required to generate an
                                                authentication token to call the POST endpoints.
                                                <br />
                                                The GET endpoints do not need authorisation.
                                            </p>
                                            <p>
                                                <img
                                                    className="alignnone size-large wp-image-12017"
                                                    src="https://bian.org/wp-content/uploads/2021/12/Token_endpoint-1024x133.png"
                                                    alt=""
                                                    width={1024}
                                                    height={133}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/Token_endpoint-1024x133.png 1024w, https://bian.org/wp-content/uploads/2021/12/Token_endpoint-300x39.png 300w, https://bian.org/wp-content/uploads/2021/12/Token_endpoint-768x100.png 768w"
                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                />
                                                <br />
                                                To call the POST /Token endpoint you would need an username
                                                and password.
                                            </p>
                                            <p>
                                                <img
                                                    className="alignnone size-medium wp-image-12018"
                                                    src="https://bian.org/wp-content/uploads/2021/12/Token_schema-300x102.png"
                                                    alt=""
                                                    width={300}
                                                    height={102}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/Token_schema-300x102.png 300w, https://bian.org/wp-content/uploads/2021/12/Token_schema.png 592w"
                                                    sizes="(max-width: 300px) 100vw, 300px"
                                                />
                                            </p>
                                            <p>
                                                Please expand the /Token endpoint in the API swagger
                                                definition to find out more.
                                            </p>
                                        </li>
                                        <li>
                                            <h2>Metas</h2>
                                            <p>
                                                These endpoints manage Meta data related to the BIAN model
                                                data. The key attributes are Organisation and Version. eg:
                                                Organisation = BIAN and Version = 10.0.0.
                                            </p>
                                            <p>
                                                The schema as available in the API definition is as follows:
                                                <img
                                                    className="alignnone wp-image-11895 size-full"
                                                    src="https://bian.org/wp-content/uploads/2021/12/Meta_schema.png"
                                                    alt=""
                                                    width={634}
                                                    height={670}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/Meta_schema.png 634w, https://bian.org/wp-content/uploads/2021/12/Meta_schema-284x300.png 284w"
                                                    sizes="(max-width: 634px) 100vw, 634px"
                                                />
                                            </p>
                                            <p>&nbsp;</p>
                                            <p>
                                                Below is a list of endpoints to manage the Meta information:
                                            </p>
                                            <p>
                                                <img
                                                    className="alignnone wp-image-11897 size-large"
                                                    src="https://bian.org/wp-content/uploads/2021/12/Metas_endpoints-1024x546.png"
                                                    alt=""
                                                    width={1024}
                                                    height={546}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/Metas_endpoints-1024x546.png 1024w, https://bian.org/wp-content/uploads/2021/12/Metas_endpoints-300x160.png 300w, https://bian.org/wp-content/uploads/2021/12/Metas_endpoints-768x410.png 768w, https://bian.org/wp-content/uploads/2021/12/Metas_endpoints.png 1444w"
                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                />
                                            </p>
                                            <p>The key endpoint is highlighted in the red box, above.</p>
                                            <p>
                                                Please expand each endpoint in the API definition to find
                                                more information and on how to call it.
                                            </p>
                                            <p>&nbsp;</p>
                                            <ol>
                                                <li>
                                                    <h2>Business Areas</h2>
                                                    <p>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related to Business
                                                        Areas.
                                                    </p>
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                            <p>
                                                The schema as available in the API definition is as follows:
                                            </p>
                                            <p>
                                                <img
                                                    className="alignnone wp-image-11902 size-full"
                                                    src="https://bian.org/wp-content/uploads/2021/12/BusinessArea_schema.png"
                                                    alt=""
                                                    width={580}
                                                    height={458}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessArea_schema.png 580w, https://bian.org/wp-content/uploads/2021/12/BusinessArea_schema-300x237.png 300w"
                                                    sizes="(max-width: 580px) 100vw, 580px"
                                                />
                                            </p>
                                            <p>
                                                Some of theÂ&nbsp;keyÂ&nbsp;fieldsÂ&nbsp;are highlighted in
                                                the red boxes, above.
                                            </p>
                                            <p>
                                                Below is a list of endpoints to manage
                                                theÂ&nbsp;BusinessArea information:
                                            </p>
                                            <p>&nbsp;</p>
                                            <p>
                                                <img
                                                    className="alignnone wp-image-11903 size-large"
                                                    src="https://bian.org/wp-content/uploads/2021/12/BusinessArea_endpoints-1024x696.png"
                                                    alt=""
                                                    width={1024}
                                                    height={696}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessArea_endpoints-1024x696.png 1024w, https://bian.org/wp-content/uploads/2021/12/BusinessArea_endpoints-300x204.png 300w, https://bian.org/wp-content/uploads/2021/12/BusinessArea_endpoints-768x522.png 768w, https://bian.org/wp-content/uploads/2021/12/BusinessArea_endpoints.png 1430w"
                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                />
                                            </p>
                                            <p>The key endpoint is highlighted in the red box, above.</p>
                                            <p>
                                                Please expand each endpoint in the API definition to find
                                                more information and on how to call it.
                                            </p>
                                            <ol>
                                                <li>
                                                    <h2>Business Domains</h2>
                                                    <p>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related to Business
                                                        Domains.
                                                    </p>
                                                </li>
                                            </ol>
                                            <p>
                                                The schema as available in the API definition is as follows:
                                            </p>
                                            <p>
                                                <img
                                                    className="alignnone wp-image-11907 size-full"
                                                    src="https://bian.org/wp-content/uploads/2021/12/BusinessDomain_schema.png"
                                                    alt=""
                                                    width={722}
                                                    height={528}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessDomain_schema.png 722w, https://bian.org/wp-content/uploads/2021/12/BusinessDomain_schema-300x219.png 300w"
                                                    sizes="(max-width: 722px) 100vw, 722px"
                                                />
                                            </p>
                                            <p>
                                                Some of theÂ&nbsp;keyÂ&nbsp;fieldsÂ&nbsp;are highlighted in
                                                the red boxes, above.
                                            </p>
                                            <p>
                                                Below is a list of endpoints to manage
                                                theÂ&nbsp;BusinessDomain information:
                                            </p>
                                            <p>
                                                <img
                                                    className="alignnone wp-image-11908 size-large"
                                                    src="https://bian.org/wp-content/uploads/2021/12/BusinessDomain_endpoints-1024x819.png"
                                                    alt=""
                                                    width={1024}
                                                    height={819}
                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessDomain_endpoints-1024x819.png 1024w, https://bian.org/wp-content/uploads/2021/12/BusinessDomain_endpoints-300x240.png 300w, https://bian.org/wp-content/uploads/2021/12/BusinessDomain_endpoints-768x614.png 768w, https://bian.org/wp-content/uploads/2021/12/BusinessDomain_endpoints.png 1490w"
                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                />
                                            </p>
                                            <p>The key endpoint is highlighted in the red box, above.</p>
                                            <p>
                                                Please expand each endpoint in the API definition to find
                                                more information and on how to call it.
                                            </p>
                                            <ol>
                                                <li>
                                                    <h2>Service Domains</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Service Domains.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-11911 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/ServiceDomain_schema-780x1024.png"
                                                            alt=""
                                                            width={780}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/ServiceDomain_schema-780x1024.png 780w, https://bian.org/wp-content/uploads/2021/12/ServiceDomain_schema-229x300.png 229w, https://bian.org/wp-content/uploads/2021/12/ServiceDomain_schema-768x1008.png 768w, https://bian.org/wp-content/uploads/2021/12/ServiceDomain_schema.png 1064w"
                                                            sizes="(max-width: 780px) 100vw, 780px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fieldsÂ&nbsp;are highlighted in
                                                        the red boxes, above.
                                                    </p>
                                                    <p>
                                                        Below is a list of endpoints to manage the ServiceDomain
                                                        information:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-11912 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/ServiceDomain_endpoints-1024x669.png"
                                                            alt=""
                                                            width={1024}
                                                            height={669}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/ServiceDomain_endpoints-1024x669.png 1024w, https://bian.org/wp-content/uploads/2021/12/ServiceDomain_endpoints-300x196.png 300w, https://bian.org/wp-content/uploads/2021/12/ServiceDomain_endpoints-768x502.png 768w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of
                                                        theÂ&nbsp;/ServiceDomainsBasic endpoints, to get the
                                                        BianId and Name for each Service Domain. The BianId or
                                                        Name can then be used to call one of the other endpoints
                                                        to get detailed information about each Service Domain.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /ServiceDomains endpoints; in this case
                                                        you will need to use pagination as described in the
                                                        endpoint description.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it.
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Business Objects</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Business Objects.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-11946 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BusinessObjecT_schema-552x1024.png"
                                                            alt=""
                                                            width={552}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessObjecT_schema-552x1024.png 552w, https://bian.org/wp-content/uploads/2021/12/BusinessObjecT_schema-162x300.png 162w, https://bian.org/wp-content/uploads/2021/12/BusinessObjecT_schema-768x1425.png 768w, https://bian.org/wp-content/uploads/2021/12/BusinessObjecT_schema.png 774w"
                                                            sizes="(max-width: 552px) 100vw, 552px"
                                                        />
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                        <br />
                                                        FeatureUID – ID of the fields/attributes that belong to
                                                        this business object
                                                        <br />
                                                        FeatureName – Name of the field /attribute that belongs
                                                        to this business object
                                                        <br />
                                                        OwnerUID
                                                        <br />
                                                        OwnerNameBelow is a list of endpoints to manage the
                                                        ServiceDomain information
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-11947 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BusinessObject_endpoints-1024x724.png"
                                                            alt=""
                                                            width={1024}
                                                            height={724}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessObject_endpoints-1024x724.png 1024w, https://bian.org/wp-content/uploads/2021/12/BusinessObject_endpoints-300x212.png 300w, https://bian.org/wp-content/uploads/2021/12/BusinessObject_endpoints-768x543.png 768w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of
                                                        theÂ&nbsp;/BusinessObjectsBasic endpoints, to get the
                                                        BianId and Name for each Business Object. The BianId or
                                                        Name can then be used to call one of the other endpoints
                                                        to get detailed information about each Business Object.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /BusinessObjects endpoints; in this case
                                                        you will need to use pagination as described in the
                                                        endpoint description.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it.
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Business Object Classes</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Business Object Classes.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-11956 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BOClasses_schema-466x1024.png"
                                                            alt=""
                                                            width={466}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BOClasses_schema-466x1024.png 466w, https://bian.org/wp-content/uploads/2021/12/BOClasses_schema-137x300.png 137w, https://bian.org/wp-content/uploads/2021/12/BOClasses_schema-768x1687.png 768w, https://bian.org/wp-content/uploads/2021/12/BOClasses_schema.png 908w"
                                                            sizes="(max-width: 466px) 100vw, 466px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                        <br />
                                                        AttributeListÂ&nbsp;-Â&nbsp;List of fields/attributes
                                                        that belong to this business object class
                                                    </p>
                                                    <p>For each Attribute:</p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                        <br />
                                                        <strong>And</strong> <strong>one</strong>{" "}
                                                        <strong>of</strong>:<br />
                                                        a. BOEnumerationÂ&nbsp;– If the attribute is a BO
                                                        Enumeration, then the BO Enumeration object is defined,
                                                        else it is null.
                                                        <br />
                                                        b. BODataType – If the attribute is a BO DataType, then
                                                        the BOÂ&nbsp;DataType object is defined, else it is
                                                        null.
                                                        <br />
                                                        c. BOPrimitiveType – If the attribute is a
                                                        BOÂ&nbsp;DataType, then the
                                                        BOÂ&nbsp;DataTypeÂ&nbsp;object is defined, else it is
                                                        null.
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-11957 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BOClasses_endpoints-1024x758.png"
                                                            alt=""
                                                            width={1024}
                                                            height={758}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BOClasses_endpoints-1024x758.png 1024w, https://bian.org/wp-content/uploads/2021/12/BOClasses_endpoints-300x222.png 300w, https://bian.org/wp-content/uploads/2021/12/BOClasses_endpoints-768x569.png 768w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of
                                                        theÂ&nbsp;/BOClassesBasic endpoints, to get the BianId
                                                        and Name for each Business Object. The BianId or Name
                                                        can then be used to call one of the other endpoints to
                                                        get detailed information about each Business Object
                                                        Class.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /BOClasses endpoints; in this case you
                                                        will need to use pagination as described in the endpoint
                                                        description.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it.
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Business Object Enumerations</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Business Object Enumerations.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-11978"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BOEnumerations_schema-664x1024.png"
                                                            alt=""
                                                            width={664}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BOEnumerations_schema-664x1024.png 664w, https://bian.org/wp-content/uploads/2021/12/BOEnumerations_schema-195x300.png 195w, https://bian.org/wp-content/uploads/2021/12/BOEnumerations_schema-768x1185.png 768w, https://bian.org/wp-content/uploads/2021/12/BOEnumerations_schema.png 1180w"
                                                            sizes="(max-width: 664px) 100vw, 664px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                        <br />
                                                        AttributeListÂ&nbsp;-Â&nbsp;List of fields/attributes
                                                        that belong to this business object enumeration
                                                    </p>
                                                    <p>For each Attribute:</p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                        <br />
                                                        EnumerationLiterals – each EnumerationLiteral is one
                                                        possible value of the enumeration. Each Enumeration
                                                        Literal has key attributes such as BianId, Name, and
                                                        Description.
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-11979"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BOEnumerations_endpoints-1024x760.png"
                                                            alt=""
                                                            width={1024}
                                                            height={760}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BOEnumerations_endpoints-1024x760.png 1024w, https://bian.org/wp-content/uploads/2021/12/BOEnumerations_endpoints-300x223.png 300w, https://bian.org/wp-content/uploads/2021/12/BOEnumerations_endpoints-768x570.png 768w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of
                                                        theÂ&nbsp;/BOEnumerationsBasic endpoints, to get the
                                                        BianId and Name for each Business Object. The BianId or
                                                        Name can then be used to call one of the other endpoints
                                                        to get detailed information about each Business Object
                                                        Enumeration.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /BOEnumerations endpoints; in this case
                                                        you will need to use pagination as described in the
                                                        endpoint description.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it.
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Business Object Data Types</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Business Object Data Types.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-11988"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BODataTypes_schema-528x1024.png"
                                                            alt=""
                                                            width={528}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BODataTypes_schema-528x1024.png 528w, https://bian.org/wp-content/uploads/2021/12/BODataTypes_schema-155x300.png 155w, https://bian.org/wp-content/uploads/2021/12/BODataTypes_schema.png 744w"
                                                            sizes="(max-width: 528px) 100vw, 528px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-11993"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BODataTypesBasic_endpoints-1024x892.png"
                                                            alt=""
                                                            width={1024}
                                                            height={892}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BODataTypesBasic_endpoints-1024x892.png 1024w, https://bian.org/wp-content/uploads/2021/12/BODataTypesBasic_endpoints-300x261.png 300w, https://bian.org/wp-content/uploads/2021/12/BODataTypesBasic_endpoints-768x669.png 768w, https://bian.org/wp-content/uploads/2021/12/BODataTypesBasic_endpoints.png 1380w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of
                                                        theÂ&nbsp;/BODataTypesBasic endpoints, to get the BianId
                                                        and Name for each Business Object. The BianId or Name
                                                        can then be used to call one of the other endpoints to
                                                        get detailed information about each Business Object Data
                                                        Type.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /BODataTypes endpoints.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Business Object Primitive Types</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Business ObjectÂ&nbsp;Primitive Types.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-11992"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_schema-535x1024.png"
                                                            alt=""
                                                            width={535}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_schema-535x1024.png 535w, https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_schema-157x300.png 157w, https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_schema.png 760w"
                                                            sizes="(max-width: 535px) 100vw, 535px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-11991"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_endpoints-1024x790.png"
                                                            alt=""
                                                            width={1024}
                                                            height={790}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_endpoints-1024x790.png 1024w, https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_endpoints-300x231.png 300w, https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_endpoints-768x593.png 768w, https://bian.org/wp-content/uploads/2021/12/BOPrimitiveTypes_endpoints.png 1550w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of
                                                        theÂ&nbsp;/BOPrimitiveTypesBasic endpoints, to get the
                                                        BianId and Name for each Business Object Primitive Type.
                                                        The BianId or Name can then be used to call one of the
                                                        other endpoints to get detailed information about each
                                                        Business Object Primitive Type.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /BOPrimitiveTypes endpoints.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Business Scenarios</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related
                                                        toÂ&nbsp;Business Scenarios.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-12000 size-large"
                                                            src="https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_schema-1-722x1024.png"
                                                            alt=""
                                                            width={722}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_schema-1-722x1024.png 722w, https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_schema-1-211x300.png 211w, https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_schema-1-768x1090.png 768w, https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_schema-1.png 1490w"
                                                            sizes="(max-width: 722px) 100vw, 722px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                    </p>
                                                    <p>
                                                        ServiceConnections – each ‘ServiceConnection’ contains:
                                                    </p>
                                                    <ol>
                                                        <li>
                                                            ‘FromServiceConectionPart’ – This is the Service
                                                            Domain from where the Service Connection
                                                            originates.Â&nbsp; Key attribute: ‘
                                                            <div>
                                                                <div>‘ServiceDomainName’</div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            ‘ToServiceConectionPart’ – This is the Service
                                                            DomainÂ&nbsp;to where the Service Connection is
                                                            made.Â&nbsp; Key attribute: ‘
                                                            <div>
                                                                ‘ServiceDomainName’ and
                                                                ‘ServiceOperationDescription’
                                                            </div>
                                                            <p>
                                                                <img
                                                                    className="alignnone size-large wp-image-11996"
                                                                    src="https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_endpoints-1024x522.png"
                                                                    alt=""
                                                                    width={1024}
                                                                    height={522}
                                                                    srcSet="https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_endpoints-1024x522.png 1024w, https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_endpoints-300x153.png 300w, https://bian.org/wp-content/uploads/2021/12/BusinessScenarios_endpoints-768x391.png 768w"
                                                                    sizes="(max-width: 1024px) 100vw, 1024px"
                                                                />
                                                            </p>
                                                            <p>
                                                                Some of the key endpoint are highlighted in the red
                                                                box, above.
                                                            </p>
                                                            <p>
                                                                We recommend starting with one of
                                                                theÂ&nbsp;/BusinessScenariosBasic endpoints, to get
                                                                the BianId and Name for each Business Object. The
                                                                BianId or Name can then be used to call one of the
                                                                other endpoints to get detailed information about
                                                                each Business Scenario.
                                                            </p>
                                                            <p>
                                                                If you need to have the entire dataset, then please
                                                                call one of the GET /BusinessScenarios endpoints; in
                                                                this case you will need to use pagination as
                                                                described in the endpoint description.
                                                            </p>
                                                            <p>
                                                                Please expand each endpoint in the API definition to
                                                                find more information and on how to call it.
                                                            </p>
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li>
                                                    <h2>
                                                        Scenario Groups – Groups of functionally related
                                                        business scenarios
                                                    </h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related to (Business)
                                                        Scenario Groups.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-12002"
                                                            src="https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_shema-985x1024.png"
                                                            alt=""
                                                            width={985}
                                                            height={1024}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_shema-985x1024.png 985w, https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_shema-289x300.png 289w, https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_shema-768x798.png 768w"
                                                            sizes="(max-width: 985px) 100vw, 985px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                    </p>
                                                    <p>
                                                        BusinessScenariosÂ&nbsp;-Â&nbsp;each BusinessScenario
                                                        has the attributes needed to define them.
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-12003"
                                                            src="https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_endpoints-1024x529.png"
                                                            alt=""
                                                            width={1024}
                                                            height={529}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_endpoints-1024x529.png 1024w, https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_endpoints-300x155.png 300w, https://bian.org/wp-content/uploads/2021/12/ScenarioGroups_endpoints-768x397.png 768w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of the
                                                        /ScenarioGroupsBasic endpoints, to get the BianId and
                                                        Name for each Business Scenario. The BianId or Name can
                                                        then be used to call one of the other endpoints to get
                                                        detailed information about each Business Scenario.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /ScenarioGroups endpoints; in this case
                                                        you will need to use pagination as described in the
                                                        endpoint description.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it.
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>Service Domain APIs</h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related to Service
                                                        Domain APIs.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-full wp-image-12007"
                                                            src="https://bian.org/wp-content/uploads/2021/12/SD-APIs_schema.png"
                                                            alt=""
                                                            width={648}
                                                            height={658}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/SD-APIs_schema.png 648w, https://bian.org/wp-content/uploads/2021/12/SD-APIs_schema-295x300.png 295w"
                                                            sizes="(max-width: 648px) 100vw, 648px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        Description
                                                        <br />
                                                        OAS3_YAML – the YAML for the Service Domain API in OAS3
                                                        format.
                                                    </p>
                                                    <p>&nbsp;</p>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-12008"
                                                            src="https://bian.org/wp-content/uploads/2021/12/SD-APIs_endpoints-1024x826.png"
                                                            alt=""
                                                            width={1024}
                                                            height={826}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/SD-APIs_endpoints-1024x826.png 1024w, https://bian.org/wp-content/uploads/2021/12/SD-APIs_endpoints-300x242.png 300w, https://bian.org/wp-content/uploads/2021/12/SD-APIs_endpoints-768x620.png 768w, https://bian.org/wp-content/uploads/2021/12/SD-APIs_endpoints.png 1482w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of theÂ&nbsp;GET
                                                        /ApisBasic endpoints, to get the BianId and Name for
                                                        each Business Object. The BianId or Name can then be
                                                        used to call one of the other endpoints to get detailed
                                                        information about each Service Domain API.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /Apis endpoints.
                                                    </p>
                                                    <p>
                                                        Please expand each endpoint in the API definition to
                                                        find more information and on how to call it.
                                                    </p>
                                                </li>
                                                <li>
                                                    <h2>
                                                        Service Domain Information Html Pages (as in bian.org)
                                                    </h2>
                                                    <ol>
                                                        These group of endpoints provide the ability
                                                        toÂ&nbsp;GET and POST (if you are providing a reference
                                                        implementation or feedback) data related to Service
                                                        Domain Information Html Pages.
                                                    </ol>
                                                    <p>
                                                        The schema as available in the API definition is as
                                                        follows:
                                                    </p>
                                                    <p>
                                                        <img
                                                            className="alignnone wp-image-12011 size-full"
                                                            src="https://bian.org/wp-content/uploads/2021/12/HtmlPage_schema-1.png"
                                                            alt=""
                                                            width={620}
                                                            height={726}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/HtmlPage_schema-1.png 620w, https://bian.org/wp-content/uploads/2021/12/HtmlPage_schema-1-256x300.png 256w"
                                                            sizes="(max-width: 620px) 100vw, 620px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the keyÂ&nbsp;fields such are highlighted in the
                                                        red boxes, above:
                                                    </p>
                                                    <p>
                                                        BianId
                                                        <br />
                                                        Name
                                                        <br />
                                                        WpPageURL – URL of theÂ&nbsp;html page (in the bian.org
                                                        API Portal)
                                                        <br />
                                                        HtmlContentÂ&nbsp;– theÂ&nbsp;content of the page in
                                                        HTML format (in bian.org API Portal).
                                                    </p>
                                                    <p>The HtmlContent has the following key content:</p>
                                                    <ol>
                                                        <li style={{ listStyleType: "none" }}>
                                                            <ol>
                                                                <li>A link to the BIAN API Console</li>
                                                                <li>Executive Summary</li>
                                                                <li>Examples of Use</li>
                                                                <li>Related Business Scenarios</li>
                                                                <li>Core Business Object</li>
                                                                <li>Key Features</li>
                                                            </ol>
                                                        </li>
                                                    </ol>
                                                    <p>
                                                        <img
                                                            className="alignnone size-large wp-image-12012"
                                                            src="https://bian.org/wp-content/uploads/2021/12/HtmlPage_endpoints-1024x730.png"
                                                            alt=""
                                                            width={1024}
                                                            height={730}
                                                            srcSet="https://bian.org/wp-content/uploads/2021/12/HtmlPage_endpoints-1024x730.png 1024w, https://bian.org/wp-content/uploads/2021/12/HtmlPage_endpoints-300x214.png 300w, https://bian.org/wp-content/uploads/2021/12/HtmlPage_endpoints-768x548.png 768w"
                                                            sizes="(max-width: 1024px) 100vw, 1024px"
                                                        />
                                                    </p>
                                                    <p>
                                                        Some of the key endpoint are highlighted in the red box,
                                                        above.
                                                    </p>
                                                    <p>
                                                        We recommend starting with one of theÂ&nbsp;GET
                                                        /HtmlPagesBasic endpoints, to get the BianId and Name
                                                        for each Html Page. The BianId or Name can then be used
                                                        to call one of the other endpoints to get detailed
                                                        information about each HtmlPage.
                                                    </p>
                                                    <p>
                                                        If you need to have the entire dataset, then please call
                                                        one of the GET /HtmlPages endpoints.Please expand each
                                                        endpoint in the API definition to find more information
                                                        and on how to call it.
                                                    </p>
                                                </li>
                                            </ol>
                                            <p>&nbsp;</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div>
            <div className="container">
                <footer>
                    <div className="row">
                        <div className="col-xs-12">
                            <hr />
                        </div>
                        <div className="col-xs-12 col-sm-5">
                            <a className="brand" href="/">
                                <img src="https://bian.org/wp-content/themes/bian/img/logo-white.png" />
                            </a>
                            <address>
                                <div className="textwidget">
                                    <p>
                                        General enquiries:
                                        <br />
                                        <a href="mailto:info@bian.org">info@bian.org</a>
                                        <br />
                                    </p>
                                </div>
                            </address>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <h5>Quick links</h5>
                            <ul id="menu-footer-navigation" className="quick-links">
                                <li
                                    id="menu-item-24"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-24"
                                >
                                    <a href="https://bian.org/about-bian/">About Bian </a>
                                </li>
                                <li
                                    id="menu-item-27"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-27"
                                >
                                    <a href="https://bian.org/participate/">Participate </a>
                                </li>
                                <li
                                    id="menu-item-10677"
                                    className="menu-item menu-item-type-post_type menu-item-object-page current-page-ancestor menu-item-10677"
                                >
                                    <a href="https://bian.org/deliverables/bian-portal/">
                                        BIAN Portal{" "}
                                    </a>
                                </li>
                                <li
                                    id="menu-item-26"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-26"
                                >
                                    <a href="https://bian.org/members/">Members </a>
                                </li>
                                <li
                                    id="menu-item-1278"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1278"
                                >
                                    <a href="https://bian.org/imprint/">Imprint </a>
                                </li>
                                <li
                                    id="menu-item-3749"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3749"
                                >
                                    <a href="https://bian.org/privacy-policy-bian/">
                                        Privacy Policy of BIAN{" "}
                                    </a>
                                </li>
                                <li
                                    id="menu-item-10674"
                                    className="menu-item menu-item-type-post_type menu-item-object-page current-page-ancestor menu-item-10674"
                                >
                                    <a href="https://bian.org/deliverables/">Deliverables </a>
                                </li>
                            </ul>{" "}
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <h5>BIAN updates</h5>
                            <ul id="menu-social-links" className="social-links">
                                <li
                                    id="menu-item-272"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-272"
                                >
                                    <a href="https://bian.org/news-room/bian-newsletter/">
                                        <i className="icon mail" /> Subscribe{" "}
                                    </a>
                                </li>
                                <li
                                    id="menu-item-31"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-31"
                                >
                                    <a href="https://www.linkedin.com/groups/2098345/">
                                        <i className="icon linkedin" /> LinkedIn{" "}
                                    </a>
                                </li>
                            </ul>{" "}
                        </div>
                        <div className="col-xs-12">
                            <small>
                                {" "}
                                <div className="textwidget">
                                    <br />
                                </div>
                            </small>
                        </div>
                        <div className="col-xs-12">
                            <hr />
                        </div>
                    </div>
                </footer>
            </div>
            {/* jQuery */}
        </div>

    );
}

export default Sample
