import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

function WorkingGroup() {
  const [selectedList, setSelectedList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [dataTableData, setDataTableData] = useState([]);
  const [filterConvertedData, setFilterConvertedData] = useState([]);

  useEffect(() => {
    getWorkingGroup();
  }, []);

  // Function to open the modal and set the selected item
  const openModal = (item) => {
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const getWorkingGroup = async () => {
    const userVal = JSON.parse(localStorage.getItem("tokenDetails"));
    // `${process.env.REACT_APP_SECURITY_S1}GetWorkingGroupByOrganisationId/${userVal.OrganisationID}`,
    // ${process.env.REACT_APP_SECURITY_S1}WorkingGroup

    await axios
      .get(
        `${process.env.REACT_APP_SECURITY_S1}GetWorkingGroupByOrganisationId/${userVal.OrganisationID}`,
        // `${process.env.REACT_APP_SECURITY_S1}WorkingGroup`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        }
      )
      .then(async (response) => {
        // If request is good...
        console.log("If request is good... ", response.data);
        const convertedData = response.data.map((item, index) => ({
          isSigned: item.isSigned,
          name: item.name,
          description: item.description,
          charter: item.charter,
          lastModified: item.lastModified,
          uid: item.uid,
        }));

        setDataTableData(convertedData);
        setFilterConvertedData(convertedData);
      })
      .catch((error) => {
        console.log("error " + error);
      });
  };

  const columns = [
    {
      name: "Working Group Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Charter Text",
      selector: (row) => (row.charter == null ? "" : row.charter),
      cell: (row) => {
        return (
          <div style={{ whiteSpace: "normal" }}>
            {(row.charter == null ? "" : row.charter).slice(0, 10)}{" "}
            <Button
              size="small"
              variant="outlined"
              style={{ height: "20px" }}
              onClick={() => {
                openModal(row.charter == null ? "" : row.charter);
                setSelectedItem(row.charter == null ? "" : row.charter);
              }}
            >
              Read more
            </Button>
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Date Time",
      selector: (row) => row.lastModified,
      sortable: true,
    },
  ];

  const handleFilterSearch = (event) => {
    const newData = filterConvertedData.filter(
      (row) =>
        row.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.description
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.charter.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataTableData(newData);
  };

  async function saveAgreements() {
    const idList = selectedList.map((item) => item.uid);
    console.log("selectedList", selectedList);
    const userVal = JSON.parse(localStorage.getItem("tokenDetails"));
    // `${process.env.REACT_APP_SECURITY_S1}GetWorkingGroupByOrganisationId/${userVal.OrganisationID}`,
    console.log("ids ", idList);
    console.log("userVal.OrganisationID ", userVal.OrganisationID);
    await axios
      .put(
        `${process.env.REACT_APP_SECURITY_S1}/WorkingGroupsUpdate?organisationId=${userVal.OrganisationID}`,
        idList,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        }
      )
      .then(async (response) => {
        // If request is good...
        console.log("If request is good... ", response.data);
        window.alert("saved");
      })
      .catch((error) => {
        console.log("error " + error);
      });
  }

  const handleChange = ({ selectedRows }) => {
    setSelectedList(selectedRows);
    console.log("Selected Rows: ", selectedRows);
  };

  const isRowSelectable = (row) => {
    // Condition to disable selection for a specific row
    return row.isSigned == true; // Disable selection for the row with ID 2
  };

  return (
    <Box style={{ overflow: "auto", height: "65vh" }}>
      <Container style={{ paddingTop: "30px" }}>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 10px",
          }}
        >
          <Typography>Working Group</Typography>
          <CardActions>
            <input
              type="text"
              onChange={handleFilterSearch}
              placeholder="Search"
              style={{
                border: "1px solid grey",
                padding: "6px",
                marginRight: "12px",
                borderRadius: "4px",
              }}
            />
            {/* userDetails != null && userDetails.OrganisationName == 'BIAN' */}
            {/* (userDetails.Role == 'ToolAdministrator' || userDetails.Role == 'UserAdministrator')  */}
            <Link to="/manage/working-group/add">
              <Button
                size="small"
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ marginRight: "12px" }}
              >
                Add working group
              </Button>
            </Link>
            <Button size="small" variant="outlined" onClick={saveAgreements}>
              Submit
            </Button>
          </CardActions>
        </Card>
        <TableContainer component={Paper} style={{}}>
          <DataTable
            pagination
            columns={columns}
            data={dataTableData}
            selectableRows
            selectableRowsDisabledField="disabled"
            selectableRowDisabled={isRowSelectable}
            onSelectedRowsChange={handleChange}
          />
          {/* <div className="row">
            <b>Selected Row Items(Click Button To Get):</b>
            <code>{JSON.stringify(selectedList)}</code>
          </div> */}
        </TableContainer>
      </Container>

      <Modal isOpen={showModal} onRequestClose={closeModal}>
        <button style={{ position: "sticky" }} onClick={closeModal}>
          close
        </button>
        <br />
        <p>{selectedItem}</p>
      </Modal>
    </Box>
  );
}

export default WorkingGroup;
