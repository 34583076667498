import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import InsertLinkIcon from "@mui/icons-material/InsertLink";

const OrganisationProfile = ({ details, callbackFunction }) => {
  const [organisation, setOrganisation] = useState({
    uid: "",
    name: "",
    displayName: "",
    description: "",
    emailAddress: "",
    webSite: "",
    phoneNumber: "",
    status: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let { orgId } = useParams();

  const setOrganisationData = (organisationDetail) => {
    return {
      uid:
        organisationDetail.uid !== "string" &&
        organisationDetail.uid !== null &&
        organisationDetail.uid !== undefined
          ? organisationDetail.uid
          : "",
      name:
        organisationDetail.name !== "string" &&
        organisationDetail.name !== null &&
        organisationDetail.name !== undefined
          ? organisationDetail.name
          : "",
      displayName:
        organisationDetail.displayName !== "string" &&
        organisationDetail.displayName !== null &&
        organisationDetail.displayName !== undefined
          ? organisationDetail.displayName
          : "",
      description:
        organisationDetail.description !== "string" &&
        organisationDetail.description !== null &&
        organisationDetail.description !== undefined
          ? organisationDetail.description
          : "",
      emailAddress:
        organisationDetail.emailAddress !== "string" &&
        organisationDetail.emailAddress !== null &&
        organisationDetail.emailAddress !== undefined
          ? organisationDetail.emailAddress
          : "",
      webSite:
        organisationDetail.webSite !== "string" &&
        organisationDetail.webSite !== null &&
        organisationDetail.webSite !== undefined
          ? organisationDetail.webSite
          : "",
      phoneNumber:
        organisationDetail.phoneNumber !== "string" &&
        organisationDetail.phoneNumber !== null &&
        organisationDetail.phoneNumber !== undefined
          ? organisationDetail.phoneNumber
          : "",
      status:
        organisationDetail.status !== "string" &&
        organisationDetail.status !== null &&
        organisationDetail.status !== undefined
          ? organisationDetail.status
          : "",
    };
  };

  const loadOrganisation = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "OrganisationByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "OrganisationByUid/" + uid);

    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log("If request is good... organisation  =>", response.data);
        setError(false);
        setOrganisation(setOrganisationData(response.data));
        callbackFunction();
      })
      .catch((error) => {
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };
  useEffect(() => {
    loadOrganisation(orgId);
  }, [details]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Avatar
            src={organisation.avatar}
            sx={{
              height: 64,
              mb: 2,
              width: 64,
            }}
          />

          {organisation.displayName ? (
            <Typography color="textPrimary" gutterBottom variant="h5">
              {organisation.displayName}
            </Typography>
          ) : (
            <Typography color="textPrimary" gutterBottom variant="h5">
              {organisation.name}
            </Typography>
          )}
          {organisation.description && (
            <Typography color="textSecondary" variant="body2">
              {organisation.description}
            </Typography>
          )}
          {organisation.emailAddress !== undefined && (
            <Typography color="textSecondary" variant="body2">
              {organisation.emailAddress}
            </Typography>
          )}
          {organisation.webSite !== undefined && (
            <Link href={organisation.webSite}>
              <Button color="primary" variant="text" size="small">
                Visit
              </Button>
            </Link>
          )}
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <Button color="primary" fullWidth variant="text">
          Upload picture
        </Button>
      </CardActions>
    </Card>
  );
};

export default OrganisationProfile;
