import React from "react";
import FileViewer from "react-file-viewer";

const DataType_v1 = () => {
    return <>
        <FileViewer
            fileType={'docx'}
            filePath={require("./DataType_v1.docx")}
            style={{
                maxHght: '616px',
                overflowY: 'scroll',
                minWidth: '80%'
            }}
        />
    </>

}
export default DataType_v1