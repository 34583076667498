import React, { useEffect } from "react";
import "./components/dashboard/dashboard.css";
import { ThemeProvider } from "@mui/material/styles";
import { dashboard } from "./components/theme/dashboard";
import Dashboard from "./components/dashboard/Dashboard";
// import Sample from './components/Sample';
import Authentication from "./components/userlogin/Authentication";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./components/main/Home";
import "../src/components/dashboard/BusinessScenarioService/sample.css";
import "../src/components/dashboard/BusinessScenario/demo/diagram.css";
import Login from "./components/userlogin/Login";
import Unauthorize from "./components/userlogin/Unauthorize";
import ForgotUsername from "./components/userlogin/ForgotUsername";
import ForgotPassword from "./components/userlogin/ForgotPassword";
import CreatePassword from "./components/userlogin/CreatePassword";
import QnAs from "./pages/QnA/QnA";
import Register from "./components/userlogin/Register";
import Manage from "./pages/Manage/Manage";
import Certification from "./pages/Certifications/Certification";
import withAppInsights from "./AppInsights";
import { useDetectAdBlock } from "adblock-detect-react";
import QnAQuestions from "./pages/QnA/components/QnAQuestions";
import Qusetion from "./pages/QnA/components/Qusetion";
import ApiDocumentation from "./components/redoc/ApiDocumentation";

// import { ApplicationInsights } from '@microsoft/applicationinsights-web'
// import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
// import { createBrowserHistory } from "history";
// const browserHistory = createBrowserHistory({ basename: 'https://uksouth-1.in.applicationinsights.azure.com' });

// var reactPlugin = new ReactPlugin();

// const appInsights = new ApplicationInsights({
// 	config: {
// 		connectionString: 'b01164f1-cb00-482b-beba-f0386f9a2e69',
// 		extensions: [reactPlugin],
// 		extensionConfig: {
// 			[reactPlugin.identifier]: { history: browserHistory }
// 		}

// 	}
// });
// appInsights.loadAppInsights();
// appInsights.trackPageView();

function App() {
	const pathVal = window.location.pathname;
	const adBlockDetected = useDetectAdBlock();

	useEffect(() => {
		if (adBlockDetected) {
			console.log("ad blocket detected");
		} else {
			console.log("not detected");
		}
	}, []);

	return (
		// <AppInsightsErrorBoundary
		// 	onError={({ msg }) => console.log("Some error occurs", msg)}
		// 	appInsights={appInsights}>
		<>
			<ThemeProvider theme={dashboard}>
				{pathVal === "/" ? (window.location.pathname = "/dashboard") : null}

				<Routes>
					<Route
						path="/dashboard/*"
						component={Dashboard}
						element={<Dashboard />}
					/>

					<Route
						path="/account/*"
						component={Authentication}
						element={<Authentication />}
					/>
					<Route path="/home" component={Home} element={<Home />} />
					<Route path="/manage/*" component={Home} element={<Home />} />
					<Route
						exact="true"
						path="/manage/"
						element={<Manage path="/manage" />}
					/>

					<Route path="/QnAs" element={<QnAs />}>
						<Route index element={<QnAQuestions />} />
						<Route path="question/:id" element={<Qusetion />} />
					</Route>

					<Route path="/certification/*" element={<Certification />} />
					{/* <Route path="/" component={<Navigate to='/dashboard' />} /> */}
					<Route path="/login" component={Login} element={<Login />} />
					<Route exact="true" path="/register" element={<Register />} />
					<Route exact="true" path="/api-doc" element={<ApiDocumentation />} />
					<Route
						exact="true"
						path="/forgot/username"
						element={<ForgotUsername />}
					/>
					<Route
						exact="true"
						path="/forgot/password"
						element={<ForgotPassword />}
					/>
					<Route
						exact="true"
						path="/forgot/password/create/"
						element={<CreatePassword />}
					/>
					{/* <Route path="/sample" component={Sample} element={<Sample />} /> */}
					<Route element={<Unauthorize />} />
				</Routes>
			</ThemeProvider>
		</>
		// </AppInsightsErrorBoundary>
	);
}

export default withAppInsights(App);
