import React from "react";
import { Navigate } from "react-router-dom";

const withLogged = (Component) => {
  const AuthRoute = () => {
    //   if (localStorage.getItem("auth_token") == 1995) {
    return <Component />;
    // } else {
    //   return <Navigate to="/" />;
    // }
  };

  return AuthRoute;
};

const withApprove = (Component) => {
  const AuthRoute = () => {
    if (localStorage.getItem("auth_token_status") == 1996) {
      return <Component />;
    } else {
      return <Navigate to="/" />;
    }
  };

  return AuthRoute;
};

export { withLogged, withApprove };
