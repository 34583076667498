import { GET_ORGANISATION_WITHOUT_LOGIN, GET_ORGANISATION } from "./type";
import axios from "axios";
import { getOrgAPI } from "../../utility/api/api_generator";

const org_api = getOrgAPI();

export const getOrganisation = (payload) => async (dispatch) => {
  try {
    const response = await axios.get(org_api).catch((error) => {
      console.error("error in api data fetching of getOrganisation", error);
    });
    if (response.status === 200) {
      if ("data" in response) {
        dispatch(getOrganisationData(response.data));
      } else {
        dispatch(getOrganisationData([]));
      }
    } else {
      dispatch(getOrganisationData([]));
    }
  } catch {
    console.error("error in try block of getOrganisation");
  }
};

export const getOrganisationData = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ORGANISATION,
      payload: payload,
    });
  } catch {
    dispatch({
      type: GET_ORGANISATION,
      payload: payload,
    });
  }
};
