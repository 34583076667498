import { CallToActionRounded } from "@mui/icons-material";

const INITIAL_STATE = {
  SelectedFeedback: "",
  fromToConnection: [],
  fromToConnectionAllConnection: [],
  navBarDropDownValue: {
    "label": "BIAN v10.1.0",
    "name": "BIAN",
    "organisation": "BIAN",
    "version": "10.1.0",
    "id": "BIAN_Release_10.1.0"
  },
  allCollection: {},
  newConnection: "",
  domainDetails: [],
  allElements: [],
  createStatus: false,
  createdConnection: [],
  allNodePositionData: {},
  selectedTab: '',
  businessScenarioData: {},
  defaultServiceDomainText: '',
  businessScenarioDefaultSelected: '',
  feedbackDomain: null,
  setFeedbackServiceDomain: {},
  selectedFeedbackDomainVal: {},
  allFeedbackData: [],
  newallFeed: [],
  refreshNodes: false,
  dataTypeVal: '',
  classVal: '',
  searchVal: [],
  enumrationVal: '',
  primitiveDataTypeVal: '',
  searchText: '',
  defaultServiceData: [],
  searchField: '',
  updatedDomain: {},
  feedbackNav: {
    "label": "BIAN v10.1.0",
    "name": "BIAN",
    "organisation": "BIAN",
    "version": "10.1.0",
    "id": "BIAN_Release_10.1.0"
  },
  updateBusinessCount: 0
};

const reducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case "SELECT_TAB":
      return {
        ...state,
        selectedTab: action.payload,
      };
    case "UPDATE_BUSINESS_SCENARIO":
      return {
        ...state,
        defaultBusinessScenario: action.payload,
      };

    case "SEARCHING_TEXT":
      return {
        ...state,
        searchText: action.payload,
      };
    case "UPDATE_DOMAIN":
      return {
        ...state,
        updatedDomain: action.payload,
      };
    case "DEFAULT_SERVICE_DATA":
      return {
        ...state,
        defaultServiceData: action.payload,
      };
    case "SEARCHING_FIELD":
      return {
        ...state,
        searchField: action.payload,
      };
    case "SEARCH_VAL":
      return {
        ...state,
        searchVal: action.payload,
      };
    case "DATA_TYPE_VAL":
      return {
        ...state,
        dataTypeVal: action.payload,
      };
    case "PRIMITIVE_DATA_TYPE_VAL":
      return {
        ...state,
        primitiveDataTypeVal: action.payload,
      }
    case "CLASS_VAL":
      return {
        ...state,
        classVal: action.payload,
      };

    case "ENUMRATION_VAL":
      return {
        ...state,
        enumrationVal: action.payload,
      };
    case "SELECTED_FEEDBACK_DOMAIN":
      return {
        ...state,
        feedbackDomain: action.payload,
      }
    case "CREATEDIAGRAM":
      return {
        ...state,
        createStatus: action.payload,
      };
    case "ADDCONNECTION":
      return {
        ...state,
        createdConnection: [...state.createdConnection, action.payload]
      };
    case "CLEARADDCONNECTION":
      return {
        ...state,
        createdConnection: [],
        allCollection: [],
        newConnection: [],
        newallFeed: [],
        allFeedbackDat: [],
      };
    case "DOMAIN_DETAILS":
      return {
        ...state,
        domainDetails: action.payload,
      };
    case "SELECT_FEEDBACK_NAV":
      return {
        ...state,
        feedbackNav: action.payload,
      };
    case "SELECTED_FEEDBACK_DOMAIN_VAL":
      return {
        ...state,
        selectedFeedbackDomainVal: action.payload,
      };
    case "SELECTED_FEEDBACK_SERVICE":
      return {
        ...state,
        SelectedFeedback: [...state.SelectedFeedback, action.payload[0]]
      };
    case "ALL_FEEDBACK_DOMAIN":
      return {
        ...state,
        allFeedbackData: action.payload
      };
    case "SET_FEEDBACK_SERVIES_DOMAIN":
      return {
        ...state,
        setFeedbackServiceDomain: action.payload
      };
    case "FROM_TO_CONNECTION":
      return {
        ...state,
        fromToConnection: action.payload
      };

    case "ALL_CONNECT_CREATED":
      return {
        ...state,
        fromToConnectionAllConnection: action.payload
      };
    case "BUSINESS_SCENARIO_SERVICE_SELECTED":
      return {
        ...state,
        businessScenarioDefaultSelected: action.payload
      };
    case "SELECTED_BUSINESS_SCENARIO":
      return {
        ...state,
        // fromToConnection: action.payload
        businessScenarioData: action.payload
      };
    case "SERVICE_DOMAIN_TEXT":
      return {
        ...state,
        // fromToConnection: action.payload
        defaultServiceDomainText: action.payload
      };
    case "NAVBAR_DROP_DOWN_VALUE":
      return {
        ...state,
        navBarDropDownValue: action.payload

      };
    case "ALLCOLLECTION":
      return {
        ...state,
        allCollection: action.payload
      };
    case "REFRESH_ALL_NODES":
      return {
        ...state,
        refreshNodes: action.payload
      };
    case "UPDATEPOSITION":
      const newFeedNode = state?.SelectedFeedback && state?.SelectedFeedback !== "" && state?.SelectedFeedback.length > 0 && state?.SelectedFeedback.find(({ name }) => name === action.data.id)
      if (newFeedNode) {
        const allFeedNode = []
        state.SelectedFeedback.map((item) => {
          if (item.name === action.data.id) {
            item.x = parseInt(action.data.x)
            item.y = parseInt(action.data.y)
          }
          allFeedNode.push(item)
        })
        return {
          ...state,
          SelectedFeedback: allFeedNode
        };
      } else {

        const mainCollect = []
        state.allCollection.map((item) => {
          if (item.fromServiceConnectionPart.serviceDomainName === action.data.id) {
            item.fromServiceConnectionPart.x = parseInt(action.data.x)
            item.fromServiceConnectionPart.y = parseInt(action.data.y)
          }
          if (item.toServiceConnectionPart.serviceDomainName === action.data.id) {
            item.toServiceConnectionPart.x = parseInt(action.data.x)
            item.toServiceConnectionPart.y = parseInt(action.data.y)
          }
          mainCollect.push(item)
        })
        return {
          ...state,
          allCollection: mainCollect
        };
      }

    case "UPDATEPOSITIONNEWDIAGRAM":
      const allNodePositionVal = state.allNodePositionData
      allNodePositionVal[action.data.id] = action.data
      return {
        ...state,
        allNodePositionData: allNodePositionVal
      };

    case "SETPOSITION":
      const allNodePosition = []
      if (action.payload && state.allCollection) {
        state.allCollection.map((item) => {
          if(item) {
            const from = action?.payload.find(({ id }) => id === item?.fromServiceConnectionPart?.serviceDomainName)
            item.fromServiceConnectionPart.x = parseInt(from?.position?.x)
            item.fromServiceConnectionPart.y = parseInt(from?.position?.y)
            const to = action.payload.find(({ id }) => id === item.toServiceConnectionPart.serviceDomainName)
            item.toServiceConnectionPart.x = parseInt(to?.position?.x)
            item.toServiceConnectionPart.y = parseInt(to?.position?.y)
            allNodePosition.push(item)
          }
        })
      }

      return {
        ...state,
        allCollection: allNodePosition
      };

    case "ALL_FEED_DOMAIN":
      return {
        ...state,
        newallFeed: [...state.newallFeed, action.payload]
      };

    case "NEW_CONNECTION":
      const defaultData = {
        "from": action.payload.source,
        "to": action.payload.target,
        "shape": null,
        "connection": action.payload.serviceOperationDescription,
        "uid": action.payload.target
      }
      return {
        ...state,
        newConnection: [...state.newConnection, { ...action.payload, type: action.payload.actiontype }],
        fromToConnection: [...state.fromToConnection, defaultData]
      };

    case "UPDATE_CONNECTION_NAME":
      // const selectedele = state?.allCollection.find((val) => val?.bianId === action.payload.editableEdge.bianId)
      const selectedele = state?.allCollection?.find((item) =>
          item?.from === action.payload?.editableEdge?.source && item?.to === action.payload?.editableEdge?.target
        )
      if (selectedele) {
        if (selectedele && selectedele.toServiceConnectionPart) {
          selectedele.toServiceConnectionPart.serviceOperationDescription = action.payload.changeConnectionName
        }
        const allEle = state?.allCollection.filter((data) => data?.bianId !== action.payload.editableEdge.bianId)
        const newArr = [selectedele, ...allEle];
        return {
          ...state,
          allCollection: newArr
        };
      } else {
        let selectedElementNew = state?.newConnection?.find((item) =>
          item?.source === action.payload?.editableEdge?.source && item?.target === action.payload?.editableEdge?.target
        )
        selectedElementNew = {...selectedElementNew, serviceOperationName: action.payload.changeConnectionName}
        const allElementValNew = state?.newConnection?.filter((item) =>
          item?.source !== action.payload?.editableEdge?.source && item?.target !== action.payload?.editableEdge?.target
        )

        return {
          ...state,
          newConnection: [...allElementValNew, selectedElementNew]
        };
      }


    case "DELETE_NODE":
      const aftDeleteNode = state.allCollection.filter(({ fromServiceConnectionPart, toServiceConnectionPart }) => fromServiceConnectionPart.serviceDomainName !== action.payload && toServiceConnectionPart.serviceDomainName !== action.payload)
      return {
        ...state,
        allCollection: aftDeleteNode
      };
    case "UPDATEEDGECONNECTION":
      const selectedElement = state.allCollection.find((item) =>
        item && `${item?.bianId}` === action.payload.editableEdge.bianId
      )
      if (!selectedElement) {
        const newSelecteble = state.allCollection.find((item) =>
          item && `${item?.fromServiceConnectionPart?.serviceDomainName}` === action.payload.editableEdge.source &&
          `${item?.toServiceConnectionPart?.serviceDomainName}` === action.payload.editableEdge.target
        )

        if (newSelecteble) {
          // newSelecteble.toServiceConnectionPart.serviceOperationName = action.payload.serviceDescription
          newSelecteble.toServiceConnectionPart = {
            ...newSelecteble.toServiceConnectionPart, ...action?.payload?.actionTermDetails, type: action?.payload?.actionTermDetails?.actiontype,
            serviceOperationName: action.payload.serviceDescriptionName
          }
          const allElementVal2 = state.allCollection.filter((item) =>
            item && `${item?.fromServiceConnectionPart?.serviceDomainName}` !== action.payload.editableEdge.source &&
            `${item?.toServiceConnectionPart?.serviceDomainName}` !== action.payload.editableEdge.target)
          const newArray2 = [newSelecteble, ...allElementVal2];

          return {
            ...state,
            allCollection: newArray2
          };
        }else{
          const selectedElementNew = state?.newConnection?.find((item) =>
          item?.source === action.payload?.editableEdge?.source && item?.target === action.payload?.editableEdge?.target
          )
          selectedElementNew.serviceOperationDescription = action.payload.serviceDescriptionName
          
          const allElementValNew = state?.newConnection?.filter((item) =>
            item?.source !== action.payload?.editableEdge?.source && item?.target !== action.payload?.editableEdge?.target
          )

          return {
            ...state,
            newConnection: [...allElementValNew, selectedElementNew]
          };


        }
      } else {

        if (selectedElement) {
          if (selectedElement && selectedElement.toServiceConnectionPart) {
            // selectedElement.toServiceConnectionPart.serviceOperationName = action.payload.serviceDescription
            selectedElement.toServiceConnectionPart = {
              ...selectedElement.toServiceConnectionPart, ...action?.payload?.actionTermDetails, type: action?.payload?.actionTermDetails?.actiontype,
              serviceOperationName: action.payload.serviceDescriptionName
            }
          }
          const allElementVal = state.allCollection.filter((item) => item && `${item?.bianId}` !== action.payload.editableEdge.bianId)
          const newArray = [selectedElement, ...allElementVal];

          return {
            ...state,
            allCollection: newArray
          };
        } else {
          const selectedElementNew = state.newConnection.find((item) =>
            item?.source === action.payload?.editableEdge?.source && item?.target === action.payload?.editableEdge?.target
          )
          selectedElementNew.serviceOperationName = action.payload.serviceDescriptionName
          const allElementValNew = state.newConnection.filter((item) =>
            item?.source !== action.payload?.editableEdge?.source && item?.target !== action.payload?.editableEdge?.target
          )
          return {
            ...state,
            newConnection: [...allElementValNew, selectedElementNew]
          };
        }
      }

    case "UPDATEEDGECONNECTIONNAME":
      const selectedElementName = state.allCollection.find((item) =>
        item && `${item?.bianId}` === action.payload.editableEdge.bianId
      )
      if (!selectedElementName) {
        const newSelecteble = state.allCollection.find((item) =>
          item && `${item?.fromServiceConnectionPart?.serviceDomainName}` === action.payload.editableEdge.source &&
          `${item?.toServiceConnectionPart?.serviceDomainName}` === action.payload.editableEdge.target

        )
        if (newSelecteble) {
          newSelecteble.toServiceConnectionPart = {
            ...newSelecteble.toServiceConnectionPart, ...action?.payload?.actionTermDetails, type: action?.payload?.actionTermDetails?.actiontype,
            serviceOperationDescription: action.payload.serviceDescriptionName
          }

        }

        const allElementVal2 = state.allCollection.filter((item) =>
          item && `${item?.fromServiceConnectionPart?.serviceDomainName}` !== action.payload.editableEdge.source &&
          `${item?.toServiceConnectionPart?.serviceDomainName}` !== action.payload.editableEdge.target)
        const newArray2 = [newSelecteble, ...allElementVal2];

        return {
          ...state,
          allCollection: newArray2
        };

      } else {
        if (selectedElementName) {

          if (selectedElementName && selectedElementName.toServiceConnectionPart) {
            selectedElementName.toServiceConnectionPart = {
              ...selectedElementName.toServiceConnectionPart, ...action?.payload?.actionTermDetails, type: action?.payload?.actionTermDetails?.actiontype,
              serviceOperationDescription: action.payload.serviceDescriptionName
            }
          }

          const allElementVal = state.allCollection.filter((item) => item && `${item?.bianId}` !== action.payload.editableEdge.bianId)

          const newArray = [selectedElementName, ...allElementVal];
          return {
            ...state,
            allCollection: newArray
          };
        } else {

          const selectedElementNew = state?.newConnection?.find((item) =>
            item?.source === action.payload?.editableEdge?.source && item?.target === action.payload?.editableEdge?.target
          )
          selectedElementNew.serviceOperationDescription = action.payload.serviceDescription
          const allElementValNew = state?.newConnection?.filter((item) =>
            item?.source !== action.payload?.editableEdge?.source && item?.target !== action.payload?.editableEdge?.target
          )
          return {
            ...state,
            newConnection: [...allElementValNew, selectedElementNew]
          };
        }
      }


    case "SAVEDIAGRAM":
      return {
        ...state,
        SelectedFeedback: "",
        newConnection: "",
        allElements:[]
      };
    
    case "UPDATE_BUSINESS_SCENARIO_COUNT":
      return {
        ...state,
        updateBusinessCount: state.updateBusinessCount + 1,
      };

      
    case "ALL_ELEMENT":
      return {
        ...state,
        allElements: action.payload,
      };
    default: return state;

  }

};

export default reducer;