export const generate_url = (data) => {
  var type = process.env.REACT_APP_VERSION;
  var base_url = process.env.REACT_APP_MODEL_ACCESS_S1;
  if (type === "S2") {
    base_url = process.env.REACT_APP_MODEL_ACCESS_S2;
  }
  return base_url + data;
};

// META API
export const getMetaAPI = () => {
  return generate_url("Metas");
};

// Organisation API
export const getOrgAPI = () => {
  return generate_url("Metas");
};

// Service From Org API
export const getServiceFromOrgAPI = (orgName, orgVersion) => {
  var apiPath = "ServiceDomainsBasic/" + orgName + "/" + orgVersion;
  return generate_url(apiPath);
};
