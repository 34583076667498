import {
	Box,
	ListItemText,
	MenuList,
	MenuItem,
	styled,
	useTheme,
} from "@mui/material";
import React from "react";

const SideBarMenuItem = styled(MenuItem)(({ theme }) => ({
	paddingLeft: "1rem",
	paddingRight: "1rem",
	paddingTop: "0.5rem",
	paddingBottom: "0.5rem",
}));

const QnASideBar = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				width: "180px",
				paddingTop: "24px",
				height: "100vh",
				borderRight: `1px solid ${theme.palette.secondary.contrastText}`,
			}}>
			<MenuList>
				<SideBarMenuItem>
					<ListItemText>Menu 1</ListItemText>
				</SideBarMenuItem>
				<SideBarMenuItem>
					<ListItemText>Menu 2</ListItemText>
				</SideBarMenuItem>
				<SideBarMenuItem>
					<ListItemText>Menu 3</ListItemText>
				</SideBarMenuItem>
				<SideBarMenuItem>
					<ListItemText>Menu 4</ListItemText>
				</SideBarMenuItem>
			</MenuList>
		</Box>
	);
};

export default QnASideBar;
