import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Card,
  CardActions,
  Button,
  Typography,
  Menu,
  MenuItem,
  LinearProgress,
  Chip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function Users() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);
  const [convertedData, setConvertedData] = useState([]);
  const [filterConvertedData, setFilterConvertedData] = useState([]);
  const [userEdit, setUserEdit] = useState({});
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const open = Boolean(anchorEl);
  const [userDetails, setUserDetails] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (data) => {
    console.log("data => ", data);
    setUserEdit(data);
    console.log("userEdit =>", userEdit);
  };

  const handleView = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Users");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Users");
    console.log("userDetails.OrganisationName ");
    setUserDetails(JSON.parse(localStorage.getItem("tokenDetails")));
    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log("response.data, ", response.data);
        console.log(response.data);
        setUsers(response.data);
        const convertedData = response.data.map((item, index) => ({
          SlNo: index + 1,
          Username: item.userName,
          Email: item.emailAddress,
          Permissions: item.roles[0]?.name ?? "",
          Organisation: item.organisation?.name ?? "",
          Status: item.status,
          uid: item.uid,
        }));
        if (
          JSON.parse(localStorage.getItem("tokenDetails")).OrganisationName ==
          "BIAN"
        ) {
          setConvertedData(convertedData);
          setFilterConvertedData(convertedData);
          setError(false);
        } else {
          const filteredUsers = convertedData.filter(
            (user) =>
              user.Organisation ==
              JSON.parse(localStorage.getItem("tokenDetails")).OrganisationName
          );

          setConvertedData(filteredUsers);
          setFilterConvertedData(filteredUsers);
          setError(false);
        }
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Users information From Server");
      });
  }, []);

  const columns = [
    {
      name: "Sl.No",
      selector: (row) => row.SlNo,
      sortable: true,
    },
    {
      name: "Username",
      selector: (row) => row.Username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
    },
    {
      name: "Permissions",
      selector: (row) => row.Permissions,
      sortable: true,
    },
    {
      name: "Organisation",
      selector: (row) => row.Organisation,
      sortable: true,
    },
    {
      name: "Status",
      // selector: (row) => row.Status,
      // sortable: true,
      cell: (row) =>
        row.Status ? (
          <Chip label={row.Status} color="success" size="small" />
        ) : (
          ""
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <Link to={"/manage/user/view/" + row?.uid}>
          <Button
            style={{ margin: "0 5px" }}
            size="small"
            variant="outlined"
            startIcon={<ViewIcon />}
          >
            View
          </Button>
        </Link>
      ),
    },
  ];

  const handleFilterSearch = (event) => {
    const newData = filterConvertedData.filter(
      (row) =>
        (row.Username ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.Permissions ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.Organisation ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.Email ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.Status ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    // const filteredUsers = newData.filter(user => user.Organisation == userDetails.OrganisationName);
    setConvertedData(newData);
  };

  return (
    <Box style={{ overflow: "auto", height: "65vh" }}>
      <Container style={{ paddingTop: "30px" }}>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <Typography variant="h6">
            <Typography variant="strong">User Details</Typography>
          </Typography>
          {(
            userDetails == null ? (
              <></>
            ) : (
              userDetails != null && userDetails.OrganisationName == "BIAN"
            )
          ) ? (
            <CardActions>
              <input
                type="text"
                onChange={handleFilterSearch}
                placeholder="Search"
                className="form-control"
                style={{
                  border: "1px solid grey",
                  padding: "6px",
                  marginRight: "12px",
                  borderRadius: "4px",
                }}
              />
              <Link to="/manage/user/add">
                <Button size="small" variant="outlined" startIcon={<AddIcon />}>
                  Add User
                </Button>
              </Link>
            </CardActions>
          ) : userDetails.Role == "ToolAdministrator" ||
            userDetails.Role == "UserAdministrator" ? (
            <CardActions>
              <input
                type="text"
                onChange={handleFilterSearch}
                placeholder="Search"
                className="form-control"
                style={{
                  border: "1px solid grey",
                  padding: "6px",
                  marginRight: "12px",
                  borderRadius: "4px",
                }}
              />
              <Link to="/manage/user/add">
                <Button size="small" variant="outlined" startIcon={<AddIcon />}>
                  Add User
                </Button>
              </Link>
            </CardActions>
          ) : (
            <></>
          )}
        </Card>
        <TableContainer component={Paper} style={{}}>
          <DataTable pagination columns={columns} data={convertedData} />
        </TableContainer>
      </Container>
    </Box>
  );
}

export default Users;
