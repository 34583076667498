import {
  SELECT_DOMAIN_FOR_CERTIFICATION,
  CURRENT_DOMAIN_FOR_CERTIFICATION,
  SELECT_COURSE_FOR_CERTIFICATION,
} from "./type";

export const selectDomainForCertification = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SELECT_DOMAIN_FOR_CERTIFICATION,
      payload: payload,
    });
  } catch {
    dispatch({
      type: SELECT_DOMAIN_FOR_CERTIFICATION,
      payload: payload,
    });
  }
};

export const selectCourseForCertification = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SELECT_COURSE_FOR_CERTIFICATION,
      payload: payload,
    });
  } catch {
    dispatch({
      type: SELECT_COURSE_FOR_CERTIFICATION,
      payload: payload,
    });
  }
};

export const currentDomainForCertification = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CURRENT_DOMAIN_FOR_CERTIFICATION,
      payload: payload,
    });
  } catch {
    dispatch({
      type: CURRENT_DOMAIN_FOR_CERTIFICATION,
      payload: payload,
    });
  }
};
