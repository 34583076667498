import React, { useEffect, useState } from "react";
import { useNavigate as useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
  CardContent,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LocalPrintshopSharp } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const AccountProfileDetails = (props) => {
  const [organisation, setOrganisation] = useState([]);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState({
    uid: "",
    userName: "",
    firstName: "",
    lastName: "",
    password: "",
    emailAddress: "",
    phoneNumber: "",
    organisation: "",
    role: "",
    status: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState("");
  let history = useHistory();
  let { userId } = useParams();
  const userStatus = [
    {
      id: 1,
      name: "new",
      display_name: "New",
    },
    {
      id: 2,
      name: "inactive",
      display_name: "In-Active",
    },
    {
      id: 3,
      name: "active",
      display_name: "Active",
    },
  ];

  // useEffect(() => {
  //   if (props?.userDetails) {
  //     setUser(setUserData(props?.userDetails))
  //   }
  //   console.log("props?.userDetails ", props?.userDetails);
  // }, [props?.userDetails])

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleOrgChange = (event) => {
    setUser({
      ...user,
      organisation: { ...user.organisation, uid: event.target.value },
    });
  };

  const handleRoleChange = (event) => {
    console.log("event.target.value ", event.target.value);
    setUser({ ...user, role: event.target.value });
  };

  const handleStatusChange = (event) => {
    setUser({ ...user, status: event.target.value });
  };

  const loadUser = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserByUid/" + uid);
    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        setError(false);
        setUser(setUserData(response.data));
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  useEffect(async () => {
    await getOrganization();
    await getRoles();
    loadUser(userId);
  }, []);

  const getOrganizationById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "OrganisationByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "OrganisationByUid/" + uid);
    console.log("axiosUrl axiosUrl", axiosUrl);
    setError(false);
    // return {};
    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        return response.data;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoleById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "RoleByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "RoleByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log("role data response.data", response.data);
        setError(false);
        return response.data;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  const getUserData = async () => {
    var userData = {
      uid: userId,
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      organisation: await getOrganizationById(user.organisation.uid),
      roles: [await getRoleById(user.role)],
      status: user.status,
    };
    return userData;
  };

  const setUserData = (userDetail) => {
    console.log("userDetail.roles ", userDetail.roles);
    return {
      uid:
        userDetail.uid !== "string" &&
        userDetail.uid !== null &&
        userDetail.uid !== undefined
          ? userDetail.uid
          : "",
      userName:
        userDetail.userName !== "string" &&
        userDetail.userName !== null &&
        userDetail.userName !== undefined
          ? userDetail.userName
          : "",
      firstName:
        userDetail.firstName !== "string" &&
        userDetail.firstName !== null &&
        userDetail.firstName !== undefined
          ? userDetail.firstName
          : "",
      lastName:
        userDetail.lastName !== "string" &&
        userDetail.lastName !== null &&
        userDetail.lastName !== undefined
          ? userDetail.lastName
          : "",
      emailAddress:
        userDetail.emailAddress !== "string" &&
        userDetail.emailAddress !== null &&
        userDetail.emailAddress !== undefined
          ? userDetail.emailAddress
          : "",
      phoneNumber:
        userDetail.phoneNumber !== "string" &&
        userDetail.phoneNumber !== null &&
        userDetail.phoneNumber !== undefined
          ? userDetail.phoneNumber
          : "",
      password:
        userDetail.password !== "string" &&
        userDetail.password !== null &&
        userDetail.password !== undefined
          ? userDetail.password
          : "",
      organisation:
        userDetail.organisation !== "string" &&
        userDetail.organisation?.uid !== null &&
        userDetail.organisation?.uid !== undefined
          ? {
              uid: userDetail.organisation.uid,
            }
          : "",
      role: [
        userDetail.roles[0]?.uid !== "string" &&
        userDetail.roles[0]?.uid !== null &&
        userDetail.roles[0]?.uid !== undefined
          ? userDetail.roles[0].uid
          : "",
      ],
      status:
        userDetail.status !== "string" &&
        userDetail.status !== null &&
        userDetail.status !== undefined
          ? userDetail.status
          : "",
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    setIsLoading(true);
    setSuccess(false);
    setSuccessText("");
    const userData = await getUserData();
    console.log("userData is ", userData);
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserUpdate/");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserUpdate/");

    await axios
      .put(axiosUrl, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(async (response) => {
        // If request is good...
        console.log(
          "If request is good... handleSubmit is executed",
          response.data
        );
        setSuccessText("Saved");
        setSuccess(true);
        if (userData.status == "active") {
          await axios
            .post(
              process.env.REACT_APP_NODE_CONFLUENCE_API +
                "api/user/invite-by-emails",
              JSON.stringify({
                emails: [userData.emailAddress],
              }),
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Basic ${process.env.REACT_APP_CONFLUENCE_API_KEY}`,
                },
              }
            )
            .then((res) => {
              console.log("Requested email send by confluence", res);
              setError(false);
              setIsLoading(false);
              setSuccessText("Sent mail");
              setSuccess(true);
              props.callbackFunction();
            })
            .catch((error) => {
              setIsLoading(false);
              console.log(" error.response :", error.response);
              console.log(" error.response.data :", error.response.data);
            });
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          // Request made and server responded
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.statusText +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add User " + error.message);
        }
      });
    setIsLoading(false);
  };

  const getOrganization = async () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Organisations");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Organisations");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        setOrganisation(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoles = async () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Roles");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Roles");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setRoles(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="username"
                label="User Name"
                name="userName"
                autoFocus
                value={user.userName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="emailAddress"
                label="Email Address"
                name="emailAddress"
                autoComplete="emailAddress"
                value={user.emailAddress}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="firstName"
                label="First Name"
                name="firstName"
                autoFocus
                value={user.firstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                value={user.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                value={user.password}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                name="phoneNumber"
                label="Phone Number"
                type="text"
                id="phoneNumber"
                value={user.phoneNumber}
                onChange={handleInputChange}
              />
            </Grid>
            {console.log(
              "6666666666666666666666666666666",
              user?.organisation?.uid
            )}
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="selectOrg">Organisation</InputLabel>

                <Select
                  labelId="selectOrg"
                  id="demo-simple-select-org"
                  value={user?.organisation?.uid}
                  key={user?.organisation?.uid}
                  label="Organisation"
                  onChange={handleOrgChange}
                >
                  {organisation &&
                    organisation?.length > 0 &&
                    organisation.map((org, i) => (
                      <MenuItem value={org.uid} key={i}>
                        <strong>{org.name}</strong>{" "}
                        {org.phoneNumber && (
                          <p style={{ margin: "0px 4px" }}>
                            {" "}
                            Phone: {org.phoneNumber}
                          </p>
                        )}{" "}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="selectRole">Role</InputLabel>
                <Select
                  labelId="selectRole"
                  id="demo-simple-select-role"
                  value={user.role}
                  label="Role"
                  onChange={handleRoleChange}
                >
                  {roles.map((role) => (
                    <MenuItem value={role.uid} key={role.uid}>
                      <strong>{role.name}</strong>{" "}
                      {role.phoneNumber && (
                        <p style={{ margin: "0px 4px" }}>
                          {" "}
                          Phone: {role.phoneNumber}
                        </p>
                      )}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="selectRole">Status</InputLabel>
                <Select
                  labelId="selectStatus"
                  id="demo-simple-select-status"
                  value={user.status}
                  label="Status"
                  onChange={handleStatusChange}
                >
                  {userStatus.map((mapData) => (
                    <MenuItem value={mapData.name} key={mapData.name}>
                      <p style={{ margin: "0px 4px" }}>
                        {mapData.display_name}
                      </p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Typography
          style={{
            paddingLeft: "16px",
            paddingBottom: "8px",
            paddingRight: "8px",
            color: "green",
          }}
        >
          Please set status to Active to activate this user in the BIAN Portal
          and to request BIAN wiki access for them.
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          {isLoading ? (
            <LoadingButton loading variant="outlined" style={{ width: "100%" }}>
              Submit
            </LoadingButton>
          ) : (
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              type="submit"
            >
              Save details
            </Button>
          )}
        </Box>
      </Card>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )}
      {success && (
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          {successText}
        </Alert>
      )}
    </form>
  );
};

export default AccountProfileDetails;
