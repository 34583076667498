var MODEL_ACCESS_END_POINT = "";
var NODE_MODEL_ACCESS_END_POINT = process.env.REACT_APP_NODE_PORTAL;
var SECURITY_END_POINT = "";
var FUNCTION_END_POINT = "";
var CONTENT_MANAGER_END_POINT = "";
var SEARCH_ENDPOINT = "";

const VERSION = process.env.REACT_APP_VERSION;

MODEL_ACCESS_END_POINT = VERSION === "S1" ? process.env.REACT_APP_MODEL_ACCESS_S1 : process.env.REACT_APP_MODEL_ACCESS_S2;

SECURITY_END_POINT = VERSION === "S1" ? process.env.REACT_APP_SECURITY_S1 : process.env.REACT_APP_SECURITY_S2;

FUNCTION_END_POINT = VERSION === "S1" ? process.env.REACT_APP_FUNCTION_S1 : process.env.REACT_APP_FUNCTION_S2;

CONTENT_MANAGER_END_POINT = VERSION === "S1" ? process.env.REACT_APP_CONTENT_MANAGER_S1 : process.env.REACT_APP_CONTENT_MANAGER_S2;

SEARCH_ENDPOINT = process.env.REACT_APP_SEARCH;

export { MODEL_ACCESS_END_POINT, NODE_MODEL_ACCESS_END_POINT, SECURITY_END_POINT, FUNCTION_END_POINT, CONTENT_MANAGER_END_POINT, SEARCH_ENDPOINT };
