import React from "react";
import { Route, Routes } from "react-router-dom";
import Users from "../Security/Users";
import UserAdd from "../Security/UserAdd";
import UserEdit from "../Security/UserEdit";
import UserView from "../Security/UserView";
import Organisation from "../Security/Organisation";
import OrganisationView from "../Security/OrganisationView";
import OrganisationAdd from "../Security/OrganisationAdd";
import Roles from "../Security/Roles";
import RoleAdd from "../Security/RoleAdd";
import { withLogged } from "../utils/withAuth";
import NavigationBar from "./NavigationBar";
import WorkingGroup from "../Security/WorkingGroup";
import AddWorkingGroup from "../Security/WorkingGroupAdd";

function Home() {
  return (
    <div style={{ overflow: "scroll" }}>
      <NavigationBar />
      <Routes>
        <Route exact="true" path="/users/" element={<Users path="/users" />} />
        <Route
          exact="true"
          path="/user/add"
          element={<UserAdd path="/user/add" />}
        />
        <Route
          exact="true"
          path="/working-group/add"
          element={<AddWorkingGroup path="/user/add" />}
        />
        <Route
          exact="true"
          path="/user/edit/:userId"
          element={<UserEdit path="/user/edit/:userId" />}
        />
        <Route
          exact="true"
          path="/user/view/:userId"
          element={<UserView path="/user/view/:userId" />}
        />
        <Route
          exact="true"
          path="/organisation/"
          element={<Organisation path="/organisation" />}
        />
        <Route
          exact="true"
          path="/organisation/add"
          element={<OrganisationAdd path="/organisation/add" />}
        />
        <Route
          exact="true"
          path="/organisation/view/:orgId"
          element={<OrganisationView path="/organisation/view/:orgId" />}
        />
        <Route
          exact="true"
          path="/working-group"
          element={<WorkingGroup path="/working-group" />}
        />
        <Route exact="true" path="/role/" element={<Roles path="/role" />} />
        <Route
          exact="true"
          path="/role/add"
          element={<RoleAdd path="/role/add" />}
        />
        <Route
          exact="true"
          path="/role/edit/:roleId"
          element={<RoleAdd path="/role/add" />}
        />
        <Route
          exact="true"
          path="/working-group/"
          element={<WorkingGroup path="/working-group" />}
        />
      </Routes>
    </div>
  );
}

export default withLogged(Home);
