import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
	ReactPlugin,
	withAITracking,
} from "@microsoft/applicationinsights-react-js";
// import { globalHistory } from "@reach/router"
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({
	basename: process.env.REACT_APP_AZURE_BASE_WEBSITE,
});

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
	config: {
		instrumentationKey: 'b01164f1-cb00-482b-beba-f0386f9a2e69',
		// instrumentationKey: "d5ca632e-6168-4b8a-8b52-a84195c8fa5d",
		// instrumentationKey: process.env.REACT_APP_AZURE_INSTRUMENTKEY,
		extensions: [reactPlugin],
		enableAutoRouteTracking: true,
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
ai.loadAppInsights();

const withAppInsights = (Component) => withAITracking(reactPlugin, Component);
export default withAppInsights;
export const appInsights = ai.appInsights;
export { reactPlugin };
