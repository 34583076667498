import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Card,
  CardActions,
  Button,
  Typography,
  Menu,
  MenuItem,
  LinearProgress,
  Chip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";

import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ViewIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ServicesAssigned(props) {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [activeChip, setActiveChip] = useState("");
  const [userDomain, setUserDomain] = useState([]);
  let { userId } = useParams();

  const handleChipChange = (passed) => {
    setActiveChip(passed);
    setUsers([]);

    if (passed !== "") {
      let axiosUrl = "";
      process.env.REACT_APP_VERSION === "S1" &&
        (axiosUrl =
          process.env.REACT_APP_MODEL_ACCESS_S1 +
          "ServiceDomainsBasicByStatusUser/" +
          passed +
          "/" +
          userId);
      process.env.REACT_APP_VERSION === "S2" &&
        (axiosUrl =
          process.env.REACT_APP_MODEL_ACCESS_S2 +
          "ServiceDomainsBasicByStatusUser/" +
          passed +
          "/" +
          userId);

      axios
        .get(axiosUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        })
        .then((response) => {
          // If request is good...
          console.log(response.data);
          setUserDomain(response.data);
          setError(false);
        })
        .catch((error) => {
          console.log("error " + error);
          setError(true);
          setErrorText(
            "Unable to retrieve Users assigned information From Server"
          );
        });
    }
  };

  useEffect(() => {
    handleChipChange("Feedback-Inprocess");
  }, []);

  return (
    <Box>
      <Box>
        <Card
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {activeChip == "Feedback" ? (
            <Chip
              style={{ margin: "10px 5px" }}
              variant="filled"
              color="primary"
              label="Feedback"
              onClick={() => handleChipChange("Feedback")}
            />
          ) : (
            <Chip
              style={{ margin: "10px 5px" }}
              label="Feedback"
              onClick={() => handleChipChange("Feedback")}
            />
          )}
          {activeChip == "Feedback-Created" ? (
            <Chip
              style={{ margin: "10px 5px" }}
              variant="filled"
              color="primary"
              label="Feedback-Created"
              onClick={() => handleChipChange("Feedback-Created")}
            />
          ) : (
            <Chip
              style={{ margin: "10px 5px" }}
              label="Feedback-Created"
              onClick={() => handleChipChange("Feedback-Created")}
            />
          )}
          {activeChip == "Feedback-Inprocess" ? (
            <Chip
              style={{ margin: "10px 5px" }}
              variant="filled"
              color="primary"
              label="Feedback-Inprocess"
              onClick={() => handleChipChange("Feedback-Inprocess")}
            />
          ) : (
            <Chip
              style={{ margin: "10px 5px" }}
              label="Feedback-Inprocess"
              onClick={() => handleChipChange("Feedback-Inprocess")}
            />
          )}
          {activeChip == "Feedback-Submitted" ? (
            <Chip
              style={{ margin: "10px 5px" }}
              variant="filled"
              color="primary"
              label="Feedback-Submitted"
              onClick={() => handleChipChange("Feedback-Submitted")}
            />
          ) : (
            <Chip
              style={{ margin: "10px 5px" }}
              label="Feedback-Submitted"
              onClick={() => handleChipChange("Feedback-Submitted")}
            />
          )}
          {activeChip == "Published" ? (
            <Chip
              style={{ margin: "10px 5px" }}
              variant="filled"
              color="primary"
              label="Published"
              onClick={() => handleChipChange("Published")}
            />
          ) : (
            <Chip
              style={{ margin: "10px 5px" }}
              label="Published"
              onClick={() => handleChipChange("Published")}
            />
          )}
          {activeChip !== "" && (
            <Chip
              style={{ margin: "10px 5px" }}
              variant="filled"
              color="primary"
              label="Clear"
              onClick={() => handleChipChange("")}
              onDelete={() => handleChipChange("")}
            />
          )}
        </Card>
        <TableContainer component={Paper} style={{}}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <StyledTableRow
                style={{ backgroundColor: "#10414a", color: "white" }}
              >
                <StyledTableCell
                  style={{ border: "1px solid white", color: "white" }}
                  align="left"
                >
                  Sl.No
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white", color: "white" }}
                  align="left"
                >
                  Services
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white", color: "white" }}
                  align="center"
                >
                  Version
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white", color: "white" }}
                  align="center"
                >
                  Status
                </StyledTableCell>
                <StyledTableCell
                  style={{ border: "1px solid white", color: "white" }}
                  align="right"
                >
                  Action
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {userDomain.length === 0 && !error && (
                <StyledTableRow>
                  <StyledTableCell colspan="7">
                    <LinearProgress color="primary" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
              {error && (
                <StyledTableRow>
                  <StyledTableCell colspan="7">{errorText}</StyledTableCell>
                </StyledTableRow>
              )}
              {userDomain.map((mapData, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row">
                      {mapData?.name}
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row">
                      {mapData?.version ? mapData?.version : "v1"}
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row">
                      {mapData.status ? mapData.status : "none"}
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ display: "flex" }}>
                      {/* <Link to={'/user/edit/'+mapData?.uid}>
											<Button style={{ margin: "0 5px" }} size="small" variant="outlined" >
												<EditIcon />
											</Button>
										</Link> */}
                      <Button
                        style={{ margin: "0 5px" }}
                        size="small"
                        variant="contained"
                      >
                        <ViewIcon />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ServicesAssigned;
