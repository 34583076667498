import React, { useState, useEffect, useLayoutEffect } from "react";
import {
	AppBar,
	Toolbar,
	Autocomplete,
	TextField,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
	Route,
	Routes,
	useLocation,
	Link,
	useNavigate as useHistory,
} from "react-router-dom";
import axios from "axios";

function Navbar(props) {
	const dispatch = useDispatch();
	let history = useHistory();
	const [errorAC, setErrorAC] = useState(null);
	const [isLoadedAC, setIsLoadedAC] = useState(false);
	const [itemsAC, setItemsAC] = useState([]);
	const [defaultValueS, setDefaultValueS] = useState("");
	const [defaultValueF, setDefaultValueF] = useState("");
	const [serviceBank, setServiceBank] = useState("");
	const [feedBank, setFeedBank] = useState("");
	const [hideVal, setHideVal] = useState(true);
	const [HelpPage, setHelpPage] = useState(true);
	const [locatedHelpName, setLocatedHelpName] = useState("");
	const [activePath, setactivePath] = useState("");

	const [openTabFieldVal, setOpenTabFieldVal] = useState("");
	const searchField = useSelector((state) => state?.common?.searchField);

	const [openTabVal, setOpenTabVal] = useState("");
	const path = window.location.pathname.split("/");
	const businessScenarioDataVal = useSelector(
		(state) => state?.common?.businessScenarioData
	);
	const feedbackSelected = useSelector(
		(state) => state?.common?.setFeedbackServiceDomain
	);
	const selectedFeedbackDomainVal = useSelector(
		(state) => state?.common?.selectedFeedbackDomainVal
	);
	const updatedDomainVal = useSelector((state) => state?.common?.updatedDomain);
	const organisation = businessScenarioDataVal.organisation || "BIAN";
	const version = businessScenarioDataVal.version || "10.1.0";
	const selectedTabVal = useSelector((state) => state?.common?.selectedTab);
	const defaultVersion =
		organisation && version ? `${organisation} v${version}` : null;
	const location = useLocation();

	useEffect(() => {
		if (selectedTabVal && selectedTabVal != "") {
			setOpenTabFieldVal(selectedTabVal);
		}
	}, [selectedTabVal]);

	useEffect(() => {
		if (updatedDomainVal?.id) {
			setDefaultValueS(updatedDomainVal);
			props.onAutoSearchChange("service", updatedDomainVal);

			dispatch({ type: "DOMAIN_DETAILS", payload: updatedDomainVal });
			dispatch({ type: "NAVBAR_DROP_DOWN_VALUE", payload: updatedDomainVal });

			let axiosUrl = "";
			process.env.REACT_APP_VERSION === "S1" &&
				(axiosUrl =
					process.env.REACT_APP_MODEL_ACCESS_S1 +
					"ServiceDomainsBasic/" +
					updatedDomainVal.organisation +
					"/" +
					updatedDomainVal.version);
			process.env.REACT_APP_VERSION === "S2" &&
				(axiosUrl =
					process.env.REACT_APP_MODEL_ACCESS_S2 +
					"ServiceDomainsBasic/" +
					updatedDomainVal.organisation +
					"/" +
					updatedDomainVal.version);

			axios
				.get(axiosUrl, {
					headers: {
						Authorization: "Bearer " + localStorage.getItem("jwtToken"),
					},
				})
				.then(
					(response) => {
						var newOrgService = [];
						response.data.length > 0 &&
							response.data.map((item) =>
								newOrgService.push({
									name: item.name,
									id: item.id,
									bianId: item.bianId,
									uid: item.uid,
									status: item.status,
									meta: item.meta,
								})
							);

						dispatch({ type: "ALL_FEEDBACK_DOMAIN", payload: newOrgService });
					},
					(error) => console.log(error)
				);
		}
	}, [updatedDomainVal]);

	useEffect(() => {
		let axiosUrl = "";
		process.env.REACT_APP_VERSION === "S1" &&
			(axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "Metas");
		process.env.REACT_APP_VERSION === "S2" &&
			(axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "Metas");

		fetch(axiosUrl)
			.then((res) => res.json())
			.then(
				(result) => {
					setIsLoadedAC(true);
					var newOrgVersion = [];
					if (result.length > 0) {
						result.map((item) =>
							newOrgVersion.push({
								label: item.organisation + " v" + item.version,
								name: item.organisation,
								organisation: item.organisation,
								version: item.version,
								id: item.uid,
							})
						);
						let defaultOrgSelected = result.filter(
							(filterArray) => filterArray.organisation === "BIAN"
						);
						if (defaultOrgSelected && defaultOrgSelected.length > 0) {
							dispatch({
								type: "SELECTED_FEEDBACK_DOMAIN_VAL",
								payload: defaultOrgSelected[0],
							});
							dispatch({
								type: "SELECT_FEEDBACK_NAV",
								payload: defaultOrgSelected[0],
							});
						}
						if (defaultOrgSelected.length > 0) {
							setDefaultValueS(
								defaultOrgSelected[0].organisation +
									" v" +
									defaultOrgSelected[0].version
							);
							setDefaultValueF(
								defaultOrgSelected[0].organisation +
									" v" +
									defaultOrgSelected[0].version
							);
							props.onAutoSearchChange("service", defaultOrgSelected[0]);
							props.onAutoSearchChange("feedback", defaultOrgSelected[0]);
						}
						if (newOrgVersion.length > 0) {
							dispatch({
								type: "NAVBAR_DROP_DOWN_VALUE",
								payload: newOrgVersion[0],
							});
						}
					}
					setItemsAC(newOrgVersion);
				},
				(error) => {
					setIsLoadedAC(true);
					setErrorAC(error);
				}
			);
	}, []);

	function handleOnchngeAutosearchService(event, chngeVal) {
		setDefaultValueS(chngeVal);
		props.onAutoSearchChange("service", chngeVal);

		dispatch({ type: "DOMAIN_DETAILS", payload: chngeVal });
		dispatch({ type: "NAVBAR_DROP_DOWN_VALUE", payload: chngeVal });

		let axiosUrl = "";
		process.env.REACT_APP_VERSION === "S1" &&
			(axiosUrl =
				process.env.REACT_APP_MODEL_ACCESS_S1 +
				"ServiceDomainsBasic/" +
				chngeVal.organisation +
				"/" +
				chngeVal.version);
		process.env.REACT_APP_VERSION === "S2" &&
			(axiosUrl =
				process.env.REACT_APP_MODEL_ACCESS_S2 +
				"ServiceDomainsBasic/" +
				chngeVal.organisation +
				"/" +
				chngeVal.version);

		axios
			.get(axiosUrl, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("jwtToken"),
				},
			})
			.then(
				(response) => {
					var newOrgService = [];
					response.data.length > 0 &&
						response.data.map((item) =>
							newOrgService.push({
								name: item.name,
								id: item.id,
								bianId: item.bianId,
								uid: item.uid,
								status: item.status,
								meta: item.meta,
							})
						);

					dispatch({ type: "ALL_FEEDBACK_DOMAIN", payload: newOrgService });
				},
				(error) => console.log(error)
			);
	}

	useEffect(() => {
		if (selectedFeedbackDomainVal) {
			props.onAutoSearchChange("feedback", selectedFeedbackDomainVal);
		}
	}, [selectedFeedbackDomainVal]);

	function handleOnKeyupAutosearchService(event, chngeVal) {
		setDefaultValueS(chngeVal);
		props.onAutoSearchChange("service", chngeVal);
	}

	function handleOnchngeAutosearchFeed(event, chngeVal) {
		setDefaultValueF(chngeVal);
		props.onAutoSearchChange("feedback", chngeVal);
		dispatch({ type: "SELECT_FEEDBACK_NAV", payload: chngeVal });
	}

	function handleOnKeyupAutosearchFeed(event, chngeVal) {
		setDefaultValueF(chngeVal);
		props.onAutoSearchChange("feedback", chngeVal);
	}

	const pages = [
		{
			name: "Business Scenario",
		},
		{
			name: "Service Domain",
		},
		{
			name: "Classes",
		},
		{
			name: "Enumerations",
		},
		{
			name: "Data Type",
		},
		{
			name: "Primitive Type",
		},
	];

	const pages2 = [
		{
			label: "Business Scenario | Wireframe",
			value: "BusinessScenario",
			path: "business-scenario",
			help: "/dashboard/help/business-scenario-help",
			helpName: "business-scenario-help",
		},
		{
			label: "Service Domain",
			value: "ServiceDomain",
			path: "services",
			help: "/dashboard/help/services-help",
			helpName: "services-help",
		},
		{
			label: "Class",
			value: "Class",
			path: "classes",
			help: "/dashboard/help/classes-help",
			helpName: "classes-help",
		},
		{
			label: "Enumeration",
			value: "Enumerations",
			path: "enumerations",
			help: "/dashboard/help/enumerations-help",
			helpName: "enumerations-help",
		},
		{
			label: "Data Type",
			value: "DataType",
			path: "data-type",
			help: "/dashboard/help/data-type-help",
			helpName: "data-type-help",
		},
		{
			label: "Primitive Type",
			value: "PrimitiveType",
			path: "primitive-type",
			help: "/dashboard/help/primitive-type-help",
			helpName: "primitive-type-help",
		},
	];

	const changeTab = (path) => {
		props.changeTabVal(path.split("/")[2]);
		dispatch({ type: "SELECT_TAB", payload: path.split("/")[2] });
		history(`/dashboard/business-scenario-services/${path.split("/")[2]}`);
	};

	const changeMainTab = (path) => {
		dispatch({ type: "SEARCHING_FIELD", payload: path });
		dispatch({ type: "SELECT_TAB", payload: path });

		history(`/dashboard/business-scenario-services/${path}`);

		// dispatch({ type: "SELECT_TAB", payload: path })

		setOpenTabFieldVal(path);
		// if (textVal && textVal.length > 2) {
		// 	axios({
		// 		method: "get",
		// 		url: `${process.env.REACT_APP_SEARCH}/Search?search=${textVal}`,
		// 		headers: {
		// 			Authorization: "Bearer " + localStorage.getItem("jwtToken"),
		// 		},
		// 	}).then((res) => {
		// 		if (event.target.value) {
		// 			const allSearchVal = res.data.filter(({ artefactType }) => artefactType === event.target.value)
		// 			dispatch({ type: "SEARCH_VAL", payload: allSearchVal })
		// 		} else {
		// 			dispatch({ type: "SEARCH_VAL", payload: res.data })
		// 		}
		// 	});
		// }
	};

	useEffect(() => {
		setOpenTabVal(selectedTabVal);
	}, [selectedTabVal]);

	useLayoutEffect(() => {
		if (
			location.pathname === "/dashboard" ||
			location.pathname === "/dashboard/"
		) {
			setHideVal(true);
		} else {
			setHideVal(false);
		}
		const pathNameVal = location.pathname.split("/");
		if (pathNameVal.length >= 2) {
			if (pathNameVal[2] == "help") {
				setHelpPage(true);
				setLocatedHelpName(pathNameVal[3]);
			} else {
				setHelpPage(false);
			}
		}
		if (pathNameVal.length >= 3) {
			setactivePath(pathNameVal[3]);
		}
	}, [location]);

	return (
		<>
			<AppBar position="static" className="navbar__container">
				<Toolbar className="toolbar">
					{selectedTabVal === "business-scenario-services" &&
					feedbackSelected?.uid ? (
						<Autocomplete
							value={
								feedbackSelected?.label
									? feedbackSelected?.label
									: defaultValueS
							}
							onKeyup={handleOnKeyupAutosearchService}
							onChange={handleOnchngeAutosearchService}
							inputValue={serviceBank}
							onInputChange={(event, newInputValue) => {
								setServiceBank(newInputValue);
							}}
							disablePortal
							autoSelect
							id="autocomplete_onload_bank_service"
							options={itemsAC}
							sx={{ width: 300, my: 2 }}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Select your Company"
									variant="outlined"
									className="auto_complete"
								/>
							)}
						/>
					) : (
						<Autocomplete
							value={defaultValueS}
							onKeyup={handleOnKeyupAutosearchService}
							onChange={handleOnchngeAutosearchService}
							inputValue={serviceBank}
							onInputChange={(event, newInputValue) => {
								setServiceBank(newInputValue);
							}}
							disablePortal
							autoSelect
							id="autocomplete_onload_bank_service"
							options={itemsAC}
							sx={{ width: 300, my: 2 }}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Select your Company"
									variant="outlined"
									className="auto_complete"
								/>
							)}
						/>
					)}
					{/* {!hideVal ?
						<div className="tab-view" style={{ display: "flex" }}>
							{pages.map((page) => (
								<MenuItem key={page.path} onClick={() => changeTab(page.path)} style={{ display: "flex", color: openTabVal === page.path.split('/')[2] ? "white" : "grey", alignItems: 'center' }}>
									<Typography>{page.name}</Typography>
								</MenuItem>
							))}
						</div>
						: */}

					{!hideVal && !HelpPage ? (
						<div className="tab-view" style={{ display: "flex" }}>
							{pages2.map((page) => (
								<MenuItem
									key={page.value}
									onClick={() => changeMainTab(page.path)}
									style={{
										display: "flex",
										color: activePath === page.path ? "white" : "grey",
										alignItems: "center",
									}}>
									<Typography>{page.label}</Typography>
								</MenuItem>
							))}
						</div>
					) : null}
					{/* } */}
					{HelpPage ? (
						<div className="tab-view" style={{ display: "flex" }}>
							{pages2.map((page, i) => (
								<Link
									to={page.help}
									key={i}
									onClick={() => changeMainTab(page.path)}>
									<MenuItem
										key={page.value}
										style={{
											display: "flex",
											color:
												locatedHelpName === page.helpName ? "white" : "grey",
											alignItems: "center",
										}}>
										<Typography>{page.label}</Typography>
									</MenuItem>
								</Link>
							))}
						</div>
					) : null}
					<Autocomplete
						value={defaultValueF}
						onKeyup={handleOnKeyupAutosearchFeed}
						onChange={handleOnchngeAutosearchFeed}
						inputValue={feedBank}
						onInputChange={(event, newInputValue) => {
							setFeedBank(newInputValue);
						}}
						disablePortal
						autoSelect
						id="autocomplete_onload_bank_feed"
						options={itemsAC}
						sx={{ width: 300, my: 1 }}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder="Select your Company"
								variant="outlined"
								className="auto_complete"
							/>
						)}
					/>
				</Toolbar>
			</AppBar>
		</>
	);
}

export default Navbar;
