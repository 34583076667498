import { Button, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import _ from "lodash";

const QnATitle = ({ title }) => {
	const theme = useTheme();
	return (
		<Stack
			direction={"row"}
			justifyContent={"space-between"}
			sx={{ width: "100%" }}>
			<Typography
				variant="h5"
				sx={{ fontWeight: theme.typography.fontWeightRegular }}>
				{_.startCase(title)}
			</Typography>

			<Button
				variant="outlined"
				color="secondary"
				sx={{
					textTransform: "capitalize",
				}}>
				Ask Question
			</Button>
		</Stack>
	);
};

export default QnATitle;
