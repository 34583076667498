import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Servicelist from './Servicelist'
import Feedbacklist from './Feedbacklist'
import MainContent from './MainContent'
import { makeStyles } from '@mui/styles';
import axios from "axios";
import withAppInsights from './../../../AppInsights';

import './service.css';


const useStyles = makeStyles((theme) => ({
	maincontainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	left_bar: {
		margin: '20px 10px 0 20px',
		borderRadius: '10px',
		width: '290px',
		[theme.breakpoints.down('md')]: {
			width: '100px',
		},
	},
	right_bar: {
		margin: '20px 20px 0 10px',
		borderRadius: '10px',
		width: '290px',
		[theme.breakpoints.down('md')]: {
			width: '100px',
		},
	},

	maincontent: {
		width: 'calc(100% - 620px)',
		[theme.breakpoints.down('md')]: {
			width: 'calc(100% - 240px)',
		},
	},
}));


function Service(props) {
	const path = window.location.pathname.split('/')
	const businessScenarioDataVal = useSelector((state) => state?.common?.businessScenarioData);
	const businessScenarioData = useSelector((state) => state?.common?.defaultBusinessScenario);

	let organisation = businessScenarioDataVal?.organisation ? businessScenarioDataVal.organisation : 'BIAN'
	let version = businessScenarioDataVal?.version ? businessScenarioDataVal.version : '10.1.0'
	const classes = useStyles();
	const dispatch = useDispatch()
	const [serviceItems, setserviceItems] = useState('');
	const [feedItems, setfeedItems] = useState('');
	const [skeletonSer, setSkeletonSer] = useState(false);
	const [skeletonFer, setSkeletonFeed] = useState(false);
	const [suid, setsuid] = useState('');
	const [fuid, setfuid] = useState('');
	const [selectService, setSelectService] = useState([]);
	const [userFil, setUserFil] = useState("");
	const refreshNodes = useSelector((state) => state?.common?.refreshNodes);


	useEffect(() => {
		if (refreshNodes) {
			fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/BusinessScenariosBasic/` + props.serviceOrg.organisation + `/` + props.serviceOrg.version)
				.then(res => res.json())
				.then(
					(result) => {
						var newOrgService = []
						result.length > 0 && result.map(item => newOrgService.push({ name: item.name, id: item.id, bianId: item.bianId, uid: item.uid, status: item.status }))

						setserviceItems(newOrgService)
						setSkeletonSer(false)
						dispatch({ type: "REFRESH_ALL_NODES", payload: false })
					},
					(error) => console.log(error)
				)
		}
	}, [refreshNodes])

	// BIAN v10.0.0
	useEffect(() => {
		if (suid) {
			const defaultData = serviceItems;
			// const selectVal = defaultData.find(({ uid }) => uid === suid)
			// dispatch({ type: "SELECTED_BUSINESS_SCENARIO", payload: { organisation, version, name: selectVal.name } })
		}
	}, [suid])

	useEffect(() => {
		if (businessScenarioData) {
			setsuid(businessScenarioData)
		}
	}, [businessScenarioData])



	// Service


	useEffect(() => {
		if (userFil === "Business Scenario") {
			if (props.serviceOrg.organisation !== undefined) {
				setSkeletonSer(true)
				fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/BusinessScenariosBasic/` + props.serviceOrg.organisation + `/` + props.serviceOrg.version)
					.then(res => res.json())
					.then(
						(result) => {
							var newOrgService = []
							result.length > 0 && result.map(item => newOrgService.push({ name: item.name, id: item.id, bianId: item.bianId, uid: item.uid, status: item.status, type: item.type }))

							setserviceItems(newOrgService)
							setSkeletonSer(false)
						},
						(error) => console.log(error)
					)
			} else {
				setSkeletonSer(true)
				fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/BusinessScenariosBasic/` + organisation + `/` + version)
					.then(res => res.json())
					.then(
						(result) => {
							var newOrgService = []
							result.length > 0 && result.map(item => newOrgService.push({ name: item.name, id: item.id, bianId: item.bianId, uid: item.uid, status: item.status, type: item.type }))
							setserviceItems(newOrgService)
							setSkeletonSer(false)
						},
						(error) => console.log(error)
					)
			}
		} else {
			const userDetails = JSON.parse(localStorage.getItem('userDetails'));

			if (props.serviceOrg.organisation && userDetails?.id) {
				fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/BusinessScenariosBasic/` + userDetails.id + `/` + props.serviceOrg.organisation + `/` + props.serviceOrg.version)
					.then(res => res.json())
					.then(
						(result) => {
							var newOrgService = []
							result.length > 0 && result.map(item => newOrgService.push({ name: item.name, id: item.id, bianId: item.bianId, uid: item.uid, status: item.status }))
							setserviceItems(newOrgService)
							setSkeletonSer(false)
						},
						(error) => console.log(error)
					)
			} else {
				if (userDetails?.id) {
					fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/BusinessScenariosBasic/` + organisation + `/` + version)
						.then(res => res.json())
						.then(
							(result) => {
								var newOrgService = []
								result.length > 0 && result.map(item => newOrgService.push({ name: item.name, id: item.id, bianId: item.bianId, uid: item.uid, status: item.status }))
								setserviceItems(newOrgService)
								setSkeletonSer(false)
							},
							(error) => console.log(error)
						)
				}
			}
		}


		// if (props.serviceOrg.organisation !== undefined) {
		// setSkeletonSer(true);

		let axiosUrl = "";
		process.env.REACT_APP_VERSION === "S1" &&
			(axiosUrl =
				process.env.REACT_APP_MODEL_ACCESS_S1 +
				"ServiceDomainsBasic/" +
				props.serviceOrg.organisation +
				"/" +
				props.serviceOrg.version);
		process.env.REACT_APP_VERSION === "S2" &&
			(axiosUrl =
				process.env.REACT_APP_MODEL_ACCESS_S2 +
				"ServiceDomainsBasic/" +
				props.serviceOrg.organisation +
				"/" +
				props.serviceOrg.version);

		axios
			.get(axiosUrl, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("jwtToken"),
				},
			})
			.then(
				(response) => {
					var newOrgService = [];
					response.data.length > 0 &&
						response.data.map((item) =>
							newOrgService.push({
								name: item.name,
								id: item.id,
								bianId: item.bianId,
								uid: item.uid,
								status: item.status,
								meta: item.meta,
							})
						);
					dispatch({ type: "ALL_FEEDBACK_DOMAIN", payload: newOrgService })
				},
				(error) => console.log(error)
			);
		// }
	}, [props.serviceOrg, userFil, businessScenarioDataVal])

	// Feedback
	useEffect(() => {
		dispatch({ type: "SELECTED_FEEDBACK_DOMAIN", payload: props.feedOrg })
		if (props?.feedOrg?.organisation !== undefined) {
			setSkeletonFeed(true)
			fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `ServiceDomainsBasic/` + props.feedOrg.organisation + `/` + props.feedOrg.version)
				.then(res => res.json())
				.then(
					(result) => {
						// if (localStorage.getItem('jwtToken')) {
						var newOrgService = []
						result.length > 0 && result.map(item => newOrgService.push({ name: item.name, id: item.id, bianId: item.bianId, uid: item.uid, status: item.status, extendedDefinition: item.extendedDefinition }))
						dispatch({ type: "ALL_FEED_DOMAIN", payload: newOrgService })

						setfeedItems(newOrgService)
						setSkeletonFeed(false)
						// }
					},
					(error) => console.log(error)
				)
		}
	}, [props.feedOrg])


	function handleServiceRadioChange(uidVal) {
		// console.log("uid handleServiceRadioChange from child is =>" + uid);
		setsuid(uidVal)
		localStorage.setItem('serviceRadioId', uidVal)


	}

	function handleFeedRadioChange(uid) {
		// console.log("uid handleFeedRadioChange from child is =>" + uid);
		setfuid(uid)
		localStorage.setItem('serviceFeedRadioId', uid)
	}

	const newSelectedService = (service) => {
		setSelectService(service)
		dispatch({ type: "SELECTED_FEEDBACK_SERVICE", payload: service })

	}
	return (
		<>

			<div className={classes.maincontainer}>
				<div className={classes.left_bar}><Servicelist setUserFilter={setUserFil} skeleton={skeletonSer} serviceItems={serviceItems} serviceUid={suid} handleRadioChange={(uIdData) => handleServiceRadioChange(uIdData)} /></div>
				<div className={classes.maincontent}><MainContent serviceUid={suid} feedUid={fuid} newService={selectService} /></div>
				<div className={classes.right_bar}><Feedbacklist skeleton={skeletonFer} serviceItems={feedItems} handleRadioChange={handleFeedRadioChange} selectedService={newSelectedService} /></div>
			</div>
		</>
	);
}

export default withAppInsights(Service);
