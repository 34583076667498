import React, { useState, useEffect, useLayoutEffect } from "react";
import {
	Container,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Divider,
	Skeleton,
	Stack,
	Box,
	Typography,
	Chip,
	Button,
	IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import Add from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import Searcharea from "../../utils/Searcharea";
import { reactPlugin } from "../../../AppInsights";

import {
	useAppInsightsContext,
	useTrackMetric,
	useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "calc(100vh - 280px)",
		overflow: "hidden",
		overflowY: "scroll",
	},
	item: {
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		padding: "15px 0px",
	},
	icons: {},
	path: {
		paddingLeft: "10px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	formControl: { width: "100%", height: "" },
}));

function Servicelist(props) {
	const path = window.location.pathname.split("/");
	const businessScenarioDataVal = useSelector(
		(state) => state?.common?.businessScenarioData
	);
	const businessScenarioData = useSelector(
		(state) => state?.common?.defaultBusinessScenario
	);

	const urlPath =
		(businessScenarioDataVal &&
			businessScenarioDataVal?.name &&
			businessScenarioDataVal?.name.replaceAll("-", " ")) ||
		null;
	const classes = useStyles();
	let organisation = businessScenarioDataVal?.organisation
		? businessScenarioDataVal.organisation
		: "BIAN";
	let version = businessScenarioDataVal?.version
		? businessScenarioDataVal.version
		: "10.1.0";
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState(urlPath);
	const jwtData = localStorage.getItem("jwtToken");
	const [activeChip, setActiveChip] = useState("Business Scenario");
	const [applyFilter, setApplyFilter] = useState(false);
	const [radioVal, setRadioVal] = useState();
	const [servicesItems, setServicesItems] = useState([]);
	const [allItems, setAllItems] = useState([]);

	const [defaultRadio, setDefaultRadio] = useState("");
	const domainStatus = ["Business Scenario", "Wireframe"];

	const trackCustomEvents = useTrackEvent(
		reactPlugin,
		"Selected Bussiness Scenario"
	);
	const trackCustomMatric = useTrackMetric(
		reactPlugin,
		"Select Bussiness Scenario Matric Data"
	);

	useLayoutEffect(() => {
		if (urlPath && props.serviceItems) {
			const selectedObj = props.serviceItems.find(
				({ name }) => name === urlPath
			);
			if (selectedObj) {
				props.handleRadioChange(selectedObj.uid);
				radioValue = selectedObj.uid;
				dispatch({ type: "CREATEDIAGRAM", payload: false });
				setRadioVal(selectedObj.uid);
				setSearchText(selectedObj.name);
			}
		}
	}, [urlPath, props?.serviceItems, businessScenarioDataVal]);

	useEffect(() => {
		props.setUserFilter(activeChip);
	}, [activeChip]);

	useEffect(() => {
		if (businessScenarioData && servicesItems && servicesItems.length > 0) {
			const selectedOne = servicesItems.find(
				({ uid }) => uid == businessScenarioData
			);
			if (selectedOne?.name) {
				setSearchText(selectedOne?.name);
			}
		}
	}, [businessScenarioData, servicesItems]);

	const handleChipChange = (passed) => {
		// passed === "" && setApplyFilter(false);
		// setActiveChip(passed);
		// props.handleChipChange(passed);
		if (passed) {
			const filterData = allItems.filter(({ type }) => type == passed);
			setServicesItems(filterData);
		} else {
			setServicesItems(allItems);
		}
		setActiveChip(passed);
		setApplyFilter(false);
	};
	var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
	var showSkeleton = false;
	var radioValue = "";

	// rerender whwn dependency var changes
	// useEffect(() => {

	// 	console.log("skeleton is", props.skeleton)
	// 	console.log("serviceItems is", props.serviceItems)

	// }, [props.serviceItems, props.skeleton])

	// Show Skeleton make var true when data is not there or api is fetching data
	(props.skeleton === undefined || props.skeleton === true) &&
		(showSkeleton = true);

	// show skelenton n number of times
	const radioSkeletons = [];
	for (let i = 1; i <= 20; i++) {
		radioSkeletons.push(i);
	}
	function handlesearchText(text) {
		//   if (text.target.value.length > 3) {
		//     console.log(">3");
		//     setSearchText(text.target.value)
		//   }
		//   else if (text.target.value.length === 0) {
		//     console.log("===0");
		//     setSearchText(text.target.value)
		//   }
		//   else {
		//     console.log("else");
		//     return
		//   }
		setSearchText(text.target.value);
	}

	useEffect(() => {
		if (props?.serviceItems) {
			setAllItems(props.serviceItems);
			const filterData = props.serviceItems.filter(
				({ type }) => type == "BusinessScenario" || !type
			);
			setServicesItems(filterData);
			// const selectVal = props.serviceItems.find(({ uid }) => uid === radioVal)
			// dispatch({ type: "SELECTED_BUSINESS_SCENARIO", payload: { organisation, version, name: selectVal.name } })
		}
	}, [props.serviceItems]);

	const changeRadioVal = (event, value) => {
		const selectedItem = servicesItems.filter((item) => item.uid === value);
		trackCustomEvents({ name: selectedItem[0]?.name });
		trackCustomMatric({ name: selectedItem[0]?.name, average: 30 });
		props.handleRadioChange(value);
		radioValue = value;
		dispatch({ type: "CREATEDIAGRAM", payload: false });
		setRadioVal(value);
		// setDefaultRadio(value)
	};

	return (
		<Container className={combinedClasses}>
			{applyFilter ? (
				<Box
					style={{
						padding: "10px",
					}}>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<Typography varinat="small">Apply filter: </Typography>
						<Button
							variant="text"
							size="small"
							onClick={() => setApplyFilter(false)}>
							Hide
						</Button>
					</Box>
					<Divider style={{ margin: "6px 0" }} />
					<Box
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "start",
							flexDirection: "column",
						}}>
						{domainStatus.map((cur) => {
							return (
								<Chip
									size="small"
									style={{ margin: "10px 5px" }}
									variant={activeChip == cur ? "filled" : "outlined"}
									color="primary"
									label={cur}
									key={cur}
									onClick={() => handleChipChange(cur)}
								/>
							);
						})}
						{activeChip !== "" && (
							<Chip
								size="small"
								style={{ margin: "10px 5px" }}
								variant="filled"
								color="primary"
								label="Clear"
								onClick={() => handleChipChange("")}
								onDelete={() => handleChipChange("")}
							/>
						)}
					</Box>
				</Box>
			) : (
				<Box>
					{/* <FormControl className={classes.formControl}> */}
					<Button
						variant="outlined"
						onClick={() => {
							jwtData
								? dispatch({ type: "CREATEDIAGRAM", payload: true })
								: (window.location.href = "/login");
						}}
						style={{ width: "100%", marginBottom: "5px" }}>
						<Add /> Create
					</Button>
					<div className="searchcontainer">
						<Searcharea
							className="searchVal"
							searchText={handlesearchText}
							text={searchText}
						/>
					</div>
					<Divider style={{ margin: "15px 0" }} />

					<FormLabel id="demo-service-radio-buttons-group-label">
						{activeChip ? activeChip : "All"}
					</FormLabel>
					<IconButton
						aria-label="filter"
						size="small"
						color={activeChip !== "" ? "danger" : "primary"}
						onClick={() => setApplyFilter(true)}
						style={{ float: "right", textAlign: "right" }}>
						<FilterListIcon size="small" />
					</IconButton>
					<RadioGroup
						aria-labelledby="demo-service-radio-buttons-group-label"
						value={defaultRadio || props.serviceUid}
						name="service-radio-buttons-group"
						onChange={(event, value) => {
							changeRadioVal(event, value);
						}}>
						{servicesItems?.length > 0 &&
							!showSkeleton &&
							servicesItems.map((loop) => {
								if (!searchText || searchText?.length === 0) {
									return (
										<FormControlLabel
											value={loop.uid}
											key={loop.uid}
											control={<Radio />}
											label={loop.name}
										/>
									);
								} else if (searchText?.length > 0) {
									return (
										<FormControlLabel
											value={loop.uid}
											key={loop.uid}
											control={<Radio />}
											label={loop.name}
											style={{
												display: loop.name
													.toLowerCase()
													.includes(searchText.toLowerCase())
													? "block"
													: "none",
											}}
										/>
									);
								} else {
									return (
										<Typography variant="label" component="label">
											No results found
										</Typography>
									);
								}
							})}
						{(servicesItems?.length === 0 ||
							servicesItems?.length === undefined ||
							servicesItems?.length === null ||
							showSkeleton) &&
							radioSkeletons.map((loopSkeleton, index) => (
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										paddingTop: "10px",
									}}
									key={"skeletonKey" + index}>
									<Skeleton
										variant="circular"
										width={20}
										height={20}
										sx={{ marginRight: "7px" }}
									/>
									<Skeleton
										sx={{ flexGrow: "1" }}
										height={20}
										variant="rectangular"
									/>
								</Box>
							))}
					</RadioGroup>
					{/* </FormControl> */}
				</Box>
			)}
		</Container>
	);
}

export default Servicelist;
