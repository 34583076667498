import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
  CardContent,
  Grid,
  FormGroup,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";

import { useNavigate as useHistory } from "react-router-dom";

function AddUser() {
  const [organisation, setOrganisation] = useState({
    uid: "",
    name: "",
    displayName: "",
    description: "",
    emailAddress: "",
    webSite: "",
    phoneNumber: "",
    status: "",
  });

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let history = useHistory();

  const handleInputChange = (event) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
  };

  const getOrganisationData = async () => {
    var orgData = {
      name: organisation.name,
      displayName: organisation.displayName,
      description: organisation.description,
      emailAddress: organisation.emailAddress,
      webSite: organisation.webSite,
      phoneNumber: organisation.phoneNumber,
      status: organisation.status,
    };
    return orgData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    const userData = await getOrganisationData();
    console.log("userData is ", userData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Organisation");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Organisation");

    await axios
      .post(axiosUrl, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        history("/manage/organisation");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(" error.response :", error.response);
          console.log(" error.response.data :", error.response.data);
          console.log(" error.response.status :", error.response.status);
          console.log(" error.response.headers :", error.response.headers);
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.statusText +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add Organisation " + error.message);
        }
      });
  };

  return (
    <Container sx={{ pt: 4 }}  style={{overflow: 'auto', height: '65vh'}}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader
            subheader="* Fill the required fields"
            title="Add Organisation"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="name"
                  label="Organisation Name"
                  name="name"
                  autoFocus
                  value={organisation.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="emailAddress"
                  label="Email Address"
                  name="emailAddress"
                  autoComplete="emailAddress"
                  value={organisation.emailAddress}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="displayName"
                  label="Organisation Display Name"
                  name="displayName"
                  autoFocus
                  value={organisation.displayName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  name="phoneNumber"
                  label="Phone Number"
                  type="text"
                  id="phoneNumber"
                  value={organisation.phoneNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="description"
                  label="Description"
                  name="description"
                  value={organisation.description}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="uid"
                  label="Organisation Id"
                  name="uid"
                  value={organisation.uid}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="webSite"
                  label="Web Site"
                  name="webSite"
                  autoComplete="webSite"
                  value={organisation.webSite}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={handleInputChange}
                      name="status"
                    />
                  }
                  label="Status"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              type="submit"
            >
              Add
            </Button>
          </Box>
        </Card>
      </form>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )}
    </Container>
  );
}

export default AddUser;
