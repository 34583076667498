import { combineReducers } from "redux";

import orgReducer from "../organisation/reducer";
import certificationReducer from "../certification/reducer";
import CommonReducer from "./../../components/redux/Common.reducer"


export default combineReducers({
  organisationData: orgReducer,
  certification: certificationReducer,
  common: CommonReducer
});
