import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import TreeView from "@mui/lab/TreeView";
import { v4 as uuidv4 } from "uuid";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
	Collapse,
	Typography,
	Divider,
	Box,
	Card,
	Skeleton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	DialogActions,
	RadioGroup,
	Button,
} from "@mui/material";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import DiagramModule from "./DiagramModule";
import Diagram from "./Diagram";
import DiagramCreate from "./DiagramCreate";
import { createGraphLayout } from "./layout";
import dagre from "dagre";
import LabelBody from "./LabelBody";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import moment from "moment";
import ERD from "./ERD";
import { SmartToaster, toast } from "react-smart-toaster";
import { Close, Edit } from "@mui/icons-material";

import { reactPlugin } from "../../../AppInsights";

import {
	useAppInsightsContext,
	useTrackMetric,
	useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

function TransitionComponent(props) {
	const style = useSpring({
		from: {
			opacity: 0,
			transform: "translate3d(20px,0,0)",
		},
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

const StyledTreeItem = styled((props) => (
	<TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
	[`& .${treeItemClasses.iconContainer}`]: {
		"& .close": {
			opacity: 0.3,
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 15,
		paddingLeft: 18,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
	},
}));

function Domain(props) {
	const dispatch = useDispatch();
	const [treeItem, setTreeItem] = useState({});
	const [skeleton, setSkeleton] = useState(true);
	const [connectionNodes, setconnectionNodes] = useState([]);
	const [connectionLinks, setconnectionLinks] = useState([]);
	const [allNdDataVal, setAllNdDataVal] = useState([]);
	const [value, setValue] = React.useState("1");
	const [modalOpen, setModalOpen] = useState(false);
	const [createNewDiagram, setCreateNewDiagram] = useState(false);
	const [createStatus, setCreateStatus] = useState(false);
	const [open, setOpen] = React.useState(false);
	const [updateKey, setUpdateKey] = React.useState(0);
	const [openEditableName, setOpenEditableName] = React.useState(false);
	const [editableName, setEditableName] = React.useState(false);
	const [businessScenarioEditText, setBusinessScenarioEditText] =
		React.useState("");
	const selectedFeedbackDomainVal = useSelector(
		(state) => state?.common?.selectedFeedbackDomainVal
	);

	const [businessScenariosVal, setBusinessScenarioText] = React.useState(
		"Sample Business Scenario 1"
	);
	const [businessScenariosData, setBusinessScenarioData] = React.useState("");

	const trackCustomCreateEvent = useTrackEvent(
		reactPlugin,
		"Create event in Business Scenario"
	);
	const trackCustomCreateMatric = useTrackMetric(
		reactPlugin,
		"Create Matrics Bussiness Scenario"
	);

	const SelectedFeedback = useSelector(
		(state) => state?.common?.SelectedFeedback
	);
	const createStatusVal = useSelector((state) => state?.common?.createStatus);
	const createdConnectionVal = useSelector(
		(state) => state?.common?.createdConnection
	);
	const allNodePosition = useSelector(
		(state) => state?.common?.allNodePositionData
	);
	const domainDetails = useSelector((state) => state?.common?.domainDetails);
	const newallFeed = useSelector((state) => state?.common?.newallFeed);
	const updateBusinessCount = useSelector(
		(state) => state?.common?.updateBusinessCount
	);

	const [modalOpenData, setModalOpenData] = useState({
		title: "Title",
		description: "Description",
		status: "Status",
		data_type: "String",
	});

	useEffect(() => {
		setCreateStatus(createStatusVal);
		if (createStatusVal === true) {
			setAllNdDataVal([]);
			setTreeItem([]);
			setOpen(true);
		}
	}, [createStatusVal]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseEditable = () => {
		setOpenEditableName(false);
	};

	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.setDefaultEdgeLabel(() => ({}));

	const nodeWidth = 172;
	const nodeHeight = 160;

	const handleModalClickOpen = () => {
		var getItem = JSON.parse(localStorage.getItem("selectToEdit"));
		setModalOpenData(getItem);
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const getLayoutedElements = (nodes, edges, direction = "TB") => {
		const isHorizontal = direction === "LR";
		dagreGraph.setGraph({ rankdir: direction });

		nodes.forEach((node) => {
			dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
		});

		edges.forEach((edge) => {
			dagreGraph.setEdge(edge.source, edge.target);
		});

		dagre.layout(dagreGraph);

		nodes.forEach((node) => {
			const nodeWithPosition = dagreGraph.node(node.id);
			node.targetPosition = isHorizontal ? "left" : "top";
			node.sourcePosition = isHorizontal ? "right" : "bottom";

			// We are shifting the dagre node position (anchor=center center) to the top left
			// so it matches the React Flow node anchor point (top left).
			node.position = {
				x: nodeWithPosition.x - nodeWidth / 2,
				y: nodeWithPosition.y - nodeHeight / 2,
			};

			return node;
		});

		return { allConnectionNodes: nodes, edges };
	};

	useEffect(() => {
		if (
			props.serviceUid !== undefined &&
			props.serviceUid !== null &&
			props.serviceUid !== ""
		) {
			fetch(
				process.env.REACT_APP_MODEL_ACCESS_S1 +
					`/BusinessScenarioByUID/` +
					props.serviceUid
			)
				.then((res) => res.json())
				.then(
					(result) => {
						setTreeItem(result);
						localStorage.setItem("servicetree", JSON.stringify(result));
						props.callbackFunction(result);
						setSkeleton(false);
					},
					(error) => {
						console.log("error ==> ", error);
					}
				);
		}
	}, [props.serviceUid, updateBusinessCount]);

	const resetChart = () => {
		fetch(
			process.env.REACT_APP_MODEL_ACCESS_S1 +
				`/BusinessScenarioByUID/` +
				props.serviceUid
		)
			.then((res) => res.json())
			.then(
				(result) => {
					setTreeItem(result);
					localStorage.setItem("servicetree", JSON.stringify(result));
					props.callbackFunction(result);
					setSkeleton(false);
				},
				(error) => {
					console.log("error ==> ", error);
				}
			);
	};

	useEffect(() => {
		if (props?.selectedService && props?.selectedService.length > 0) {
			const defaultArr = [];
			newConnection &&
				newConnection.length > 0 &&
				newConnection.map((item) => {
					defaultArr.push({
						id: 0,
						source: item.source,
						target: item.target,
						labelStyle: {
							fill: "white",
							fontWeight: 700,
						},
						labelBgStyle: {
							fill: "black",
							with: "20px",
							fillOpacity: 0.7,
						},
						labelBgWidth: "20px",
						type: "step",
						label: "Double Click",
						animated: true,
					});
				});

			let maxPosition = 0;
			let positionVal = {};
			allNdDataVal.map((item) => {
				if (item.position) {
					if (item.position.x > maxPosition) {
						maxPosition = item.position.x;
						positionVal = item.position;
					}
				}
			});
			// allElements
			const { name } = props.selectedService[0];
			const mainObj = {
				id: name,
				sourcePosition: "right",
				targetPosition: "left",
				data: {
					label: (
						<div class="tooltip">
							{name}
							<span
								class="tooltiptext"
								style={{
									marginLeft: "0px",
									marginRight: "20px",
									display: "hidden",
								}}></span>
						</div>
					),
				},
				position: {
					x: maxPosition ? maxPosition + 200 : 400,
					y: positionVal.y && positionVal.y !== 0 ? positionVal.y : 40,
				},
			};
			const data = {
				id: name,
				x: maxPosition ? maxPosition + 200 : 400,
				y: positionVal.y && positionVal.y !== 0 ? positionVal.y : 40,
			};
			dispatch({ type: "UPDATEPOSITIONNEWDIAGRAM", data });
			const mainArr = allNdDataVal.concat(defaultArr);
			setAllNdDataVal([...mainArr, mainObj]);
		}
	}, [props?.selectedService]);

	const onlyUnique = (value, index, self) => {
		return self.indexOf(value) === index;
	};

	const groupBy = (list, keyGetter) => {
		const map = new Map();
		list.forEach((item) => {
			const key = keyGetter(item);
			const collection = map.get(key);
			if (!collection) {
				map.set(key, [item]);
			} else {
				collection.push(item);
			}
		});
		return map;
	};

	const getRandomColor = () => {
		var letters = "0123456789ABCDEF";
		var color = "#";
		for (var i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)];
		}
		return color;
	};

	useEffect(async () => {
		if (treeItem.serviceConnections) {
			setUpdateKey(updateKey + 1);
			setAllNdDataVal([]);

			const nodeIds = [];
			const allNodesVal = [];
			const nodesVal = [];

			treeItem.serviceConnections.map((item) => {
				if (
					item.fromServiceConnectionPart.serviceDomainName.charAt(0) === " "
				) {
					item.fromServiceConnectionPart.serviceDomainName =
						item.fromServiceConnectionPart.serviceDomainName.replace(" ", "");
				}
				if (item.toServiceConnectionPart.serviceDomainName.charAt(0) === " ") {
					item.toServiceConnectionPart.serviceDomainName =
						item.toServiceConnectionPart.serviceDomainName.replace(" ", "");
				}
			});

			treeItem.serviceConnections.map((data) => {
				if (
					!nodesVal.includes(data.fromServiceConnectionPart.serviceDomainName)
				) {
					if (
						data.fromServiceConnectionPart.serviceDomainName.split(" ")
							.length === 2
					) {
						nodesVal.push(
							`   ${data.fromServiceConnectionPart.serviceDomainName}   `
						);
					} else {
						nodesVal.push(
							`   ${data.fromServiceConnectionPart.serviceDomainName}   `
						);
					}
				}
				if (
					!nodesVal.includes(data.toServiceConnectionPart.serviceDomainName)
				) {
					if (
						data.toServiceConnectionPart.serviceDomainName.split(" ").length ===
						2
					) {
						nodesVal.push(
							`   ${data.toServiceConnectionPart.serviceDomainName}   `
						);
					} else {
						nodesVal.push(
							`   ${data.toServiceConnectionPart.serviceDomainName}   `
						);
					}
				}
			});

			let allNodeVal = [];
			await treeItem.serviceConnections.map((data, i) => {
				allNodeVal.push({
					from: data?.fromServiceConnectionPart?.serviceDomainName,
					to: data?.toServiceConnectionPart?.serviceDomainName,
					shape:
						data?.toServiceConnectionPart?.type == "Business event" ||
						data?.fromServiceConnectionPart?.type == "Business event"
							? "triangle"
							: data?.toServiceConnectionPart?.type == "Grouping" ||
							  data?.fromServiceConnectionPart?.type == "Grouping"
							? "circle"
							: null,
					connection: data?.toServiceConnectionPart?.serviceOperationName,
					uid: data?.toServiceConnectionPart?.uid,
				});
			});

			dispatch({ type: "FROM_TO_CONNECTION", payload: allNodeVal });

			const mainDataVal = treeItem.serviceConnections.map((data, i) => {
				data.from = data?.fromServiceConnectionPart?.serviceDomainName;
				data.to = data?.toServiceConnectionPart?.serviceDomainName;
				data.shape =
					data?.toServiceConnectionPart?.type == "Business event" ||
					data?.fromServiceConnectionPart?.type == "Business event"
						? "triangle"
						: data?.toServiceConnectionPart?.type == "Grouping" ||
						  data?.fromServiceConnectionPart?.type == "Grouping"
						? "circle"
						: null;
				data.connection = data?.toServiceConnectionPart?.serviceOperationName;
				data.uid = data?.toServiceConnectionPart?.uid;
			});

			dispatch({
				type: "ALL_CONNECT_CREATED",
				payload: treeItem.serviceConnections,
			});

			const mainArr = [];

			const grouped = groupBy(allNodeVal, (nd) => nd.from);

			const groupName = [];
			const groupMearge = [];
			treeItem.serviceConnections.map((item) => {
				if (
					!groupName.includes(item.fromServiceConnectionPart.serviceDomainName)
				) {
					const selectedGroup = grouped.get(
						item.fromServiceConnectionPart.serviceDomainName
					);
					groupMearge.push(selectedGroup);
					groupName.push(item.fromServiceConnectionPart.serviceDomainName);
				}
			});

			let allGrp = [];
			for (let index = 0; index < groupMearge.length + 1; index++) {
				if (groupMearge[groupMearge.length - index]) {
					groupMearge[groupMearge.length - index].map((values) => {
						if (!allGrp.includes(values)) {
							allGrp.push(values);
						}
					});
				}
			}

			for (let index = 0; index < 4; index++) {
				const sortNodes = allNodeVal
					.sort(
						(a, b) =>
							allNodeVal.filter((v) => v.from === a.from).length -
							allNodeVal.filter((v) => v.from === b.from).length
					)
					.pop();

				allNodeVal.map(({ from, to }, index) => {
					if (from === sortNodes.from || to === sortNodes.to) {
						mainArr.push({ from, to });
					}
				});
			}

			allGrp.map((item, index) => {
				if (!nodeIds.includes(item.to)) {
					nodeIds.push(item.to);
					allNodesVal.push({
						id: item.to,
						// data: {
						//   label: (
						//     <div class="tooltip">
						//       {item.to}
						//       <span class="tooltiptext" style={{marginLeft:"20px", marginRight:"20px"}}>{item.connection}</span>
						//     </div>
						//   )
						// },
						data: item.connection,
						shape: item.shape,
						position: { x: 200, y: 200 },
						sourcePosition: "right",
						targetPosition: "left",
					});
				}
			});
			allGrp.map((item, index) => {
				// const { id, serviceDomainName } = item.fromServiceConnectionPart
				if (!nodeIds.includes(item.from)) {
					nodeIds.push(item.from);
					allNodesVal.push({
						id: item.from,
						// data: {
						//   label: (
						//     <div class="tooltip">
						//       {item.to}
						//       <span class="tooltiptext" style={{marginLeft:"0px", marginRight:"20px"}}>{item.connection}</span>
						//     </div>
						//   )
						// },
						data: item.connection,
						shape: item.shape,
						position: { x: 200, y: 200 },
						sourcePosition: "right",
						targetPosition: "left",
					});
				}
			});

			const allLinksVal = [];
			treeItem.serviceConnections.map((item, index) => {
				// if (!allLinksVal.includes({
				//   id: item.toServiceConnectionPart,
				//   source: item.fromServiceConnectionPart.serviceDomainName,
				//   target: item.toServiceConnectionPart.serviceDomainName,
				//   animated: true,
				//   type: 'step',
				//   style: { stroke: 'grey', strokeDasharray: 10 },
				//   // labelBgStyle: { fill: 'grey',with:"20px", fillOpacity: 0.7 },
				//   labelBgStyle: { fill: 'white', with: "20px", fillOpacity: 0.7 },
				//   label: item.toServiceConnectionPart.serviceOperationDescription,
				//   // arrowHeadType: 'arrow',
				// })) {
				allLinksVal.push({
					id: item.toServiceConnectionPart,
					source: item.fromServiceConnectionPart.serviceDomainName,
					target: item.toServiceConnectionPart.serviceDomainName,
					animated: true,
					type: "step",
					// style: { stroke: 'grey', strokeDasharray: 10 },
					// labelBgStyle: { fill: 'grey', with:"20px", fillOpacity: 0.7 },
					labelBgStyle: { fill: "white", with: "20px", fillOpacity: 0.7 },
					label: item.toServiceConnectionPart.serviceOperationDescription,
					// arrowHeadType: 'arrow',
				});
				// }
			});
			const allNd = [];
			const ndVal = allNodesVal;
			ndVal.map((item) => {
				allNd.push({
					id: item.id,
					sourcePosition: "right",
					targetPosition: "left",
					shape: item.shape,
					data: {
						label: (
							<div
								class={`${
									item.id.toLowerCase() == "production fulfillment" ||
									item.id.toLowerCase() == "production operations" ||
									item.id.toLowerCase() == "service event" ||
									item.id.toLowerCase() == "customer event"
										? "shape_triangle tooltip"
										: item.id.toLowerCase() == "product fulfillment sds"
										? "shape_circle tooltip"
										: "tooltip"
								} `}>
								{item.id}
								<span
									class="tooltiptext"
									style={{ marginLeft: "0px", marginRight: "20px" }}>
									{item.data}
								</span>
							</div>
						),
					},
					position: { x: 20, y: 20 },
				});
			});

			const allEdg = [];
			const edgVal = allLinksVal;
			edgVal.map((item) => {
				allEdg.push({
					id: item.id.id,
					source: item.source,
					target: item.target,
					// data: {
					//   label: (
					//     <div >
					//       <span style={{ marginLeft: "0px", marginRight: "20px", visibility: 'visible' }}>{item.label}</span>
					//     </div>
					//   )
					// },
					labelStyle: { fill: "white", fontWeight: 700 },
					labelBgStyle: { fill: "black", with: "20px", fillOpacity: 0.7 },
					labelBgWidth: "20px",
					type: "step",
					label: "Double Click",
					animated: true,
				});
			});

			let mainNodeVal = [];
			allEdg.map((data, i) => {
				if (data.source && data.target) {
					mainNodeVal.push({
						from: data.source,
						to: data.target,
						id: i,
						// 'connection': data?.data?.label?.props?.children?.props?.children,
						uid: data?.id,
					});
				}
			});
			const allItemNode = [];
			allNd.map((itemVal) => {
				treeItem.serviceConnections.map((data) => {
					if (data.fromServiceConnectionPart.serviceDomainName === itemVal.id) {
						itemVal.position.x = data.fromServiceConnectionPart.x;
						itemVal.position.y = data.fromServiceConnectionPart.y;
					}

					if (data.toServiceConnectionPart.serviceDomainName === itemVal.id) {
						itemVal.position.x = data.toServiceConnectionPart.x;
						itemVal.position.y = data.toServiceConnectionPart.y;
					}
				});
				allItemNode.push(itemVal);
			});
			// dispatch({ type: "FROM_TO_CONNECTION", payload: mainNodeVal })
			setAllNdDataVal(allItemNode.concat(allEdg));
			createGraphLayout(allItemNode.concat(allEdg))
				.then((els) => {
					console.log("els els ", els);
					setAllNdDataVal(els);
				})
				.catch((err) => console.error("setAllNdDataVal err", err));

			setconnectionNodes(allNodesVal);
			setconnectionLinks(allLinksVal);
		}
	}, [treeItem]);

	const radioSkeletons = [];
	for (let i = 1; i <= 10; i++) {
		radioSkeletons.push(i);
	}

	const { allConnectionNodes, edges } = getLayoutedElements(
		connectionNodes,
		connectionLinks
	);

	useEffect(() => {
		if (
			window.location.href.split("?").length === 2 &&
			window.location.href.split("?")[1] === "new"
		) {
			setCreateNewDiagram(true);
		}
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const _SELECTED_FEEDBACK = useSelector(
		(state) => state?.common?.SelectedFeedback
	);
	const _ALL_COLLECTION = useSelector((state) => state?.common?.allCollection);
	const navBarDropDownValue = useSelector(
		(state) => state?.common?.navBarDropDownValue
	);
	const newConnection = useSelector((state) => state?.common?.newConnection);
	const [feedbackData, SetFeedbackData] = useState("");

	const saveBusinessScenario = () => {
		const userVal = JSON.parse(localStorage.getItem("tokenDetails"));
		setBusinessScenarioData(businessScenariosVal);
		setOpen(false);

		const defaultDomain = {
			label: navBarDropDownValue.label.replace(
				"BIAN",
				userVal.OrganisationName
			),
			name: navBarDropDownValue.name.replace("BIAN", userVal.OrganisationName),
			organisation: navBarDropDownValue.organisation.replace(
				"BIAN",
				userVal.OrganisationName
			),
			version: navBarDropDownValue.version.replace(
				"BIAN",
				userVal.OrganisationName
			),
			id: navBarDropDownValue.id.replace("BIAN", userVal.OrganisationName),
		};
		dispatch({ type: "UPDATE_DOMAIN", payload: defaultDomain });
	};

	useEffect(() => {
		if (_SELECTED_FEEDBACK && newConnection) {
			_SELECTED_FEEDBACK?.map((ele, index) => {
				const connectionIndex = newConnection.findIndex(
					({ target }) => target === ele.name
				);
				if (connectionIndex >= 0) {
					const selectedConst = _ALL_COLLECTION.find((val) => {
						if (
							val &&
							val.fromServiceConnectionPart &&
							val.toServiceConnectionPart
						) {
							const { fromServiceConnectionPart, toServiceConnectionPart } =
								val;
							return (
								(fromServiceConnectionPart &&
									fromServiceConnectionPart?.serviceDomainName ===
										newConnection[connectionIndex].source) ||
								(toServiceConnectionPart &&
									toServiceConnectionPart.serviceDomainName ===
										newConnection[connectionIndex].source)
							);
						}
					});

					let mainVal = {};
					if (
						selectedConst?.fromServiceConnectionPart &&
						selectedConst?.fromServiceConnectionPart?.serviceDomainName ===
							newConnection[index]?.source
					) {
						const {
							id,
							metaLight,
							uid,
							serviceDomainName,
							serviceOperationDescription,
							x,
							y,
						} = selectedConst?.fromServiceConnectionPart;
						mainVal = {
							serviceDomainName,
							serviceOperationDescription,
							id: id,
							bianId: null,
							metaLight,
							status: null,
							name: `${navBarDropDownValue.organisation}-${
								navBarDropDownValue.version
							}-${serviceDomainName.replaceAll(" ", "")}`,
							displayName: null,
							description: null,
							uid,
							x,
							y,
						};
					} else {
						if (selectedConst?.toServiceConnectionPart) {
							const {
								id,
								metaLight,
								uid,
								serviceDomainName,
								serviceOperationDescription,
								x,
								y,
							} = selectedConst?.toServiceConnectionPart;
							mainVal = {
								serviceDomainName,
								serviceOperationDescription,
								id: id,
								bianId: null,
								metaLight,
								status: null,
								name: `${navBarDropDownValue.organisation}-${
									navBarDropDownValue.version
								}-${serviceDomainName.replaceAll(" ", "")}`,
								displayName: null,
								description: null,
								uid,
								x,
								y,
							};
						}
					}

					if (!mainVal.serviceDomainName) {
						const selectedData = _SELECTED_FEEDBACK.find(
							({ name }) => name === newConnection[connectionIndex].source
						);
						mainVal = {
							serviceDomainName: selectedData?.name,
							serviceOperationDescription: null,
							id: selectedData?.id,
							bianId: null,
							metaLight: {
								comments: null,
								differenceLights: null,
								uid: selectedData?.uid,
							},
							status: null,
							name: `${navBarDropDownValue.organisation}-${
								navBarDropDownValue.version
							}-${selectedData.name.replaceAll(" ", "")}`,
							displayName: null,
							description: null,
							uid: selectedData?.uid,
							x: ele.x,
							y: ele.y,
						};
					}
					// newallFeed
					const daata = {
						fromServiceConnectionPart: mainVal,
						toServiceConnectionPart: {
							serviceDomainName: ele.name,
							serviceOperationName: newConnection[connectionIndex]
								.serviceOperationDescription
								? newConnection[connectionIndex].serviceOperationDescription
								: null,
							serviceOperationDescription: newConnection[connectionIndex]
								.serviceOperationName
								? newConnection[connectionIndex].serviceOperationName
								: null,
							id: ele.id,
							bianId: null,
							x: ele.x,
							y: ele.y,
							metaLight: {
								comments: null,
								differenceLights: null,
								uid: mainVal?.metaLight?.uid,
							},
							status: null,
							name: ele.uid,
							displayName: null,
							description: null,
							uid: uuidv4(),
						},
						bianId: null,
						description: null,
						displayName: null,

						metaLight: {
							comments: null,
							differenceLights: null,
							uid: mainVal?.metaLight?.uid,
						},
						status: null,
						name: null,
						displayName: `${mainVal.name}-${ele.name}`,
						description: null,
						uid: uuidv4(),
					};
					SetFeedbackData([...feedbackData, daata]);
				}
			});
		}
	}, [_SELECTED_FEEDBACK, newConnection]);

	const saveChart = async () => {
		const jwtToken = localStorage.getItem("jwtToken");
		if (!jwtToken) {
			toast.info(
				"You must sign in first, to be able to save your work. Any unconnected service domains will not be saved."
			);
			// window.location.href = "/";
		}
		const userDetails = localStorage.getItem("cacheToken");

		let mainReq = [];
		const mainAllConnect = _ALL_COLLECTION.filter(function (element) {
			return element !== undefined;
		});

		mainAllConnect.map((itemData) => {
			itemData.fromServiceConnectionPart.name = `${
				navBarDropDownValue.organisation
			}-${
				navBarDropDownValue.version
			}-${itemData.fromServiceConnectionPart.serviceDomainName.replaceAll(
				" ",
				""
			)}`;
			itemData.toServiceConnectionPart.name = `${
				navBarDropDownValue.organisation
			}-${
				navBarDropDownValue.version
			}-${itemData.toServiceConnectionPart.serviceDomainName.replaceAll(
				" ",
				""
			)}`;
			itemData.toServiceConnectionPart.serviceOperationDescription =
				itemData.toServiceConnectionPart.serviceOperationDescription;
		});
		if (feedbackData) {
			let mainFeedVal = [];
			const allFeedData = feedbackData;

			const reversArr = allFeedData.reverse();
			const arrayUniqueByKey = [
				...new Map(
					reversArr.reverse().map((item) => [item["displayName"], item])
				).values(),
			];
			mainReq = _ALL_COLLECTION.concat(arrayUniqueByKey);
		} else {
			mainReq = _ALL_COLLECTION;
		}

		const organisationInstanceId = uuidv4();
		const tokenData = JSON.parse(localStorage.getItem("tokenDetails"));
		const outerData = {
			diagramUrl: null,
			scenarioGroup: {
				businessScenarios: null,
				id: 0,
				uid: null,
				bianId: null,
				meta: {
					organisation: navBarDropDownValue.name,
					organisationVersion: null,
					organisationInstance: null,
					version: navBarDropDownValue.version,
					comments: null,
					differences: null,
					feedback: null,
					name: navBarDropDownValue.id,
					displayName: null,
					description: null,
					uid: navBarDropDownValue.id,
				},
				name: "Bookings and Interest Management",
				displayName: null,
				description: null,
				status: null,
			},
			bianId: uuidv4(),
			meta: {
				organisation: navBarDropDownValue.organisation.replace(
					"BIAN",
					tokenData.OrganisationName
				),
				organisation: navBarDropDownValue.name.replace(
					"BIAN",
					tokenData.OrganisationName
				),
				organisationVersion: null,
				organisationInstance: organisationInstanceId,
				version: navBarDropDownValue.version,
				version: navBarDropDownValue.version,
				comments: null,
				differences: null,
				feedback: null,
				name: navBarDropDownValue.id.replace(
					"BIAN",
					tokenData.OrganisationName
				),
				displayName: null,
				description: null,
				uid: navBarDropDownValue.id.replace("BIAN", tokenData.OrganisationName),
				feedback: {
					assignments: [
						{
							userUID: userDetails,
							timeStamp: moment(),
						},
					],
				},
			},
			name: props.servicetree.name,
			displayName: null,
			description: null,
			uid: props.servicetree.uid.replace("BIAN", tokenData.OrganisationName),
			status: props.servicetree.status,
			actiontype: navBarDropDownValue?.type,
			baseCRBQName: navBarDropDownValue?.baseCRBQName,
			actionTerm: navBarDropDownValue?.actionTerm,
		};
		const mainPayload = mainReq.filter(function (element) {
			return element !== undefined;
		});
		const comData = { serviceConnections: mainPayload };
		const postUrl =
			process.env.REACT_APP_MODEL_ACCESS_S1 + `BusinessScenarios/Update`;
		const headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + jwtToken,
		};

		const userVal = JSON.parse(localStorage.getItem("tokenDetails"));

		const defaultDomain = {
			label: navBarDropDownValue.label.replace(
				"BIAN",
				userVal.OrganisationName
			),
			name: navBarDropDownValue.name.replace("BIAN", userVal.OrganisationName),
			organisation: navBarDropDownValue.organisation.replace(
				"BIAN",
				userVal.OrganisationName
			),
			version: navBarDropDownValue.version.replace(
				"BIAN",
				userVal.OrganisationName
			),
			id: navBarDropDownValue.id.replace("BIAN", userVal.OrganisationName),
		};

		await axios
			.put(postUrl, [Object.assign(comData, outerData)], { headers: headers })
			.then((response) => {
				toast.success("Diagram has been Created Successfully.");
				dispatch({ type: "SAVEDIAGRAM" });
				setAllNdDataVal([]);
				SetFeedbackData([]);
				dispatch({ type: "CLEARADDCONNECTION", payload: [] });
				dispatch({ type: "UPDATE_DOMAIN", payload: defaultDomain });
				dispatch({ type: "UPDATE_BUSINESS_SCENARIO", payload: outerData.uid });
				dispatch({ type: "UPDATE_BUSINESS_SCENARIO_COUNT" });
				dispatch({ type: "CREATEDIAGRAM", payload: false });
			})
			.catch(function (error) {
				if (error.response) {
					toast.error("Something went to Wrong.");
					console.log(" error.response :", error.response);
				}
			});
	};

	const saveNewChart = async () => {
		const userDetails = localStorage.getItem("cacheToken");
		const jwtToken = localStorage.getItem("jwtToken");
		if (!jwtToken) {
			toast.info(
				"You must sign in first, to be able to save your work. Any unconnected service domains will not be saved."
			);
		}
		const organisationInstanceId = uuidv4();
		const allNodeData = [];
		createdConnectionVal.map((nodeVal) => {
			const metaUID = uuidv4();
			const sourceId =
				SelectedFeedback &&
				SelectedFeedback.find(({ id, name }) => name === nodeVal.source);
			const targetId =
				SelectedFeedback &&
				SelectedFeedback.find(({ id, name }) => name === nodeVal.target);
			allNodeData.push({
				fromServiceConnectionPart: {
					serviceDomainName: nodeVal.source,
					serviceOperationDescription: null,
					id: sourceId?.id,
					bianId: null,
					status: null,
					name: `${navBarDropDownValue.organisation}-${
						navBarDropDownValue.version
					}-${nodeVal.source.replaceAll(" ", "")}`,
					displayName: null,
					description: null,
					metaLight: {
						comments: null,
						differenceLights: null,
						uid: metaUID,
					},
					uid: uuidv4(),
					x: allNodePosition[nodeVal.source]?.x || null,
					y: allNodePosition[nodeVal.source]?.y || null,
				},
				toServiceConnectionPart: {
					serviceDomainName: nodeVal.target,
					serviceOperationName: nodeVal?.serviceOperationName,
					serviceOperationDescription: nodeVal.serviceOperationDescription,
					id: targetId?.id,
					bianId: null,
					x: allNodePosition[nodeVal.target]?.x || null,
					y: allNodePosition[nodeVal.target]?.y || null,
					metaLight: {
						comments: null,
						differenceLights: null,
						uid: metaUID,
					},
					status: null,
					name: `${navBarDropDownValue.organisation}-${
						navBarDropDownValue.version
					}-${nodeVal.target.replaceAll(" ", "")}`,
					displayName: null,
					description: null,
					uid: uuidv4(),
				},
				bianId: uuidv4(),
				description: null,
				displayName: null,
				actiontype: nodeVal?.type,
				baseCRBQName: nodeVal?.baseCRBQName,
				actionTerm: nodeVal?.actionTerm,
				metaLight: {
					comments: null,
					differenceLights: null,
					uid: metaUID,
				},
				status: null,
				name: null,
				displayName: null,
				description: null,
				uid: uuidv4(),
			});
		});
		const tokenData = JSON.parse(localStorage.getItem("tokenDetails"));
		const userVal = JSON.parse(localStorage.getItem("tokenDetails"));
		const outerData = {
			diagramUrl: null,
			scenarioGroup: {
				businessScenarios: null,
				id: 0,
				uid: null,
				bianId: null,
				meta: {
					organisation: userVal.OrganisationName,
					organisationVersion: null,
					organisationInstance: organisationInstanceId,
					version: "10.0.0",
					comments: null,
					differences: null,
					feedback: null,
					name: `${userVal.OrganisationName}_Release_10.0.0`,
					displayName: null,
					description: null,
					uid: `${userVal.OrganisationName}_Release_10.0.0`,
				},
				name: businessScenariosData,
				displayName: null,
				description: null,
				status: null,
			},
			bianId: uuidv4(),
			meta: {
				organisation: navBarDropDownValue.organisation.replace(
					"BIAN",
					userVal.OrganisationName
				),
				organisationVersion: null,
				organisationInstance: organisationInstanceId,
				version: navBarDropDownValue.version,
				comments: null,
				differences: null,
				feedback: null,
				name: navBarDropDownValue.id.replace("BIAN", userVal.OrganisationName),
				displayName: null,
				description: null,
				uid: navBarDropDownValue.id.replace("BIAN", userVal.OrganisationName),
				feedback: {
					assignments: [
						{
							userUID: userDetails,
							timeStamp: moment(),
						},
					],
				},
			},
			name: businessScenariosData,
			displayName: null,
			description: null,
			uid: navBarDropDownValue.id + "-" + organisationInstanceId,
			status: "Registered",
		};

		const comData = { serviceConnections: allNodeData };
		const postUrl =
			process.env.REACT_APP_MODEL_ACCESS_S1 + `/BusinessScenarios`;
		const headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + jwtToken,
		};

		const defaultDomain = {
			label: navBarDropDownValue.label.replace(
				"BIAN",
				userVal.OrganisationName
			),
			name: navBarDropDownValue.name.replace("BIAN", userVal.OrganisationName),
			organisation: navBarDropDownValue.organisation.replace(
				"BIAN",
				userVal.OrganisationName
			),
			version: navBarDropDownValue.version.replace(
				"BIAN",
				userVal.OrganisationName
			),
			id: navBarDropDownValue.id.replace("BIAN", userVal.OrganisationName),
		};

		await axios
			.post(postUrl, [Object.assign(comData, outerData)], { headers: headers })
			.then((response) => {
				toast.success("Diagram has been Created Successfully.");
				dispatch({ type: "SAVEDIAGRAM" });
				dispatch({ type: "UPDATE_DOMAIN", payload: defaultDomain });
				dispatch({ type: "UPDATE_BUSINESS_SCENARIO", payload: outerData.uid });
				dispatch({ type: "CLEARADDCONNECTION", payload: [] });
				dispatch({ type: "CREATEDIAGRAM", payload: false });

				setCreateStatus(false);
				setAllNdDataVal([]);
				SetFeedbackData([]);
			})
			.catch(function (error) {
				if (error.response) {
					toast.error("Something went to Wrong.");
					console.log(" error.response :", error.response);
				}
			});
	};

	const saveBusinessScenarioEditName = () => {
		treeItem.name = editableName;
		setOpenEditableName(false);
	};

	return (
		<>
			<SmartToaster
				store={toast}
				lightBackground={true}
				position={"top_right"}
			/>
			{createStatus ? (
				<Box>
					<Box style={{ padding: "10px" }}>
						<Typography gutterBottom variant="h5" component="div">
							{businessScenariosData ? businessScenariosData : ""}
							<Edit
								onClick={() => setOpen(true)}
								style={{ cursor: "pointer" }}
							/>
						</Typography>
						<Divider style={{ margin: "8px 0" }} />
					</Box>
				</Box>
			) : (
				!skeleton && (
					<Box>
						<Box style={{ padding: "10px" }}>
							<Typography gutterBottom variant="h5" component="div">
								{treeItem.name}
								<Edit
									onClick={() => {
										setOpenEditableName(true);
										setEditableName(treeItem.name);
									}}
									style={{ cursor: "pointer" }}
								/>
								<br />{" "}
								<div style={{ color: "red", fontSize: "12px" }}>
									<WarningAmberIcon style={{ marginBottom: "-6px" }} />{" "}
									<p style={{ marginTop: "-4px" }}>
										Please note that currently you will not able to save
										changes. To save changes, you must first login!
									</p>
								</div>
							</Typography>
							{treeItem.description != null && (
								<Typography variant="body2" color="text.secondary">
									{treeItem.description}
								</Typography>
							)}
							<Divider style={{ margin: "8px 0" }} />
						</Box>
					</Box>
				)
			)}

			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList onChange={handleChange} aria-label="lab API tabs example">
						<Tab label="Diagram" value="1" />
						{/* <Tab label="Sample 2" value="2" /> */}
						<Button onClick={() => resetChart()}>Reset</Button>
						<Button
							onClick={() =>
								dispatch({ type: "REFRESH_ALL_NODES", payload: true })
							}>
							Refresh
						</Button>
						{
							localStorage.getItem("jwtToken") ? (
								<Button
									onClick={() => {
										createStatus ? saveNewChart() : saveChart();
									}}>
									Save
								</Button>
							) : null
							// <Button disabled onClick={() => toast.info('Please Log in if you want to save your changes.')}>Edit</Button>
						}
					</TabList>
				</Box>

				<TabPanel value="1">
					{" "}
					<Card variant="outlined">
						{createStatus ? (
							<DiagramCreate
								allNodes={allNdDataVal}
								allConnectionData={treeItem?.serviceConnections}
								links={edges}
								allDetails={treeItem}
								serviceUid={props?.serviceUid.split("-")}
							/>
						) : (
							<Diagram
								key={updateKey}
								allNodes={allNdDataVal}
								allConnectionData={treeItem?.serviceConnections}
								links={edges}
								allDetails={treeItem}
								serviceUid={props?.serviceUid.split("-")}
							/>
						)}
					</Card>
				</TabPanel>
				<TabPanel value="2">{/* <ERD schema={schema} /> */}</TabPanel>
			</TabContext>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle style={{ minWidth: "400px" }}>
					Business Scenario
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Business Scenario"
						type="text"
						fullWidth
						variant="standard"
						value={businessScenariosVal}
						onChange={(e) => setBusinessScenarioText(e.target.value)}
						required
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						onClick={() => {
							trackCustomCreateEvent({ name: businessScenariosVal });
							// trackCustomCreateMatric({ name: businessScenariosVal });
							saveBusinessScenario();
						}}>
						Save
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={openEditableName} onClose={handleCloseEditable}>
				<DialogTitle style={{ minWidth: "400px" }}>
					Business Scenario
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Business Scenario"
						type="text"
						fullWidth
						variant="standard"
						value={editableName}
						onChange={(e) => setEditableName(e.target.value)}
						required
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseEditable}>Cancel</Button>
					<Button onClick={() => saveBusinessScenarioEditName()}>Save</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default Domain;
