import React from "react";

export default [
  {
    id: "1",
    type: "input",
    data: {
      label: (
        <>
          Welcome to <strong>React Flow!</strong>
        </>
      )
    },
    position: { x: 250, y: 0 },
    sourcePosition: 'right',
  },
  {
    id: "2",
    data: {
      label: (
        <>
          This is a <strong>default node</strong>
        </>
      )
    },
    position: { x: 100, y: 100 },
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  {
    id: "3",
    data: {
      label: (
        <>
          This one has a <strong>custom style</strong>
        </>
      )
    },
    position: { x: 400, y: 100 },
    style: {
      background: "#D6D5E6",
      color: "#333",
      border: "1px solid #222138",
      width: 180,
    },
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  {
    id: "4",
    position: { x: 250, y: 200 },
    data: {
      label: "Another default node"
    },
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  {
    id: "5",
    data: {
      label: "Node id: 5"
    },
    position: { x: 250, y: 325 },
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  {
    id: "6",
    type: "output",
    data: {
      label: (
        <>
          An <strong>output node</strong>
        </>
      )
    },
    position: { x: 100, y: 480 },
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  {
    id: "7",
    type: "output",
    data: { label: "Another output node" },
    position: { x: 400, y: 450 },
    sourcePosition: 'right',
    targetPosition: 'left',
  },
  // {
  //   id: "e1-2",
  //   source: "1",
  //   target: "2",
  //   arrowHeadType: 'arrowclosed',
  //   style: {
  //     strokeDasharray: "4 "
  //   },
  // },
  // { id: "e1-3", source: "1", target: "3" },
  // {
  //   id: "e3-4",
  //   source: "3",
  //   target: "4",
  //   animated: true,
  //   label: "animated edge",
  // },
  // {
  //   id: "e4-5",
  //   source: "4",
  //   target: "5",
  //   arrowHeadType: "arrowclosed",
  //   label: "edge with arrow head"
  // },
  // {
  //   id: "e5-6",
  //   source: "5",
  //   target: "6",
  //   type: "smoothstep",
  //   label: "smooth step edge"
  // },
  // {
  //   id: "e5-7",
  //   source: "5",
  //   target: "7",
  //   type: "step",
  //   style: { stroke: "#f6ab6c" },
  //   label: "a step edge",
  //   animated: true,
  //   labelStyle: { fill: "#f6ab6c", fontWeight: 700 }
  // }
];
