import React, { useEffect, useState } from 'react'
import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';
import OrganisationProfile from './OrganisationProfile';
import OrganisationProfileDetails from './OrganisationProfileDetails';
import ServicesAssigned from './ServicesAssigned';

function ViewOrganisations() {
     const [refreshUser, setRefreshUser] = useState(false);

     const handleOrganisationProfileDetails = () => {
          console.log("If request is good... callbackFunction handleOrganisationProfileDetails executed ");
          setRefreshUser(true)
     };

     const handleOrganisationProfile = () => {
          console.log("If request is good... callbackFunction handleOrganisationProfile executed");
          setRefreshUser(false)
     };

     return (
          <>
               <Box
                    component="main"
                    sx={{
                         flexGrow: 1,
                         py: 8
                    }}
                    style={{overflow: 'auto', height: '63vh'}}
               >
                    <Container maxWidth="lg">
                         <Card sx={{ p: 2 }}>
                              <Typography
                                   sx={{ mb: 3 }}
                                   variant="h4"
                              >
                                   Organisation Details
                                   <Divider />
                              </Typography>
                              <Grid
                                   container
                                   spacing={3}
                              >
                                   <Grid
                                        item
                                        lg={4}
                                        md={6}
                                        xs={12}
                                   >
                                        <OrganisationProfile details={refreshUser} callbackFunction={handleOrganisationProfile} />
                                   </Grid>
                                   <Grid
                                        item
                                        lg={8}
                                        md={6}
                                        xs={12}
                                   >
                                        <OrganisationProfileDetails callbackFunction={handleOrganisationProfileDetails} />
                                   </Grid>
                              </Grid>
                         </Card>
                    </Container>
               </Box>
          </>
     )
}

export default ViewOrganisations