import React, { useEffect, useState } from "react";
import { useNavigate as useHistory, useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
  CardContent,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LocalPrintshopSharp } from "@mui/icons-material";

const OrganisationProfileDetails = (props) => {
  const [organisation, setOrganisation] = useState({
    uid: "",
    name: "",
    displayName: "",
    description: "",
    emailAddress: "",
    webSite: "",
    phoneNumber: "",
    status: "",
  });

  const [userDetails, setUserDetails] = useState(null);


    useEffect(() => {
        setUserDetails(JSON.parse(localStorage.getItem("tokenDetails")));
    }, [userDetails])

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let history = useHistory();
  let { orgId } = useParams();

  const handleInputChange = (event) => {
    setOrganisation({
      ...organisation,
      [event.target.name]: event.target.value,
    });
  };

  const loadOrganisation = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "OrganisationByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "OrganisationByUid/" + uid);

    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log("If request is good... ", response.data);
        setError(false);
        setOrganisation(setOrganisationData(response.data));
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };
  useEffect(() => {
    loadOrganisation(orgId);
  }, []);

  const getOrganisationData = async () => {
    var orgData = {
      uid: orgId,
      name: organisation.name,
      displayName: organisation.displayName,
      description: organisation.description,
      emailAddress: organisation.emailAddress,
      webSite: organisation.webSite,
      phoneNumber: organisation.phoneNumber,
      status: organisation.status,
    };
    return orgData;
  };

  const setOrganisationData = (organisationDetail) => {
    return {
      uid:
        organisationDetail.uid !== "string" &&
        organisationDetail.uid !== null &&
        organisationDetail.uid !== undefined
          ? organisationDetail.uid
          : "",
      name:
        organisationDetail.name !== "string" &&
        organisationDetail.name !== null &&
        organisationDetail.name !== undefined
          ? organisationDetail.name
          : "",
      displayName:
        organisationDetail.displayName !== "string" &&
        organisationDetail.displayName !== null &&
        organisationDetail.displayName !== undefined
          ? organisationDetail.displayName
          : "",
      description:
        organisationDetail.description !== "string" &&
        organisationDetail.description !== null &&
        organisationDetail.description !== undefined
          ? organisationDetail.description
          : "",
      emailAddress:
        organisationDetail.emailAddress !== "string" &&
        organisationDetail.emailAddress !== null &&
        organisationDetail.emailAddress !== undefined
          ? organisationDetail.emailAddress
          : "",
      webSite:
        organisationDetail.webSite !== "string" &&
        organisationDetail.webSite !== null &&
        organisationDetail.webSite !== undefined
          ? organisationDetail.webSite
          : "",
      phoneNumber:
        organisationDetail.phoneNumber !== "string" &&
        organisationDetail.phoneNumber !== null &&
        organisationDetail.phoneNumber !== undefined
          ? organisationDetail.phoneNumber
          : "",
      status:
        organisationDetail.status !== "string" &&
        organisationDetail.status !== null &&
        organisationDetail.status !== undefined
          ? organisationDetail.status
          : "",
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    const organisationData = await getOrganisationData();
    console.log("organisationData is ", organisationData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "OrganisationUpdate/");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "OrganisationUpdate/");

    await axios
      .put(axiosUrl, organisationData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(
          "If request is good... handleSubmit is executed",
          response.data
        );
        setError(false);
        props.callbackFunction();
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(" error.response :", error.response);
          console.log(" error.response.data :", error.response.data);
          console.log(" error.response.status :", error.response.status);
          console.log(" error.response.headers :", error.response.headers);
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.statusText +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add User " + error.message);
        }
      });
  };

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="name"
                label="Organisation Name"
                name="name"
                autoFocus
                value={organisation.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="emailAddress"
                label="Email Address"
                name="emailAddress"
                autoComplete="emailAddress"
                value={organisation.emailAddress}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="displayName"
                label="Organisation Display Name"
                name="displayName"
                autoFocus
                value={organisation.displayName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                name="phoneNumber"
                label="Phone Number"
                type="text"
                id="phoneNumber"
                value={organisation.phoneNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="description"
                label="Description"
                name="description"
                value={organisation.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="webSite"
                label="Web Site"
                name="webSite"
                autoComplete="webSite"
                value={organisation.webSite}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      organisation.status === "on" ||
                      organisation.status === "true"
                        ? true
                        : false
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={handleInputChange}
                    name="status"
                  />
                }
                label="Status"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            type="submit"
            disabled= {userDetails == null ? false : userDetails.OrganisationName != "BIAN"}
          >
            Save details
          </Button>
        </Box>
      </Card>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )}
    </form>
  );
};

export default OrganisationProfileDetails;
