import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors";
const arrayOfColor = [
  { colorName: "red", colorValue: red },
  { colorName: "pink", colorValue: pink },
  { colorName: "purple", colorValue: purple },
  { colorName: "deepPurple", colorValue: deepPurple },
  { colorName: "indigo", colorValue: indigo },
  { colorName: "blue", colorValue: blue },
  { colorName: "lightBlue", colorValue: lightBlue },
  { colorName: "cyan", colorValue: cyan },
  { colorName: "teal", colorValue: teal },
  { colorName: "green", colorValue: green },
  { colorName: "lightGreen", colorValue: lightGreen },
  { colorName: "lime", colorValue: lime },
  { colorName: "yellow", colorValue: yellow },
  { colorName: "amber", colorValue: amber },
  { colorName: "orange", colorValue: orange },
  { colorName: "deepOrange", colorValue: deepOrange },
  { colorName: "brown", colorValue: brown },
  { colorName: "grey", colorValue: grey },
  { colorName: "blueGrey", colorValue: blueGrey },
];

const arrayOfShades = [
  "500",
  "50",
  "100",
  "200",
  "300",
  "400",
  "500",
  "600",
  "700",
  "800",
  "900",
  "A100",
  "A200",
  "A400",
  "A700",
];
export const randomMUIColorGenerator = (shades = "") => {
  if (shades !== "") {
    return arrayOfColor[Math.floor(Math.random() * arrayOfColor.length)]
      .colorValue[shades];
  }
  return arrayOfColor[Math.floor(Math.random() * arrayOfColor.length)]
    .colorValue[
    arrayOfShades[Math.floor(Math.random() * arrayOfShades.length)]
  ];
};

export const randomMUIColorNameGenerator = () => {
  console.log("vahjj cde che", arrayOfColor[6].colorName, arrayOfColor);
  return arrayOfColor[Math.floor(Math.random() * arrayOfColor.length)]
    .colorName;
};

export const randomNumberGenerator = len => {
  var r = "";
  [...Array(len)].map((i) => (r += String(Math.floor(Math.random() * 9))));
  return r;
};
