import React from "react";
import { Box } from "@mui/material";

const Qusetion = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				p: "1.5rem",
				// padding: "24px",
				// width: "100%",
				// maxHeight: `calc(100vh - 100px)`,
				// overflowY: "auto",
				// scrollBehavior: "smooth",
			}}>
			This is a Qusetion page
		</Box>
	);
};

export default Qusetion;
