import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import React from 'react'
import bannerLogo from '../../assets/images/logo.png'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';

function AuthenticationBar() {
     return (
          <Box sx={{ flexGrow: 1 }} className="search-container">
               <AppBar position="static" className='searchAppBar'>
                    <Toolbar>
                         <Typography
                              variant="h4"
                              noWrap
                              component="div"
                              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                         >
                              <Link to="/" value="Home" >
                                   <img src={bannerLogo} alt="Banner Logo" height="35px" />
                              </Link>
                         </Typography>
                         <Box>
                              <Link to="/login" value="Login" >
                                   <Button
                                        variant='contained'
                                        color='primary'
                                        style={{ margin: '0 10px' }}
                                   >
                                        Login
                                   </Button>
                              </Link>
                              <Link to="/register" value="Register" >
                                   <Button
                                        variant='outlined'
                                        color='primary'
                                        style={{ margin: '0 10px' }}
                                   >
                                        Register
                                   </Button>
                              </Link>
                         </Box>
                    </Toolbar>
               </AppBar>
          </Box>
     )
}

export default AuthenticationBar