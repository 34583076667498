import React, { useState, useEffect } from "react";
import {
	Container,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Divider,
	Skeleton,
	Stack,
	Box,
	Typography,
	Button,
	Chip,
	IconButton,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import Searcharea from "../../utils/Searcharea";
import { reactPlugin } from "../../../AppInsights";

import {
	useAppInsightsContext,
	useTrackMetric,
	useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "calc(100vh - 150px)",
		overflow: "hidden",
		overflowY: "scroll",
	},
	item: {
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		padding: "15px 0px",
	},
	icons: {},
	path: {
		paddingLeft: "10px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	formControl: { width: "100%", height: "" },
}));

function Servicelist(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const domainStatus = ["Extended Definition", "All"];

	const [searchText, setSearchText] = useState();
	const [defaultKey, setdefaultKey] = useState(0);

	const [activeChip, setActiveChip] = useState("All");
	const [applyFilter, setApplyFilter] = useState(false);
	const [serviceItemList, setServiceItemList] = useState(false);
	const [allItemList, setAllItemList] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const trackCustomEvents = useTrackEvent(
		reactPlugin,
		"Selected Bussiness Scenario Service"
	);
	const trackCustomMatric = useTrackMetric(
		reactPlugin,
		"Select Bussiness Scenario Service Matric Data"
	);

	const businessScenarioDataVal = useSelector(
		(state) => state?.common?.defaultServiceDomainText
	);
	const businessScenarioDefaultSelected = useSelector(
		(state) => state?.common?.businessScenarioDefaultSelected
	);
	const feedbackSelected = useSelector(
		(state) => state?.common?.setFeedbackServiceDomain
	);
	var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
	var showSkeleton = false;
	var radioValue = "";

	const handleChipChange = (passed) => {
		// passed === "" && setApplyFilter(false);
		if (passed === "All") {
			setServiceItemList(allItemList);
		} else {
			const extedDef = allItemList.filter(
				({ extendedDefinition }) => extendedDefinition === true
			);
			setServiceItemList(extedDef);
		}
		setActiveChip(passed);

		// props.handleChipChange(passed);
	};

	// useEffect(() => {
	//   if (props?.serviceItems) {
	//     setServiceItemList(props.serviceItems)
	//   }
	// }, [props?.serviceItems])

	// rerender whwn dependency var changes
	// useEffect(() => {

	// 	console.log("skeleton is", props.skeleton)
	// 	console.log("serviceItems is", props.serviceItems)

	// }, [props.serviceItems, props.skeleton])

	// Show Skeleton make var true when data is not there or api is fetching data
	(props.skeleton === undefined || props.skeleton === true) &&
		(showSkeleton = true);

	// show skelenton n number of times
	const radioSkeletons = [];
	for (let i = 1; i <= 20; i++) {
		radioSkeletons.push(i);
	}

	const handlesearchText = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
		// feedbackSelected && feedbackSelected.name = e.target.value

		dispatch({ type: "SERVICE_DOMAIN_TEXT", payload: e.target.value });
	};

	useEffect(() => {
		if (businessScenarioDefaultSelected || feedbackSelected) {
			props.handleRadioChange(
				businessScenarioDefaultSelected || feedbackSelected.uid
			);
			radioValue = feedbackSelected.uid;
			setdefaultKey(defaultKey + 1);
		}
	}, [businessScenarioDefaultSelected]);

	useEffect(() => {
		if (props?.serviceItems) {
			setServiceItemList(props.serviceItems);
			setAllItemList(props.serviceItems);
		}
	}, [props?.serviceItems]);

	useEffect(() => {
		if (isLoading) {
			setTimeout(setIsLoading(false), 5000);
			setdefaultKey(defaultKey + 1);
		}
	}, [isLoading]);

	// useEffect(() => {
	//   if (feedbackSelected) {
	//     props.handleRadioChange(feedbackSelected.uid);
	//     radioValue = feedbackSelected.uid;
	//     setdefaultKey(defaultKey + 1)
	//   }
	// }, [feedbackSelected])

	return (
		<Container className={combinedClasses}>
			<FormControl className={classes.formControl}>
				<div className="searchcontainer">
					<Searcharea
						searchText={handlesearchText}
						text={
							searchText || searchText == ""
								? searchText
								: feedbackSelected?.name
								? feedbackSelected?.name
								: businessScenarioDataVal
								? businessScenarioDataVal
								: ""
						}
					/>
				</div>
				{applyFilter ? (
					<Box
						style={{
							padding: "10px",
						}}>
						<Box
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<Typography varinat="small">Apply filter: </Typography>
							<Button
								variant="text"
								size="small"
								onClick={() => setApplyFilter(false)}>
								Hide
							</Button>
						</Box>
						<Divider style={{ margin: "6px 0" }} />
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "start",
								flexDirection: "column",
							}}>
							{domainStatus.map((cur) => {
								return (
									<Chip
										size="small"
										style={{ margin: "10px 5px" }}
										variant={activeChip == cur ? "filled" : "outlined"}
										color="primary"
										label={cur}
										key={cur}
										onClick={() => handleChipChange(cur)}
									/>
								);
							})}
							{activeChip !== "" && (
								<Chip
									size="small"
									style={{ margin: "10px 5px" }}
									variant="filled"
									color="primary"
									label="Clear"
									onClick={() => handleChipChange("")}
									onDelete={() => handleChipChange("")}
								/>
							)}
						</Box>
					</Box>
				) : (
					<>
						<Box style={{ display: "inline-flex" }}>
							<Divider style={{ margin: "15px 0" }} />

							<FormLabel
								id="demo-service-radio-buttons-group-label"
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									flexDirection: "row",
								}}>
								<Typography> Service Domain</Typography>
							</FormLabel>
							<IconButton
								aria-label="filter"
								size="small"
								color={activeChip !== "" ? "danger" : "primary"}
								onClick={() => setApplyFilter(true)}
								style={{ marginLeft: "88px" }}>
								<FilterListIcon size="small" />
							</IconButton>
						</Box>
						<RadioGroup
							aria-labelledby="demo-service-radio-buttons-group-label"
							key={defaultKey}
							defaultValue={
								feedbackSelected.uid
									? feedbackSelected.uid
									: businessScenarioDefaultSelected
							}
							name="service-radio-buttons-group"
							className={`${isLoading ? "show-loading" : "show-data"}`}
							onChange={(event, value) => {
								const selectedItem = serviceItemList.filter(
									(item) => item.uid === value
								);
								console.log("selected is", selectedItem, value);
								trackCustomEvents({ name: selectedItem[0]?.name });
								trackCustomMatric({ name: selectedItem[0]?.name, average: 1 });
								setIsLoading(true);
								setdefaultKey(defaultKey + 1);
								props.handleRadioChange(value);
								radioValue = value;
							}}>
							{!isLoading &&
								serviceItemList?.length > 0 &&
								!showSkeleton &&
								serviceItemList
									.filter((filterVal) => {
										if (searchText !== "" && feedbackSelected.name) {
											return filterVal.name
												?.toLowerCase()
												.includes(
													searchText?.toLowerCase() ||
														feedbackSelected.name.toLowerCase()
												);
										} else {
											if (
												businessScenarioDataVal === undefined ||
												businessScenarioDataVal?.length === 0
											) {
												return filterVal;
											} else if (
												filterVal.name
													.toLowerCase()
													.includes(
														searchText?.toLowerCase() ||
															businessScenarioDataVal.toLowerCase()
													)
											) {
												return filterVal;
											}
										}
									})
									.map((loop, i) => {
										return (
											<div key={i}>
												<FormControlLabel
													value={loop.uid}
													key={loop.uid}
													control={<Radio />}
													label={
														loop.meta.organisationInstance ? (
															<Box>
																<Box
																	variant="h5"
																	component="h5"
																	style={{
																		fontSize: "15px",
																		fontWeight: "500",
																		color: "#343434",
																	}}>
																	{loop.name}
																</Box>
																<Box
																	variant="small"
																	component="small"
																	style={{
																		fontSize: "14px",
																		fontWeight: "500",
																		color: "#5787a1",
																	}}>
																	{loop.meta.organisationInstance}
																</Box>
															</Box>
														) : (
															loop.name
														)
													}
												/>
											</div>
										);
									})}
							{!isLoading &&
								(serviceItemList?.length === 0 ||
									serviceItemList?.length === undefined ||
									serviceItemList?.length === null ||
									showSkeleton) &&
								radioSkeletons.map((loopSkeleton, index) => (
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											paddingTop: "10px",
										}}
										key={"skeletonKey" + index}>
										<Skeleton
											variant="circular"
											width={20}
											height={20}
											sx={{ marginRight: "7px" }}
										/>
										<Skeleton
											sx={{ flexGrow: "1" }}
											height={20}
											variant="rectangular"
										/>
									</Box>
								))}

							{isLoading &&
								radioSkeletons.map((loopSkeleton, index) => (
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											paddingTop: "10px",
										}}
										key={"skeletonKey" + index}>
										<Skeleton
											variant="circular"
											width={20}
											height={20}
											sx={{ marginRight: "7px" }}
										/>
										<Skeleton
											sx={{ flexGrow: "1" }}
											height={20}
											variant="rectangular"
										/>
									</Box>
								))}
						</RadioGroup>
					</>
				)}
			</FormControl>
		</Container>
	);
}

export default Servicelist;
