import { Button, ButtonGroup, styled } from "@mui/material";
import React from "react";

const ButtonItem = styled(Button)(({ theme }) => ({
	textTransform: "capitalize",
}));

const QnAButtonGroup = () => {
	return (
		<ButtonGroup variant="outlined" aria-label="outlined button group">
			<ButtonItem>Interesting</ButtonItem>
			<ButtonItem>Hot</ButtonItem>
			<ButtonItem>Week</ButtonItem>
			<ButtonItem>Month</ButtonItem>
		</ButtonGroup>
	);
};

export default QnAButtonGroup;
