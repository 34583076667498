import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        <Button>BIAN</Button>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function CreatePassword() {
  const [tokenStatus, setTokenStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pass, setPass] = useState("");
  const [conPass, setConPass] = useState("");
  const [conPassAlert, setConPassAlert] = useState({
    status: false,
    severity: "",
    title: "",
    msg: "",
    buttonSubmitStatus: false,
  });
  const [passEye, setPassEye] = useState({
    pass: "password",
    passView: false,
    passCon: "password",
    passConView: false,
  });
  const [adminJwtToken, setAdminJwtToken] = useState("");
  const [msgAlert, setMsgAlert] = useState({
    status: false,
    severity: "",
    title: "",
    msg: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [organisation, setOrganisation] = useState([]);
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const classes = useStyles();

  var defaultUser = {
    username: process.env.REACT_APP_DEFAULT_USERNAME,
    password: process.env.REACT_APP_DEFAULT_PASSWORD,
  };

  const getAuthenticate = async (loginUser) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

    await axios
      .post(axiosUrl, loginUser)
      .then((res) => {
        if (res.status === 200) {
          setAdminJwtToken(res.data.jwtToken);
          loadUser(res.data.jwtToken);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
      });
  };

  const setUserData = (userDetail) => {
    return {
      uid:
        userDetail.uid !== "string" &&
        userDetail.uid !== null &&
        userDetail.uid !== undefined
          ? userDetail.uid
          : "",
      userName:
        userDetail.userName !== "string" &&
        userDetail.userName !== null &&
        userDetail.userName !== undefined
          ? userDetail.userName
          : "",
      firstName:
        userDetail.firstName !== "string" &&
        userDetail.firstName !== null &&
        userDetail.firstName !== undefined
          ? userDetail.firstName
          : "",
      lastName:
        userDetail.lastName !== "string" &&
        userDetail.lastName !== null &&
        userDetail.lastName !== undefined
          ? userDetail.lastName
          : "",
      email:
        userDetail.emailAddress !== "string" &&
        userDetail.emailAddress !== null &&
        userDetail.emailAddress !== undefined
          ? userDetail.emailAddress
          : "",
      phoneNumber:
        userDetail.phoneNumber !== "string" &&
        userDetail.phoneNumber !== null &&
        userDetail.phoneNumber !== undefined
          ? userDetail.phoneNumber
          : "",
      token:
        userDetail.passwordResetToken !== "string" &&
        userDetail.passwordResetToken !== null &&
        userDetail.passwordResetToken !== undefined
          ? userDetail.passwordResetToken
          : "",
      password:
        userDetail.password !== "string" &&
        userDetail.password !== null &&
        userDetail.password !== undefined
          ? userDetail.password
          : "",
      organisation:
        userDetail.organisation !== "string" &&
        userDetail.organisation?.uid !== null &&
        userDetail.organisation?.uid !== undefined
          ? userDetail.organisation.uid
          : "",
      role: [
        userDetail.roles[0]?.uid !== "string" &&
        userDetail.roles[0]?.uid !== null &&
        userDetail.roles[0]?.uid !== undefined
          ? userDetail.roles[0].uid
          : "",
      ],
    };
  };

  const loadUser = async (authenticateToken) => {
    const query = new URLSearchParams(window.location.search);
    var userUID = query.get("userUID");
    var resetToken = query.get("resetToken");
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserByUid/" + userUID);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserByUid/" + userUID);

    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticateToken,
        },
      })
      .then((response) => {
        setIsLoading(false);

        if (
          response.data?.passwordResetToken?.isExpired === false &&
          response.data.passwordResetToken?.token == resetToken
        ) {
          setTokenStatus(true);
        } else {
          setTokenStatus(false);
        }
        // If request is good...
        console.log("If request is good... ", response.data);
        setError(false);
        setUser(response.data);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const changePasswordApiCall = (e) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserUpdate/");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserUpdate/");
    axios({
      method: "PUT",
      url: axiosUrl,
      data: user,
      headers: { Authorization: "Bearer " + adminJwtToken },
    })
      .then((res) => {
        if (res.status === 200) {
          setMsgAlert({
            status: true,
            severity: "success",
            title: "Request is sent",
            msg: "Changing Password Request is sent to admin, you will be notified soon",
          });
          navigate("/dashboard");
        } else {
          setMsgAlert({
            status: true,
            severity: "error",
            title: "Request sent Failed",
            msg: "Unable to Sent Request For Changing Password",
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
        setMsgAlert({
          status: true,
          severity: "error",
          title: "Request sent Failed",
          msg: "Unable to Sent Request For Changing Password",
        });
      });

    setTimeout(() => {
      setMsgAlert({
        status: false,
        severity: "",
        title: "",
        msg: "",
      });
    }, 5000);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (user.hasOwnProperty("passwordResetToken")) {
      if (user.passwordResetToken.hasOwnProperty("isExpired")) {
        var hce = new Date(Date.now());
        user.passwordResetToken.expires = hce.toJSON();
        setTimeout(changePasswordApiCall(e), 100);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("crawler", false);
    getAuthenticate(defaultUser);
  }, []);

  function handleConPassChange(e) {
    if (pass === e.target.value) {
      setConPass(e.target.value);
      setConPassAlert({
        status: true,
        severity: "success",
        title: "Great!",
        msg: "Passwords match.",
        buttonSubmitStatus: true,
      });
      setUser({
        ...user,
        password: pass,
      });
      setTimeout(() => {
        setConPassAlert({
          status: false,
          severity: "",
          title: "",
          msg: "",
          buttonSubmitStatus: true,
        });
      }, 4000);
    } else {
      setConPass(e.target.value);
      setConPassAlert({
        status: true,
        severity: "error",
        title: "Error",
        msg: "Passwords do not match.",
        buttonSubmitStatus: false,
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {tokenStatus ? (
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleForgotPassword}
          >
            <FormControl variant="outlined" sx={{ m: 1, width: "100%" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="pass"
                label="Password"
                name="pass"
                autoComplete="pass"
                autoFocus
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                type={passEye.pass}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setPassEye({
                          ...passEye,
                          passView: !passEye.passView,
                          pass:
                            passEye.pass === "password" ? "text" : "password",
                        })
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {passEye.passView ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl variant="outlined" sx={{ m: 1, width: "100%" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="conPass"
                label="Confirm Password"
                name="conPass"
                autoComplete="conPass"
                autoFocus
                value={conPass}
                onChange={handleConPassChange}
                type={passEye.passCon}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setPassEye({
                          ...passEye,
                          passConView: !passEye.passConView,
                          passCon:
                            passEye.passCon === "password"
                              ? "text"
                              : "password",
                        })
                      }
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {passEye.passConView ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {conPassAlert.status && (
              <Alert severity={conPassAlert.severity} style={{ width: "100%" }}>
                <AlertTitle>{conPassAlert.title}</AlertTitle>
                {conPassAlert.msg}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!conPassAlert.buttonSubmitStatus}
            >
              Change
            </Button>
          </form>
          {msgAlert.status && (
            <Alert severity={msgAlert.severity} style={{ width: "100%" }}>
              <AlertTitle>{msgAlert.title}</AlertTitle>
              {msgAlert.msg}
            </Alert>
          )}
        </div>
      ) : isLoading ? (
        <div className={classes.paper}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div className={classes.paper}>
          <Alert
            severity="error"
            style={{ width: "100%" }}
            action={
              <Button color="info" variant="contained" href="/forgot/password">
                Reset
              </Button>
            }
          >
            <AlertTitle>Invalid Token</AlertTitle>
            Either Token is invalid or got expired
            <strong> - Please try to reset password again</strong>
          </Alert>
        </div>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
