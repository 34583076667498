import React, { useState, useEffect } from "react";

import Demo from "./demo";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

const ContainerDiv = styled(Container)`
  font-family: sans-serif;
  text-align: center;
`;

const DemoArea = styled(Col)`
  width: 100%;
  height: 85vh;
`;

export default function Diagram(props) {
    const [nodeVal, setnodeVal] = useState([]);
    const [linkVal, setlinkVal] = useState([]);
    useEffect(() => {
        setnodeVal(props.allNodes)
    }, [props.allNodes])

    useEffect(() => {
        setlinkVal(props.links)
    }, [props.links])

    

  return (
    <ContainerDiv fluid>
      <Row>
        <DemoArea>
          <Demo allNodes={nodeVal} allDetails={props.allDetails} allConnectionData={props.allConnectionData} addTree={props.addTree} serviceUid={props.serviceUid} links={linkVal} setStepLine={props.addStepLine} removeStepLine={props.removeStepLine} />
        </DemoArea>
      </Row>
    </ContainerDiv>
  );
}
