import { Grid } from '@mui/material'
import React from 'react'
import ShowGroupCardItem from './ShowGroupCardItem'

function ShowGroupCard(props) {
    return (
        <>
            <Grid container style={{ margin: "20px 10px", width: "auto" }}>
                {props.itemList.map(mapData => (
                    <Grid item xs={props.xs} md={props.md} lg={props.lg} style={{ padding: "10px" }} key={props.avatarText}>
                        <ShowGroupCardItem
                            cardHeader={{
                                bgcolor: mapData?.bgcolor ? mapData?.bgcolor : '',
                                avatarText: mapData?.avatarText ? mapData?.avatarText : '',
                                title: mapData?.title ? mapData?.title : '',
                                subheader: mapData?.subheader ? mapData?.subheader : ''
                            }}
                            cardAction={{
                                to: mapData?.to ? mapData?.to : '',
                                toText: mapData?.toText ? mapData?.toText : '',
                                visual: mapData?.showCardAction ? mapData?.showCardAction : '',
                                cardType: mapData?.cardType ? mapData?.cardType : '',
                                cardTypeAction: mapData?.cardTypeAction ? mapData?.cardTypeAction : '',
                            }}
                            cardBody={{
                                visual: mapData?.showCardBody ? mapData?.showCardBody : false,
                                primary: mapData?.primary ? mapData?.primary : '',
                                description: mapData?.description ? mapData?.description : '',
                            }}
                            cardMain={{
                                hoverColor: mapData?.bgColorName ? mapData?.bgColorName : ''
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default ShowGroupCard