import React from 'react'
import { Avatar, Card, CardActions, CardHeader, Divider, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from '@mui/system';

function ShowGroupCardItem(props) {
    if (props.cardAction.cardType === 'link') {
        return <>
            <Link to={props.cardAction.to}>
                <Card className={"customCardShadow customCardHover " + props.cardMain.hoverColor}>
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: props.cardHeader.bgcolor }} aria-label={props.cardHeader.title}>{props.cardHeader.avatarText}</Avatar>
                        }
                        title={props.cardHeader.title}
                        subheader={props.cardHeader.subheader}
                    />
                    {props.cardBody.visual === true && <>
                        <Divider />
                        <Box sx={{ p: 2 }}>
                            <Typography style={{ fontSize: "13px" }} color="text.secondary" gutterBottom>{props.cardBody.description}</Typography>
                        </Box>
                    </>
                    }
                    {props.cardAction.visual === true && <>
                        <Divider />
                        <CardActions disableSpacing>
                            {/* <Link to={props.cardAction.to} style={{ display: "flex", color: "grey", alignItems: 'center' }}> */}
                            <Box sx={{ display: "flex", color: "grey !important", alignItems: 'center' }}>
                                <Typography style={{ fontSize: "14px" }}>{props.cardAction.toText}</Typography>
                                <ArrowForwardIcon style={{ fontSize: "16px", marginLeft: "10px" }} />
                            </Box>
                            {/* </Link> */}
                        </CardActions>
                    </>}
                </Card>
            </Link>
        </>
    }

    else if (props.cardAction.cardType === 'card') {
        return <>
            <Card className={"customCardShadow customCardHover " + props.cardMain.hoverColor}>
                <CardHeader
                    title={props.cardHeader.title}
                    subheader={props.cardHeader.subheader}
                />
                <Divider />
                {props.cardBody.visual === true && <>
                    <Divider />
                    <Box sx={{ p: 2 }}>
                        <Typography style={{ fontSize: "13px" }} color="text.secondary" gutterBottom>{props.cardBody.description}</Typography>
                    </Box>
                </>}
            </Card>
        </>
    }
    else if (props.cardAction.cardType === 'redux-func-call') {
        return <>
            <Card className={"customCardShadow customCardHover " + props.cardMain.hoverColor} onClick={() => props.cardAction.cardTypeAction(props.cardAction.to)} sx={{ cursor: "pointer" }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: props.cardHeader.bgcolor }} aria-label={props.cardHeader.title}>{props.cardHeader.avatarText}</Avatar>
                    }
                    title={props.cardHeader.title}
                    subheader={props.cardHeader.subheader}
                />
                <Divider />
                {props.cardBody.visual === true && <Box sx={{ p: 2 }}>
                    <Typography style={{ fontSize: "13px" }} color="text.secondary" gutterBottom>{props.cardBody.description}</Typography>
                </Box>}

                {props.cardBody.visual === true && props.cardAction.visual === true && <Divider />}

                {props.cardAction.visual === true && <CardActions disableSpacing>
                    {/* <Link to={props.cardAction.to} style={{ display: "flex", color: "grey", alignItems: 'center' }}> */}
                    <Box sx={{ display: "flex", color: "grey !important", alignItems: 'center' }}>
                        <Typography style={{ fontSize: "14px" }}>{props.cardAction.toText}</Typography>
                        <ArrowForwardIcon style={{ fontSize: "16px", marginLeft: "10px" }} />
                    </Box>
                    {/* </Link> */}
                </CardActions>}
            </Card>
        </>
    }
    else {
        return <>
            <Card className={"customCardShadow customCardHover " + props.cardMain.hoverColor} onClick={props.cardAction.cardTypeAction} sx={{ cursor: "pointer" }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: props.cardHeader.bgcolor }} aria-label={props.cardHeader.title}>{props.cardHeader.avatarText}</Avatar>
                    }
                    title={props.cardHeader.title}
                    subheader={props.cardHeader.subheader}
                />
                <Divider />
                {props.cardBody.visual === true && <Box sx={{ p: 2 }}>
                    <Typography style={{ fontSize: "13px" }} color="text.secondary" gutterBottom>{props.cardBody.description}</Typography>
                </Box>}

                {props.cardBody.visual === true && props.cardAction.visual === true && <Divider />}

                {props.cardAction.visual === true && <CardActions disableSpacing>
                    {/* <Link to={props.cardAction.to} style={{ display: "flex", color: "grey", alignItems: 'center' }}> */}
                    <Box sx={{ display: "flex", color: "grey !important", alignItems: 'center' }}>
                        <Typography style={{ fontSize: "14px" }}>{props.cardAction.toText}</Typography>
                        <ArrowForwardIcon style={{ fontSize: "16px", marginLeft: "10px" }} />
                    </Box>
                    {/* </Link> */}
                </CardActions>}
            </Card>
        </>
    }
}

export default ShowGroupCardItem