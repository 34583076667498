import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import AddIcon from "@mui/icons-material/Add";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
} from "@mui/material";
import axios from "axios";
import { useNavigate as useHistory } from "react-router-dom";
import { getAutoGeneratedPassword } from "../../utility/helper";
import {
  getUserPasswordToken,
  passwordResetMail,
} from "../../utility/api/authentication";
import { TrendingUpRounded } from "@mui/icons-material";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        <Button>BIAN</Button>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: "8px auto",
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Register() {
  const classes = useStyles();
  const [organisation, setOrganisation] = useState([]);
  const [user, setUser] = useState({
    userName: "",
    firstName: "",
    lastName: "",
    password: "",
    emailAddress: "",
    phoneNumber: "",
    organisation: "",
    role: "",
    origin: "ApprovalApp",
  });
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [alertEmail, setAlertEmail] = useState({
    status: false,
    severity: "",
    msg: "",
  });
  const [alertPhone, setAlertPhone] = useState({
    status: false,
    severity: "",
    msg: "",
  });
  let history = useHistory();

  var defaultUser = {
    username: process.env.REACT_APP_DEFAULT_USERNAME,
    password: process.env.REACT_APP_DEFAULT_PASSWORD,
  };

  const getAuthenticate = async (loginUser) => {
    localStorage.setItem("jwtToken", "");
    localStorage.setItem("firstName", "");

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

    await axios
      .post(axiosUrl, loginUser)
      .then((res) => {
        if (res.status === 200) {
          var response = res.data;
          localStorage.setItem("jwtToken", response.jwtToken);
          localStorage.setItem("firstName", response.firstName);
          getOrganization(response.jwtToken);
          getRoles(response.jwtToken);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
      });
  };

  useEffect(() => {
    getAuthenticate(defaultUser);
    localStorage.setItem("auth_token", "3215");
    localStorage.setItem("auth_token_status", "3216");
  }, []);

  const getOrganization = async (jwtToken) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Organisations");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Organisations");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setOrganisation(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoles = async (jwtToken) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Roles");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Roles");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setRoles(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  const handleInputChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleEmailChange = (event) => {
    let mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let Email = event.target.value.trimLeft();
    Email != ""
      ? Email.match(mailformat)
        ? setAlertEmail({
            severity: "success",
            status: true,
            msg: "Email format is correct",
          })
        : setAlertEmail({
            severity: "error",
            status: true,
            msg: "Enter Correct Mail!",
          })
      : setAlertEmail({
          severity: "error",
          status: true,
          msg: "Fill email first!",
        });
    setUser({
      ...user,
      emailAddress: event.target.value,
      username: event.target.value,
    });
  };

  const handleOrgChange = (event) => {
    setUser({ ...user, organisation: event.target.value });
  };

  const handleRoleChange = (event) => {
    setUser({ ...user, role: event.target.value });
  };

  const getOrganizationById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "OrganisationByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "OrganisationByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        return response.data;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  const getRoleById = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "RoleByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "RoleByUid/" + uid);

    return await axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        var array = [];
        array.push(response.data);
        console.log("array is", array);
        return array;
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  };

  async function passwordResetMethod(user_detail) {
    // Password reset method functionality and then send generated format to passwordResetMail
    const fetched_user_detail = await getUserPasswordToken(
      {
        username: user_detail.userName,
        password: user_detail.password,
      },
      localStorage.getItem("jwtToken")
    );

    if (fetched_user_detail.status === true) {
      const passwordResetResponse = await passwordResetMail(
        {
          fromDisplayName: "BIAN Approval App",
          to: fetched_user_detail.response.emailAddress,
          subject: "Password Reset Request",
          userUID: fetched_user_detail.response.uid,
          resetToken: fetched_user_detail.response.passwordResetToken.token,
          new: true,
          hostUrl: `${window.location.protocol}//${window.location.host}`,
        },
        localStorage.getItem("jwtToken")
      );
      console.log(`window.location.hostname ${window.location.host}`);

      return { ...passwordResetResponse, responseCode: "mail" };
    } else {
      return { ...fetched_user_detail, responseCode: "token" };
    }
  }

  const getUserData = async () => {
    var userData = {
      // userName: user.userName,
      userName: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      password: await getAutoGeneratedPassword("BIAN-DUMMY-@4-", 4),
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      organisation: await getOrganizationById(user.organisation),
      // roles: await getRoleById(user.role),
      roles: [
        {
          description: "Read and Write rights",
          displayName: "",
          name: "Contributor",
          uid: "a919d534-630a-4c86-9f83-878a88052b7c",
        },
      ],
      status: "New",
      origin: "ApprovalApp",
    };
    return userData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const userData = await getUserData();
    console.log("userData is ", userData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "User");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "User");

    await axios
      .post(axiosUrl, userData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(async (response) => {
        // If request is good...
        console.log("response.data {register}", response.data);
        if (response.data.includes("already")) {
          setLoading(false);

          setError(true);
          setErrorText(response.data);
        } else {
          setError(false);

          // Send reset password token
          const passwordResetMethodResponse = await passwordResetMethod(
            userData
          );
          if (passwordResetMethodResponse.status) {
            await axios
              .get(
                `${process.env.REACT_APP_SECURITY_S1}UsersByOrganisationId/${userData.organisation.uid}`,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                  },
                }
              )
              .then(async (orgUserResponse) => {
                // If request is good...
                console.log("If request is good... ", orgUserResponse.data);
                setError(false);
                // send email
                (orgUserResponse.data ?? []).forEach(async (oneUser) => {
                  console.log("oneUser ,", oneUser);
                  console.log(
                    `oneUser.organisation.uid ${oneUser.organisation.uid} ${userData.organisation.uid}`
                  );
                  console.log(`oneUser.roles[0].name ${oneUser.roles[0].name}`);
                  if (
                    oneUser.organisation.uid == userData.organisation.uid &&
                    (oneUser.roles[0].name == "ToolAdministrator" ||
                      oneUser.roles[0].name == "UserAdministrator")
                  ) {
                    console.log("sending mail");
                    await axios
                      .post(
                        process.env.REACT_APP_NODE_CONFLUENCE_API +
                          "api/send-email",
                        {
                          to: oneUser.emailAddress,
                          subject: "BIAN Portal - New user approval request",
                          text: `${window.location.origin}/manage/user/view/${response.data}`,
                          username: `${userData.firstName} ${userData.lastName}`,
                          userEmail: userData?.emailAddress ?? "",
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        }
                      )
                      .then((orgUserResponse) => {
                        setLoading(false);
                        // If request is good...
                        localStorage.setItem("org", oneUser.organisation);
                        localStorage.setItem(
                          "org_name",
                          oneUser.organisation.name
                        );
                        localStorage.setItem(
                          "org_admin_name",
                          `${oneUser.firstName} ${oneUser.lastName}`
                        );
                        localStorage.setItem(
                          "org_admin_email",
                          oneUser?.emailAddress
                        );
                        console.log(orgUserResponse.data);
                        setError(false);
                        history("/account/registered");
                      })
                      .catch(function (error) {
                        console.log("Error", error.message);
                        setLoading(false);
                        setError(true);
                        setErrorText("Unable to send mail " + error.message);
                      });
                  }
                });
              })
              .catch((error) => {
                console.log("error " + error);
                setError(true);
                setLoading(false);
                setErrorText(
                  "Unable to retrieve Organisations information From Server"
                );
              });
          } else {
            setLoading(false);
            setError(true);
            setErrorText("Unable to send reset password mail " + error.message);
          }

          // end of  Send reset password token

          //To get user details from organization to send approval email
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(" error.response :", error.response);
          console.log(" error.response.data :", error.response.data);
          console.log(" error.response.status :", error.response.status);
          console.log(" error.response.headers :", error.response.headers);
          setError(true);
          setLoading(false);
          setErrorText(
            error.response.status +
              " " +
              error.response.data +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setLoading(false);
          setError(true);
          setErrorText("Unable to Add User " + error.message);
        }
      });
  };

  return (
    <Container sx={{ pt: 4 }}>
      {organisation.length > 0 ? (
        <>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <form noValidate onSubmit={handleSubmit}>
            {/* <Box>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="username"
                label="User Name"
                name="userName"
                autoFocus
                value={user.userName}
                onChange={handleInputChange}
              />
            </Box> */}
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="emailAddress"
                label="Email Address"
                name="emailAddress"
                autoComplete="emailAddress"
                value={user.emailAddress}
                onChange={handleEmailChange}
              />
              {alertEmail.status && (
                <Alert severity={alertEmail.severity}>{alertEmail.msg}</Alert>
              )}
            </Box>

            <Box>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="firstName"
                label="First Name"
                name="firstName"
                autoFocus
                value={user.firstName}
                onChange={handleInputChange}
              />
            </Box>
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                value={user.lastName}
                onChange={handleInputChange}
              />
            </Box>

            {/* <Box>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                value={user.password}
                onChange={handleInputChange}
              />
            </Box> */}
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                name="phoneNumber"
                label="Phone Number"
                type="text"
                id="phoneNumber"
                value={user.phoneNumber}
                onChange={handleInputChange}
              />
              {alertPhone.status && (
                <Alert severity={alertPhone.severity}>{alertPhone.msg}</Alert>
              )}
            </Box>

            <Box style={{ margin: "20px 0" }}>
              <FormControl fullWidth>
                <InputLabel id="selectOrg">Organisation</InputLabel>
                <Select
                  labelId="selectOrg"
                  id="demo-simple-select-org"
                  value={user.organisation}
                  label="Organisation"
                  onChange={handleOrgChange}
                >
                  {organisation.map((org) => (
                    <MenuItem value={org.uid} key={org.uid}>
                      <strong>{org.name}</strong>{" "}
                      {org.phoneNumber && (
                        <p style={{ margin: "0px 4px" }}>
                          {" "}
                          Phone: {org.phoneNumber}
                        </p>
                      )}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {/* <Box style={{ margin: "20px 0" }}>
              <FormControl fullWidth>
                <InputLabel id="selectRole">Role</InputLabel>
                <Select
                  labelId="selectRole"
                  id="demo-simple-select-role"
                  value={user.role}
                  label="Role"
                  onChange={handleRoleChange}
                >
                  {roles.map((role) => (
                    <MenuItem value={role.uid} key={role.uid}>
                      <strong>{role.name}</strong>{" "}
                      {role.phoneNumber && (
                        <p style={{ margin: "0px 4px" }}>
                          {" "}
                          Phone: {role.phoneNumber}
                        </p>
                      )}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  endIcon={!loading ? <AddIcon /> : null}
                >
                  {loading ? "Saving" : "Register"}
                </Button>
                <Link to="/login" variant="body2">
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    startIcon={<LockOutlinedIcon />}
                  >
                    Login
                  </Button>
                </Link>
              </Box>
            </Box>
          </form>
        </>
      ) : (
        <LinearProgress color="primary" />
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
