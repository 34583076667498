import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
  TextareaAutosize,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";

import { useNavigate as useHistory } from "react-router-dom";

function AddWorkingGroup() {
  const [organisation, setOrganisation] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [roles, setRoles] = useState([]);
  const [workingGroup, setWorkingGroup] = useState({
    workingGroupName: "",
    workingGroupDescription: "",
    charterText: "",
  });
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let history = useHistory();
  const [alertEmail, setAlertEmail] = useState({
    status: false,
    severity: "",
    msg: "",
  });
  const [alertPhone, setAlertPhone] = useState({
    status: false,
    severity: "",
    msg: "",
  });

  const handleInputChange = (event) => {
    setWorkingGroup({
      ...workingGroup,
      [event.target.name]: event.target.value,
    });
  };

  const getWorkingGroupData = async () => {
    var workingGroupData = {
      name: workingGroup.workingGroupName,
      description: workingGroup.workingGroupDescription,
      charter: workingGroup.charterText,
    };
    return workingGroupData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(event);
    const workingGroupData = await getWorkingGroupData();
    console.log("userData is ", workingGroupData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "/WorkingGroup/");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "/WorkingGroup/");

    await axios
      .post(axiosUrl, workingGroupData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(async (response) => {
        // If request is good...
        history("/manage/working-group");
        console.log(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.data +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add User " + error.message);
        }
      });
  };

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("tokenDetails")));
  }, []);

  return (
    <Box style={{ overflow: "auto", height: "65vh" }}>
      <Container sx={{ pt: 4 }}>
        <Card>
          <CardHeader
            subheader="* Fill the required fields"
            title="Add Working Group"
          />
          <Divider />

          <form noValidate onSubmit={handleSubmit}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box style={{ padding: "10px", flex: "1" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="workingGroupName"
                  label="Working Group Name"
                  name="workingGroupName"
                  autoFocus
                  value={workingGroup.firstName}
                  onChange={handleInputChange}
                />
              </Box>
              <Box sx={{ padding: "10px", flex: "1" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="workingGroupDescription"
                  label="Working Group Description"
                  name="workingGroupDescription"
                  autoComplete="workingGroupDescription"
                  value={workingGroup.lastName}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>

            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box style={{ padding: "10px", flex: "1" }}>
                <TextareaAutosize
                  style={{
                    width: "100%",
                    height: "150px",
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  name="charterText"
                  label="Charter Text"
                  type="text"
                  id="charterText"
                  value={workingGroup.password}
                  onChange={handleInputChange}
                ></TextareaAutosize>
              </Box>
            </Box>

            {(
              userDetails == null ? (
                <></>
              ) : (
                userDetails != null &&
                userDetails.OrganisationName == "BIAN" &&
                (userDetails.Role == "ToolAdministrator" ||
                  userDetails.Role == "UserAdministrator")
              )
            ) ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box style={{ padding: "10px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Add Working Group
                  </Button>
                </Box>
              </Box>
            ) : (
              <></>
            )}

            {/* <FormControlLabel
                                   control={<Checkbox value="remember" color="primary" />}
                                   label="Remember me"
                              /> */}
          </form>
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorText}
            </Alert>
          )}
        </Card>
      </Container>
    </Box>
  );
}

export default AddWorkingGroup;
