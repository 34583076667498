import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
  SvgIcon,
  Collapse,
  Typography,
  Divider,
  Box,
  Button,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  RadioGroup,
  Stack,
  Chip,
} from "@mui/material";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import DomainLabel from "./DomainLabel";
import ModelFormEdit from "./ModelFormEdit";
import CheckIcon from "@mui/icons-material/Check";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import axios from "axios";
import ShowMore from "./ShowMore";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function Domain(props) {
  const [treeItem, setTreeItem] = useState({});
  const [skeleton, setSkeleton] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenData, setModalOpenData] = useState({
    title: "Title",
    description: "Description",
    status: "Status",
    data_type: "String",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClickOpen = () => {
    var getItem = JSON.parse(localStorage.getItem("selectToModify"));
    setModalOpenData(getItem);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleTreeSubmit = () => {
    var submitDta = JSON.parse(localStorage.getItem("servicetree"));
    var submitToken = "Bearer " + localStorage.getItem("jwtToken");

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainUpdate");

    axios({
      method: "put",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    }).then((res) => {
      const response = res.data;
      console.log("response is from axios api ", response);
    });

    setOpen(false);
  };

  useEffect(() => {
    if (
      props.serviceUid !== undefined &&
      props.serviceUid !== null &&
      props.serviceUid !== ""
    ) {
      let axiosUrl = "";
      process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "BOEnumeration/" + props.serviceUid);
      process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "BOEnumeration/" + props.serviceUid);

      axios({
        method: "get",
        url: axiosUrl,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      }).then((res) => {
        setTreeItem(res.data);
        localStorage.setItem("servicetree", JSON.stringify(res.data));
        props.callbackFunction(res.data);
        setSkeleton(false);
      });
    }
  }, [props.serviceUid]);

  const radioSkeletons = [];
  for (let i = 1; i <= 10; i++) {
    radioSkeletons.push(i);
  }

  return (
    <>
      {!skeleton && (
        <Box>
          <Box>
            <Box
              style={{
                padding: "5px 10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography gutterBottom variant="h5" component="h5">
                  {treeItem.name}
                </Typography>
                {/* {treeItem?.uid != null && (
                  <Typography variant="body1" color="#3a77837d">
                    {treeItem?.uid}
                  </Typography>
                )} */}
                {treeItem.description != null && (
                  <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
                    {treeItem.description}
                  </Typography>
                )}
                <Stack direction="row" spacing={1}>
                  {/* {treeItem.status !== "" &&
                    treeItem.status !== undefined && (
                      <Chip
                        title={"Status : " + treeItem.status}
                        label={treeItem.status}
                        color="primary"
                        size="small"
                      />
                    )} */}
                  {treeItem.data_type !== "" &&
                    treeItem.data_type !== undefined &&
                    treeItem.data_type !== null && (
                      <Chip
                        title={"Data Type : " + treeItem.data_type}
                        label={treeItem.data_type}
                        color="info"
                        size="small"
                      />
                    )}
                </Stack>
              </Box>
            </Box>
            <Divider style={{ margin: "0 0 8px 0" }} />
          </Box>

          <DragDropContext>
            <TreeView
              aria-label="customized"
              defaultExpanded={["1"]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
            >
              {/* <Droppable>
                  {(provided, snapshot) => ( */}
              <div>

                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.12)",
                    marginTop: "5px",
                  }}
                >

                  <StyledTreeItem
                    sx={{
                      border: "3px solid #fff",
                    }}
                    nodeId={treeItem.uid}
                    key={treeItem.uid}
                    label={
                      <DomainLabel
                        commentUser=""
                        modalOpen={handleModalClickOpen}
                        modalClose={handleModalClose}
                        title={treeItem?.name}
                        description={treeItem?.description}
                        secDesc={treeItem?.featureName}
                        status={treeItem?.status}
                        data_type={treeItem?.dataType}
                        data_type2={treeItem?.boType ? <><div style={{ cursor: 'pointer' }}>{treeItem?.boType}</div></> : null}
                        type1="Enumeration"
                        type2=""
                        type3={treeItem?.uid}
                        serORfeed="feedback"
                        metaLight={treeItem?.metaLight}
                        showCompareRadio="false"
                        group_name="radio-buttons-group-feedback"
                        level="level2"
                        editId={treeItem?.uid}
                        parentId={treeItem?.uid}
                        editEnabled="false"
                        editButtonEnable="false"
                        showSubTitle="true"
                        changeStatusText="Dont"
                        meta={treeItem?.meta}
                      />
                    }>
                    {/* Attribute map :Start */}
                    {treeItem?.enumerationLiterals?.length > 0 && treeItem.enumerationLiterals.map(attrmap => (
                      <Box
                        sx={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          marginTop: "5px",
                        }}
                      >
                        <StyledTreeItem
                          sx={{
                            border: "3px solid #fff",
                          }}
                          nodeId={attrmap?.uid}
                          key={attrmap?.uid}
                          label={
                            <DomainLabel
                              commentUser=""
                              modalOpen={handleModalClickOpen}
                              modalClose={handleModalClose}
                              title={attrmap?.name}
                              description={attrmap?.description}
                              secDesc={attrmap?.featureName}
                              status={attrmap?.status}
                              data_type={attrmap?.dataType}
                              data_type2={attrmap?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap?.boType}</div></> : null}
                              type1="enumerationLiteral"
                              type2=""
                              type3={attrmap?.uid}
                              serORfeed="feedback"
                              metaLight={attrmap?.metaLight}
                              showCompareRadio="false"
                              group_name="radio-buttons-group-feedback"
                              level="level2"
                              editId={attrmap?.uid}
                              parentId={attrmap?.uid}
                              editEnabled="false"
                              editButtonEnable="false"
                              showSubTitle="false"
                              changeStatusText="Dont"
                              meta={attrmap?.meta}
                            />
                          }
                        />
                        <ShowMore editEnabled="false" editButtonEnable="false" borderAllow="false" serOrFeed="service" otherDetails={attrmap} />
                      </Box>
                    )
                    )}
                    {/* Attribute map :End */}
                  </StyledTreeItem>
                  <ShowMore editEnabled="false" editButtonEnable="false" borderAllow="false" serOrFeed="service" otherDetails={treeItem} />
                </Box>
              </div>
              {/* )}
             </Droppable> */}
            </TreeView>
          </DragDropContext>
        </Box>
      )}

      {skeleton && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <Skeleton sx={{ flexGrow: "1" }} height={40} variant="rectangular" />
          <Divider style={{ margin: "8px 0" }} />
        </Box>
      )}

      {skeleton && (
        <Box sx={{ padding: "10px 15px" }}>
          {radioSkeletons.map((index) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "10px",
              }}
              key={"skeletonKey" + index}
            >
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={{ marginRight: "7px" }}
              />
              <Skeleton
                sx={{ flexGrow: "1" }}
                height={20}
                variant="rectangular"
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

export default Domain;
