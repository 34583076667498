import { Card, CardActions, Typography, Button } from '@mui/material'
import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useNavigate, useLocation, Link, useNavigate as useHistory } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import {
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PsychologyAltIcon from '@mui/icons-material/DeviceUnknown';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import BIAN_Terms from './../dashboard/Service/help/BIAN_Terms';

import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from "@mui/material/Paper";
import { ResizableBox } from "react-resizable";
import debounce from 'lodash.debounce';
import Draggable from "react-draggable";

function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function GoBack(props) {
    const allCollection = useSelector((state) => state?.common?.searchVal);
    const selectedTabVal = useSelector((state) => state?.common?.selectedTab);
    const [textVal, setTextVal] = useState();
    const [open, setOpen] = React.useState(false);
    const [termModule, setTermModule] = React.useState(false);
    let history = useHistory();
    const [dropDownVal, setDropDownVal] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('ALL');
    const [hideVal, setHideVal] = useState(true);
    const [helpPage, setHelpPage] = useState(false);
    const [activeTabVal, setActiveTabVal] = useState('');
    const [searchingVal, setSearchingVal] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
  

    const debouncedSearch = debounce((term) => {
        dispatch({ type: "SEARCHING_TEXT", payload: searchTerm })
        setSearchingVal(searchTerm)
      }, 500); 

      useEffect(() => {
        debouncedSearch(searchTerm);
        return () => debouncedSearch.cancel(); // Cancel the debounce on component unmount
      }, [searchTerm, debouncedSearch]);

      const handleInputChange = (event) => {
        console.log("event.target.value, ", event.target.value);
        setSearchTerm(event.target.value);
      };

    const location = useLocation();
    const handleChange = (event, newValue) => {
        if (newValue == 'Reset') {
            setValue('');
        } else {
            setValue(newValue);
        }
    };
    const pages = [
        {
            name: "All",
            path: "all",
        },
        {
            name: "Business Scenario | Wireframe",
            path: "BusinessScenario"
        },
        {
            name: "Service Domain",
            path: "ServiceDomain"
        },
        {
            name: "Class",
            path: "Class"
        },
        {
            name: "Enumeration",
            path: "Enumerations"
        },
        {
            name: "Data Type",
            path: "DataType"
        },
        {
            name: "Primitive Type",
            path: "PrimitiveType"
        },
        {
            name: "Reset",
            path: "Reset"
        }
    ]

    const pages2 = [
        {
            label: "Business Scenario | Wireframe",
            value: "BusinessScenario",
            path: "business-scenario",
            help: '/dashboard/help/business-scenario-help',
            helpName: 'business-scenario-help'
        },
        {
            label: "Service Domain",
            value: "ServiceDomain",
            path: "services",
            help: '/dashboard/help/services-help',
            helpName: 'services-help'

        },
        {
            label: "Class",
            value: "Class",
            path: "classes",
            help: '/dashboard/help/classes-help',
            helpName: 'classes-help'
        },
        {
            label: "Enumeration",
            value: "Enumerations",
            path: "enumerations",
            help: '/dashboard/help/enumerations-help',
            helpName: 'enumerations-help'
        },
        {
            label: "Data Type",
            value: "DataType",
            path: "data-type",
            help: '/dashboard/help/data-type-help',
            helpName: 'data-type-help'
        },
        {
            label: "Primitive Type",
            value: "PrimitiveType",
            path: "primitive-type",
            help: '/dashboard/help/primitive-type-help',
            helpName: 'primitive-type-help'
        }
    ]
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseTermModule = () => {
        setTermModule(false)
    }

    const searchText = (e) => {
        const searchTxt = e.target.value;
        dispatch({ type: "SEARCHING_TEXT", payload: searchTxt })
        setSearchingVal(searchTxt)
        // setTextVal(searchTxt)
        // if (searchTxt.length > 2) {
        //     axios({
        //         method: "get",
        //         url: `${process.env.REACT_APP_SEARCH}/Search?search=${searchTxt}`,
        //         headers: {
        //             Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        //         },
        //     }).then((res) => {
        //         if (dropDownVal) {
        //             const allSearchVal = res.data.filter(({ artefactType }) =>
        //                 artefactType == dropDownVal

        //             )
        //             dispatch({ type: "SEARCH_VAL", payload: allSearchVal })
        //         } else {
        //             dispatch({ type: "SEARCH_VAL", payload: res.data })
        //         }
        //     });
        // }
    }

    const handleChangeServiceDomain = (event) => {
        setDropDownVal(event.target.value)
        dispatch({ type: "SEARCHING_FIELD", payload: event.target.value })

        // if (textVal && textVal.length > 2) {
        //     axios({
        //         method: "get",
        //         url: `${process.env.REACT_APP_SEARCH}/Search?search=${textVal}`,
        //         headers: {
        //             Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        //         },
        //     }).then((res) => {
        //         if (event.target.value) {
        //             const allSearchVal = res.data.filter(({ artefactType }) => artefactType === event.target.value)
        //             dispatch({ type: "SEARCH_VAL", payload: allSearchVal })
        //         } else {
        //             dispatch({ type: "SEARCH_VAL", payload: res.data })
        //         }
        //     });
        // }
    }

    const changeTab = (path) => {
        // props.changeTabVal(path)
        // dispatch({ type: "SELECT_TAB", payload: path })
        if (path != 'Reset') {
            dispatch({ type: "SEARCHING_FIELD", payload: path })
        } else {
            dispatch({ type: "SEARCHING_FIELD", payload: '' })
            dispatch({ type: "SEARCHING_TEXT", payload: '' })
            dispatch({ type: "SEARCH_VAL", payload: [] })
            setSearchingVal('')
        }

    }

    useLayoutEffect(() => {
        if (location.pathname == '/dashboard' || location.pathname == '/dashboard/') {
            setHideVal(true)
        } else {
            setHideVal(false)
        }
        const pathNameVal = location.pathname.split('/')

        if (pathNameVal.length >= 2) {
            if (pathNameVal[2] == 'help') {
                if (pathNameVal[3]) {
                    setActiveTabVal(pathNameVal[3])
                }
                setHelpPage(true)
            } else {
                setHelpPage(false)
            }
        }
    }, [location])

    const changeMainTab = (path) => {
        dispatch({ type: "SEARCHING_FIELD", payload: path })
        dispatch({ type: "SELECT_TAB", payload: path })
        history(`/dashboard/business-scenario-services/${path}`);
    }

    return (
        <div style={{ margin: "0px 10px", display: props.display }}>

            <Card sx={{ display: "flex", justifyContent: "end", p: 1, boxShadow: '0 4px 24px 0 rgba(34,41,47,.1)' }}>
                {/* <div id="inputWrapper">
                    <input
                        id="input"
                        className='inputSearch'
                        type="search"
                        autoComplete="off"
                        spellCheck="false"
                        role="combobox"
                        placeholder="Search Google or type a URL"
                        aria-live="polite"
                        onChange={(e) => searchText(e)}
                    />
                    <ntp-realbox-icon
                        id="icon"
                        in-searchbox=""
                        background-image=""
                        mask-image="search.svg"
                    />
                    <button id="voiceSearchButton" title="Search by voice" />
                    <ntp-realbox-dropdown id="matches" role="listbox" hidden="" />
                </div> */}
                {/* <Select
                    style={{ minWidth: "200px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Services"
                    placeholder='Select Services'
                    onChange={(handleChangeServiceDomain)}
                >
                    {
                        edgeDropDown && edgeDropDown.length > 0 && edgeDropDown.map((item) =>
                            <MenuItem value={item.value}>{item.label}</MenuItem>
                        )
                    }
                </Select> */}
                {
                    !hideVal ?
                        <>
                            <div style={{
                                marginLeft: '85px',
                                marginRight: '-85px',
                                display: 'grid',
                                marginBottom: '-8px',
                                cursor: 'pointer',
                            }} onClick={() =>
                                selectedTabVal == 'business-scenario' ?
                                    window.open('/dashboard/help/business-scenario-help', '_blank').focus()
                                    :
                                    selectedTabVal == 'business-scenario-services' ?
                                        window.open('/dashboard/help/services-help', '_blank').focus()
                                        : selectedTabVal == 'data-type' ?
                                            window.open('/dashboard/help/data-type-help', '_blank').focus()
                                            : selectedTabVal == 'enumerations' ?
                                                window.open('/dashboard/help/enumerations-help', '_blank').focus()
                                                : selectedTabVal == 'classes' ?
                                                    window.open('/dashboard/help/classes-help', '_blank').focus()
                                                    : selectedTabVal == 'primitive-type' ?
                                                        window.open('/dashboard/help/primitive-type-help', '_blank').focus()
                                                        : null
                            } >
                                <div style={{ display: 'inline-flex' }}>
                                    <PsychologyAltIcon fontSize="small" style={{ marginLeft: '4px', color: "#757575" }}
                                    />
                                </div>
                                <p style={{ fontSize: '11px', color: "#000000DE", width: 'min-content', textAlign: 'center' }}>Page Help</p>

                            </div>
                            <div onClick={() => setTermModule(true)} style={{
                                marginLeft: '110px',
                                // marginRight: '-85px',
                                display: 'grid',
                                marginBottom: '-8px',
                                cursor: 'pointer',
                            }} >
                                <div style={{ display: 'inline-flex' }}>
                                    <AutoStoriesIcon fontSize="small" style={{ marginLeft: '8px', color: "#757575" }}
                                    />
                                </div>
                                <p style={{ fontSize: '11px', color: "#000000DE", width: 'min-content', textAlign: 'center' }}>BIAN Terms</p>

                            </div>
                        </>
                        :
                        <>
                            <div style={{
                                marginLeft: '85px',
                                marginRight: '-85px',
                                display: 'grid',
                                marginBottom: '-8px',
                                cursor: 'pointer',
                            }} >
                                <div style={{ display: 'inline-flex' }}>
                                    <PsychologyAltIcon fontSize="small" style={{ marginLeft: '4px', color: "#757575" }}
                                        onClick={() =>
                                            window.open('/dashboard/help/dashboard-help', '_blank').focus()
                                        } />
                                </div>
                                <p style={{ fontSize: '11px', color: "#000000DE", width: 'min-content', textAlign: 'center' }}>Page Help</p>

                            </div>
                            <div onClick={() => setTermModule(true)} style={{
                                marginLeft: '110px',
                                // marginRight: '-85px',
                                display: 'grid',
                                marginBottom: '-8px',
                                cursor: 'pointer',
                            }} >
                                <div style={{ display: 'inline-flex' }}>
                                    <AutoStoriesIcon fontSize="small" style={{ marginLeft: '8px', color: "#757575" }}
                                    />
                                </div>
                                <p style={{ fontSize: '11px', color: "#000000DE", width: 'min-content', textAlign: 'center' }}>BIAN Terms</p>

                            </div>
                        </>
                }
                {/* {
                    !hideVal &&
                    <div style={{
                        marginLeft: '100px',
                        marginRight: '-85px',
                        display: 'grid',
                        marginBottom: '-8px',
                        cursor: 'pointer',
                    }} >
                        <div style={{ display: 'inline-flex' }}>

                            <YouTubeIcon onClick={handleClickOpen} fontSize="small" style={{ paddingLeft: "6px", color: "#757575" }} />
                        </div>
                        <p style={{ fontSize: '11px', color: "#000000DE", width: 'min-content', textAlign: 'center' }}>Video Help</p>

                    </div>
                } */}

                <input id="search-bar" style={{ visibility: hideVal ? "inherit" : 'hidden' }} 
                value={searchTerm}
                onChange={handleInputChange}
                placeholder='Search Bian eg: credit card, open account, investment portfolio management' type="text" />
                {/* <input id="search-bar" style={{ visibility: hideVal ? "inherit" : 'hidden' }} onChange={(e) => searchText(e)} value={searchingVal} placeholder='Search Bian eg: credit card, open account, investment portfolio management' type="text" /> */}


                <CardActions disableSpacing>
                    <Button onClick={() => navigate(-1)} style={{ display: "flex", color: "grey", alignItems: 'center' }}>
                        <Typography style={{ fontSize: "14px" }}>
                            Go Back
                        </Typography>
                        <ArrowBackIcon style={{ fontSize: "16px", marginLeft: "10px" }} />
                    </Button>
                </CardActions>


            </Card>
            {
                hideVal ?
                    <Card
                        id="search-bar-tab"
                        style={{ display: 'flex' }}
                    >
                        <p style={{ marginTop: '10px' }}> Filter search result by</p><Tabs
                            value={value}
                            onChange={handleChange}
                            style={{ justifyContent: "center" }}
                            aria-label="wrapped label tabs example"
                        >
                            {pages.map(({ path, name }) => (
                                <Tab onClick={() => changeTab(path)} value={path} label={name} />
                            ))}
                            {/* <Tab
                        value="ALL"
                        label="ALL"
                        wrapped
                    />
                    <Tab value="Business-Scenario" label="Business Scenario" />
                    <Tab value="Service-Domain" label="Service Domain" />
                    <Tab value="Classes" label="Classes" />
                    <Tab value="Data-Types" label="Data Types" />
                    <Tab value="Enumerations" label="Enumerations" />
                    <Tab value="Primitive-Types" label="Primitive Types" /> */}
                        </Tabs>
                    </Card>
                    : null
            }
            {
                helpPage ?
                    <Card
                        id="search-bar-tab"
                        style={{ display: 'flex', marginTop: "-50px" }}
                    >
                        <p style={{ marginTop: '10px' }}> Help Pages</p><Tabs
                            value={value}
                            onChange={handleChange}
                            style={{ justifyContent: "center" }}
                            aria-label="wrapped label tabs example"
                        >
                            {pages2.map(({ path, label, help, helpName }, i) => (
                                <Link to={help} key={i} style={{ color: activeTabVal == helpName ? 'blue' : "black" }} onClick={() => changeMainTab(path)}>
                                    <Tab value={path} label={label} />
                                </Link>
                            ))}
                            {/* <Tab
                        value="ALL"
                        label="ALL"
                        wrapped
                    />
                    <Tab value="Business-Scenario" label="Business Scenario" />
                    <Tab value="Service-Domain" label="Service Domain" />
                    <Tab value="Classes" label="Classes" />
                    <Tab value="Data-Types" label="Data Types" />
                    <Tab value="Enumerations" label="Enumerations" />
                    <Tab value="Primitive-Types" label="Primitive Types" /> */}
                        </Tabs>
                    </Card>
                    : null
            }

            <Dialog
                open={open}
                fullWidth={true}
                PaperComponent={PaperComponent}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='youtube-dialog'
            >
                <DialogTitle id="alert-dialog-title" className='youtube-dialog'>
                    {"Help"}
                    <p style={{
                        marginLeft: '40px',
                        fontSize: '15px',
                        color: '#9a9292',
                        marginBottom: '0px'
                    }}>Drag to Move</p>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    className='youtube-dialog'
                >
                    {/* <DialogContentText id="alert-dialog-description" className='youtube-dialog'> */}
                    <iframe
                        // width="950"
                        className="myIframe"
                        style={{ width: "100%" }}
                        height="480"
                        src={`https://www.youtube.com/embed/bi2OPrRwSTk`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="Embedded youtube"
                    />
                    {/* </DialogContentText> */}
                </DialogContent>
            </Dialog>



            <Dialog
                open={termModule}
                fullWidth={true}
                PaperComponent={PaperComponent}
                onClose={handleCloseTermModule}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='youtube-dialog'
            >
                <DialogTitle id="alert-dialog-title" className='youtube-dialog'>
                    {"BIAN Terms"}
                    <p style={{
                        marginLeft: '40px',
                        fontSize: '15px',
                        color: '#9a9292',
                        marginBottom: '0px'
                    }}>Drag to Move</p>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseTermModule}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    className='youtube-dialog'
                >
                    <BIAN_Terms />
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default GoBack