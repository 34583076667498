import React, { useState, useRef, useEffect } from "react";
import CustomEdge from './../CustomEdge';
import './diagram.css'
import ReactFlow, {
  ReactFlowProvider,
  removeElements,
  addEdge,
  updateEdge,
  isNode
} from "react-flow-renderer";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveIcon from '@mui/icons-material/Save';
import ApiIcon from "../../../../assets/images/image001.png";
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Diagram from "./../Diagram";
import initialElements from "./initial-elements";
import { useNavigate, useNavigate as useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { connect } from "react-redux"
import {
  increaseCounter,
  decreaseCounter,
} from "../../../redux/Counter/counter.actions"
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid';
import { Badge, Collapse } from '@mui/material';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import SvgIcon from '@mui/material/SvgIcon';
import TreeView from '@mui/lab/TreeView';
import { useSpring, animated } from 'react-spring'
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { Close, Edit, ArrowRightAlt } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView();
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const Transition = React.forwardRef(function Transition(
  // props & {
  //   children: React.ReactElement;
  // },ssss
  props,
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OverviewFlow = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [elements, setElements] = useState([]);
  const [AllData, setAllData] = useState([]);
  const [IsHoveer, setIsHoveer] = useState(false);
  const [edgeLeave, setedgeLeave] = useState();
  const [AllConnectionNode, setAllConnectionNode] = useState();
  const [SelectedNode, setSelectedNode] = useState();
  const [edgeHover, setedgeHover] = useState();
  const [UpdatedNode, setUpdatedNode] = useState();
  const [xPosition, setxPosition] = useState(0);
  const [yPosition, setyPosition] = useState(0);
  const [SerOppData, setSerOppData] = useState();
  const [styles, setstyles] = useState({});
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [SelectedNodeId, setSelectedNodeId] = React.useState();
  const [expanded, setExpanded] = useState();
  const [expandedInput, setExpandedInput] = useState();
  const [expandedOutput, setExpandedOutput] = useState();
  const [editableData, setEditableData] = useState();
  const [openEditmodal, setOpenEditModal] = useState(false)
  const [temp, setTemp] = useState()
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [selectedList, setselectedList] = useState([]);
  const [OpenEditServiceDomain, setOpenEditServiceDomain] = useState(false);
  const [edgeDeleteModal, setedgeDeleteModal] = useState(false);
  const [editableEdge, setEditableEdge] = useState({});
  const [deleteCloseModal, setDeleteCloseModal] = useState(false);
  const [editCloseModal, setEditCloseModal] = useState(false);
  const [editCloseModalName, setEditCloseModalName] = useState(false);
  const [edgeDropDown, setEdgeDropDown] = useState([]);
  const [connectedEdge, setConnectedEdge] = useState();
  const [connectionModal, setConnectionModal] = useState(false);
  const [connectionDesc, setConnectionDesc] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  // const [serviceDescriptionName, setConnectionDetails] = useState("");
  const [serviceDescriptionNewNode, setServiceDescriptionNewNode] = useState("");
  const [scenarioNameEdit, serScenarioNameEdit] = useState(false);
  const [deleteNodeModal, setDeleteNodeModal] = useState(false);

  const [updatedConnectionEdge, setUpdatedConnectionEdge] = useState();
  const [changeConnectionName, setChangeConnectionName] = useState();
  const [defaultEdgeDropDown, setDefaultEdgeDropDown] = useState([]);

  const [edgeId, setEdgeId] = useState(0);
  const [connectionDetails, setConnectionDetails] = useState([]);
  const [connectionApiEndPoint, setConnectionApiEndPoint] = useState('');
  const [selectedActionTerm, setSelectedActionTerm] = useState('');
  const [allOptionData, setAllOptionData] = useState([]);
  const [NewAttr, setNewAttr] = useState(false);



  const domainDetails = useSelector((state) => state?.common?.domainDetails);

  const [selectedEdgeToServiceConnection, setSelectedEdgeToServiceConnection] = useState({});
  const [defaultOperationData, setDefaultOperationData] = useState({});

  const _ALL_COLLECTION = useSelector((state) => state?.common?.allCollection);
  const selectedFeedbackDomainVal = useSelector((state) => state?.common?.selectedFeedbackDomainVal);

  const _FROM_TO_CON = useSelector((state) => state?.common?.fromToConnection);
  const allConnectionDataDetault = useSelector((state) => state?.common?.fromToConnectionAllConnection);

  const newConnections = useSelector((state) => state?.common?.newConnection);
  const allFeedbackData = useSelector((state) => state?.common?.allFeedbackData);
  const feedbackNav = useSelector((state) => state?.common?.feedbackNav);
  // useEffect(()=>{
  //   if(allElements.length !== elements.length){
  //     dispatch({type:"ALL_ELEMENT", payload: elements})
  //   }
  // },[elements])

  // useEffect(()=>{
  //   if(allElements.length !== elements.length){
  //     setElements(allElements)
  //   }
  // },[allElements])

  const onConnect = (params) => {
    const nameVal = selectedFeedbackDomainVal.organisation ? selectedFeedbackDomainVal.organisation : 'BIAN'
    const versionVal = selectedFeedbackDomainVal.version ? selectedFeedbackDomainVal.version : '10.1.0'

    fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + nameVal + `-` + versionVal + `-` + params.target.replaceAll(" ", ""))
      .then(res => res.json())
      .then(
        (result) => {
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            setDefaultEdgeDropDown(result.serviceOperations)
            const serOppArr = result.serviceOperations.map(({ id, displayName }) => { return { id, label: displayName } })
            setEdgeDropDown(serOppArr)
          }
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )
    setConnectionModal(true)
    setConnectedEdge(params)
  };

  const saveConnection = () => {
    const edgeData = connectedEdge
    edgeData.serviceOperationDescription = connectionDesc;
    dispatch({ type: "NEW_CONNECTION", payload: edgeData })
    edgeData.type = 'step';
    edgeData.animated = true;
    setElements((els) => addEdge(edgeData, els))
    setConnectionModal(false)

    // dispatch({type:"UPDATEEDGECONNECTION", payload: {updatedConnectionEdge, editableEdge}})
  }

  const saveConnectionNewNode = () => {
    // defaultEdgeDropDown
    const selectedVal = defaultEdgeDropDown.find(({ name }) => name == serviceDescriptionNewNode)
    setDefaultOperationData(selectedVal)
    const edgeData = connectedEdge
    edgeData.serviceOperationDescription = serviceDescriptionNewNode;
    edgeData.actiontype = selectedVal?.type;
    edgeData.baseCRBQName = selectedVal?.baseCRBQName;
    edgeData.actionTerm = selectedVal?.actionTerm;
    edgeData.uid = selectedVal?.uid;

    edgeData.data = elements[0].data
    dispatch({ type: "NEW_CONNECTION", payload: edgeData })
    edgeData.type = 'step';
    edgeData.animated = true;
    setElements((els) => addEdge(edgeData, els))
    // setElements((els) => {
    //   return els.map((el) => {
    //     if (isNode(el)) {
    //       if (el.id === edgeData.target) {
    //         el.data = elements[0].data

    //         return {
    //           ...el,
    //           style: {
    //             backgroundColor: "white"
    //           },
    //           data: {
    //             ...el.data,
    //             label: {
    //               ...el.data.label,
    //               props: {
    //                 ...el.data.label.props,
    //                 children: Array.isArray(el.data.label.props.children) === true && el.data.label.props.children.map((item, index) => {
    //                   if (index === 0) {
    //                     return item = edgeData.target
    //                   }
    //                   if (index === 1) {

    //                     return {
    //                       ...item,
    //                       props: {
    //                         ...item.props,
    //                         children: serviceDescriptionNewNode
    //                       },
    //                     };
    //                   }

    //                   return item;
    //                 })
    //               }
    //             }
    //           }
    //         };
    //       }
    //     }
    //     return el;

    //   });
    // })
    setConnectionModal(false)

    // dispatch({type:"UPDATEEDGECONNECTION", payload: {updatedConnectionEdge, editableEdge}})
  }

  useEffect(() => {
    // const selectedVal = props.allConnectionData && props.allConnectionData.map(({ id }, i) => id = i)
    setAllConnectionNode(props.allConnectionData)
  }, [props.allConnectionData])


  useEffect(() => {
    dispatch({ type: "ALLCOLLECTION", payload: AllConnectionNode })
  }, [AllConnectionNode])

  useEffect(() => {
    if (props.allNodes) {
      const nodes = props.allNodes.filter((item) => item.position)
      dispatch({ type: "SETPOSITION", payload: nodes })

      const mainDataVal = props.allNodes.map((el, i) => {
        if (el?.id != 0) {
          return {
            ...el,
            style: {
              borderColor: "white",
              border: "5px solid white",
              borderWidth: "5px",
            },
          }
        } else {
          return {
            ...el,
            id: i,
            style: {
              stroke: '#b1b1b7',
              strokeWidth: '4px',
              strokeDasharray: '6'
            },
          }
        }
      })
      setElements(mainDataVal)
      props.increaseCounter(mainDataVal)
    }
  }, [props.allNodes])

  useEffect(() => {
    if (elements) {

      setAllData(elements)
    }
  }, [elements])

  useEffect(() => {
    if (UpdatedNode) {
      setAllData(UpdatedNode)
    }
  }, [UpdatedNode])

  const moveOnEdge = (edgeHover) => {
    const selectedEdge = AllConnectionNode.find(({ fromServiceConnectionPart, toServiceConnectionPart }) => fromServiceConnectionPart.serviceDomainName === edgeHover.source && toServiceConnectionPart.serviceDomainName === edgeHover.target)

    let mainConnection = {}
    if (selectedEdge) {
      mainConnection = selectedEdge
    } else {
      mainConnection = newConnections.find(({ source, target }) => source === edgeHover.source && target === edgeHover.target)
    }
    setedgeLeave()
    setElements((elms) => {
      return elms.map((el) => {

        if (el.id === edgeHover.source) {
          return {
            ...el,
            style: {
              borderColor: "#08830b",
              border: "5px solid #08830b",
              borderWidth: "5px",
              color: "#ed6c02"
            },
          };
        }
        if (el.id === edgeHover.target) {
          if (Array.isArray(el.data.label.props.children) === true) {
            return {
              ...el,
              style: {
                borderColor: "#08830b",
                border: "5px solid #08830b",
                borderWidth: "5px",
                color: "#0088d1"
              },
              data: {
                ...el.data,
                label: {
                  ...el.data.label,
                  props: {
                    ...el.data.label.props,
                    children: el.data.label.props.children.map((item, index) => {
                      if (index === 1) {
                        return {
                          ...item,
                          props: {
                            ...item.props,
                            children: mainConnection?.toServiceConnectionPart?.serviceOperationDescription || mainConnection?.toServiceConnectionPart?.serviceOperationName || mainConnection?.serviceOperationDescription,
                            style: {
                              ...item.props.style,
                              visibility: 'visible'
                            }
                          },
                        };
                      }
                      return item;
                    })
                  }
                }
              }
            };
          } else {
            return {
              ...el,
              style: {
                borderColor: "#08830b",
                border: "5px solid #08830b",
                borderWidth: "5px",
                color: "#0088d1"
              },
            };
          }

        }
        return el;
      });
    });
  }

  useEffect(() => {
    if (edgeLeave) {

      setElements((elms) => {
        return elms.map((el) => {
          if (isNode(el)) {
            if (Array.isArray(el.data.label.props.children) === true) {
              return {
                ...el,
                style: {
                  backgroundColor: "white",
                  borderColor: "white",
                  border: "5px solid white",
                  borderWidth: "5px",
                },
                data: {
                  ...el.data,
                  label: {
                    ...el.data.label,
                    props: {
                      ...el.data.label.props,
                      children: Array.isArray(el.data.label.props.children) === true && el.data.label.props.children.map((item, index) => {
                        if (index === 1) {
                          return {
                            ...item,
                            props: {
                              ...item.props,
                              style: {
                                ...item.props.style,
                                visibility: 'hidden'
                              }
                            },
                          };
                        }
                        return item;
                      })
                    }
                  }
                }
              };
            } else {
              return {
                ...el,
                style: {
                  backgroundColor: "white",
                  borderColor: "white",
                  border: "5px solid white",
                  borderWidth: "5px",
                },
              };
            }
          }

          return el;
        });
      });
    }
  }, [edgeLeave])

  const moveOnNode = (node) => {
    setSelectedNodeId(node.id)

    // setElements((elms) => {
    //   return elms.map((el) => {
    //     if (el.id === node.id) {
    //       return {
    //         ...el,
    //         style: {
    //           borderColor: "#ed6c02",
    //           border: "4px solid #ed6c02",
    //           borderWidth: "4px",
    //         },
    //       }
    //     }
    //     if (el.source == node.id || el.target == node.id) {

    //       return {
    //         ...el,
    //         style: {
    //           stroke: 'green',
    //           strokeWidth: '16px',
    //           strokeDasharray: '16'
    //         },
    //       };
    //     }
    //     return el;
    //   });
    // });

    setElements((elms) => {
      return elms.map((el) => {
        if (el.id === node.id) {
          return {
            ...el,
            style: {
              borderColor: "#ed6c02",
              border: "4px solid #ed6c02",
              borderWidth: "4px",
            },
          }
        }
        if (el.source === node.id || el.target === node.id) {
          return {
            ...el,
            style: {
              stroke: '#ed6c02',
              strokeWidth: '6px',
              strokeDasharray: '6'
            },
          };
        }
        return el;
      });
    });

    setElements((elms) => {
      return elms.map((el) => {
        if (Array.isArray(el?.data?.label?.props?.children) === true) {
          return {
            ...el,
            data: {
              ...el.data,
              label: {
                ...el.data.label,
                props: {
                  ...el.data.label.props,
                  children: el.data.label.props.children.map((item, index) => {
                    if (index === 1) {
                      return {
                        ...item,
                        props: {
                          ...item.props,
                          children: '',
                          style: {
                            ...item.props.style,
                            visibility: 'hidden'
                          }
                        },
                      };
                    }
                    return item;
                  })
                }
              }
            }
          };
        }
        //  else {
        //   return {
        //     ...el,
        //     style: {
        //       borderColor: "#08830b",
        //       border: "5px solid #08830b",
        //       borderWidth: "5px",
        //       color: "#0088d1"
        //     },
        //   };
        // }
        return el;
      });
    });


    setElements((elms) => {
      return elms.map((el) => {

        if (el.id === node.id) {
          if (Array.isArray(el.data.label.props.children) === true) {
            return {
              ...el,
              style: {
                borderColor: "#08830b",
                border: "5px solid #08830b",
                borderWidth: "5px",
                color: "#0088d1"
              },
              data: {
                ...el.data,
                label: {
                  ...el.data.label,
                  props: {
                    ...el.data.label.props,
                    children: el.data.label.props.children.map((item, index) => {
                      if (index === 1) {
                        return {
                          ...item,
                          props: {
                            ...item.props,
                            children: 'Double Click',
                            style: {
                              ...item.props.style,
                              visibility: 'visible'
                            }
                          },
                        };
                      }
                      return item;
                    })
                  }
                }
              }
            };
          } else {
            return {
              ...el,
              style: {
                borderColor: "#08830b",
                border: "5px solid #08830b",
                borderWidth: "5px",
                color: "#0088d1"
              },
            };
          }

        }
        return el;
      });
    });

  }

  const leaveOnNode = (node) => {
    setElements((elms) => {
      return elms.map((el) => {
        if (el.id === node.id) {
          return {
            ...el,
            style: {
              borderColor: "white",
              border: "5px solid white",
              borderWidth: "5px",
            },
          }
        }
        if (el.source === node.id || el.target === node.id) {
          return {
            ...el,
            style: {
              stroke: '#b1b1b7',
              strokeWidth: '4px',
              strokeDasharray: '6'
            },
          };
        }
        return el;
      });
    });


    setElements((elms) => {
      return elms.map((el) => {
        if (isNode(el)) {
          if (Array.isArray(el.data.label.props.children) === true) {
            return {
              ...el,
              style: {
                backgroundColor: "white",
                borderColor: "white",
                border: "5px solid white",
                borderWidth: "5px",
              },
              data: {
                ...el.data,
                label: {
                  ...el.data.label,
                  props: {
                    ...el.data.label.props,
                    children: Array.isArray(el.data.label.props.children) === true && el.data.label.props.children.map((item, index) => {
                      if (index === 1) {
                        return {
                          ...item,
                          props: {
                            ...item.props,
                            style: {
                              ...item.props.style,
                              visibility: 'hidden'
                            }
                          },
                        };
                      }
                      return item;
                    })
                  }
                }
              }
            };
          } else {
            return {
              ...el,
              style: {
                backgroundColor: "white",
                borderColor: "white",
                border: "5px solid white",
                borderWidth: "5px",
              },
            };
          }
        }

        return el;
      });
    });

  }

  const onSelectNode = (node) => {
    const domainNName = props.allDetails.meta.name.split('_')
    const nameVal = selectedFeedbackDomainVal.organisation ? selectedFeedbackDomainVal.organisation : 'BIAN'
    const versionVal = selectedFeedbackDomainVal.version ? selectedFeedbackDomainVal.version : '10.1.0'
    setSelectedNode(node?.data?.label?.props?.children[0])
    setTemp(node?.data?.label?.props?.children[1]?.props?.children)
    fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + nameVal + `-` + versionVal + `-` + node.id.replaceAll(" ", ""))
      .then(res => res.json())
      .then(
        (result) => {
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            const serOppArr = result.serviceOperations.map(({ uid, name, inputAttributes, outputAttributes }) => { return { uid, name, inputAttributes, outputAttributes } })
            if (serOppArr.length > 0) {
              setSerOppData(serOppArr)
              setOpen(true);
            }
          }
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )
    setElements((elms) => {
      return elms.map((el) => {
        if (isNode(el)) {
          if (el.id === node.id) {
            if (Array.isArray(el.data.label.props.children) === true) {
              return {
                ...el,
                style: {
                  backgroundColor: "white"
                },
                data: {
                  ...el.data,
                  label: {
                    ...el.data.label,
                    props: {
                      ...el.data.label.props,
                      children: Array.isArray(el.data.label.props.children) === true && el.data.label.props.children.map((item, index) => {
                        if (index === 2) {
                          return {
                            ...item,
                            props: {
                              ...item.props,
                              style: {
                                ...item.props.style,
                                visibility: 'visible'
                              }
                            },
                          };
                        }
                        return item;
                      })
                    }
                  }
                }
              };
            } else {
              return {
                ...el,
                style: {
                  backgroundColor: "white"
                },
              };
            }
          } else {
            if (Array.isArray(el.data.label.props.children) === true) {
              return {
                ...el,
                style: {
                  backgroundColor: "white"
                },
                data: {
                  ...el.data,
                  label: {
                    ...el.data.label,
                    props: {
                      ...el.data.label.props,
                      children: Array.isArray(el.data.label.props.children) === true && el.data.label.props.children.map((item, index) => {
                        if (index === 2) {
                          return {
                            ...item,
                            props: {
                              ...item.props,
                              style: {
                                ...item.props.style,
                                visibility: 'hidden'
                              }
                            },
                          };
                        }
                        return item;
                      })
                    }
                  }
                }
              };
            }
          }
        }
        return el;
      });
    })
  }

  const setEdgeDoubleClick = (edge) => {
    let selectedVal = {}
    // const newConnections = useSelector((state) => state?.common?.newConnection);
    // const allFeedbackData = useSelector((state) => state?.common?.allFeedbackData);
    const selectedObj = _ALL_COLLECTION.find((item) => item?.toServiceConnectionPart?.serviceDomainName === edge.target)
    // const selectedTarget = allFeedbackData.find((item) => item?.name === edge.target)
    if (selectedObj) {
      selectedVal = selectedObj?.toServiceConnectionPart
      setSelectedEdgeToServiceConnection(selectedObj.toServiceConnectionPart)
    } else {
      const fromVal = _ALL_COLLECTION.find((item) => item?.fromServiceConnectionPart?.serviceDomainName === edge.target)
      selectedVal = fromVal?.fromServiceConnectionPart
    }

    let strVal = ''
    if (selectedVal?.name) {
      strVal = selectedVal?.name
    } else {
      strVal = props.serviceUid[0] + `-` + props.serviceUid[1] + `-` + edge.target.replaceAll(" ", "")
    }

    var allMainData = []
    const nameVal = selectedFeedbackDomainVal.organisation ? selectedFeedbackDomainVal.organisation : 'BIAN'
    const domainNName = props.allDetails.meta.name.split('_')
    const versionVal = selectedFeedbackDomainVal.version ? selectedFeedbackDomainVal.version : '10.1.0'
    const mainStr = `${nameVal}-${versionVal}-${selectedObj?.toServiceConnectionPart?.name ? selectedObj?.toServiceConnectionPart?.name.split('-')[2] : selectedObj?.toServiceConnectionPart?.serviceDomainUID.split('-')[2]}`

    fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + mainStr)
      .then(res => res.json())
      .then(
        (result) => {
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            const serOppArr = result.serviceOperations.map(({ id, displayName }) => { return { id, label: displayName } })
            setEdgeDropDown(serOppArr)
            const selectedObjDataDefault = result.serviceOperations.find(({ name }) =>
              selectedObj.toServiceConnectionPart.serviceOperationName == name
            )

            setDefaultOperationData(selectedObjDataDefault)
          }

          const newFindVal = newConnections && newConnections.length > 0 && newConnections?.find(({ source, target }) => source === edge.source && target === edge.target)

          const selectedObjData = result.serviceOperations.find(({ name }) =>
            name == newFindVal?.serviceOperationDescription || selectedObj.toServiceConnectionPart.serviceDomainName == name
          )

          if (!selectedObj?.toServiceConnectionPart) {
            setSelectedEdgeToServiceConnection(selectedObjData)
            setNewAttr(true)
          } else {
            setNewAttr(false)
          }
          // setServiceDescription(newFindVal?.serviceOperationDescription)
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            allMainData.push(result.serviceOperations)

            if (!selectedObj.toServiceConnectionPart) {
              result.serviceOperations.find((data) => {
              })
            }

            const serOppArr = result.serviceOperations.map(({ id, displayName }) => { return { id, label: displayName } })
            setEdgeDropDown(serOppArr)
          }
          // setSerOppData(serOppArr)
          // setOpen(true);
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )


    // fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + domainNName[0] + `-` + domainNName[2] + `-` + selectedObj?.toServiceConnectionPart?.serviceDomainName.replaceAll(" ", ""))
    fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + nameVal + `-` + versionVal + `-` + selectedObj?.toServiceConnectionPart?.serviceDomainName.replaceAll(" ", ""))
      .then(res => res.json())
      .then(
        (result) => {

          if (result.serviceOperations && result.serviceOperations.length > 0) {
            const mainVal = []
            const serOppArr = result.serviceOperations.map((item, { uid, name, inputAttributes, outputAttributes }) => {

              if (item.displayName == selectedVal.serviceOperationName) {
                mainVal.push(item)
              }
            })
            if (mainVal.length > 0) {
              setSerOppData(mainVal)
            }
          }
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )

    setEdgeId(edge.id)
    const selectedElement = elements.filter((item) => item?.source === edge.source && item?.target === edge.target)
    const mainEdges = []
    const mainVal = _ALL_COLLECTION

    const findEdge = mainVal.find((item) =>
      item && item?.fromServiceConnectionPart?.serviceDomainName === edge?.source && item && item?.toServiceConnectionPart?.serviceDomainName === edge?.target)

    if (findEdge) {
      if (findEdge?.toServiceConnectionPart?.serviceOperationName) {
        setServiceDescription(findEdge.toServiceConnectionPart.serviceOperationName)
        setConnectionDetails(findEdge.toServiceConnectionPart.serviceOperationDescription)

      } else {
        setServiceDescription('')
        setConnectionDetails('')
      }

      if (findEdge?.toServiceConnectionPart?.apiEndPointLink) {
        setConnectionApiEndPoint(findEdge?.toServiceConnectionPart?.apiEndPointLink)
      } else {
        setConnectionApiEndPoint('')
      }

      if (findEdge?.toServiceConnectionPart?.serviceOperationDescription) {
        setConnectionDetails(findEdge.toServiceConnectionPart.serviceOperationDescription)

      } else {
        setConnectionDetails('')

      }

      // selectedElement.map((item) => {
      //   item.label = item?.data?.label?.props?.children?.props?.children
      //   mainEdges.push(item)
      // })
      edge.bianId = selectedObj?.bianId || uuidv4()
      setEditableEdge(edge)
      setedgeDeleteModal(true)
    } else {
      const newFindVal = newConnections.find(({ source, target }) => source === edge.source && target === edge.target)
      setServiceDescription(newFindVal?.serviceOperationDescription)
      setConnectionDetails(newFindVal?.serviceOperationName)
      setSelectedEdgeToServiceConnection(newFindVal)
      // setConnectionDetails(newFindVal?.serviceOperationName)

      edge.bianId = selectedObj?.bianId || uuidv4()
      setEditableEdge(edge)
      setedgeDeleteModal(true)
    }
  }

  const removeDeleteModal = () => {
    setedgeDeleteModal(false)
    setConnectionDetails([])
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleEditModel = () => {
    setOpenEditModal(true)
  }
  const handleEditModelClose = () => {
    setOpenEditModal(false)
  };
  const handleClose = () => {

    setOpen(false);

  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleInputChange =
    (panel) => (event, newExpanded) => {
      setExpandedInput(newExpanded ? panel : false);
    };

  const handleOutputChange =
    (panel) => (event, newExpanded) => {
      setExpandedOutput(newExpanded ? panel : false);
    };

  const handleListItemClick = (
    event,
    index,
  ) => {
    setSelectedIndex(index);
  };

  const openEditModal = (uid) => {
    axios.get(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceOperationByUID/` + uid, { headers: { Authorization: 'Bearer ' + localStorage.getItem("jwtToken") } })
      .then(response => {
        setEditableData(response.data[0])
        setOpenEditServiceDomain(true)
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  }

  const changeValue = (event, label) => {
    setEditableData((ele) => {
      ele[label] = event.target.value
      return ele;
    })
  }

  const selectList = (uid) => {
    if (selectedList.includes(uid)) {
      setselectedList(selectedList.filter((item) => item !== uid))
    } else {
      setselectedList([...selectedList, uid])
    }
  }

  const removeEdge = async () => {
    await setElements((elms) => {
      return elms.filter((value) => value.source !== editableEdge.source || value.target !== editableEdge.target
      )
    })
    setedgeDeleteModal(false)
  }

  const deleteModal = () => {
    setDeleteCloseModal(false);
  };

  const editModal = () => {
    setEditCloseModal(false);
  };

  const editModalName = () => {
    setEditCloseModalName(false);
  };

  const nodeDragStop = (event, node) => {
    const data = { id: node.id, x: node.position.x, y: node.position.y }
    dispatch({ type: "UPDATEPOSITION", data })
  }

  const closeConnectionModal = () => {
    setConnectionModal(false)
  }

  const handleChangeServiceDomain = (event) => {
    // setUpdatedConnectionEdge(event.target.value)
    setServiceDescription(event.target.value)
  }

  const handleChangeServiceDomainName = (event) => {
    // setUpdatedConnectionEdge(event.target.value)
    setConnectionDetails(event.target.value)
  }



  const handleChangeServiceDomainNewNode = (event) => {
    // setUpdatedConnectionEdge(event.target.value)
    setServiceDescriptionNewNode(event.target.value)
  }

  const saveUpdatedConnectionEdge = () => {
    // dispatch({ type: "UPDATEEDGECONNECTION", payload: { serviceDescription, editableEdge } })

    const nameVal = selectedFeedbackDomainVal.organisation ? selectedFeedbackDomainVal.organisation : 'BIAN'
    const versionVal = selectedFeedbackDomainVal.version ? selectedFeedbackDomainVal.version : '10.1.0'

    fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + nameVal + `-` + versionVal + `-` + editableEdge.target.replaceAll(" ", ""))
      .then(res => res.json())
      .then(
        (result) => {
          // connectionDetails
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            const serOppArr = result.serviceOperations.find(({ name }) => name == serviceDescription)
            // const serOppArr = result.serviceOperations.find(({ name }) => name == connectionDetails)
            const actionTermDetails = {}
            actionTermDetails.actiontype = serOppArr?.type;
            actionTermDetails.type = serOppArr?.type;
            actionTermDetails.baseCRBQName = serOppArr?.baseCRBQName;
            actionTermDetails.actionTerm = serOppArr?.actionTerm;
            actionTermDetails.uid = serOppArr?.uid;
            setSelectedEdgeToServiceConnection({ ...selectedEdgeToServiceConnection, ...actionTermDetails })
            dispatch({ type: "UPDATEEDGECONNECTION", payload: { serviceDescriptionName: serviceDescription, editableEdge, actionTermDetails } })
          }
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )

    setEditCloseModal(false)
  }

  const saveUpdatedConnectionEdgeName = () => {

    const nameVal = selectedFeedbackDomainVal.organisation ? selectedFeedbackDomainVal.organisation : 'BIAN'
    const versionVal = selectedFeedbackDomainVal.version ? selectedFeedbackDomainVal.version : '10.1.0'

    fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + nameVal + `-` + versionVal + `-` + editableEdge.target.replaceAll(" ", ""))
      .then(res => res.json())
      .then(
        (result) => {
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            const serOppArr = result.serviceOperations.find(({ name }) => name == connectionDetails)
            const actionTermDetails = {}
            // actionTermDetails.actiontype = serOppArr?.type;
            // actionTermDetails.type = serOppArr?.type;
            // actionTermDetails.baseCRBQName = serOppArr?.baseCRBQName;
            // actionTermDetails.actionTerm = serOppArr?.actionTerm;
            // actionTermDetails.uid = serOppArr?.uid;
            setSelectedEdgeToServiceConnection({ ...selectedEdgeToServiceConnection, ...actionTermDetails })
            dispatch({ type: "UPDATEEDGECONNECTIONNAME", payload: { serviceDescriptionName: connectionDetails, editableEdge, actionTermDetails } })
          }
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )

    editModalName()
  }

  const selectFeedBackOption = (value) => {
    const selectedFeedback = allFeedbackData.find(({ name }) => name === value);
    if (selectedFeedback) {
      dispatch({ type: "SET_FEEDBACK_SERVIES_DOMAIN", payload: selectedFeedback })
      dispatch({ type: "SELECT_TAB", payload: 'business-scenario-services' })
      history(`/dashboard/business-scenario-services/business-scenario-services`)

    }
  }

  const editScenarioName = (scenarioName) => {
    serScenarioNameEdit(true)
  }

  const changeDetailsText = (e) => {
    setChangeConnectionName(e.target.value)
  }

  const saveNewText = () => {
    dispatch({ type: "UPDATE_CONNECTION_NAME", payload: { editableEdge, changeConnectionName } })
    serScenarioNameEdit(false)
    setConnectionDetails(changeConnectionName)
  }

  const DeleteNodeVal = () => {
    setDeleteNodeModal(true)
    setOpen(false)
  }

  const makeDeleteNodeModal = () => {
    setDeleteNodeModal(false)
  }

  const deleteNodeData = () => {
    dispatch({ type: "DELETE_NODE", payload: SelectedNode })
    setElements((elms) => {
      return elms.filter((value) => value.id !== SelectedNode && value.source !== SelectedNode && value.target !== SelectedNode)
    })
    setDeleteNodeModal(false)
  }

  const callApiEndpoint = (item) => {
    const selectedUrl = _ALL_COLLECTION.find(({ fromServiceConnectionPart, toServiceConnectionPart }) =>
      (fromServiceConnectionPart.serviceDomainName === item[1].from || fromServiceConnectionPart.serviceDomainName == item[1]?.fromServiceConnectionPart?.serviceDomainName) &&
      toServiceConnectionPart.serviceDomainName === item[1].to || toServiceConnectionPart.serviceDomainName === item[1]?.toServiceConnectionPart?.serviceDomainName
    )
    if (selectedUrl?.toServiceConnectionPart?.apiEndPointLink) {
      window.open(selectedUrl?.toServiceConnectionPart?.apiEndPointLink, '_blank');
    } else {
      window.open(`https://app.swaggerhub.com/apis/BIAN-3/${selectedUrl?.toServiceConnectionPart?.serviceDomainName?.replaceAll(" ", "")}/${selectedUrl?.toServiceConnectionPart?.name?.split('-')[1]}#/${selectedUrl?.toServiceConnectionPart?.type || selectedUrl?.toServiceConnectionPart?.type}%20-%20${selectedUrl?.toServiceConnectionPart?.serviceOperationBaseCRBQ || selectedUrl?.toServiceConnectionPart?.baseCRBQName}/${selectedUrl?.toServiceConnectionPart?.serviceOperationActionTerm || defaultOperationData?.actionTerm}`, '_blank')
    }
  }

  const openInfoCOnnection = (e) => {
    if (e.target.className.baseVal) {
      setOpenEditModal(true)
    }
  }

  const getDefaultVal = (arr, selectedObj) => {
    if (arr && arr.length > 0) {
      const findObj = arr.find((item) => item.label == selectedObj)
      return findObj?.id
    }
  }

  const openApiLog = async (name, connectionDetails) => {
    const nameVal = selectedFeedbackDomainVal.organisation ? selectedFeedbackDomainVal.organisation : 'BIAN'
    const versionVal = selectedFeedbackDomainVal.version ? selectedFeedbackDomainVal.version : '10.1.0'

    await fetch(process.env.REACT_APP_MODEL_ACCESS_S1 + `/ServiceDomainByUID/` + nameVal + `-` + versionVal + `-` + name.replaceAll(" ", ""))
      .then(res => res.json())
      .then(
        (result) => {
          if (result.serviceOperations && result.serviceOperations.length > 0) {
            const serOppArr = result.serviceOperations.find(({ name }) => name == connectionDetails)
            const actionTermDetails = {}
            if (serOppArr?.type == 'BQ') {
              window.open(`https://app.swaggerhub.com/apis/BIAN-3/${name?.replaceAll(" ", "")}/${versionVal}#/${serOppArr?.type}%20-%20${serOppArr?.baseCRBQName}/${serOppArr?.actionTerm}${serOppArr?.baseCRBQName}`, '_blank')
            } else {
              window.open(`https://app.swaggerhub.com/apis/BIAN-3/${name?.replaceAll(" ", "")}/${versionVal}#/${serOppArr?.type}%20-%20${serOppArr?.baseCRBQName}/${serOppArr?.actionTerm}`, '_blank')
            }
          }
        },
        (error) => {
          console.log("error ==> ", error)
        }
      )
  }

  return (
    <>
      <div style={{
        display: 'table-caption',
        maxWidth: '250px',
        float: 'left',
        // marginRight: '10px',
        marginTop: '10px',
        color: 'grey',
        textAlign: 'center'
      }}> Click and Drag to move diagram on the canvas.</div>
      <div style={{
        display: 'contents',
        maxWidth: '250px',
        float: 'left',
        // marginRight: '10px',
        marginTop: '20px',
        color: 'grey',
        textAlign: 'center'
      }}> Double Click the boxes and flowing lines on the diagram to see more.</div>
      <div style={{
        display: 'table-caption',
        maxWidth: '150px',
        float: 'right',
        marginRight: '10px',
        marginTop: '10px',
        color: 'grey',
        textAlign: 'center'
      }}> Scroll to Zoom in  or out on the canvas.</div>
      <ReactFlow
        elements={elements}
        onNodeMouseMove={(event, edge) => moveOnNode(edge)}
        onNodeMouseLeave={(event, edge) => leaveOnNode(edge)}
        onConnect={onConnect}
        onNodeDoubleClick={(event, node) => onSelectNode(node)}
        onEdgeMouseMove={(event, edge) => moveOnEdge(edge)}
        onEdgeMouseLeave={(event, edge) => setedgeLeave(edge)}
        onEdgeDoubleClick={(event, edge) => setEdgeDoubleClick(edge)}
        onNodeDragStop={(event, node) => nodeDragStop(event, node)}
        defaultZoom={0.8}
      />
      <BootstrapDialog
        onClose={handleClose}

        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" style={{ backgroundColor: "#d5dcde" }} onClose={handleClose}>
          Service Operation
        </BootstrapDialogTitle>
        <DialogContent dividers>

          {Object.entries(_FROM_TO_CON)?.map((item, index) => {
            if (SelectedNode === item[1].from || SelectedNode === item[1].to) {
              return (
                <Accordion style={{
                  marginTop: '0px',
                  marginLeft: '0px'
                }}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    style={{ backgroundColor: "white" }}
                  >
                    <Grid item xs={1} sm={1} md={1} key={index} >
                      <Typography sx={{ width: '10%', flexShrink: 0 }}>
                        <Edit onClick={handleEditModel} style={{ cursor: "pointer" }} />
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sm={3} md={3} key={index} onClick={() => selectFeedBackOption(item[1]?.from)}>

                      {item[1]?.from}
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} key={index}>
                      <ArrowRightAlt style={{ marginLeft: "10px" }} />
                    </Grid>
                    <Grid item xs={1} sm={3} md={3} onClick={() => selectFeedBackOption(item[1]?.to)}>
                      {item[1].to}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4} >
                      {item[1].connection}
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} >
                      <img src={ApiIcon} style={{ width: '132%' }} onClick={() => callApiEndpoint(item)} />
                    </Grid>
                  </AccordionSummary>

                </Accordion>
              );
            }
          })}
          {
            Object.entries(_FROM_TO_CON).length == 0 && Object.entries(allConnectionDataDetault)?.map((item, index) => {
              if (SelectedNode === item[1].from || SelectedNode === item[1].to) {
                return (
                  <Accordion style={{
                    marginTop: '0px',
                    marginLeft: '0px'
                  }}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      style={{ backgroundColor: "white" }}
                    >
                      <Grid item xs={1} sm={1} md={1} key={index} >
                        <Typography sx={{ width: '10%', flexShrink: 0 }}>
                          <Edit onClick={handleEditModel} style={{ cursor: "pointer" }} />
                        </Typography>
                      </Grid>
                      <Grid item xs={1} sm={3} md={3} key={index} onClick={() => selectFeedBackOption(item[1]?.from)}>

                        {item[1]?.from}
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} key={index}>
                        <ArrowRightAlt style={{ marginLeft: "10px" }} />
                      </Grid>
                      <Grid item xs={1} sm={3} md={3} onClick={() => selectFeedBackOption(item[1]?.to)}>
                        {item[1].to}
                      </Grid>
                      <Grid item xs={2} sm={4} md={4} >
                        {item[1].connection}
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} >
                        <img src={ApiIcon} style={{ width: '132%' }} onClick={() => callApiEndpoint(item)} />
                      </Grid>
                    </AccordionSummary>

                  </Accordion>
                );
              }
            })
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={() => DeleteNodeVal()} >
            Delete Service Domain
          </Button>
          <Button onClick={handleClose} >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <BootstrapDialog
        onClose={handleEditModelClose}
        aria-labelledby="customized-dialog-title"
        open={openEditmodal}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleEditModelClose}>
          Service Operation
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* {
            console.log("11111111111111111111111111111111111111111", serviceDescription)
          }
          {
            console.log("22222222222222222222222222222222222222222", SerOppData)
          } */}

          {
            SerOppData && SerOppData.map(({ uid, name, inputAttributes, outputAttributes }) => {
              return uid && <Accordion style={{
                marginTop: '0px',
                marginLeft: '0px'
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={uid}
                  style={{ backgroundColor: selectedList.includes(uid) ? "#d5dcde" : "" }}
                  onClick={() => selectList(uid)}
                >
                  <Typography sx={{ width: '10%', flexShrink: 0 }}>
                    <Edit onClick={() => openEditModal(uid)} />
                  </Typography>
                  <MenuIcon />
                  <Typography ml={2} sx={{ color: 'text.secondary' }}>{name}</Typography>
                </AccordionSummary>

                <AccordionDetails>

                  <List component="nav" aria-label="main mailbox folders" key={1}>
                    <Accordion expanded={expandedInput === uid} onChange={handleInputChange(uid)}   >
                      <AccordionSummary id="panel1d-header">
                        <Typography style={{ fontSize: '18px' }}>Input Attribute</Typography>
                      </AccordionSummary>
                      <hr />
                      <AccordionDetails>

                        {
                          inputAttributes.map(({ uid, name, dataType }) =>
                            <>
                              <ListItemButton
                                onClick={(event) => handleListItemClick(event, uid)}
                              >
                                <ListItemIcon>
                                  <CancelPresentationIcon ml={2} />
                                  <MenuIcon ml={2} />
                                  <Typography ml={2} sx={{ color: 'text.secondary' }}>{name}<br /> <Badge badgeContent={dataType} style={{ marginLeft: "16px" }} color="info" /></Typography>
                                </ListItemIcon>
                              </ListItemButton>
                            </>
                          )
                        }
                      </AccordionDetails>
                    </Accordion>
                  </List>

                  <List component="nav" aria-label="main mailbox folders" key={2}>
                    <Accordion expanded={expandedOutput === uid} onChange={handleOutputChange(uid)}>
                      <AccordionSummary aria-controls="panel2d-header" id="panel2d-header">
                        <Typography style={{ fontSize: '18px' }}>Output Attribute</Typography>
                      </AccordionSummary>
                      <hr />
                      <AccordionDetails>
                        {
                          outputAttributes && outputAttributes.map(({ uid, name, dataType }) =>
                            <>
                              <ListItemButton
                                onClick={(event) => handleListItemClick(event, uid)}
                              >
                                <ListItemIcon>
                                  <CancelPresentationIcon ml={2} />
                                  <MenuIcon ml={2} />
                                  <Typography ml={2} sx={{ color: 'text.secondary' }}>{name}<br /> <Badge badgeContent={dataType} style={{ marginLeft: "16px" }} color="info" /></Typography>
                                </ListItemIcon>
                              </ListItemButton>
                            </>
                          )
                        }
                      </AccordionDetails>
                    </Accordion>
                  </List>
                </AccordionDetails>

              </Accordion>

            }


            )}

        </DialogContent>
      </BootstrapDialog>

      <Dialog
        className="dialog-form"
        open={OpenEditServiceDomain}
        fullWidth
        onClose={handleClose}
        maxWidth="lg"
      >
        <DialogTitle className="alert-dialog-title" style={{ padding: '16px 24px 0 24px' }}>Edit </DialogTitle>
        <Divider />
        <form
          action=""
        >
          <DialogContent>

            <Grid container>
              <Grid item xs={6} >
                <TextField
                  className="form-input"
                  fullWidth
                  label="Title"
                  margin="dense"
                  name="title"
                  // variant="standard"
                  value={editableData?.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  style={{ marginLeft: "20px" }}
                  className="form-input"
                  fullWidth
                  margin="dense"
                  label="Status"
                  name="status"
                  variant="standard"
                  value={editableData?.status}
                />
              </Grid>
            </Grid>


            <Grid container>
              <Grid item xs={6}>
                <TextField
                  className="form-input"
                  fullWidth
                  margin="dense"
                  multiline
                  rows={4}
                  label="Description"
                  name="description"
                  value={editableData?.description}
                />
              </Grid>
              <Grid item xs={6}>
              </Grid>
            </Grid>


            <Grid container>
              <Grid item xs={6}>
                <TextField
                  className="form-input"
                  fullWidth
                  label="Data Type"
                  margin="dense"
                  name="data_type"
                  value={editableData?.type}
                />
              </Grid>
              <Grid item xs={6}>
              </Grid>
            </Grid>

          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={props.handleClose}>Cancel</Button>
            <Button onClick={props.handleClose}>Save Changes</Button>
          </DialogActions>
        </form>
      </Dialog>



      <BootstrapDialog
        onClose={removeDeleteModal}

        open={edgeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: "400px" }}
      >
        <BootstrapDialogTitle style={{ minWidth: "400px" }} id="customized-dialog-title" onClose={removeDeleteModal}>
          Service Operation
        </BootstrapDialogTitle>
        <DialogContent style={{ minWidth: "400px" }}>
          <div style={{ marginBottom: "20px" }}>
            {
              // serviceDescription ?
              <Alert severity="info" style={{ marginBottom: "5px", cursor: "pointer" }} onClick={(e) => openInfoCOnnection(e)}>{serviceDescription}<EditIcon style={{ color: "#3b86cb", marginRight: "2px", marginTop: "-6px", marginLeft: "8px" }} onClick={(e) => {
                e.stopPropagation();
                setEditCloseModal(true)
              }} /></Alert>
              // : null
            }
            {
              scenarioNameEdit ?
                <div style={{ display: 'inline-flex' }}>
                  <TextField multiline={true} rows={2} id="outlined-basic" onChange={(e) => changeDetailsText(e)} fullWidth style={{ marginTop: "10px", minWidth: "168%", height: '14px', marginBottom:'45px' }} defaultValue={connectionDetails} label="Service Connection Description" variant="outlined" />
                  <Button variant="contained" style={{ marginLeft: "5px", marginTop: "10px", height:'50px' }} onClick={() => saveNewText()}>
                  <SaveIcon style={{ color: "white", marginRight: "2px", marginTop: "-6px" }} /> 
                    {/* Save */}
                    </Button>
                </div>
                :
                <Alert severity="info" style={{ marginBottom: "5px", cursor: "pointer", wordWrap: 'break-word' }}>
                 <p style={{maxWidth:'440px', paddingRight:'22px'}}> {connectionDetails ? connectionDetails : 'NA'}</p>
                  <EditIcon style={{ color: "#3b86cb", marginRight: "2px", marginTop: "-6px", marginLeft: "8px" }}
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   editScenarioName(connectionDetails)
                    // }}

                    onClick={(e) => {
                      e.stopPropagation();
                      // connectionDetails ?
                        editScenarioName(connectionDetails)
                        // :
                        // setEditCloseModalName(true)
                    }}
                  />
                </Alert>

            }
          </div>
          {
            // serviceDescription ?
            <Grid container style={{
              color: '#014361',
              backgroundColor: '#e5f6fd',
              padding: '16px',
              display: 'flex',
              marginTop:'30px'
            }}>
              {
                NewAttr ?
                  <>
                    {selectedEdgeToServiceConnection?.actionTerm || defaultOperationData?.actionTerm ?
                      <>
                        <Grid item xs={6} style={{ display: 'contents' }}>
                          <b style={{ marginRight: '4px' }}>{selectedEdgeToServiceConnection?.type || defaultOperationData?.type}: </b>
                          {selectedEdgeToServiceConnection?.baseCRBQName || defaultOperationData?.baseCRBQName}
                        </Grid>
                        <Grid item xs={1} >
                        </Grid>
                      </>
                      : null}
                    <Grid item xs={5}>
                      {/* ActionTerm: {selectedEdgeToServiceConnection?.serviceOperationActionTerm} */}
                      <b> ActionTerm:</b> {selectedEdgeToServiceConnection?.actionTerm || defaultOperationData?.actionTerm}
                    </Grid>
                  </>

                  :
                  selectedEdgeToServiceConnection?.actionTerm ?
                    <>
                      <>
                        <Grid item xs={6} style={{ display: 'contents' }}>
                          <b style={{ marginRight: '4px' }}>{selectedEdgeToServiceConnection?.type || defaultOperationData?.type}: </b>
                          {selectedEdgeToServiceConnection?.baseCRBQName || defaultOperationData?.baseCRBQName}
                        </Grid>
                        <Grid item xs={1} >
                        </Grid>
                      </>
                      <Grid item xs={5}>
                        {/* ActionTerm: {selectedEdgeToServiceConnection?.serviceOperationActionTerm} */}
                        <b> ActionTerm:</b> {selectedEdgeToServiceConnection?.actionTerm || defaultOperationData?.actionTerm}
                      </Grid>
                    </>

                    :
                    <>
                      {selectedEdgeToServiceConnection?.serviceOperationBaseCRBQType || defaultOperationData?.actionTerm ?
                        <>
                          <Grid item xs={4} style={{ display: 'contents' }}>
                            <b style={{ marginRight: '4px' }}>{selectedEdgeToServiceConnection?.serviceOperationBaseCRBQType || defaultOperationData?.type}: </b>
                            {selectedEdgeToServiceConnection?.serviceOperationBaseCRBQ || defaultOperationData?.baseCRBQName}
                          </Grid>
                          <Grid item xs={1} >
                          </Grid>
                        </>
                        : null}
                      <Grid item xs={5}>
                        {/* ActionTerm: {selectedEdgeToServiceConnection?.serviceOperationActionTerm} */}
                        <b> ActionTerm:</b> {selectedEdgeToServiceConnection?.serviceOperationActionTerm || selectedActionTerm || defaultOperationData?.actionTerm}
                      </Grid>
                    </>
              }


            </Grid>
            // : null
          }


        </DialogContent>
        <DialogActions style={{ webkitJustifyContent: "space-between" }}>
          <Button onClick={() => setDeleteCloseModal(true)} style={{ color: "black", fontSize: "16px", textTransform: "uppercase" }}>
            <DeleteIcon style={{ color: "#c13f38", marginRight: "2px", marginTop: "-6px" }} />   Delete
          </Button>
          <Button>
            {
              selectedEdgeToServiceConnection.actiontype ?
                <img src={ApiIcon} style={{ width: '18%' }} onClick={() => {
                  selectedEdgeToServiceConnection?.actiontype == 'BQ' || defaultOperationData?.actiontype == 'BQ' ?
                    window.open(`https://app.swaggerhub.com/apis/BIAN-3/${selectedEdgeToServiceConnection?.target?.replaceAll(" ", "") || selectedEdgeToServiceConnection?.serviceDomainName?.replaceAll(" ", "")}/${selectedEdgeToServiceConnection?.uid ? selectedEdgeToServiceConnection?.uid?.split('-')[1] : selectedEdgeToServiceConnection?.serviceDomainUID?.split('-')[1]}#/${selectedEdgeToServiceConnection?.actiontype || defaultOperationData?.actiontype}%20-%20${selectedEdgeToServiceConnection?.baseCRBQName || defaultOperationData?.baseCRBQName}/${selectedEdgeToServiceConnection?.actionTerm || defaultOperationData?.actionTerm}${selectedEdgeToServiceConnection?.baseCRBQName || defaultOperationData?.baseCRBQName}`, '_blank')
                    :
                    window.open(`https://app.swaggerhub.com/apis/BIAN-3/${selectedEdgeToServiceConnection?.target?.replaceAll(" ", "") || selectedEdgeToServiceConnection?.serviceDomainName?.replaceAll(" ", "")}/${selectedEdgeToServiceConnection?.uid ? selectedEdgeToServiceConnection?.uid?.split('-')[1] : selectedEdgeToServiceConnection?.serviceDomainUID?.split('-')[1]}#/${selectedEdgeToServiceConnection?.actiontype || defaultOperationData?.actiontype}%20-%20${selectedEdgeToServiceConnection?.baseCRBQName || defaultOperationData?.baseCRBQName}/${selectedEdgeToServiceConnection?.actionTerm || defaultOperationData?.actionTerm}`, '_blank')
                }} />

                :
                // <img src={ApiIcon} style={{ width: '18%' }} onClick={() => openApiLog(selectedEdgeToServiceConnection.serviceDomainName) window.open(`https://app.swaggerhub.com/apis/BIAN-3/${selectedEdgeToServiceConnection?.serviceDomainName?.replaceAll(" ", "")}/${selectedEdgeToServiceConnection?.name ? selectedEdgeToServiceConnection?.name?.split('-')[1] : selectedEdgeToServiceConnection?.serviceDomainUID?.split('-')[1]}#/${selectedEdgeToServiceConnection?.serviceOperationBaseCRBQType || defaultOperationData?.serviceOperationBaseCRBQType}%20-%20${selectedEdgeToServiceConnection?.serviceOperationBaseCRBQ || defaultOperationData?.baseCRBQName}/${selectedEdgeToServiceConnection?.serviceOperationActionTerm || defaultOperationData?.actionTerm}`, '_blank')} />
                <img src={ApiIcon} style={{ width: '18%' }} onClick={() => openApiLog(selectedEdgeToServiceConnection.serviceDomainName, selectedEdgeToServiceConnection.serviceOperationName || selectedEdgeToServiceConnection.serviceOperationDescription)} />


            }
            {/* <img src={ApiIcon} style={{ width: '18%' }} onClick={() => window.open(connectionApiEndPoint, '_blank')} /> */}

          </Button>
          {/* <Button onClick={() => setDeleteCloseModal(true)} autoFocus style={{ color: "black", fontSize: "16px", textTransform: "uppercase" }}>
            <SaveIcon style={{ color: "#3b86cb", marginRight: "2px", marginTop: "-6px" }} /> Save
          </Button> */}
          <Button onClick={removeDeleteModal} autoFocus style={{ color: "black", fontSize: "16px", textTransform: "uppercase" }}>
            <SaveIcon style={{ color: "#3b86cb", marginRight: "2px", marginTop: "-6px" }} /> Save
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <Dialog
        open={deleteCloseModal}
        onClose={deleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ minWidth: "400px" }}>
          {"Delete Edge"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="error"><h4>Are You Sure?</h4>
                Please ensure you click the Save button on the top right - <b>if you would like to save your changes!</b></Alert>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteModal} variant="contained" style={{ backgroundColor: "#3b86cb" }}>NO, CANCEL</Button>
          <Button onClick={() => {
            removeEdge()
            removeDeleteModal()
            deleteModal()
          }} variant="contained" style={{ backgroundColor: "#c23f38" }}>
            YES, DELETE
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={editCloseModalName}
        onClose={editModalName}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Service Operation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <InputLabel id="demo-simple-select-label" style={{ minWidth: "200px" }}>Name</InputLabel>
              <Select
                style={{ minWidth: "400px", maxWidth: "400px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={updatedConnectionEdge}
                value={connectionDetails}
                label="Name"
                onChange={handleChangeServiceDomainName}
              >
                {
                  edgeDropDown && edgeDropDown.length > 0 && edgeDropDown.map((item, i) =>
                    <MenuItem value={item.label}>{item.label}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={editModal}>Cancel</Button>
            <Button onClick={() => saveUpdatedConnectionEdgeName()}>Save Changes</Button>
          </DialogActions>
        </DialogActions>
      </Dialog>


      <Dialog
        open={editCloseModal}
        onClose={editModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Service Operation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl fullWidth style={{ marginTop: "10px" }}>
              <InputLabel id="demo-simple-select-label" style={{ minWidth: "200px" }}>Name</InputLabel>
              <Select
                style={{ minWidth: "400px", maxWidth: "400px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={updatedConnectionEdge}
                value={serviceDescription}
                label="Name"
                onChange={handleChangeServiceDomain}
              >
                {
                  edgeDropDown && edgeDropDown.length > 0 && edgeDropDown.map((item, i) =>
                    <MenuItem value={item.label}>{item.label}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={editModal}>Cancel</Button>
            <Button onClick={() => saveUpdatedConnectionEdge()}>Save Changes</Button>
          </DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog
        open={connectionModal}
        onClose={closeConnectionModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Service Operation"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <InputLabel id="demo-simple-select-label" style={{ minWidth: "200px" }}>Name</InputLabel>
            <Select
              style={{ minWidth: "400px", maxWidth: "400px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={updatedConnectionEdge}
              label="Edge"
              onChange={handleChangeServiceDomainNewNode}
            >
              {
                edgeDropDown && edgeDropDown.length > 0 && edgeDropDown.map((item) =>
                  <MenuItem value={item.label}>{item.label}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button onClick={closeConnectionModal}>Cancel</Button>
            <Button onClick={() => saveConnectionNewNode()}>Save Changes</Button>
          </DialogActions>
        </DialogActions>
      </Dialog>




      <Dialog
        open={deleteNodeModal}
        onClose={makeDeleteNodeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ minWidth: "400px" }}>
          {"Delete Service Domain"}
        </DialogTitle>
        <DialogContent style={{ minWidth: "400px" }}>
          <DialogContentText id="alert-dialog-description">
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="error"><h4>Are You Sure?</h4>
              </Alert>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={makeDeleteNodeModal} variant="contained" style={{ backgroundColor: "#3b86cb" }}>NO, CANCEL</Button>
          <Button onClick={() => {
            // makeDeleteNodeModal()
            deleteNodeData()
          }} variant="contained" style={{ backgroundColor: "#c23f38" }}>
            YES, DELETE
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

// export default OverviewFlow;
const mapStateToProps = state => {
  return {
    count: state?.counter?.count,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    increaseCounter: (data) => dispatch(increaseCounter(data)),
    decreaseCounter: () => dispatch(decreaseCounter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OverviewFlow)