import React, { useEffect, useState } from 'react';
import { Divider, FormControl, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import '.././../../assets/css/form.css'
import axios from 'axios';


const Transition = React.forwardRef(function Transition(props, ref) {
     return <Slide direction="up" ref={ref} {...props} />;
});


function ModelFormEdit(props) {


     var trimData = ''
     if (props.data.hasOwnProperty('title')) {
          trimData = props.data.title.trim()
     }
     else {
          trimData = ''
     }

     const url = process.env.REACT_APP_MODEL_ACCESS_S1+`ServiceDomain/`
     const [formdata, setFormdata] = useState({})
     useEffect(() => {
          setFormdata({
               id: props.data.id,
               uid: props.data.uid,
               buid: props.data.buid,
               title: props.data.title,
               description: props.data.description,
               status: props.data.status,
               data_type: props.data.data_type,
          })
     }, [props.data])

     function handleformChange(event) {
          var newDta = { ...formdata }
          var inputName = event.target.name
          if (inputName === 'title') {
               newDta['title'] = event.target.value
          }
          else if (inputName === 'description') {
               newDta['description'] = event.target.value
          }
          else if (inputName === 'status') {
               newDta['status'] = event.target.value
          }
          else if (inputName === 'data_type') {
               newDta['data_type'] = event.target.value
          }
          else {
          }
          setFormdata(newDta)
     }

     var p_type1 = props.data.type1
     var p_type2 = props.data.type2
     var p_type3 = props.data.type3
     var p_level = props.data.level
     var p_editId = props.data.editId
     var p_editEnabled = props.data.editEnabled
     var p_serORfeed = props.data.serORfeed

     function handleformSubmit(event) {
          event.preventDefault()
          console.log("feedback type",p_type1);
          console.log("feedback form submission",formdata);

          var getLocal = ''
          if (p_serORfeed === 'service') {
               getLocal = JSON.parse(localStorage.getItem('servicetree'))
          }
          else if (p_serORfeed === 'feedback') {
               getLocal = JSON.parse(localStorage.getItem('feedbacktree'))
          }
          else {
               return
          }

          if (p_type1 === 'characteristics') {
               console.log("type1 characteristics data is =>", p_type1);
               getLocal.characteristics.hasOwnProperty('name') && (getLocal.characteristics.name = formdata.title)
               getLocal.characteristics.hasOwnProperty('description') && (getLocal.characteristics.description = formdata.description)
               getLocal.characteristics.hasOwnProperty('status') && (getLocal.characteristics.status = formdata.status)
               getLocal.characteristics.hasOwnProperty('data_type') && (getLocal.characteristics.data_type = formdata.data_type)


          }
          else if (p_type1 === 'businessArea') {
               console.log("type1 businessArea data is =>", p_type1);
               getLocal.businessArea.hasOwnProperty('name') && (getLocal.businessArea.name = formdata.title)
               getLocal.businessArea.hasOwnProperty('description') && (getLocal.businessArea.description = formdata.description)
               getLocal.businessArea.hasOwnProperty('status') && (getLocal.businessArea.status = formdata.status)
               getLocal.businessArea.hasOwnProperty('data_type') && (getLocal.businessArea.data_type = formdata.data_type)

          }
          else if (p_type1 === 'businessDomain') {
               console.log("type1 businessDomain data is =>", p_type1);
               getLocal.businessDomain.hasOwnProperty('name') && (getLocal.businessDomain.name = formdata.title)
               getLocal.businessDomain.hasOwnProperty('description') && (getLocal.businessDomain.description = formdata.description)
               getLocal.businessDomain.hasOwnProperty('status') && (getLocal.businessDomain.status = formdata.status)
               getLocal.businessDomain.hasOwnProperty('data_type') && (getLocal.businessDomain.data_type = formdata.data_type)
          }
          else if (p_type1 === 'controlRecord') {
               console.log("type1 controlRecord data is =>", p_type1);
               if (p_level === 'level1') {
                    console.log("level1 data is =>", p_level);
               }
               else if (p_level === 'level2') {
                    console.log("level2 data is =>", p_level);
               }
               else if (p_level === 'level3') {
                    console.log("level3 data is =>", p_level);
               }
               else {
                    console.log("level else data is =>", p_level);
                    return
               }
          }
          else if (p_type1 === 'behaviourQualifiers') {
               console.log("type1 behaviourQualifiers data is =>", p_type1);
               if (p_level === 'level1') {
                    console.log("level1 data is =>", p_level);
               }
               else if (p_level === 'level2') {
                    console.log("level2 data is =>", p_level);
               }
               else if (p_level === 'level3') {
                    console.log("level3 data is =>", p_level);
               }
               else {
                    console.log("level else data is =>", p_level);
                    return
               }
          }
          else if (p_type1 === 'serviceOperations') {
               console.log("type1 serviceOperations data is =>", p_type1);
               if (p_level === 'level1') {
                    console.log("level1 data is =>", p_level);
               }
               else if (p_level === 'level2') {
                    console.log("level2 data is =>", p_level);
               }
               else if (p_level === 'level3') {
                    console.log("level3 data is =>", p_level);
               }
               else {
                    console.log("level else data is =>", p_level);
                    return
               }
          }
          else if (p_type1 === 'businessScenarios') {
               console.log("type1 businessScenarios data is =>", p_type1);
               if (p_level === 'level1') {
                    console.log("level1 data is =>", p_level);
               }
               else if (p_level === 'level2') {
                    console.log("level2 data is =>", p_level);
               }
               else if (p_level === 'level3') {
                    console.log("level3 data is =>", p_level);
               }
               else {
                    console.log("level else data is =>", p_level);
                    return
               }
          }
          else {
               console.log("type else data is =>", p_type1);
               if (p_level === 'level1') {
                    console.log("level1 data is =>", p_level);
               }
               else if (p_level === 'level2') {
                    console.log("level2 data is =>", p_level);
               }
               else if (p_level === 'level3') {
                    console.log("level3 data is =>", p_level);
               }
               else {
                    console.log("level else data is =>", p_level);
                    return
               }
               return
          }

          if (p_serORfeed === 'service') {
               localStorage.setItem('servicetree', JSON.stringify(getLocal))
          }
          else if (p_serORfeed === 'feedback') {
               localStorage.setItem('feedbacktree', JSON.stringify(getLocal))
          }
          else {
               return
          }


     }

     return <>
          <Dialog
               className="dialog-form"
               open={props.open}
               TransitionComponent={Transition}
               keepMounted
               onClose={props.handleClose}
               aria-describedby={"alert-dialog-slide-" + trimData}
          >
               <DialogTitle className="alert-dialog-title" style={{ padding: '16px 24px 0 24px' }}>Edit {props.data.title}</DialogTitle>
               {props.data.description && <DialogContentText className="alert-dialog-text" style={{ padding: '0 24px 10px 24px' }}>{props.data.description}</DialogContentText>}
               <Divider />
               <form onSubmit={(fsubmit) => handleformSubmit(fsubmit)} action="" id={"alert-dialog-slide-" + trimData}>
                    <DialogContent>
                         <Box className="form-control" >
                              <TextField
                                   className="form-input"
                                   fullWidth
                                   label="Title"
                                   margin="dense"
                                   id={"outlined-title" + trimData}
                                   onChange={e => handleformChange(e)}
                                   name="title"
                                   value={formdata.title ? formdata.title : ''}
                              />
                         </Box>
                         <Box className="form-control" >
                              <TextField
                                   className="form-input"
                                   fullWidth
                                   margin="dense"
                                   multiline
                                   rows={4}
                                   label="Description"
                                   id={"outlined-description" + trimData}
                                   onChange={e => handleformChange(e)}
                                   name="description"
                                   value={formdata.description ? formdata.description : ''}
                              />
                         </Box>
                         <Box className="form-control" >
                              <TextField
                                   className="form-input"
                                   fullWidth
                                   margin="dense"
                                   label="Status"
                                   id={"outlined-status" + trimData}
                                   onChange={e => handleformChange(e)}
                                   name="status"
                                   value={formdata.status ? formdata.status : ''}
                              />
                         </Box>
                         <Box className="form-control" >
                              <TextField
                                   className="form-input"
                                   fullWidth
                                   label="Data Type"
                                   margin="dense"
                                   id={"outlined-type" + trimData}
                                   onChange={e => handleformChange(e)}
                                   name="data_type"
                                   value={formdata.data_type ? formdata.data_type : ''}
                              />
                         </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                         <Button type="cancel" onClick={props.handleClose}>Cancel</Button>
                         <Button type="submit" onClick={props.handleClose}>Edit</Button>
                    </DialogActions>
               </form>
          </Dialog>
     </>;
}

export default ModelFormEdit;
