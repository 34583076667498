import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import BookIcon from "@mui/icons-material/Book";
import APIIcon from "../../../assets/images/image001.png";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MailIcon from "@mui/icons-material/Mail";
import LandscapeRoundedIcon from "@mui/icons-material/LandscapeRounded";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import Logout from "@mui/icons-material/Logout";
import { Login } from "@mui/icons-material";
import { Link } from "react-router-dom";

function Navtabs(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [users, setUserDetails] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    console.log("called");
    setUserDetails(JSON.parse(localStorage.getItem("tokenDetails")));
    console.log(users);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  var userDetails = {
    firstName: localStorage.getItem("firstName"),
  };

  return (
    <Box className="drops">
      {/* <a
        id="endpoint"
        tabIndex={-1}
        className="yt-simple-endpoint style-scope ytd-mini-guide-entry-renderer"
        title="Home"
        href="/"
        style={{ display: 'contents' }}
      >
        
        <BookIcon fontSize="medium" />
        <div
          id="tooltip"
          className="style-scope tp-yt-paper-tooltip hidden"
          style-target="tooltip"
          style={{ display: 'table-caption', textAlign: "center" }}
        >
          Practitioner
          Guide
        </div>
      
      </a> */}

      {/* <div class="dropdown" style={{ marginLeft: '200px' }}>
        <button class="dropbtn">
          <IconButton>
            <img src={APIIcon} style={{
              width: '12%',

            }} />
          </IconButton></button>
        <div class="dropdown-content">
          <a href="#" >

            <div class="dropdown2">
              <button class="dropbtn2">Sandbox API Help
              </button>
              <div class="dropdown-content2" style={{ marginLeft: "80%" }}>
                <a href="#" onClick={() => window.open('https://bian-modelapisandbox.azurewebsites.net/', '_blank')}>URL</a>
                <a href="#" onClick={() => window.open('https://bian.org/deliverables/bian-portal/model-api-sandbox-help/', '_blank')}>Help</a>
              </div>
            </div>
          </a>
          <a href="#">
            <div class="dropdown3">
              <button class="dropbtn3">Live API Help
              </button>
              <div class="dropdown-content3" style={{ marginLeft: "80%" }}>
                <a href="#" >URL</a>
                <a href="#" onClick={() => window.open('https://bian.org/deliverables/bian-portal/model-api-live-help/', '_blank')}>Help</a>
              </div>
            </div></a>
        </div>
      </div> */}

      {/* <div class="dropdown">
        <button class="dropbtn"><IconButton>
          <Badge color="info" className="drops_badges"><BookIcon fontSize="medium" />
          </Badge>
        </IconButton></button>
        <div class="dropdown-content">
          <a href="#" onClick={() => window.open('https://bian.org/wp-content/uploads/2020/10/BIAN-Semantic-API-Pactitioner-Guide-V8.1-FINAL.pdf', '_blank')}>Practitioner Guide</a>
        </div>
      </div>
      <div class="dropdown">
        <button class="dropbtn"><IconButton>
          <Badge color="info" className="drops_badges"><LandscapeRoundedIcon fontSize="medium" />
          </Badge>
        </IconButton></button>
        <div class="dropdown-content">
          <a href="#" onClick={() => window.open('https://bian.org/servicelandscape-10-0-0/', '_blank')}>Service Landscape</a>
        </div>
      </div>


      <Tooltip title="Latest Mail">
        <IconButton>
          <Badge badgeContent="5" color="info" className="drops_badges">
            <MailIcon color="dark" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title="Latest Notification">
        <IconButton>
          <Badge badgeContent="9+" color="danger" className="drops_badges">
            <NotificationsActiveIcon color="dark" />
          </Badge>
        </IconButton>
      </Tooltip> */}

      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar className="drops_user">
            {userDetails?.firstName ? userDetails?.firstName[0] : "A"}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem>
                         <Avatar className="my_profile_li">
                              {props.userDetails?.username ? props.userDetails?.username[0] : "R"}
                         </Avatar>
                         Profile
                    </MenuItem>
                    {/* <Divider />
                    <MenuItem>
                         <ListItemIcon>
                              <PersonAdd fontSize="small" />
                         </ListItemIcon>
                         Add another account
                    </MenuItem>
                    <MenuItem>
                         <ListItemIcon>
                              <Settings fontSize="small" />
                         </ListItemIcon>
                         Settings
                    </MenuItem>*/}
        {/* <MenuItem>
          <div onClick={() => window.open('https://bian.org/wp-content/uploads/2020/10/BIAN-Semantic-API-Pactitioner-Guide-V8.1-FINAL.pdf', '_blank')}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            Practitioner Guide
          </div>
        </MenuItem> */}
        {/* <MenuItem>
        <div onClick={() => window.open('https://bian.org/servicelandscape-10-0-0/', '_blank')}>
          <ListItemIcon>
            <LandscapeRoundedIcon fontSize="small" />
          </ListItemIcon>
          Service Landscape
        </div>
      </MenuItem> */}
        {localStorage.getItem("jwtToken") ? (
          <>
            <Link
              to="/login"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Link>
            {users == null ? (
              <></>
            ) : users.Role == "ToolAdministrator" ||
              users.Role == "UserAdministrator" ||
              users.OrganisationName == "BIAN" ? (
              <>
                <Link
                  to={"/manage/user/view/" + localStorage.getItem("cacheToken")}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <Avatar className="drops_user">
                        {userDetails?.firstName
                          ? userDetails?.firstName[0]
                          : "A"}
                      </Avatar>
                    </ListItemIcon>
                    My Profile
                  </MenuItem>
                </Link>
                <Link
                  to="/manage/"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>
                      <Avatar className="drops_user">AM</Avatar>
                    </ListItemIcon>
                    Access Management
                  </MenuItem>
                </Link>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Link
            to="/login"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              Login
            </MenuItem>
          </Link>
        )}
      </Menu>
    </Box>
  );
}

export default Navtabs;
