import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import axios from "axios";
import { Alert, AlertTitle } from "@mui/material";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        <Button>BIAN</Button>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function ForgotPassword() {
  const [resetEmailId, setResetEmailId] = useState("");
  const [adminJwtToken, setAdminJwtToken] = useState("");
  const [msgAlert, setMsgAlert] = useState({
    status: false,
    severity: "",
    title: "",
    msg: "",
  });
  const classes = useStyles();

  var defaultUser = {
    username: process.env.REACT_APP_DEFAULT_USERNAME,
    password: process.env.REACT_APP_DEFAULT_PASSWORD,
  };

  const getAuthenticate = async (loginUser) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

    await axios
      .post(axiosUrl, loginUser)
      .then((res) => res.status === 200 && setAdminJwtToken(res.data.jwtToken))
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
      });
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    console.log("clicked for forgot", adminJwtToken);
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S1 + "Users/PasswordResetToken");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_SECURITY_S2 + "Users/PasswordResetToken");
    axios({
      method: "post",
      url: axiosUrl,
      data: { username: resetEmailId, password: "" },
      headers: { Authorization: "Bearer " + adminJwtToken },
    })
      .then((res) => {
        console.log("response is Users/PasswordResetToken ==> ", res);
        if (res.status === 200) {
          sendPasswordResetMail({
            fromDisplayName: "BIAN Approval App",
            to: res.data.emailAddress,
            subject: "Password Reset Request",
            userUID: res.data.uid,
            resetToken: res.data.passwordResetToken.token,
            hostUrl: `${window.location.protocol}//${window.location.host}`,
          });
        } else {
          setMsgAlert({
            status: true,
            severity: "error",
            title: "Message sent Failed",
            msg: "Unable to find user with this mail Id",
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
        setMsgAlert({
          status: true,
          severity: "error",
          title: "Message sent Failed",
          msg: "Unable to find user with this mail Id",
        });
      });

    // setTimeout(() => {
    //   setMsgAlert({
    //     status: false,
    //     severity: "",
    //     title: "",
    //     msg: "",
    //   });
    // }, 5000);
  };

  const sendPasswordResetMail = (resetUser) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_FUNCTION_S1 + "SendPasswordResetMail");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_FUNCTION_S2 + "SendPasswordResetMail");
    axios({
      method: "post",
      url: axiosUrl,
      data: resetUser,
      headers: { Authorization: "Bearer " + adminJwtToken },
    })
      .then((res) => {
        if (res.status === 200) {
          setMsgAlert({
            status: true,
            severity: "success",
            title: "Request Sent",
            msg: "You should receive an email from the BIAN Approval App with a link to reset your password. If you do not see this email in your inbox, please check your Junk / Spam mail folder.",
          });
        } else {
          setMsgAlert({
            status: true,
            severity: "error",
            title: "Request sent Failed",
            msg: "Unable to Sent Request For Changing Password",
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
        setMsgAlert({
          status: true,
          severity: "error",
          title: "Request sent Failed",
          msg: "Unable to Sent Request For Changing Password",
        });
      });

    // setTimeout(() => {
    //   setMsgAlert({
    //     status: false,
    //     severity: "",
    //     title: "",
    //     msg: "",
    //   });
    // }, 5000);
  };

  useEffect(() => {
    localStorage.setItem("crawler", false);
    getAuthenticate(defaultUser);
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={handleForgotPassword}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="emailAddress"
            autoComplete="email"
            autoFocus
            value={resetEmailId}
            onChange={(e) => setResetEmailId(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Verify
          </Button>
          <Grid container>
            <Grid item>
              <Link to="/register" variant="body2">
                <Button size="small">{"Don't have an account? Sign Up"}</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/" variant="body2">
                <Button size="small">
                  {"Already Have an account? Sign In"}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </form>
        {msgAlert.status && (
          <Alert severity={msgAlert.severity} style={{ width: "100%" }}>
            <AlertTitle>{msgAlert.title}</AlertTitle>
            {msgAlert.msg}
          </Alert>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
