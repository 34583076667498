import { blue, brown, green, orange, purple, red } from '@mui/material/colors'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import ShowGroupCard from '../../components/utils/ShowGroupCard'

function CertHome() {
    const [listOfCardItem, setListOfCardItem] = useState([])

    const arrayOfColor = [{ name: 'green', ...green }, { name: 'blue', ...blue }, { name: 'red', ...red }, { name: 'purple', ...purple }, { name: 'orange', ...orange }, { name: 'brown', ...brown }]
    var loopOfLink = []
    loopOfLink.push({
        bgcolor: `${arrayOfColor[0][500]}`,
        bgColorName: `${arrayOfColor[0]['name']}`,
        avatarText: 'CA',
        title: "Assesser",
        subheader: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        showCardAction: true,
        cardType: "link",
        to: "/certification/certificate_assessr/",
        toText: "Assess Certificate",
        showCardBody: true,
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia."
    })
    loopOfLink.push({
        bgcolor: `${arrayOfColor[1][500]}`,
        bgColorName: `${arrayOfColor[1]['name']}`,
        avatarText: 'GC',
        title: "Get Certificate",
        subheader: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
        showCardAction: true,
        cardType: "link",
        to: "/certification/certificate_taker/",
        toText: "Get Started",
        showCardBody: true,
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia.Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit, ipsum aliquid eius minus vero dignissimos. Earum iusto perferendis minus quia."
    })


    return (
        <>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                <ShowGroupCard itemList={loopOfLink} xs={12} md={6} lg={6} />
            </Box>
        </>
    )
}

export default CertHome