import React, { useState } from "react";
import { green, blue, red, yellow } from '@mui/material/colors';
import NavigationBar from '../../components/main/NavigationBar';
import ShowGroupCard from '../../components/utils/ShowGroupCard';
import { withLogged } from '../../components/utils/withAuth';
import { useEffect } from "react";

function Manage() {

  const [userDetails, setUserDetails] = useState(null);


    useEffect(() => {
        setUserDetails(JSON.parse(localStorage.getItem("tokenDetails")));
    }, [userDetails])
    

    var listOfCardItem = [
        {
            bgcolor: green[500],
            bgColorName: "green",
            avatarText: 'UM',
            title: 'Users Management',
            subheader: "Add, Edit and Delete User",
            to: "/manage/users/",
            toText: "View All Users",
            showCardAction: true,
            cardType:"link",
        },
        {
            bgcolor: blue[500],
            bgColorName: "blue",
            avatarText: 'RM',
            title: 'Roles Management',
            subheader: "Add, Edit and Delete Roles",
            to: "/manage/role",
            toText: "View All Roles",
            showCardAction: true,
            cardType:"link",
        },
        {
            bgcolor: red[500],
            bgColorName: "red",
            avatarText: 'OM',
            title: 'Organisations Management',
            subheader: "Add, Edit and Delete Organisation",
            to: "/manage/organisation",
            toText: "View All Organisations",
            showCardAction: true,
            cardType:"link",
        },
        {
            bgcolor: yellow[500],
            bgColorName: "yellow",
            avatarText: 'WG',
            title: 'Working Group',
            subheader: "Manage working group",
            to: "/manage/working-group",
            toText: "View All working groups",
            showCardAction: true,
            cardType:"link",
        }
    ]

    var listOfCardItemWithoutBIANUser = [
        {
            bgcolor: green[500],
            bgColorName: "green",
            avatarText: 'UM',
            title: 'Users Management',
            subheader: "Add, Edit and Delete User",
            to: "/manage/users/",
            toText: "View All Users",
            showCardAction: true,
            cardType:"link",
        },
        {
            bgcolor: yellow[500],
            bgColorName: "yellow",
            avatarText: 'WG',
            title: 'Working Group',
            subheader: "Manage working group",
            to: "/manage/working-group",
            toText: "View All working groups",
            showCardAction: true,
            cardType:"link",
        }
    ]

    return (
        <>
            <NavigationBar />
            <ShowGroupCard itemList={userDetails != null ? (userDetails.OrganisationName == "BIAN" ? listOfCardItem : listOfCardItemWithoutBIANUser) : listOfCardItemWithoutBIANUser} xs={12} md={6} lg={4} />
        </>
    )
}

export default withLogged(Manage)