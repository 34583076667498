import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
	Divider,
	FormControl,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Alert,
	AlertTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import ".././../../assets/css/form.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Demo = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
}));

function ModelFormDelete(props) {
	var trimData = "";
	if (props.data?.hasOwnProperty("title")) {
		trimData = props.data.title.trim();
	} else {
		trimData = "";
	}

	const [formdata, setFormdata] = useState({});
	useEffect(() => {
		setFormdata({
			id: props.data.id,
			uid: props.data.editId,
			editId: props.data.editId,
			buid: props.data.buid,
			title: props.data.title,
			description: props.data.description,
			status: props.data.status,
			data_type: props.data.data_type,
			type1: props.data.type1,
			type2: props.data.type2,
			type3: props.data.type3,
			level: props.data.level,
			comments: props.data.comments != null ? props.data.comments : [],
		});
	}, [props.data]);

	function deleteItem(e) {
		e.preventDefault();
		var getLocalStorage = JSON.parse(localStorage.getItem("feedbacktree"));
		var selectToModify = JSON.parse(localStorage.getItem("selectToModify"));
		console.log("getLocalStorage starts is", getLocalStorage);
		console.log("formdata is", formdata, " | and level is", selectToModify.level, props.data.level);
		if (selectToModify.level === "level2") {
			if (selectToModify.type1 === "controlRecord") {
				let objIndex = '';
				if (getLocalStorage?.controlRecord?.attributes?.length > 0) {
					objIndex = getLocalStorage.controlRecord.attributes.findIndex((obj) => obj.uid == selectToModify.uid);
					if (objIndex !== '') {
						getLocalStorage.controlRecord.attributes.splice(objIndex, 1)
					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else if (selectToModify.type1 === "behaviourQualifiers") {
				let objIndex = '';
				if (getLocalStorage?.behaviourQualifiers?.length > 0) {
					objIndex = getLocalStorage.behaviourQualifiers.findIndex((obj) => obj.uid == selectToModify.uid);
					if (objIndex !== '') {
						getLocalStorage.behaviourQualifiers.splice(objIndex, 1)
					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else if (selectToModify.type1 === "serviceOperations") {
				let objIndex = '';
				if (getLocalStorage?.serviceOperations?.length > 0) {
					objIndex = getLocalStorage.serviceOperations.findIndex((obj) => obj.uid == selectToModify.uid);
					if (objIndex !== '') {
						getLocalStorage.serviceOperations.splice(objIndex, 1)
					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else if (selectToModify.type1 === "businessScenarios") {
				let objIndex = '';
				if (getLocalStorage?.businessScenarios?.length > 0) {
					objIndex = getLocalStorage.businessScenarios.findIndex((obj) => obj.uid == selectToModify.uid);
					if (objIndex !== '') {
						getLocalStorage.businessScenarios.splice(objIndex, 1)
					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else {
				console.log("level2 type1 not matching", formdata.type1);
			}
		} else if (selectToModify.level === "level3") {
			if (selectToModify.type1 === "behaviourQualifiers") {
				if (getLocalStorage?.behaviourQualifiers?.length > 0) {
					let parentIndex = ''
					parentIndex = getLocalStorage.behaviourQualifiers.findIndex((obj) => obj.uid == selectToModify.parentId);
					if (parentIndex !== '') {
						let objIndex = '';
						objIndex = getLocalStorage.behaviourQualifiers[parentIndex].attributes.findIndex((obj) => obj.uid == selectToModify.uid);
						if (objIndex !== '') {
							getLocalStorage.behaviourQualifiers[parentIndex].attributes.splice(objIndex, 1)
						}
						else {
							return
						}
					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else if (selectToModify.type1 === "serviceOperations") {
				if (getLocalStorage?.serviceOperations?.length > 0) {
					let parentIndex = ''
					parentIndex = getLocalStorage.serviceOperations.findIndex((obj) => obj.uid == selectToModify.parentId);
					if (parentIndex !== '') {
						let objIndex = '';
						if (selectToModify.ioType === "inputAttributes") {
							objIndex = getLocalStorage.serviceOperations[parentIndex].inputAttributes.findIndex((obj) => obj.uid == selectToModify.uid);
							if (objIndex !== '') {
								getLocalStorage.serviceOperations[parentIndex].inputAttributes.splice(objIndex, 1)
							}
							else {
								return
							}
						}
						else if (selectToModify.ioType === "outputAttributes") {
							objIndex = getLocalStorage.serviceOperations[parentIndex].outputAttributes.findIndex((obj) => obj.uid == selectToModify.uid);
							if (objIndex !== '') {
								getLocalStorage.serviceOperations[parentIndex].outputAttributes.splice(objIndex, 1)
							}
							else {
								return
							}
						}
						else {
							return
						}

					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else {
				return
			}
		} else {
			return;
		}

		localStorage.setItem("feedbacktree", JSON.stringify(getLocalStorage));
		console.log("getLocalStorage ends is", getLocalStorage);
		props.handleSubmit();
		localStorage.removeItem("selectToModify");
		localStorage.removeItem("modifyStatus");
		setFormdata({})
	}


	return (
		<>
			<Dialog
				className="dialog-form"
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.handleClose}
				aria-describedby={"alert-dialog-slide-" + trimData}
			>
				<DialogTitle
					className="alert-dialog-title"
					style={{ padding: "16px 24px 0 24px" }}
				>
					Delete {props.data.title}
				</DialogTitle>
				{props.data.description && (
					<DialogContentText
						className="alert-dialog-text"
						style={{ padding: "0 24px 10px 24px" }}
					>
						{props.data.description}
					</DialogContentText>
				)}
				<Divider />
				<DialogContent>
					<Box>
						<Alert severity="error">
							<AlertTitle>Are You Sure? </AlertTitle>
							Please ensure you click the Save button on the top right —{" "}
							<strong>if you would like to save your changes!</strong>
						</Alert>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button type="submit" variant="contained" color="info" onClick={props.handleClose}>
						No, Cancel
					</Button>
					<Button type="submit" variant="contained" color="error" onClick={deleteItem}>
						Yes, Delete
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ModelFormDelete;
