import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Card,
  CardActions,
  Button,
  Typography,
  Menu,
  MenuItem,
  LinearProgress,
  Chip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import ViewIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Input } from "beautiful-react-ui";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Organisation() {
  const [organisation, setOrganisation] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [dataTableData, setDataTableData] = useState([]);
  const [filterConvertedData, setFilterConvertedData] = useState([]);

  useEffect(() => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Organisations");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Organisations");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        const convertedData = response.data.map((item, index) => ({
          name: item.name,
          email: item.emailAddress,
          phone: item.phoneNumber,
          website: item.webSite,
          status: item.status,
          uid: item.uid,
        }));

        setDataTableData(convertedData);
        setFilterConvertedData(convertedData);
        setOrganisation(response.data);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Website",
      selector: (row) => row.website,
      sortable: true,
    },
    {
      name: "Status",
      // selector: (row) => row.Status,
      // sortable: true,
      cell: (row) =>
        row.status === "on" ||
        row.status === "true" ||
        row.status === "active" ? (
          <Chip label={row.status} color="success" size="small" />
        ) : (
          ""
        ),
    },
    {
      name: "Action",
      cell: (row) => (
        <Link to={"/manage/organisation/view/" + row?.uid}>
          <Button size="small" variant="outlined" startIcon={<ViewIcon />}>
            View
          </Button>
        </Link>
      ),
    },
  ];

  const handleFilterSearch = (event) => {
    const newData = filterConvertedData.filter(
      (row) =>
        (row.name ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.phone ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.website ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.email ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.status ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setDataTableData(newData);
  };

  return (
    <Box style={{ overflow: "auto", height: "65vh" }}>
      <Container style={{ paddingTop: "30px" }}>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <Typography>Organisation Details</Typography>
          <CardActions>
            <input
              type="text"
              onChange={handleFilterSearch}
              placeholder="Search"
              style={{ border: "1px solid grey", padding: '6px', marginRight: '12px', borderRadius: '4px' }}
            />
            <Link to="/manage/organisation/add">
              <Button size="small" variant="outlined" startIcon={<AddIcon />}>
                Add Organisation
              </Button>
            </Link>
          </CardActions>
        </Card>
        <TableContainer component={Paper} style={{}}>
          <DataTable pagination columns={columns} data={dataTableData} />
        </TableContainer>
      </Container>
    </Box>
  );
}

export default Organisation;
