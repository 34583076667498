import React, { useEffect, useState } from "react";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AddBoxIcon from '@mui/icons-material/AddLink';
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";
import {
	Typography,
	TextField,
	Chip,
	Stack,
	Radio,
	RadioGroup,
	FormControlLabel,
	MenuItem,
	ListItemIcon,
	Avatar,
	Button,
	Menu,
	Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModelFormEdit from "./ModelFormEdit";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import { PersonAdd, Settings } from "@mui/icons-material";

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#353535",
		color: "#d4d4d4",
		boxShadow: theme.shadows[1],
		fontSize: 14,
		padding: "10px",
	},
}));

function LabelBody(props) {
	const [modifyButtonAppear, setModifyButtonAppear] = useState({
		add: true,
		edit: true,
		delete: true,
		ioAdd: false,
	});
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};


	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		if (props.type1 === 'characteristics' || props.type1 === 'businessArea' || props.type1 === 'businessDomain') {
			setModifyButtonAppear({
				add: false,
				edit: true,
				delete: false,
				ioAdd: false,
			})
		}
		else if (props.type1 === 'controlRecord' || props.type1 === 'businessScenarios') {
			if (props.level === 'level1') {
				setModifyButtonAppear({
					add: true,
					edit: true,
					delete: false,
					ioAdd: false,
				})
			}
			else if (props.level === 'level2') {
				setModifyButtonAppear({
					add: false,
					edit: true,
					delete: true,
					ioAdd: false,
				})
			}
			else {
				return
			}
		}
		else if (props.type1 === 'behaviourQualifiers') {
			if (props.level === 'level1') {
				setModifyButtonAppear({
					add: true,
					edit: true,
					delete: false,
					ioAdd: false,
				})
			}
			else if (props.level === 'level3') {
				setModifyButtonAppear({
					add: false,
					edit: true,
					delete: true,
					ioAdd: false,
				})
			}
			else {
				return
			}
		}
		else if (props.type1 === 'serviceOperations') {
			if (props.level === 'level1') {
				setModifyButtonAppear({
					add: true,
					edit: true,
					delete: false,
					ioAdd: false,
				})
			}
			else if (props.level === 'level2') {
				setModifyButtonAppear({
					add: false,
					edit: true,
					delete: true,
					ioAdd: true,
				})
			}
			else if (props.level === 'level3') {
				setModifyButtonAppear({
					add: false,
					edit: true,
					delete: true,
					ioAdd: false,
				})
			}
			else {
				return
			}
		}
		else {
			return
		}
	}, [props.editButtonEnable]);
	var compareEnable = false;
	try {
		if (
			props.metaLight !== null &&
			props.metaLight !== "" &&
			props.metaLight !== undefined &&
			props.metaLight.hasOwnProperty("differenceLights") &&
			props.metaLight.differenceLights.length > 0
		) {
			compareEnable = true;
		}
	} catch {
		compareEnable = false;
	}

	// {/* <Box className="d_tree_wrapper" key={props.nodeId} style={{border: compareEnable ? '3px solid green' : '3px solid #fff'}}> */ }
	return (
		<>
			<Box className="d_tree_wrapper" key={props.nodeId}>
				<Box className="d_tree_container">
					<Box className="d_tree_container_items">
						<DragHandleIcon
							fontSize="small"
							className="d_tree_container_drag"
						/>
						<Box>
							<Typography variant="text" className="d_tree_container_text">
								{props.title}{" "}
							</Typography>
							{props.showSubTitle == "true" && (
								<Typography variant="text" className="d_tree_container_second">
									{props.type1}
								</Typography>
							)}
							{props.description !== "" &&
								props.description !== null &&
								props.description !== undefined && (
									<LightTooltip title={props.description} placement="top">
										<Typography
											className="oneLineText"
											variant="body2"
											color="text.secondary"
										>
											{props.description}{" "}
										</Typography>
									</LightTooltip>
								)}
							<Stack direction="row" spacing={1}>
								{props.status !== "" &&
									props.status !== undefined &&
									props.type1 !== "serviceOperations" &&
									props.level !== 'level2' &&
									props.status !== null && (
										<Chip
											title={"Status : " + props.status}
											label={props.status}
											color="primary"
											size="small"
										/>
									)}
								{props.data_type !== "" &&
									props.data_type !== undefined &&
									props.data_type !== null && (
										<Chip
											title={"Data Type : " + props.data_type}
											label={props.data_type}
											color="info"
											size="small"
										/>
									)}
								{compareEnable &&
									props.metaLight.differenceLights.map(
										(chipName) =>
											chipName.uid &&
											chipName.change &&
											chipName.name && (
												<Chip
													key={chipName.uid}
													title={
														"Compare Status : " +
														chipName.change +
														" of " +
														chipName.name
													}
													label={chipName.change}
													color="warning"
													size="small"
												/>
											)
									)}
								{console.log(
									" props.changeStatusText =>",
									props.changeStatusText
								)}
								{props.changeStatusText != "Dont" && (
									<Chip
										title="Compare Change"
										label="Change"
										color="warning"
										size="small"
									/>
								)}
							</Stack>
						</Box>
					</Box>
					<Box>
						{/* {props.editEnabled == "true" && props.editButtonEnable && (
              <DeleteIcon
                color="error"
                sx={{mx:1}}
                onClick={() => {
                  console.log("modal open and comments is null", props);
                  localStorage.setItem(
                    "selectToModify",
                    JSON.stringify({
                      uid: props.editId,
                      editId: props.editId,
                      title: props.title,
                      description: props.description,
                      status: props.status,
                      data_type: props.data_type,
                      type1: props.type1,
                      type2: props.type2,
                      type3: props.type3,
                      level: props.level,
                      comments: props.metaLight?.comments ? props.metaLight?.comments : [],
                      meta: props.meta,
                      metaLight: props.metaLight,
                    })
                  );
                  props.modalDeleteOpen();
                }}
                fontSize="small"
                className="d_tree_container_edit"
              />
            )} */}
						{props.editButtonEnable && (
							<Box>
								<EditIcon
									id="basic-button"
									aria-controls={open ? 'basic-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									onClick={event => {
										console.log("modal open and comments is null", props);
										localStorage.setItem(
											"selectToModify",
											JSON.stringify({
												uid: props.editId,
												editId: props.editId,
												title: props.title,
												description: props.description,
												status: props.status,
												data_type: props.data_type,
												type1: props.type1,
												type2: props.type2,
												type3: props.type3,
												level: props.level,
												comments: props.metaLight?.comments ? props.metaLight?.comments : [],
												meta: props.meta,
												metaLight: props.metaLight,
												commentUser: props.commentUser,
												ioType: ''
											})
										);
										handleClick(event);
									}}
									fontSize="small"
									sx={{ cursor: "pointer" }}
								/>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
								>
									{modifyButtonAppear.add && <MenuItem
										onClick={() => {
											localStorage.setItem("modifyStatus", "add-feedback");
											props.modalOpen();
											handleClose();
										}}
									>
										<Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
											<AddIcon fontSize="small" sx={{ mr: 1 }} color="primary" />
											<Typography variant="span" component="spam">Add</Typography>
										</Box>
									</MenuItem>}
									{modifyButtonAppear.ioAdd && <MenuItem
										onClick={() => {
											localStorage.setItem("modifyStatus", "add-feedback");
											let io_type = JSON.parse(localStorage.getItem("selectToModify"));
											io_type.ioType = "input"
											localStorage.setItem("selectToModify", JSON.stringify(io_type))
											props.modalOpen();
											handleClose();
										}}
									>
										<Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
											<AddBoxIcon fontSize="small" sx={{ mr: 1 }} color="primary" />
											<Typography variant="span" component="spam">Add Input Attribute</Typography>
										</Box>
									</MenuItem>}
									{modifyButtonAppear.ioAdd && <MenuItem
										onClick={() => {
											localStorage.setItem("modifyStatus", "add-feedback");
											let io_type = JSON.parse(localStorage.getItem("selectToModify"));
											io_type.ioType = "output"
											localStorage.setItem("selectToModify", JSON.stringify(io_type))
											props.modalOpen();
											handleClose();
										}}
									>
										<Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
											<AddIcon fontSize="small" sx={{ mr: 1 }} color="primary" />
											<Typography variant="span" component="spam">Add Output Attribute</Typography>
										</Box>
									</MenuItem>}
									{modifyButtonAppear.edit && <MenuItem
										onClick={() => {
											localStorage.setItem("modifyStatus", "edit-feedback");
											props.modalOpen();
											handleClose();
										}}
									>
										<Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
											<EditIcon fontSize="small" sx={{ mr: 1 }} color="info" />
											<Typography variant="span" component="spam">Edit</Typography>
										</Box>
									</MenuItem>}

									{modifyButtonAppear.delete && <MenuItem
										onClick={() => {											
											localStorage.setItem("modifyStatus", "delete-feedback");
											let io_type = JSON.parse(localStorage.getItem("selectToModify"));
											io_type.ioType = props.type2
											io_type.parentId = props.parentId
											localStorage.setItem("selectToModify", JSON.stringify(io_type))
											props.modalOpen();
											handleClose();
										}}
									>
										<Box sx={{ display: 'flex', justifyContent: "start", alignItems: "center", width: "100% !important" }}>
											<DeleteIcon fontSize="small" sx={{ mr: 1 }} color="error" />
											<Typography variant="span" component="spam">Delete</Typography>
										</Box>
									</MenuItem>}
								</Menu>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
}

export default LabelBody;
