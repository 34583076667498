import React, { useState, useEffect } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
  SvgIcon,
  Collapse,
  Typography,
  Divider,
  Box,
  Button,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  RadioGroup,
  List
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux'
import { useSpring, animated } from "react-spring";
import { Route, Routes, useLocation, Link, useNavigate as useHistory } from 'react-router-dom';
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import LabelBody from "./DomainLabel";
import { DragDropContext } from "react-beautiful-dnd";
import axios from "axios";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = styled((props) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function Domain(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  const [treeItem, setTreeItem] = useState({});
  const [skeleton, setSkeleton] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const allCollection = useSelector((state) => state?.common?.allCollection);

  const [modalOpenData, setModalOpenData] = useState({
    title: "Title",
    description: "Description",
    status: "Status",
    data_type: "String",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleModalClickOpen = () => {
    var getItem = JSON.parse(localStorage.getItem("selectToModify"));
    setModalOpenData(getItem);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleTreeSubmit = () => {
    var submitDta = JSON.parse(localStorage.getItem("servicetree"));
    var submitToken = "Bearer " + localStorage.getItem("jwtToken");

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl =
        process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainUpdate");

    axios({
      method: "put",
      url: axiosUrl,
      data: submitDta,
      headers: { Authorization: submitToken },
    }).then((res) => {
      const response = res.data;
      console.log("response is from axios api ", response);
    });

    setOpen(false);
  };

  useEffect(() => {
    if (
      props.serviceUid !== undefined &&
      props.serviceUid !== null &&
      props.serviceUid !== ""
    ) {
      let axiosUrl = "";
      process.env.REACT_APP_VERSION === "S1" &&
        (axiosUrl =
          process.env.REACT_APP_MODEL_ACCESS_S1 +
          "ServiceDomainByUID/" +
          props.serviceUid);
      process.env.REACT_APP_VERSION === "S2" &&
        (axiosUrl =
          process.env.REACT_APP_MODEL_ACCESS_S2 +
          "ServiceDomainByUID/" +
          props.serviceUid);

      axios({
        method: "get",
        url: axiosUrl,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      }).then((res) => {
        setTreeItem(res.data);
        localStorage.setItem("servicetree", JSON.stringify(res.data));
        props.callbackFunction(res.data);
        setSkeleton(false);
      });
    }
  }, [props.serviceUid]);

  const radioSkeletons = [];
  for (let i = 1; i <= 10; i++) {
    radioSkeletons.push(i);
  }

  const selectBusinessScenario = (attrmapName) => {
    dispatch({ type: "SELECT_TAB", payload: 'business-scenario' })
    props.changeTabVal('business-scenario', props.organisation, props.version, attrmapName.name.replaceAll(' ', '-'))
    history(`/dashboard/business-scenario-services/business-scenario`);
  }

  const selectDataType = (selectedDataType) => {
    if (selectedDataType.boType === "PrimitiveType") {
      dispatch({ type: "SELECT_TAB", payload: 'primitive-type' })
      dispatch({ type: "PRIMITIVE_DATA_TYPE_VAL", payload: selectedDataType })
      history(`/dashboard/business-scenario-services/primitive-type`);

    } else if (selectedDataType.boType === "Class") {
      dispatch({ type: "SELECT_TAB", payload: 'classes' })
      dispatch({ type: "CLASS_VAL", payload: selectedDataType })
      history(`/dashboard/business-scenario-services/classes`);

    } else if (selectedDataType.boType === "Enumeration") {
      dispatch({ type: "SELECT_TAB", payload: 'enumerations' })
      dispatch({ type: "ENUMRATION_VAL", payload: selectedDataType })
      history(`/dashboard/business-scenario-services/enumerations`);

    } else {
      dispatch({ type: "SELECT_TAB", payload: 'data-type' })
      dispatch({ type: "DATA_TYPE_VAL", payload: selectedDataType })
      history(`/dashboard/business-scenario-services/data-type`);

    }
  }

  const callSymanticUI = (name) => {
    const reqObj = {
      "CustomerReference": {},
      "BankBranchOrLocationReference": {},
      "AccountType": {},
      "AccountCurrency": "string",
      "TaxReference": {},
      "EntitlementOptionSetting": "string",
      "RestrictionOptionSetting": "string",
      "Associations": "string",
      "AssociationType": "string",
      "AssociationObligationOrEntitlement": "string",
      "AssociationReference": {},
      "LinkedAccounts": "string",
      "LinkType": "string",
      "AccountDetails": "string",
      "PositionLimits": "string",
      "PositionLimitSettings": "string",
      "DateType": "string"
    }
    axios({
      method: "post",
      data: reqObj,
      url: `https://virtserver.swaggerhub.com/BIAN-3/${name.replaceAll(" ", "")}/10.0.0/${name.replaceAll(" ", "")}/Initiate`,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtToken"),
      },
    }).then((res) => {
      console.log("111111111111111111111111111111111", res)
    });
  }

  return (
    <>
      {!skeleton && (
        <Box>
          <Box>
            <Box
              style={{
                padding: "5px 10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography gutterBottom variant="h5" component="div">
                  {treeItem.name}
                </Typography>
                <p style={{ color: "red", textAlign: "right" }} onClick={() => callSymanticUI(treeItem.name)}>Semantic API</p>
                {treeItem.description != null && (
                  <Typography variant="body2" color="text.secondary">
                    {treeItem.description}
                  </Typography>
                )}
              </Box>
            </Box>
            <Divider style={{ margin: "0 0 8px 0" }} />
          </Box>

          <DragDropContext>
            <TreeView
              aria-label="customized"
              defaultExpanded={["1"]}
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
              style={{ cursor: 'pointer' }}
            >
              {/* <Droppable>
                              {(provided, snapshot) => ( */}
              <div>
                {/* 1st Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="1"
                  key="1"
                  label={
                    <LabelBody
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        <div style={{ display: 'inline-flex' }}>{treeItem?.characteristics?.functionalPattern
                          ? treeItem?.characteristics?.functionalPattern : ''}
                          <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>characteristics</div>
                        </div>
                      }
                      description={treeItem?.characteristics?.assetType}
                      sec-desc={treeItem?.characteristics?.genericArtefactType}
                      status={treeItem?.characteristics?.status}
                      data_type={treeItem?.characteristics?.dataType}
                      type1="characteristics"
                      type2=""
                      type3=""
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId={treeItem?.characteristics?.uid}
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.characteristics?.meta}
                    />
                  }
                />
                {/* 1st Item:End */}

                {/* 2nd Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="2"
                  key="2"
                  label={
                    <LabelBody
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        // treeItem?.businessArea?.name
                        //   ? treeItem?.businessArea?.name
                        //   : "Business Domain"
                        <div style={{ display: 'inline-flex' }}>{treeItem?.businessArea?.name
                          ? treeItem?.businessArea?.name : ''}
                          <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>businessArea</div>
                        </div>
                      }
                      description={treeItem?.businessArea?.description}
                      sec-desc=""
                      status={treeItem?.businessArea?.status}
                      data_type={treeItem?.businessArea?.dataType}
                      type1="businessArea"
                      type2=""
                      type3=""
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId={treeItem?.businessArea?.uid}
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.businessArea?.meta}
                    />
                  }
                />
                {/* 2nd Item:End */}

                {/* 3rd Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="3"
                  key="3"
                  label={
                    <LabelBody
                      modalOpen={handleModalClickOpen}

                      modalClose={handleModalClose}
                      title={
                        // treeItem?.businessDomain?.name
                        //   ? treeItem?.businessDomain?.name
                        //   : "Business Domain"

                        <div style={{ display: 'inline-flex' }}>{treeItem?.businessDomain?.name
                          ? treeItem?.businessDomain?.name : ''}
                          <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>businessDomain</div>
                        </div>
                      }
                      description={treeItem?.businessDomain?.description}
                      sec-desc=""
                      status={treeItem?.businessDomain?.status}
                      data_type={treeItem?.businessDomain?.dataType}
                      type1="businessDomain"
                      type2=""
                      type3=""
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId={treeItem?.businessDomain?.uid}
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.businessDomain?.meta}
                    />
                  }
                />
                {/* 3rd Item:End */}
                {/* 4th Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="4"
                  key="4"
                  label={
                    <LabelBody
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        // treeItem.controlRecord?.name
                        //   ? treeItem.controlRecord?.name
                        //   : "Control Record"

                        <div style={{ display: 'inline-flex' }}>{treeItem?.controlRecord?.name
                          ? treeItem?.controlRecord?.name : ''}
                          <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>controlRecord</div>
                        </div>
                      }
                      description={treeItem?.controlRecord?.description}
                      sec-desc=""
                      status={treeItem?.controlRecord?.status}
                      data_type={treeItem?.controlRecord?.dataType}
                      type1="controlRecord"
                      type2="22222222222222"
                      type3="33333333333333333333"
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId={treeItem?.controlRecord?.uid}
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.controlRecord?.meta}
                    />
                  }
                >
                  {treeItem?.controlRecord?.attributes?.length > 0 &&
                    treeItem?.controlRecord?.attributes.map((attrmap) => (
                      <StyledTreeItem
                        style={{ border: "3px solid #fff" }}
                        nodeId={"4" + attrmap?.id}
                        key={"4" + attrmap?.id}
                        onClick={() => selectDataType(attrmap)}

                        label={
                          <>
                            <LabelBody
                              modalOpen={handleModalClickOpen}
                              modalClose={handleModalClose}
                              title={<div style={{ cursor: 'pointer' }}> {attrmap.name}</div>}
                              // title={attrmap.name}
                              description={<div>{attrmap.description}</div>}
                              sec-desc={attrmap.dataType}
                              status={attrmap?.status}
                              data_type={<><div style={{ cursor: 'pointer' }}>{attrmap?.dataType}</div></>}
                              data_type2={attrmap?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap?.boType}</div></> : null}
                              type1="controlRecord"
                              type2=""
                              type3=""
                              serORfeed="service"
                              group_name="radio-buttons-group-service"
                              level="level2"
                              editId={attrmap?.uid}
                              editEnabled="false"
                              showCompareRadio="false"
                              meta={attrmap?.meta}
                            />
                          </>
                        }
                      />
                    ))}
                </StyledTreeItem>
                {/* 4th Item:End */}

                {/* 5th Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="5"
                  key="5"
                  label={
                    <LabelBody
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        <div style={{ display: 'inline-flex' }}>Behaviour Qualifiers
                          <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>behaviourQualifiers</div>
                        </div>}
                      description=""
                      sec-desc=""
                      status=""
                      data_type=""
                      type1="behaviourQualifiers"
                      type2=""
                      type3=""
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId=""
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.behaviourQualifiers?.meta}
                    />
                  }
                >
                  {treeItem?.behaviourQualifiers?.length > 0 &&
                    treeItem?.behaviourQualifiers?.map((attrmap) => (
                      <StyledTreeItem
                        style={{ border: "3px solid #fff" }}
                        nodeId={"5-" + attrmap?.id}
                        key={"5-" + attrmap?.id}
                        label={
                          <LabelBody
                            modalOpen={handleModalClickOpen}
                            modalClose={handleModalClose}
                            title={attrmap.name}
                            description={attrmap.description}
                            sec-desc=""
                            status={attrmap?.status}
                            data_type={attrmap?.dataType}
                            data_type2={attrmap?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap?.boType}</div></> : null}
                            type1="behaviourQualifiers"
                            type2=""
                            type3=""
                            serORfeed="service"
                            group_name="radio-buttons-group-service"
                            level="level2"
                            editId={attrmap?.uid}
                            editEnabled="false"
                            showCompareRadio="false"
                            meta={attrmap?.meta}
                          />
                        }
                      >
                        {attrmap.attributes?.length > 0 &&
                          attrmap.attributes.map((attrmap2) => (
                            <StyledTreeItem
                              style={{ border: "3px solid #fff" }}
                              nodeId={"5-" + attrmap?.id + "-" + attrmap2?.id}
                              key={"5-" + attrmap?.id + "-" + attrmap2?.id}
                              label={
                                <>
                                  <LabelBody
                                    modalOpen={handleModalClickOpen}
                                    modalClose={handleModalClose}
                                    title={attrmap2.name}
                                    description={attrmap2.description}
                                    sec-desc=""
                                    status={attrmap2?.status}
                                    data_type={attrmap2?.dataType}
                                    data_type2={attrmap2?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap2?.boType}</div></> : null}
                                    type1="behaviourQualifiers"
                                    type2=""
                                    type3=""
                                    serORfeed="service"
                                    group_name="radio-buttons-group-service"
                                    level="level3"
                                    editId={attrmap2?.uid}
                                    editEnabled="false"
                                    showCompareRadio="false"
                                    meta={attrmap2?.meta}
                                  />
                                </>

                              }
                            />
                          ))}
                      </StyledTreeItem>
                    ))}
                </StyledTreeItem>
                {/* 5th Item:End */}

                {/* 6th Item:Start */}
                <StyledTreeItem
                  style={{ border: "3px solid #fff" }}
                  nodeId="6"
                  key="6"
                  label={
                    <LabelBody
                      modalOpen={handleModalClickOpen}
                      modalClose={handleModalClose}
                      title={
                        <div style={{ display: 'inline-flex' }}>Service Operations
                          <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>serviceOperations</div>
                        </div>}
                      description=""
                      sec-desc=""
                      status=""
                      data_type=""
                      type1="serviceOperations"
                      type2=""
                      type3=""
                      serORfeed="service"
                      group_name="radio-buttons-group-service"
                      level="level1"
                      editId=""
                      editEnabled="false"
                      showCompareRadio="false"
                      meta={treeItem?.serviceOperations?.meta}
                    />
                  }
                >
                  {treeItem?.serviceOperations?.length > 0 &&
                    treeItem?.serviceOperations?.map((attrmap) => (
                      <StyledTreeItem
                        style={{ border: "3px solid #fff" }}
                        nodeId={"6-" + attrmap?.id}
                        key={"6-" + attrmap?.id}
                        label={
                          <>
                            <LabelBody
                              modalOpen={handleModalClickOpen}
                              modalClose={handleModalClose}
                              title={attrmap.name}
                              description={attrmap.description}
                              sec-desc=""
                              status={attrmap?.status}
                              data_type={attrmap?.dataType}
                              data_type2={attrmap?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap?.boType}</div></> : null}
                              type1="serviceOperations"
                              type2=""
                              type3=""
                              serORfeed="service"
                              group_name="radio-buttons-group-service"
                              level="level2"
                              editId={attrmap?.uid}
                              editEnabled="false"
                              showCompareRadio="false"
                              meta={attrmap?.meta}
                            />
                          </>

                        }
                      >
                        {attrmap.inputAttributes?.length > 0 && (
                          <Box
                            style={{ border: "1px solid rgba(0, 0, 0, 0.12)" }}
                          >
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h6"
                              style={{ textAlign: "center" }}
                            >
                              Input Attributes
                            </Typography>
                            <Divider style={{ margin: "8px 0" }} />

                            {attrmap.inputAttributes?.length > 0 &&
                              attrmap.inputAttributes.map((attrmap2) => (
                                <StyledTreeItem
                                  style={{ border: "3px solid #fff" }}
                                  nodeId={"6-1-" + attrmap2?.id}
                                  key={"6-1-" + attrmap2?.id}
                                  label={
                                    <LabelBody
                                      modalOpen={handleModalClickOpen}
                                      modalClose={handleModalClose}
                                      title={attrmap2.name}
                                      description={attrmap2.description}
                                      sec-desc=""
                                      status={attrmap2?.status}
                                      data_type={attrmap2?.dataType}
                                      data_type2={attrmap2?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap2?.boType}</div></> : null}
                                      type1="serviceOperations"
                                      type2="inputAttributes"
                                      type3=""
                                      serORfeed="service"
                                      group_name="radio-buttons-group-service"
                                      level="level3"
                                      editId={attrmap2?.uid}
                                      editEnabled="false"
                                      showCompareRadio="false"
                                      meta={attrmap2?.meta}
                                    />
                                  }
                                />
                              ))}
                          </Box>
                        )}
                        {attrmap.outputAttributes?.length > 0 && (
                          <Box
                            style={{
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                              marginTop: "5px",
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h6"
                              style={{ textAlign: "center" }}
                            >
                              Output Attributes
                            </Typography>
                            <Divider style={{ margin: "8px 0" }} />

                            {attrmap.outputAttributes?.length > 0 &&
                              attrmap.outputAttributes.map((attrmap2) => (
                                <StyledTreeItem
                                  style={{ border: "3px solid #fff" }}
                                  nodeId={"6-2-" + attrmap2?.id}
                                  key={"6-2-" + attrmap2?.id}
                                  label={
                                    <LabelBody
                                      modalOpen={handleModalClickOpen}
                                      modalClose={handleModalClose}
                                      title={attrmap2.name}
                                      description={attrmap2.description}
                                      sec-desc=""
                                      status={attrmap2?.status}
                                      data_type={attrmap2?.dataType}
                                      data_type2={attrmap2?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap2?.boType}</div></> : null}
                                      type1="serviceOperations"
                                      type2="outputAttributes"
                                      type3=""
                                      serORfeed="service"
                                      group_name="radio-buttons-group-service"
                                      level="level3"
                                      editId={attrmap2?.uid}
                                      editEnabled="false"
                                      showCompareRadio="false"
                                      meta={attrmap2?.meta}
                                    />
                                  }
                                />
                              ))}
                          </Box>
                        )}
                      </StyledTreeItem>
                    ))}
                </StyledTreeItem>
                {/* 6th Item:End */}

                {/* 7rd Item:Start */}
                {treeItem.businessScenarios?.length !== 0 && (
                  <List component="nav" aria-label="main mailbox folders" key={1} style={{ cursor: 'pointer' }}>
                    <StyledTreeItem
                      style={{ border: "3px solid #fff", cursor: 'pointer' }}
                      nodeId="7"
                      key="7"

                      label={
                        <LabelBody
                          modalOpen={handleModalClickOpen}
                          modalClose={handleModalClose}
                          style={{ cursor: 'pointer' }}

                          title={
                            // ""
                            <div style={{ display: 'inline-flex' }}>Business Scenarios
                              <div style={{ paddingLeft: "10px", color: "#c2bebe" }}>businessScenarios</div>
                            </div>
                          }
                          description=""
                          sec-desc=""
                          status=""
                          data_type=""
                          type1="businessScenarios"
                          type2=""
                          type3=""
                          serORfeed="service"
                          group_name="radio-buttons-group-service"
                          level="level1"
                          editId=""
                          editEnabled="false"
                          showCompareRadio="false"
                          meta={treeItem?.businessScenarios?.meta}
                        />
                      }
                    >

                      {treeItem?.businessScenarios?.length > 0 &&
                        treeItem?.businessScenarios?.map((attrmap) => (
                          // <Link to={`/dashboard/business-scenario/${props.organisation}/${props.version}/${attrmap.name.replaceAll(' ','-')}`} style={{ display: "flex", color: "black", alignItems: 'center' }}>

                          <StyledTreeItem
                            style={{ border: "3px solid #fff", cursor: 'pointer' }}
                            nodeId={"7" + attrmap?.id}
                            onClick={() => selectBusinessScenario(attrmap)}

                            key={"7" + attrmap?.id}
                            label={
                              <LabelBody
                                modalOpen={handleModalClickOpen}
                                modalClose={handleModalClose}
                                title={<div style={{ cursor: 'pointer' }}>{attrmap.name}</div>}
                                style={{ cursor: 'pointer', width: '100%', height: '100%' }}
                                description={attrmap.description}
                                sec-desc={attrmap.dataType}
                                status={attrmap?.status}
                                data_type={attrmap?.dataType}
                                data_type2={attrmap?.boType ? <><div style={{ cursor: 'pointer' }}>{attrmap?.boType}</div></> : null}
                                type1="businessScenarios"
                                type2=""
                                type3=""
                                serORfeed="service"
                                group_name="radio-buttons-group-service"
                                level="level2"
                                editId={attrmap?.uid}
                                editEnabled="false"
                                showCompareRadio="false"
                                meta={attrmap?.meta}
                              />
                            }
                          />

                          //  </Link>
                        ))}
                    </StyledTreeItem>
                  </List>
                )}
                {/* 7rd Item:End */}
              </div>
              {/* )}
                         </Droppable> */}
            </TreeView>
          </DragDropContext>

          {/* <ModelFormEdit data={modalOpenData} open={modalOpen} handleClose={handleModalClose} />
               <Divider style={{ margin: '8px 0' }} />

               <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '8px 0' }}>
                    <Button variant="contained" color="info" endIcon={<CheckIcon />} style={{ marginLeft: '5px' }} onClick={handleClickOpen}>
                         Submit
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                         <DialogTitle>{treeItem.name}</DialogTitle>
                         <Divider />
                         <DialogContent>
                              <DialogContentText>
                                   Are you sure you you want to sumbit this changes for {treeItem.name} in database
                              </DialogContentText>
                         </DialogContent>
                         <DialogActions>
                              <Button onClick={handleClose}>Cancel</Button>
                              <Button onClick={handleTreeSubmit}>Confirm</Button>
                         </DialogActions>
                    </Dialog>
               </Box> */}
        </Box>
      )
      }

      {
        skeleton && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <Skeleton sx={{ flexGrow: "1" }} height={40} variant="rectangular" />
            <Divider style={{ margin: "8px 0" }} />
          </Box>
        )
      }

      {
        skeleton && (
          <Box sx={{ padding: "10px 15px" }}>
            {radioSkeletons.map((index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
                key={"skeletonKey" + index}
              >
                <Skeleton
                  variant="circular"
                  width={20}
                  height={20}
                  sx={{ marginRight: "7px" }}
                />
                <Skeleton
                  sx={{ flexGrow: "1" }}
                  height={20}
                  variant="rectangular"
                />
              </Box>
            ))}
          </Box>
        )
      }
    </>
  );
}

export default Domain;
