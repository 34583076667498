import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Card,
  CardActions,
  Button,
  Typography,
  Menu,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import ViewIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Input } from "beautiful-react-ui";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Roles() {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [convertedData, setConvertedData] = useState([]);
  const [filterConvertedData, setFilterConvertedData] = useState([]);

  useEffect(() => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Roles");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Roles");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setRoles(response.data);
        const convertedData = response.data.map((item, index) => ({
          name: item.name,
          displayName: item.displayName,
          description: item.description,
          uid: item.uid,
        }));
        setConvertedData(convertedData);
        setFilterConvertedData(convertedData);
        setError(false);
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText("Unable to retrieve Roles information From Server");
      });
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Display Name",
      selector: (row) => row.displayName,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Link to={"/manage/user/view/" + row?.uid}>
          <Button
            style={{ margin: "0 5px" }}
            size="small"
            variant="outlined"
            startIcon={<ViewIcon />}
          >
            View
          </Button>
        </Link>
      ),
    },
  ];

  const handleFilterSearch = (event) => {
    const newData = filterConvertedData.filter(
      (row) =>
        (row.name ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.displayName ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        (row.description ?? "")
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setConvertedData(newData);
  };

  return (
    <Box style={{ overflow: "auto", height: "65vh" }}>
      <Container style={{ paddingTop: "30px" }}>
        <Card
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <Typography>Roles Details</Typography>
          <CardActions>
            <input
              type="text"
              onChange={handleFilterSearch}
              placeholder="Search"
              style={{ border: "1px solid grey", padding: '6px', marginRight: '12px', borderRadius: '4px' }}
            />
            <Link to="/manage/role/add">
              <Button size="small" variant="outlined" startIcon={<AddIcon />}>
                Add Roles
              </Button>
            </Link>
          </CardActions>
        </Card>
        <TableContainer component={Paper} style={{}}>
          <DataTable pagination columns={columns} data={convertedData} />
        </TableContainer>
      </Container>
    </Box>
  );
}

export default Roles;
