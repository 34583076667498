import React, { useState } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import {
  Typography,
  TextField,
  Chip,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModelFormEdit from "./ModelFormEdit";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#353535",
    color: "#d4d4d4",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: "10px",
  },
}));

function LabelBody(props) {
  var compareEnable = false;
  try {
    if (
      props.metaLight !== null &&
      props.metaLight !== "" &&
      props.metaLight !== undefined &&
      props.metaLight.hasOwnProperty("differenceLights") &&
      props.metaLight.differenceLights.length > 0
    ) {
      compareEnable = true;
    }
  } catch {
    compareEnable = false;
  }

  // {/* <Box className="d_tree_wrapper" key={props.nodeId} style={{border: compareEnable ? '3px solid green' : '3px solid #fff'}}> */ }
  return (
    <>
      <Box className="d_tree_wrapper" key={props.nodeId}>
        <Box className="d_tree_container">
          <Box className="d_tree_container_items">
            <DragHandleIcon
              fontSize="small"
              className="d_tree_container_drag"
            />
            <Box>
              <Typography variant="text" className="d_tree_container_text">
                {props.title}{" "}
              </Typography>
              {props.editEnabled == "false" && props.serORfeed == "feedback" && (
                <Typography variant="text" className="d_tree_container_second">
                  {props.type1}
                </Typography>
              )}
              {props.description !== "" &&
                props.description !== null &&
                props.description !== undefined && (
                  <LightTooltip title={props.description} placement="top">
                    <Typography
                      className="oneLineText"
                      variant="body2"
                      color="text.secondary"
                    >
                      {props.description}{" "}
                    </Typography>
                  </LightTooltip>
                )}
              <Stack direction="row" spacing={1}>
                {/*{/* {props.status !== "" &&
                  props.status !== undefined &&
                  props.status !== null && (
                    <Chip
                      title={"Status : " + props.status}
                      label={props.status}
                      color="primary"
                      size="small"
                    />
                  )} */}
                {props.data_type !== "" &&
                  props.data_type !== undefined &&
                  props.data_type !== null && (
                    <Chip
                      title={"Data Type : " + props.data_type}
                      label={props.data_type}
                      color="info"
                      size="small"
                    />
                  )}
                {props.data_type2 !== "" &&
                  props.data_type2 !== undefined &&
                  props.data_type2 !== null && (
                    <Chip
                      title={"Data Type : " + props.data_type2}
                      label={props.data_type2}
                      style={{ backgroundColor: "#ed6c02", color: "white" }}
                      size="small"
                    />
                  )}
                {compareEnable &&
                  props.metaLight.differenceLights.map(
                    (chipName) =>
                      chipName.uid &&
                      chipName.change &&
                      chipName.name && (
                        <Chip
                          key={chipName.uid}
                          title={
                            "Compare Status : " +
                            chipName.change +
                            " of " +
                            chipName.name
                          }
                          label={chipName.change}
                          color="warning"
                          size="small"
                        />
                      )
                  )}
              </Stack>
            </Box>
          </Box>

          {props.editEnabled === "true" && (
            <EditIcon
              onClick={() => {
                console.log("modal open and comments is null", props)
                localStorage.setItem(
                  "selectToModify",
                  JSON.stringify({
                    uid: props.editId,
                    editId: props.editId,
                    title: props.title,
                    description: props.description,
                    status: props.status,
                    data_type: props.data_type,
                    type1: props.type1,
                    type2: props.type2,
                    type3: props.type3,
                    level: props.level,
                    comments: props.meta?.comments ? props.meta?.comments : [],
                    meta: props.meta,
                    metaLight: props.metaLight,
                  })
                );
                props.modalOpen();
              }}
              fontSize="small"
              className="d_tree_container_edit"
            />
          )}
        </Box>
      </Box>
    </>
  );
}

export default LabelBody;
