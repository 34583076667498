import React, { useState, useEffect, useLayoutEffect, useContext } from "react";
import Navbar from "./Header/Navbar";
import LogoBar from "./Header/LogoBar";
import "./dashboard.css";
import { withLogged } from "../utils/withAuth";
import DashboardContent from "./DashboardContent";
import Sample from "./../Sample";
import CloseIcon from "@mui/icons-material/Close";
import GoBack from "../utils/GoBack";
import GitHubIcon from "@mui/icons-material/GitHub";
import {
	Route,
	Routes,
	useLocation,
	useNavigate as useHistory,
} from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Behaviour from "./Behaviour/Behaviour";
import Control from "./Control/Control";
import Operations from "./Operation/Operations";
import Service from "./Service/Service";
import BusinessScenario from "./BusinessScenario/Service";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessScenarioService from "./BusinessScenarioService/Service";
import BusinessObject from "./BusinesObject/Service";
import BusinessClasses from "./BusinessClasses/Service";
import BODataTypes from "./BODataType/Service";
import BOEnumeration from "./BOEnumeration/Service";
import { useDispatch, useSelector } from "react-redux";
import BOPrimitiveType from "./BOPrimitiveType/Service";
import CreateNewItem from "../../pages/Dashboard/CreateNewItem";
import BusinessScenarioHelp from "./Service/help/BusinessScenario_v1";
import ServicesHelp from "./Service/help/ServiceDomain";
import DatatypeHelp from "./Service/help/DataType_v1";
import ClassHelp from "./Service/help/Class_v1";
import Enumerationelp from "./Service/help/Enumeration_v1";
import PrimitiveTypeHelp from "./Service/help/PrimitiveType_v1";
import GettingStartedHelp from "./Service/help/GettingStarted_v1";
import SandboxAPIHelpHelp from "./Service/help/Sandbox_API_Help_v1";
import LiveboxAPIHelpHelp from "./Service/help/Live_API_Help_v1";
import DashboardHelp from "./Service/help/Dashboard_v1";
import EmailIcon from "@mui/icons-material/Email";
import BookIcon from "@mui/icons-material/Book";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LandscapeRoundedIcon from "@mui/icons-material/LandscapeRounded";
import LinkIcon from "@mui/icons-material/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PsychologyAltIcon from "@mui/icons-material/DeviceUnknown";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import { Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Menu from "@mui/material/Menu";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper, { PaperProps } from "@mui/material/Paper";
import { ResizableBox } from "react-resizable";
import withAppInsights from "./../../AppInsights";
import Draggable from "react-draggable";
import {
	useAppInsightsContext,
	useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { AppInsightsContextProvider } from "../../AppInsightsContex";
import QnAs from "../../pages/QnA/QnA";

function PaperComponent(props) {
	return (
		<Draggable cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}
const drawerWidth = 220;

const styles = (theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginLeft: 12,
		marginRight: 36,
	},
	menuButtonIconClosed: {
		transition: theme.transitions.create(["transform"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		transform: "rotate(0deg)",
	},
	menuButtonIconOpen: {
		transition: theme.transitions.create(["transform"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		transform: "rotate(180deg)",
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing.unit * 7 + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing.unit * 9 + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing.unit,
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing.unit * 3,
	},
	grow: {
		flexGrow: 1,
	},
});

function DashboardInitial() {
	const dispatch = useDispatch();
	const location = useLocation();
	let history = useHistory();

	const [serviceOrg, setserviceOrg] = useState({});
	const [feedOrg, setfeedOrg] = useState({});
	const [selectedNav, setselectedNav] = useState("services");
	const [tabVal, setTabVal] = useState(null);
	const [hideVal, setHideVal] = useState(false);
	const [openTabVal, setOpenTabVal] = useState("");
	const [open, setOpen] = useState(false);
	const [expand, setExpand] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [helpPage, setHelpPage] = useState(false);
	const [mailPopup, setMailPopup] = useState(false);
	const appInsights = useAppInsightsContext();
	const callDashboard = useTrackMetric(
		appInsights,
		"Dashboard Insights testing"
	);

	const selectedTabVal = useSelector((state) => state?.common?.selectedTab);

	const changeOrganization = (type, data) => {
		type === "service" && setserviceOrg(data);
		type === "feedback" && setfeedOrg(data);

		if (type === "feedback" && tabVal === "business-scenario") {
			dispatch({ type: "SELECTED_FEEDBACK_DOMAIN_VAL", payload: data });
		}
	};

	const changeTab = (name) => {
		setTabVal(name);
	};

	useLayoutEffect(() => {
		// if (!tabVal && location.pathname === '/dashboard/business-scenario-services') {
		//      setTabVal('business-scenario')
		//      setHideVal(false)
		// } else if (location.pathname === '/dashboard/business-scenario-services') {
		//      setHideVal(false)
		// }
		if (location.pathname === "/dashboard") {
			setHideVal(true);
		}
		const pathNameVal = location.pathname.split("/");
		if (pathNameVal.length >= 2) {
			if (pathNameVal[2] === "help") {
				setHelpPage(true);
			} else {
				setHelpPage(false);
			}
		}
	}, [location]);

	useEffect(() => {
		callDashboard();
		// trackDashboard('Dashboard', "Dashboard", {}, false);

		// history(`/dashboard/business-scenario-services#business-scenario`);
		dispatch({ type: "SELECT_TAB", payload: "business-scenario" });
	}, []);

	useEffect(() => {
		setOpenTabVal(selectedTabVal);
		if (selectedTabVal) {
			setTabVal(selectedTabVal);
		}
	}, [selectedTabVal]);

	const changeTabVal = (businessScenario, organisation, version, name) => {
		setTabVal(businessScenario);
		dispatch({
			type: "SELECTED_BUSINESS_SCENARIO",
			payload: { organisation, version, name },
		});
	};

	const handleDrawerOpen = () => {
		setExpand(!expand);
	};

	const handleDrawerClose = () => {
		setExpand(false);
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseMailPopup = () => {
		setMailPopup(false);
	};

	const selectOption = (text) => {
		if (text === "Practitioner Guide") {
			window.open(
				"https://bian.org/wp-content/uploads/2020/10/BIAN-Semantic-API-Pactitioner-Guide-V8.1-FINAL.pdf",
				"_blank"
			);
		}
		if (text == "Service Landscape") {
			window.open("https://bian.org/servicelandscape-12-0-0/", "_blank");
		}
		if (text === "Sandbox API Help") {
			window.open(
				"https://portal.bian.org/dashboard/help/sandbox-api/",
				"_blank"
			);
		}
		if (text === "Live API Help") {
			window.open(
				"https://bian.org/deliverables/bian-portal/model-api-live-help/",
				"_blank"
			);
		}
		if (text === "QnA") {
			window.open("/QnAs"); //! implenent this changes
		}
		if (text === "Sandbox API URL") {
			window.open("https://api-sandbox-v2.bian.org", "_blank");
		}
		if (text == "Live API URL") {
			window.open("https://api-v3.bian.org", "_blank");
		}
	};

	const Mailto = ({ email, subject, body, ...props }) => {
		return (
			<a
				href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
				style={{ color: "#757575" }}>
				{props.children}
			</a>
		);
	};

	return (
		<>
			<LogoBar />
			<GoBack />
			<Drawer
				variant="permanent"
				style={{ marginTop: "4px", marginLeft: "-40px" }}
				className={"drawerClose"}
				classes={"drawerClose"}
				open={false}>
				<div className={"toolbar"} />
				<List>
					{[
						"What is BIAN",
						"bian.org",
						"Getting Started",
						"Practitioner Guide",
						"Practitioner Community",
						"Service Landscape",
						"GitHub",
						"Sandbox API URL",
						"Sandbox API Help",
						"Live API URL",
						"QnA",
						"Live API Help",
						"Mail",
					].map((text, index) => (
						<ListItem
							onClick={() => selectOption(text)}
							button
							key={text}
							style={{
								display: "block",
								maxWidth: "90px",
								marginTop: "64px !important",
							}}>
							{/* <ListItemIcon style={{ marginLeft: "20px" }}> */}
							{text === "bian.org" ? (
								<div onClick={() => window.open("https://bian.org")}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<LanguageIcon
											fontSize="small"
											onClick={() => window.open("https://bian.org")}
										/>
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "What is BIAN" ? (
								<div onClick={() => setOpen(true)}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<OndemandVideoIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Getting Started" ? (
								<div
									style={{
										color:
											window.location.pathname.split("/").length > 2 &&
											window.location.pathname.split("/")[3] ==
												"getting-started"
												? " #6666ff"
												: null,
									}}
									onClick={() =>
										window
											.open("/dashboard/help/getting-started", "_blank")
											.focus()
									}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<DirectionsRunIcon
											fontSize="small"
											style={{
												color:
													window.location.pathname.split("/").length > 2 &&
													window.location.pathname.split("/")[3] ==
														"getting-started"
														? " #6666ff"
														: null,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Practitioner Community" ? (
								<div
									onClick={() =>
										window
											.open(
												"https://biancoreteam.atlassian.net/wiki/spaces/BPC/pages/2299723814/Ask+your+question",
												"_blank"
											)
											.focus()
									}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<GroupsIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Practitioner Guide" ? (
								<>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<BookIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</>
							) : text === "Service Landscape" ? (
								<div
									onClick={() =>
										window.open(" https://bian.org/servicelandscape-12-0-0/")
									}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<LandscapeRoundedIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "GitHub" ? (
								<div
									onClick={() =>
										window.open("https://github.com/bian-official/public")
									}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<GitHubIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Sandbox API Help" ? (
								<div
									style={{
										color:
											window.location.pathname.split("/").length > 2 &&
											window.location.pathname.split("/")[3] == "sandbox-api"
												? " #6666ff"
												: null,
									}}
									onClick={() =>
										window.open("/dashboard/help/sandbox-api", "_blank").focus()
									}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<PsychologyAltIcon
											style={{
												color:
													window.location.pathname.split("/").length > 2 &&
													window.location.pathname.split("/")[3] ==
														"sandbox-api"
														? " #6666ff"
														: null,
											}}
											fontSize="small"
										/>
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Live API Help" ? (
								<div
									style={{
										color:
											window.location.pathname.split("/").length > 2 &&
											window.location.pathname.split("/")[3] == "live-api"
												? " #6666ff"
												: null,
									}}
									onClick={() =>
										window.open("/dashboard/help/live-api", "_blank").focus()
									}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<PsychologyAltIcon
											fontSize="small"
											style={{
												color:
													window.location.pathname.split("/").length > 2 &&
													window.location.pathname.split("/")[3] == "live-api"
														? " #6666ff"
														: null,
											}}
										/>
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Sandbox API URL" ? (
								<div>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<LinkIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "QnA" ? (
								<div>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<HelpOutlineIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Live API URL" ? (
								<div>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										<LinkIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : text === "Mail" ? (
								<div onClick={() => setMailPopup(true)}>
									<ListItemIcon style={{ marginLeft: "20px" }}>
										{/* <Mailto email="portal@bian.org" subject="Hello Bian" body="Hello Bian!"> */}
										<AlternateEmailIcon fontSize="small" />
										{/* </Mailto> */}
									</ListItemIcon>
									<ListItemText
										primary={text}
										style={{
											width: "min-content",
											textAlign: "center",
											fontSize: "10px",
											minWidth: "54px",
										}}
									/>
								</div>
							) : null}
							{/* </ListItemIcon> */}
						</ListItem>
					))}
				</List>
			</Drawer>
			<main className={"content"} style={{ marginLeft: "90px" }}>
				<div className={"toolbar"}>
					{!helpPage ? (
						<Navbar
							onAutoSearchChange={changeOrganization}
							selectNav={setselectedNav}
							changeTabVal={changeTab}
						/>
					) : null}

					{/* <AppBar
                    position="fixed"
                    className={'appBar'}
                    fooJon={classNames('appBar', {
                         ['appBarShift']: expand
                    })}
               >
                    <Toolbar disableGutters={true}>
                         <IconButton
                              color="inherit"
                              aria-label="Open drawer"
                              onClick={handleDrawerOpen}
                              className={'menuButton'}
                         >
                              <MenuIcon
                                   classes={{
                                        root: expand
                                             ? 'menuButtonIconOpen'
                                             : 'menuButtonIconClosed'
                                   }}
                              />
                         </IconButton>
                         <Typography
                              variant="h6"
                              color="inherit"
                              className={'grow'}
                              noWrap
                         >
                              Mini variant menu++
                         </Typography>
                         <div>
                              <IconButton
                                   aria-owns={expand ? "menu-appbar" : undefined}
                                   aria-haspopup="true"
                                   onClick={handleMenu}
                                   color="inherit"
                              >
                                   <AccountCircle />
                              </IconButton>
                              <Menu
                                   id="menu-appbar"
                                   anchorEl={anchorEl}
                                   anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                   }}
                                   transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right"
                                   }}
                                   open={expand}
                                   onClose={handleClose}
                              >
                                   <MenuItem onClick={handleClose}>Profile</MenuItem>
                                   <MenuItem onClick={handleClose}>My account</MenuItem>
                              </Menu>
                         </div>
                    </Toolbar>
               </AppBar> */}

					<Routes>
						<Route exact="true" path="" element={<DashboardContent />} />
						<Route exact="true" path="behaviour" element={<Behaviour />} />
						<Route exact="true" path="control" element={<Control />} />
						<Route exact="true" path="operation" element={<Operations />} />
						<Route
							exact="true"
							path="services"
							element={<Service serviceOrg={serviceOrg} feedOrg={feedOrg} />}
						/>
						<Route
							exact="true"
							path="business-object"
							element={
								<BusinessObject serviceOrg={serviceOrg} feedOrg={feedOrg} />
							}
						/>
						<Route
							exact="true"
							path="business-classes"
							element={
								<BusinessClasses serviceOrg={serviceOrg} feedOrg={feedOrg} />
							}
						/>
						<Route
							exact="true"
							path="business-data-types"
							element={
								<BODataTypes serviceOrg={serviceOrg} feedOrg={feedOrg} />
							}
						/>
						<Route
							exact="true"
							path="business-enumeration"
							element={
								<BOEnumeration serviceOrg={serviceOrg} feedOrg={feedOrg} />
							}
						/>
						<Route
							exact="true"
							path="business-primitive-datatypes"
							element={
								<BOPrimitiveType serviceOrg={serviceOrg} feedOrg={feedOrg} />
							}
						/>
						<Route
							exact="true"
							path="create-new/:createtype"
							element={<CreateNewItem />}
						/>
						<Route
							exact="true"
							path="business-scenario-services/sample"
							element={<Sample />}
						/>
						<Route
							exact="true"
							path="help/business-scenario-help"
							element={<BusinessScenarioHelp />}
						/>

						<Route
							exact="true"
							path="help/services-help"
							element={<ServicesHelp />}
						/>

						<Route
							exact="true"
							path="help/data-type-help"
							element={<DatatypeHelp />}
						/>
						<Route
							exact="true"
							path="help/classes-help"
							element={<ClassHelp />}
						/>
						<Route
							exact="true"
							path="help/enumerations-help"
							element={<Enumerationelp />}
						/>
						<Route
							exact="true"
							path="help/primitive-type-help"
							element={<PrimitiveTypeHelp />}
						/>
						<Route
							exact="true"
							path="help/getting-started"
							element={<GettingStartedHelp />}
						/>
						<Route
							exact="true"
							path="help/sandbox-api"
							element={<SandboxAPIHelpHelp />}
						/>
						<Route
							exact="true"
							path="help/live-api"
							element={<LiveboxAPIHelpHelp />}
						/>
						<Route
							exact="true"
							path="help/dashboard-help"
							element={<DashboardHelp />}
						/>

						{/* <Route
                         exact="true"
                         path="business-scenario-services"
                         element={<BusinessScenarioService serviceOrg={serviceOrg} feedOrg={feedOrg} />}
                    />
                     <Route
                         path="business-scenario"
                         element={<BusinessScenario serviceOrg={serviceOrg} feedOrg={feedOrg} />}
                    />
                    <Route
                         path="business-scenario/:type/:version/:businessScenario"
                         element={<BusinessScenario serviceOrg={serviceOrg} feedOrg={feedOrg} />}
                    /> */}
					</Routes>
					{/* {
                              !helpPage && !hideVal && tabVal === 'business-scenario' ?
                                   <BusinessScenario serviceOrg={serviceOrg} feedOrg={feedOrg} />
                                   :
                                   !helpPage && !hideVal && tabVal === 'business-scenario-services' && tabVal ?
                                        <BusinessScenarioService tabAllVal={changeTabVal} serviceOrg={serviceOrg} feedOrg={feedOrg} />
                                        : !helpPage && !hideVal && tabVal === 'data-type' && tabVal ?
                                             <BODataTypes serviceOrg={serviceOrg} feedOrg={feedOrg} />
                                             : !helpPage && !hideVal && tabVal === 'classes' && tabVal ?
                                                  <BusinessClasses serviceOrg={serviceOrg} feedOrg={feedOrg} />
                                                  : !helpPage && !hideVal && tabVal === 'enumerations' && tabVal ?
                                                       <BOEnumeration serviceOrg={serviceOrg} feedOrg={feedOrg} />
                                                       : !helpPage && !hideVal && tabVal === 'primitive-type' && tabVal ?
                                                            <BOPrimitiveType serviceOrg={serviceOrg} feedOrg={feedOrg} />
                                                            : null
                         } */}

					{location.pathname ===
					"/dashboard/business-scenario-services/business-scenario" ? (
						<BusinessScenario serviceOrg={serviceOrg} feedOrg={feedOrg} />
					) : location.pathname ===
							"/dashboard/business-scenario-services/services" &&
					  location.pathname ? (
						<BusinessScenarioService
							tabAllVal={changeTabVal}
							serviceOrg={serviceOrg}
							feedOrg={feedOrg}
						/>
					) : location.pathname ===
							"/dashboard/business-scenario-services/data-type" &&
					  location.pathname ? (
						<BODataTypes serviceOrg={serviceOrg} feedOrg={feedOrg} />
					) : location.pathname ===
							"/dashboard/business-scenario-services/classes" &&
					  location.pathname ? (
						<BusinessClasses serviceOrg={serviceOrg} feedOrg={feedOrg} />
					) : location.pathname ===
							"/dashboard/business-scenario-services/enumerations" &&
					  location.pathname ? (
						<BOEnumeration serviceOrg={serviceOrg} feedOrg={feedOrg} />
					) : location.pathname ===
							"/dashboard/business-scenario-services/primitive-type" &&
					  location.pathname ? (
						<BOPrimitiveType serviceOrg={serviceOrg} feedOrg={feedOrg} />
					) : null}
				</div>

				<Dialog
					open={open}
					fullWidth={true}
					PaperComponent={PaperComponent}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					className="youtube-dialog">
					<DialogTitle id="alert-dialog-title" className="youtube-dialog">
						{"What is BIAN"}
						<p
							style={{
								marginLeft: "40px",
								fontSize: "15px",
								color: "#9a9292",
								marginBottom: "0px",
							}}>
							Drag to Move
						</p>
						<IconButton
							aria-label="close"
							onClick={handleClose}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent className="youtube-dialog">
						<iframe
							// width="950"
							// height="480"
							className="myIframe"
							style={{ width: "100%" }}
							height="480"
							src={`https://www.youtube.com/embed/kkZ_MGasmeU`}
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							title="Embedded youtube"
						/>
					</DialogContent>
					{/* <DialogActions>
                              <Button onClick={handleClose}>Close</Button>
                         </DialogActions> */}
				</Dialog>
				<Dialog
					open={mailPopup}
					style={{ minWidth: "400" }}
					onClose={handleCloseMailPopup}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle
						id="alert-dialog-title"
						style={{ minWidth: "400px" }}
						className="youtube-dialog">
						{"Send Mail"}
						<IconButton
							aria-label="close"
							onClick={handleCloseMailPopup}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}>
							<CloseIcon />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<Alert severity="info">
							portal@bian.org
							<ContentCopyIcon
								style={{
									marginBottom: "-8px",
									color: "#1ab1f5",
									right: "80px",
									position: "absolute",
									cursor: "pointer",
								}}
								onClick={() =>
									navigator.clipboard.writeText("portal@bian.org ")
								}
							/>
							<Mailto
								email="portal@bian.org"
								subject="Hello Bian"
								body="Hello Bian!">
								<EmailIcon
									style={{
										marginBottom: "-8px",
										color: "#1ab1f5",
										right: "40px",
										position: "absolute",
									}}
								/>
							</Mailto>
						</Alert>
					</DialogContent>
				</Dialog>
			</main>
		</>
	);
}

const DashboardContext = withAppInsights(DashboardInitial);

const Dashboard = ({ location, children }) => (
	<AppInsightsContextProvider>
		<DashboardContext />
	</AppInsightsContextProvider>
);

export default Dashboard;
// export default withAppInsights(Dashboard)
