import React from "react";
import { Helmet } from "react-helmet";

import QnAAppbar from "./components/Appbar";
import { Container, Stack, Paper, Box, Toolbar } from "@mui/material";
import QnASideBar from "./components/QnASideBar";
import { Outlet } from "react-router-dom";
import QnAFooter from "./components/QnAFooter";

const QnAs = () => {
	return (
		<Box
			sx={{
				overflowX: "hidden",
				display: "flex",
				flexDirection: "column",
				maxHeight: "100vh",
				overflowY: "auto",
			}}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>QnA</title>
			</Helmet>
			<QnAAppbar />
			<Toolbar />
			<Container
				sx={
					{
						// display: "flex",
						// flexGrow: 1,
					}
				}>
				<Stack direction={"row"}>
					<QnASideBar />
					<Box
						sx={{
							// overflowY: "auto",
							// flex: "1",
							width: "100%",
							// bgcolor: "red",
							// maxHeight: `100vh`,
						}}>
						<Outlet />
					</Box>
				</Stack>
			</Container>
			<QnAFooter />
		</Box>
	);
};

export default QnAs;
