import { Avatar, Box, Card, CardHeader, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { green, orange } from '@mui/material/colors';
import React, { useState } from 'react'

function ServiceDomainCard({ card, handleOnchnge }) {
    const [role, setRole] = useState(card?.role ? card.role : "");

    const handleChange = (event) => {
        handleOnchnge({ ...card, role: event.target.value })
        setRole(event.target.value);
    };

    return (
        <Box key={card.uid} data-keyid={card.uid} sx={{ minWidth: "300px" }}>
            <Card className={"customCardShadow"}>
                <CardHeader sx={{ flexDirection: "column", gap: "15px" }}
                    avatar={
                        <Avatar sx={{ bgcolor: role !== '' ? green[500] : orange[300] }} aria-label={card.name}>{card.name[0] + card.name[1]}</Avatar>
                    }
                    title={card.name}
                    subheader={card.uid}
                />
                <Divider />
                <Box sx={{ p: 2, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {/* <Typography style={{ fontSize: "13px" }} color="text.secondary" gutterBottom>{card?.meta?.organisation}</Typography> */}
                    <FormControl variant='standard' fullWidth>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                            labelId="role-label"
                            id={"role-" + card.uid}
                            value={role}
                            label="Role"
                            onChange={handleChange}
                            size="small"
                        >
                            <MenuItem value='core'>Core</MenuItem>
                            <MenuItem value='proxy'>Proxy</MenuItem>
                            <MenuItem value='external'>External</MenuItem>
                            <MenuItem value='utility'>Utility</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Card>
        </Box>
    )
}

export default ServiceDomainCard