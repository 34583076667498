import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        <Button>BIAN</Button>
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function Login() {
  const classes = useStyles();
  const [userL, setUserL] = useState("");
  const [passL, setPassL] = useState();
  const [isLoading, setLoading] = useState(false);
  const [passEye, setPassEye] = useState({
    pass: "password",
    passView: false,
  });
  const [msgAlert, setMsgAlert] = useState({
    status: false,
    severity: "",
    title: "",
    msg: "",
  });

  var defaultUser = {
    username: process.env.REACT_APP_DEFAULT_USERNAME,
    password: process.env.REACT_APP_DEFAULT_PASSWORD,
  };

  const [jwtToken, setJwtToken] = useState("");

  const getAuthenticate = async (loginUser) => {
    localStorage.setItem("jwtToken", "");
    localStorage.setItem("firstName", "");

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

    await axios
      .post(axiosUrl, loginUser)
      .then((res) => {
        if (res.status === 200) {
          var response = res.data;
          localStorage.setItem("jwtToken", response.jwtToken);
          localStorage.setItem("firstName", response.firstName);
          setJwtToken(response.jwtToken);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
      });
  };

  // loggedout = 3215
  // notapprove = 3216
  // loggedin = 1995
  // approve = 1996
  useEffect(() => {
    window.localStorage.clear();
    // getAuthenticate(defaultUser);
    // localStorage.setItem("auth_token", "3215");
    // localStorage.setItem("auth_token_status", "3216");
  }, []);

  function handleFormSubmit(e) {
    e.preventDefault();
    setLoading(true);
    var loginUser = {
      username: userL,
      password: passL,
    };

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");
    axios
      .post(axiosUrl, loginUser, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          var response = res.data;
          setLoading(false);
          if (response.status?.toLowerCase() == "active") {
            const token = jwt_decode(response.jwtToken);
            localStorage.setItem("tokenDetails", JSON.stringify(token));
            localStorage.setItem("userDetails", JSON.stringify(response));
            localStorage.setItem("jwtToken", response.jwtToken);
            localStorage.setItem("crawler", false);
            localStorage.setItem("firstName", response.firstName);
            localStorage.setItem("cacheToken", response.id);
            localStorage.setItem("auth_token", "1995");
            localStorage.setItem("auth_token_status", "1996");
            setMsgAlert({
              status: true,
              severity: "success",
              title: "Success",
              msg: "Authentication Successful",
            });
            window.location.href = "/";
          } else {
            setMsgAlert({
              status: true,
              severity: "error",
              title: "Error",
              msg: `You will be able to Sign In only after the Primary Contact for your organisation has approved your access.  The Primary Contact for ${response?.organisationName} is ${response?.userAdmin}. Their email address is: ${response?.primaryContactEmail}`,
            });
          }
        }
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          // Request made and server responded
          console.log(1);
          console.log(error.response.data.message);
          setMsgAlert({
            status: true,
            severity: "error",
            title: "Error",
            msg: error.response.data.message,
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(2);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(3);
          console.log("Error", error.message);
        }
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} variant="rectangle">
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          noValidate
          method="post"
          onSubmit={handleFormSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username / Email"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={(event) => setUserL(event.target.value)}
            value={userL}
          />
          <FormControl variant="outlined" sx={{ mt: 2, width: "100%" }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="pass"
              autoComplete="pass"
              autoFocus
              value={passL}
              onChange={(e) => setPassL(e.target.value)}
              type={passEye.pass}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setPassEye({
                        ...passEye,
                        passView: !passEye.passView,
                        pass: passEye.pass === "password" ? "text" : "password",
                      })
                    }
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {passEye.passView ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <br />
          <br />
          {isLoading ? (
            <LoadingButton loading variant="outlined" style={{ width: "100%" }}>
              Submit
            </LoadingButton>
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
          )}
          <Grid container>
            <Grid item>
              <Link to="/forgot/password" variant="body2">
                <Button size="small">Forgot password?</Button>
              </Link>
            </Grid>
            <Grid item>
              <Link to="/register" variant="body2">
                <Button size="small">{"Don't have an account? Sign Up"}</Button>
              </Link>
            </Grid>
          </Grid>
          {msgAlert.status && (
            <Alert severity={msgAlert.severity} style={{ width: "100%" }}>
              <AlertTitle>{msgAlert.title}</AlertTitle>
              {msgAlert.msg}
            </Alert>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
