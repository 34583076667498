import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
	Divider,
	FormControl,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Alert,
	AlertTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import ".././../../assets/css/form.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { generateRandomNumber, generateRandomString } from '../../utils/commonUtils'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const Demo = styled("div")(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
}));

function ModelFormAdd(props) {
	var trimData = "";
	if (props.data?.hasOwnProperty("title")) {
		trimData = props.data.title.trim();
	} else {
		trimData = "";
	}

	const [formdata, setFormdata] = useState({});
	const [propsdata, setPropsdata] = useState({});
	const [commentCount, setCommentCount] = useState(0);
	const [commentInput, setCommentInput] = useState("");
	const [commentEdit, setCommentEdit] = useState({
		cid: "",
		content: "",
		status: false,
	});
	const [handleComment, setHandleComment] = useState([]);
	useEffect(() => {
		console.log("props have data im Model Add is =>", props.data)
		setPropsdata({
			id: props.data.id,
			uid: props.data.editId,
			editId: props.data.editId,
			buid: props.data.buid,
			title: props.data.title,
			description: props.data.description,
			status: props.data.status,
			data_type: props.data.data_type,
			type1: props.data.type1,
			type2: props.data.type2,
			type3: props.data.type3,
			level: props.data.level,
			comments: props.data.comments != null ? props.data.comments : [],
		});
	}, [props.data]);

	function handleformChange(event) {
		var newDta = { ...formdata };
		var inputName = event.target.name;
		if (inputName === "title") {
			newDta["title"] = event.target.value;
			newDta["name"] = event.target.value;
		} else if (inputName === "description") {
			newDta["description"] = event.target.value;
		} else if (inputName === "status") {
			newDta["status"] = event.target.value;
		} else if (inputName === "data_type") {
			newDta["data_type"] = event.target.value;
		} else {
		}
		setFormdata(newDta);
	}

	function handleformSubmit(e) {
		e.preventDefault();
		setFormdata({
			...formdata,
			uid: generateRandomString(15, true),
			id: generateRandomNumber(13, false),
			bianId: generateRandomString(15, true)
		})
		var getLocalStorage = JSON.parse(localStorage.getItem("feedbacktree"));
		var selectToModify = JSON.parse(localStorage.getItem("selectToModify"));
		console.log("getLocalStorage starts is", getLocalStorage);
		console.log("formdata is", formdata, " | and level is", selectToModify.level, props.data.level, " || andtype is ", propsdata.type1);
		if (selectToModify.level === "level1") {
			if (selectToModify.type1 === "controlRecord") {
				getLocalStorage.controlRecord.attributes.push(formdata)
			}
			else if (selectToModify.type1 === "businessScenarios") {
				getLocalStorage.businessScenarios.push(formdata)
			}
			else if (selectToModify.type1 === "behaviourQualifiers") {
				setFormdata({ ...formdata, inputAttributes:[], outputAttributes:[]})
				getLocalStorage.behaviourQualifiers.push(formdata)
			}
			else if (selectToModify.type1 === "serviceOperations") {
				setFormdata({ ...formdata, attributes:[]})
				getLocalStorage.behaviourQualifiers.push(formdata)
			}
			else {
				console.log("level1 type1 not matching", formdata.type1);
			}
		} else if (selectToModify.level === "level2") {
			if (selectToModify.type1 === "serviceOperations") {
				let io_type = JSON.parse(localStorage.getItem("selectToModify"));
				var objIndex = 0;
				objIndex = getLocalStorage.serviceOperations.findIndex((obj) => obj.uid == selectToModify.uid);
				if (objIndex !== 0) {
					if (io_type.ioType === "input") {
						if (getLocalStorage?.serviceOperations[objIndex]?.inputAttributes?.length > 0) {
							getLocalStorage.serviceOperations[objIndex].inputAttributes.push(formdata)
						}
						else {
							getLocalStorage.serviceOperations[objIndex]['inputAttributes'] = [formdata]
						}
					}
					else if (io_type.ioType === "output") {
						if (getLocalStorage?.serviceOperations[objIndex]?.outputAttributes?.length > 0) {
							getLocalStorage.serviceOperations[objIndex].outputAttributes.push(formdata)
						}
						else {
							getLocalStorage.serviceOperations[objIndex]['outputAttributes'] = [formdata]
						}
					}
					else {
						return
					}
				}
				else {
					return
				}
			}
			else if (selectToModify.type1 === "behaviourQualifiers") {
				var objIndex = getLocalStorage.behaviourQualifiers.findIndex((obj) => obj.uid == formdata.uid);
				if (getLocalStorage?.behaviourQualifiers[objIndex]?.attributes?.length > 0) {
					getLocalStorage.behaviourQualifiers[objIndex].attributes.push(formdata)
				}
				else {
					getLocalStorage.behaviourQualifiers[objIndex]['attributes'] = [formdata]
				}
			}
			else {
				console.log("level2 type1 not matching", formdata.type1);
			}
		} else {
			return;
		}

		localStorage.setItem("feedbacktree", JSON.stringify(getLocalStorage));
		console.log("getLocalStorage ends is", getLocalStorage);
		props.handleSubmit();
		localStorage.removeItem("selectToModify");
		localStorage.removeItem("modifyStatus");
		setFormdata({})
		setCommentInput('')
		setCommentCount(0)
		setHandleComment([])
	}

	function handleCommentAdd(e) {
		var getOlderComments = formdata?.metaLight?.comments?.length > 0 ? formdata?.metaLight?.comments : [];
		console.log("before getOlderComments =>", getOlderComments);
		
		getOlderComments.push({
			cid: "cid_" + String(Math.random()).split(".")[1],
			uid: JSON.parse(localStorage.getItem("feedbacktree")).uid,
			userId: localStorage.getItem("cacheToken"),
			content: commentInput,
			date: new Date(),
		});
		setFormdata((prevState) => ({
			...prevState,
			metaLight: {
				comments: getOlderComments
			}
		}));
		console.log("formdata =>", formdata);
		setCommentInput("");
		getOlderComments.length > 0 ? setCommentCount(formdata?.metaLight?.comments?.length) : setCommentCount(1)
		setHandleComment(formdata?.metaLight?.comments);
		console.log("before formdata?.metaLight?.comments?.length =>", formdata?.metaLight?.comments?.length);
	}

	function handleCommentChange(event) {
		setCommentInput(event.target.value);
	}

	function handleCommentEdit(cid, content) {
		if (cid && content) {
			setCommentInput(content);
			setCommentEdit({
				cid: cid,
				content: content,
				status: true,
				date: new Date(),
			});
		}
	}

	function handleCommentSave() {
		var getOlderComments = formdata?.metaLight?.comments?.length > 0 ? formdata?.metaLight?.comments : [];
		console.log("getOlderComments =>", getOlderComments);
		var objIndex = getOlderComments.findIndex(
			(obj) => obj.cid == commentEdit.cid
		);
		console.log("objIndex =>", objIndex);
		console.log(
			" before getOlderComments[objIndex].content =>",
			getOlderComments[objIndex].content
		);
		getOlderComments[objIndex].content = commentInput;
		getOlderComments[objIndex].date = new Date();
		console.log("commentInput =>", commentInput);
		console.log(
			"afterf getOlderComments[objIndex].content =>",
			getOlderComments[objIndex].content
		);

		setFormdata((prevState) => ({
			...prevState,
			metaLight: {
				comments: getOlderComments
			},
		}));
		console.log("formdata =>", formdata);
		setCommentInput("");
		setCommentEdit({
			cid: "",
			content: "",
			status: false,
			date: "",
		});
		setCommentCount(formdata?.metaLight?.comments?.length);
		setHandleComment(formdata?.metaLight?.comments);
	}

	function handleCommentDelete(cid) {
		if (cid) {
			var getOlderComments = formdata.comments;
			var objIndex = getOlderComments.findIndex((obj) => obj.cid == cid);
			getOlderComments.splice(objIndex, 1);
			setFormdata((prevState) => ({
				...prevState,
				metaLight: {
					comments: getOlderComments
				}
			}));
			setCommentCount(formdata.comments.length);
			setHandleComment(formdata.comments);
		}
	}

	useEffect(() => {
		console.log(formdata, handleComment, commentCount);
	}, [formdata, handleComment, commentCount]);

	useEffect(() => {
		console.log("add get called --------------------------------")
	}, [props.open])

	return (
		<>
			<Dialog
				maxWidth="lg"
				fullWidth
				className="dialog-form"
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				onClose={props.handleClose}
				aria-describedby={"alert-dialog-slide-" + trimData}
			>
				<DialogTitle
					className="alert-dialog-title"
					style={{ padding: "16px 24px 0 24px" }}
				>
					Add {props.data.title}
				</DialogTitle>
				{props.data.description && (
					<DialogContentText
						className="alert-dialog-text"
						style={{ padding: "0 24px 10px 24px" }}
					>
						{props.data.description}
					</DialogContentText>
				)}
				<Divider />
				<form
					onSubmit={(fsubmit) => handleformSubmit(fsubmit)}
					action=""
					id={"alert-dialog-slide-" + trimData}
				>
					<DialogContent>
						<Box style={{ display: "flex", justifyContent: "space-between" }}>
							<Box style={{ flex: "1", padding: "10px" }}>
								<Box className="form-control">
									<TextField
										className="form-input"
										fullWidth
										label="Name"
										margin="dense"
										id={"outlined-title" + trimData}
										onChange={(e) => handleformChange(e)}
										name="title"
										value={formdata.title ? formdata.title : ""}
									/>
								</Box>
								<Box className="form-control">
									<TextField
										className="form-input"
										fullWidth
										margin="dense"
										multiline
										rows={4}
										label="Description"
										id={"outlined-description" + trimData}
										onChange={(e) => handleformChange(e)}
										name="description"
										value={formdata.description ? formdata.description : ""}
									/>
								</Box>
								<Box className="form-control">
									<TextField
										className="form-input"
										fullWidth
										label="Data Type"
										margin="dense"
										id={"outlined-type" + trimData}
										onChange={(e) => handleformChange(e)}
										name="data_type"
										value={formdata.data_type ? formdata.data_type : ""}
									/>
								</Box>
							</Box>
							<Box style={{ flex: "1", padding: "10px" }}>
								{commentCount > 0 && (
									<Grid>
										<Grid item xs={12} md={12}>
											<Typography
												sx={{ mt: 4, mb: 2 }}
												variant="h6"
												component="div"
											>
												User Comments
											</Typography>
											<Demo>
												<List dense>
													{handleComment.map((mapData) => {
														return (
															<ListItem key={mapData.cid}>
																{/* props.commentUser?.length > 0 && (props.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.firstName + " h " + props.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.emailAddress) */}
																<ListItemText primary={mapData.content} secondary={props.data?.commentUser?.length > 0 && props.data?.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.firstName ? props.data?.commentUser.filter(fil => fil.uid == mapData.userId)[0]?.firstName : localStorage.getItem("firstName")} />
																<ListItemIcon>
																	<IconButton
																		edge="end"
																		aria-label="delete"
																		onClick={() =>
																			handleCommentEdit(
																				mapData.cid,
																				mapData.content
																			)
																		}
																	>
																		<EditIcon size="small" color="info" />
																	</IconButton>
																	<IconButton
																		edge="end"
																		aria-label="delete"
																		onClick={() =>
																			handleCommentDelete(mapData.cid)
																		}
																	>
																		<DeleteIcon size="small" color="danger" />
																	</IconButton>
																</ListItemIcon>
															</ListItem>
														);
													})}
												</List>
											</Demo>
										</Grid>
									</Grid>
								)}
								<Box className="form-control">
									<TextField
										className="form-input"
										fullWidth
										label="Comment"
										margin="dense"
										id={"outlined-type" + trimData}
										onChange={(e) => handleCommentChange(e)}
										name="comment"
										value={commentInput}
										size="small"
										variant="standard"
										color="success"
									/>
								</Box>
								<Box
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "10px",
									}}
								>
									{commentEdit.status ? (
										<Button
											size="small"
											variant="outlined"
											color="success"
											endIcon={<CheckCircleIcon />}
											onClick={handleCommentSave}
										>
											Save Comment
										</Button>
									) : (
										commentInput && (
											<Button
												size="small"
												variant="outlined"
												endIcon={<AddIcon />}
												onClick={handleCommentAdd}
											>
												Add Comment
											</Button>
										)
									)}
								</Box>
							</Box>
						</Box>
						<Divider />
						<Box>
							<Alert severity="info">
								<AlertTitle>Note</AlertTitle>
								Please ensure you click the Save button on the top right —{" "}
								<strong>if you would like to save your changes!</strong>
							</Alert>
						</Box>
					</DialogContent>
					<Divider />
					<DialogActions>
						<Button type="submit" onClick={handleformSubmit}>
							Close
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
}

export default ModelFormAdd;
