import React from "react";
import FileViewer from "react-file-viewer";

const Live_API_Help_v1 = () => {
    return <>
        <FileViewer
            fileType={'docx'}
            filePath={require("./Live_API_Help_v1.docx")}
            style={{
                maxHght: '616px',
                overflowY: 'scroll',
                minWidth: '80%'
            }}
        />
    </>

}
export default Live_API_Help_v1