import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from '@mui/material';
import Domain from './Domain';
import Feedback from './Feedback';
import DetailDiff from './DetailDiff';



const useStyles = makeStyles((theme) => ({
	main_service_container: {
		margin: '20px 0',
		boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)!important',
		borderRadius: '5px',
	},
	container_wrapper: {
		display: 'flex',
		padding: '10px',
	},
	detail_diff_container: {
		padding: '10px',
	},
	container_items: {
		flexGrow: 1,
	}
}));

function MainContent(props) {

	const classes = useStyles()
	const [suid, setsuid] = useState('');
	const [fuid, setfuid] = useState('');
	const [servicetree, setservicetree] = useState({});
	const [feedtree, setfeedtree] = useState({});
	const [comparedata, setcomparedata] = useState({});

	useEffect(() => {
		setsuid(props.serviceUid)
		setfuid(props.feedUid)
	}, [props.serviceUid, props.feedUid])

	useEffect(() => {
		setcomparedata({})
	}, [props.feedUid])

	function saveservicetree(data){
		console.log("saveservicetree =>", data);
		setservicetree(data)
	}

	function savefeedtree(data){
		console.log("savefeedtree =>", data);
		setfeedtree(data)
	}

	function savecomparedata(data){
		console.log("savecomparedata =>", data);
		setcomparedata(data)
	}

	return (<>

		<Box container className={classes.main_service_container}>
			<Box container className={classes.container_wrapper}>
				<Card style={{ width: '50%', }} variant="outlined"><Domain treeItem='' serviceUid={suid} callbackFunction={saveservicetree} /></Card>
				<Card style={{ width: '50%', }} variant="outlined"><Feedback treeItem='' feedUid={fuid} callbackFunction={savefeedtree} comparedata={comparedata} /></Card>
			</Box>
			<Box container className={classes.detail_diff_container}>
				<DetailDiff servicetree={servicetree} feedtree={feedtree} callbackFunction={savecomparedata} />
			</Box>
		</Box>
	</>
	);
}

export default MainContent;

