import React, { useState } from 'react';
import { Box } from '@mui/system'
import './assests/ImageCard.css';
import DragImage from './assests/DragImage';
import { Button, Divider, Modal, Typography } from '@mui/material';
import { randomNumberGenerator } from '../../utility/random';

function MultipleImageUploadModal({ open, close, proceed, skip }) {
    const [image, setImage] = useState("")
    const [imageName, setImageName] = useState("")
    const [uploaded, setUploaded] = useState(false)

    const uploadMultiple = e => {
        var listName = []
        var listFile = []
        for (let index = 0; index < e.target.files.length; index++) {
            listName.push({ id: e.target.files[index].lastModified, name: e.target.files[index].name })
            listFile.push({ ...e.target.files[index], uid: "BIAN_CERTIFICATION_UPLOADED_DOC_UID_" + String(e.target.files.length) + "_" + String(index) + "_RANDOM_ID_" + String(randomNumberGenerator(6)) })
        }
        setImageName(listName)
        setImage(listFile)
        setUploaded(true)
    }

    const handleProceedOfUpload = e => {
        proceed(image)
    }

    const handleClearOfUpload = e => {
        setUploaded(false)
        setImage('')
    }

    return (
        <Modal
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="createCertificateModalContainer">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">Upload Document</Typography>
                </Box>
                <Divider sx={{ margin: " 8px 0" }} />
                <Box className="certificatModalWrapper">
                    <Box className="container">
                        {!uploaded ? <Box className="image-upload-container">
                            <input type="file" name='document[]' className='uploadFile' onChange={(e) => uploadMultiple(e)} multiple="multiple" />
                            <Box className="image-container">
                                <DragImage />
                            </Box>
                        </Box> :
                            <>
                                <Box className="links-container">
                                    {imageName?.length > 0 && imageName?.map(item => <div className="link-container" key={"uploaded-image-uid-" + item.id}>
                                        <input type="text" className="text-container" disabled="" value={item.name} />
                                    </div>)}
                                </Box>
                            </>
                        }
                    </Box>
                </Box>
                <Divider sx={{ margin: " 8px 0" }} />
                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                    {uploaded ? <>
                        <Button onClick={handleProceedOfUpload} variant="contained" color="success">Proceed</Button>
                        <Button onClick={handleClearOfUpload} color="warning">clear</Button>
                    </>
                        : <Button onClick={skip} color="warning">Skip and Proceed</Button>}
                </Box>
            </Box>
        </Modal>

    )
}

export default MultipleImageUploadModal