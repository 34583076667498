import React from "react";
import FileViewer from "react-file-viewer";

const Sandbox_API_Help_v1 = () => {
    return <>
        <FileViewer
            fileType={'docx'}
            filePath={require("./Sandbox_API_Help_v1.docx")}
            style={{
                maxHght: '616px',
                overflowY: 'scroll',
                minWidth: '80%'
            }}
        />
    </>

}
export default Sandbox_API_Help_v1