import React, { createContext, useContext } from "react";
import { reactPlugin } from "./AppInsights";

const AppInsightsContext = createContext(reactPlugin);
const useMyAppInsightsContext = () => useContext(AppInsightsContext);

const AppInsightsContextProvider = ({ children }) => {
	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			{children}
		</AppInsightsContext.Provider>
	);
};

export {
	AppInsightsContext,
	AppInsightsContextProvider,
	useMyAppInsightsContext,
};
