import { Box, Button, List, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

function SubjectCard(props) {
    console.log("props.data data in SubjectCard is", props.data);

    const [dispChapters, setDisplayChapters] = useState(false)

    function handleSubjectClick(event, type) {
        event.preventDefault();
        console.log("button got click", type);
        setDisplayChapters(!dispChapters)
    }

    useEffect(() => {
        setDisplayChapters(props.makeDefaultOpen)
    }, [props.makeDefaultOpen])

    return (
        <>
            <Box>
                <Box sx={{ padding: "5px 20px" }}>
                    <Button variant={dispChapters ? 'contained' : "outlined"} color="success" fullWidth size='small' className="certificateMainButton" onClick={event => handleSubjectClick(event, 'chapter-1')}>{props.data.subject}</Button>
                    <Box sx={{ p: 1 }}>
                        <Typography className="certificateMainButtonDescription" color="text.secondary" gutterBottom>{props.data.description}</Typography>
                        <Box sx={{ display: dispChapters ? 'block' : "none" }}>
                            {props.data.secondarySubject.length > 0 && <List dense={false}>
                                {props.data.secondarySubject.map(listItem => (
                                    <ListItem class="smallListItems_first"> <ListItemText size="small" primary={listItem.name} secondary={listItem.description} />
                                        {listItem.test.length > 0 && <List dense={false} className="smallListItems_second_container">
                                            {listItem.test.map(certificateItem => (
                                                <ListItem class="smallListItems_second">
                                                    <ListItemText size="small" primary={certificateItem.compliance} />
                                                </ListItem>
                                            ))}
                                        </List>}
                                    </ListItem>
                                ))}
                            </List>}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default SubjectCard