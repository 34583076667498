import React, { useState, useEffect } from "react";
import {
	Container,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Divider,
	Skeleton,
	Stack,
	Box,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Searcharea from "../../utils/Searcharea";
import { useSelector } from "react-redux";
import axios from "axios";

import { reactPlugin } from "../../../AppInsights";

import {
	useAppInsightsContext,
	useTrackMetric,
	useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "calc(100vh - 150px)",
		overflow: "hidden",
		overflowY: "scroll",
	},
	item: {
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		padding: "15px 0px",
	},
	icons: {},
	path: {
		paddingLeft: "10px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	formControl: { width: "100%", height: "" },
}));

function Servicelist(props) {
	const classes = useStyles();
	const [searchText, setSearchText] = useState();
	const [allItems, setAllItems] = useState([]);
	const classesVal = useSelector((state) => state?.common?.classVal);
	const [defaultKey, setdefaultKey] = useState(0);
	const [defaultSelectedValue, setDefaultSelectedValue] = useState();

	const trackCustomEvents = useTrackEvent(
		reactPlugin,
		"Selected Bussiness Classes"
	);
	const trackCustomMatric = useTrackMetric(
		reactPlugin,
		"Select Bussiness Classes Matric Data"
	);

	var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
	var showSkeleton = false;
	var radioValue = "";

	// rerender whwn dependency var changes
	// useEffect(() => {

	// 	console.log("skeleton is", props.skeleton)
	// 	console.log("serviceItems is", allItems)

	// }, [allItems, props.skeleton])

	// Show Skeleton make var true when data is not there or api is fetching data
	(props.skeleton === undefined || props.skeleton === true) &&
		(showSkeleton = true);

	// show skelenton n number of times
	const radioSkeletons = [];
	for (let i = 1; i <= 20; i++) {
		radioSkeletons.push(i);
	}
	useEffect(() => {
		if (
			!defaultSelectedValue &&
			allItems &&
			classesVal &&
			classesVal?.dataType
		) {
			let axiosUrl = "";
			process.env.REACT_APP_VERSION === "S1" &&
				(axiosUrl =
					process.env.REACT_APP_MODEL_ACCESS_S1 +
					"BOClassesBasicByNameLike/" +
					classesVal?.dataType +
					"/" +
					props.currentOrganisation +
					"/" +
					props.currentVersion +
					"/");
			process.env.REACT_APP_VERSION === "S2" &&
				(axiosUrl =
					process.env.REACT_APP_MODEL_ACCESS_S2 +
					"BOClassesBasicByNameLike/" +
					classesVal?.dataType +
					"/" +
					props.currentOrganisation +
					"/" +
					props.currentVersion +
					"/");

			axios
				.get(axiosUrl, {
					headers: {
						Authorization: "Bearer " + localStorage.getItem("jwtToken"),
					},
				})
				.then(
					(response) => {
						response.data.length > 0 && setAllItems(response.data);
						showSkeleton = false;
						const allVal = response.data;
						const selectedVal = allVal.find(
							({ name }) => name === classesVal?.dataType
						);
						if (selectedVal) {
							props.handleRadioChange(selectedVal?.uid);
							setDefaultSelectedValue(selectedVal?.uid);
							radioValue = selectedVal?.uid;
						}
						showSkeleton = true;
						setdefaultKey(defaultKey + 1);
						setSearchText(classesVal.dataType);
					},
					(error) => console.log(error)
				)
				.catch((err) => setAllItems(props.serviceItems));
		}
	}, [classesVal, allItems]);

	useEffect(() => {
		setAllItems(props.serviceItems);
	}, [props.serviceItems]);

	const searchTextResult = () => {
		let axiosUrl = "";
		process.env.REACT_APP_VERSION === "S1" &&
			(axiosUrl =
				process.env.REACT_APP_MODEL_ACCESS_S1 +
				"BOClassesBasicByNameLike/" +
				searchText +
				"/" +
				props.currentOrganisation +
				"/" +
				props.currentVersion +
				"/");
		process.env.REACT_APP_VERSION === "S2" &&
			(axiosUrl =
				process.env.REACT_APP_MODEL_ACCESS_S2 +
				"BOClassesBasicByNameLike/" +
				searchText +
				"/" +
				props.currentOrganisation +
				"/" +
				props.currentVersion +
				"/");

		axios
			.get(axiosUrl, {
				headers: {
					Authorization: "Bearer " + localStorage.getItem("jwtToken"),
				},
			})
			.then(
				(response) => {
					response.data.length > 0 && setAllItems(response.data);
					showSkeleton = false;
				},
				(error) => console.log(error)
			)
			.catch((err) => setAllItems(props.serviceItems));
	};

	const handlesearchText = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
		showSkeleton = true;
		if (e.target.value.length > 3) {
			searchTextResult();
		} else if (e.target.value.length === 0) {
			setAllItems(props.serviceItems);
		}
	};

	return (
		<Container className={combinedClasses}>
			<FormControl className={classes.formControl}>
				<div className="searchcontainer">
					<Searcharea searchText={handlesearchText} text={searchText} />
				</div>

				<Divider style={{ margin: "15px 0" }} />

				<FormLabel id="demo-service-radio-buttons-group-label">
					Business Class
				</FormLabel>
				<RadioGroup
					aria-labelledby="demo-service-radio-buttons-group-label"
					defaultValue={defaultSelectedValue}
					key={defaultKey}
					name="service-radio-buttons-group"
					onChange={(event, value) => {
						const selecteItem = allItems.filter((item) => item.uid === value);

						trackCustomEvents({ name: selecteItem[0].name });
						trackCustomMatric({ name: selecteItem[0].name, average: 20 });
						props.handleRadioChange(value);
						radioValue = value;
					}}>
					{allItems?.length > 0 &&
						!showSkeleton &&
						allItems.map((loop) => {
							if (!searchText || searchText?.length === 0) {
								return (
									<FormControlLabel
										value={loop.uid}
										key={loop.uid}
										control={<Radio />}
										label={loop.name}
									/>
								);
							} else if (searchText?.length > 0) {
								return (
									<FormControlLabel
										value={loop.uid}
										key={loop.uid}
										control={<Radio />}
										label={loop.name}
										style={{
											display: loop.name
												.toLowerCase()
												.includes(searchText.toLowerCase())
												? "block"
												: "none",
										}}
									/>
								);
							} else {
								return (
									<Typography variant="label" component="label">
										No results found
									</Typography>
								);
							}
						})}
					{!searchText &&
						allItems?.length > 0 &&
						!showSkeleton &&
						allItems.map((loop) => {
							return (
								<>
									<FormControlLabel
										value={loop.uid}
										key={loop.bianId}
										control={<Radio />}
										label={
											loop?.meta?.organisationInstance ? (
												<Box>
													<Box
														variant="h5"
														component="h5"
														style={{
															fontSize: "15px",
															fontWeight: "500",
															color: "#343434",
														}}>
														{loop.name}
													</Box>
													<Box
														variant="small"
														component="small"
														style={{
															fontSize: "14px",
															fontWeight: "500",
															color: "#5787a1",
														}}>
														{loop?.meta?.organisationInstance}
													</Box>
												</Box>
											) : (
												loop.name
											)
										}
									/>
									{/* {loop?.meta?.organisation && (
                      <Box variant="small" component="small">
                        {loop.meta.organisation}
                      </Box>
                    )} */}
								</>
							);
						})}

					{((!searchText && allItems?.length === 0) ||
						allItems?.length === undefined ||
						allItems?.length === null ||
						showSkeleton) &&
						radioSkeletons.map((loopSkeleton, index) => (
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									paddingTop: "10px",
								}}
								key={"skeletonKey" + index}>
								<Skeleton
									variant="circular"
									width={20}
									height={20}
									sx={{ marginRight: "7px" }}
								/>
								<Skeleton
									sx={{ flexGrow: "1" }}
									height={20}
									variant="rectangular"
								/>
							</Box>
						))}
				</RadioGroup>
			</FormControl>
		</Container>
	);
}

export default Servicelist;
