import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { Alert, AlertTitle, Box, Button, Card, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import AccountProfile from './AccountProfile';
import AccountProfileDetails from './AccountProfileDetails';
import ServicesAssigned from './ServicesAssigned';
import axios from 'axios';


function ViewUser() {
     var defaultUser = {
          username: process.env.REACT_APP_DEFAULT_USERNAME,
          password: process.env.REACT_APP_DEFAULT_PASSWORD,
     };
     const navigate = useNavigate();
     let { userId } = useParams();
     const dispatch = useDispatch();

     const [refreshUser, setRefreshUser] = useState(false);
     const [open, setOpen] = useState(false);
     const [userDetails, setUserDetails] = useState(null);
     const [sameUser, setSameUser] = useState(false);
     const [jwtToken, setJwtToken] = useState("");
     const [usernameByUid, setUsernameByUid] = useState("");
     const [msgAlert, setMsgAlert] = useState({
          status: false,
          severity: "",
          title: "",
          msg: "",
     });


     const getAuthenticate = async (loginUser) => {

          let axiosUrl = "";
          process.env.REACT_APP_VERSION === "S1" &&
               (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Token/authenticate");
          process.env.REACT_APP_VERSION === "S2" &&
               (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Token/authenticate");

          await axios
               .post(axiosUrl, loginUser)
               .then((res) => {
                    if (res.status === 200) {
                         var response = res.data;
                         setJwtToken(response.jwtToken);

                         if (userId == localStorage.getItem("cacheToken")) {
                              setSameUser(true)
                         }
                         loadUser(response.jwtToken)
                    }
               })
               .catch(function (error) {
                    if (error.response) {
                         // Request made and server responded
                         console.log(1);
                         console.log(error.response.data.message);
                    } else if (error.request) {
                         // The request was made but no response was received
                         console.log(2);
                         console.log(error.request);
                    } else {
                         // Something happened in setting up the request that triggered an Error
                         console.log(3);
                         console.log("Error", error.message);
                    }
               });
     };

     const loadUser = async (newAjwtToken) => {
          let axiosUrl = "";
          process.env.REACT_APP_VERSION === "S1" &&
               (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "UserByUid/" + userId);
          process.env.REACT_APP_VERSION === "S2" &&
               (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "UserByUid/" + userId);

          await axios
               .get(axiosUrl, {
                    headers: {
                         "Content-Type": "application/json",
                         Authorization: "Bearer " + newAjwtToken,
                    },
               })
               .then((response) => {
                    // If request is good...
                    setUsernameByUid(response.data.userName)
                    setUserDetails(response.data)
                    if (!response.data.userName) {
                         setMsgAlert({
                              status: true,
                              severity: "error",
                              title: "Forgotten User",
                              msg: "Unable is already forgotten",
                         });
                    }
               })
               .catch((error) => {
                    console.log("error " + error);
               });
     };

     const handleClickOpen = () => {
          setOpen(true);
     };

     const handleClose = () => {
          setOpen(false);
     };

     const handleDeleteAccount = () => {
          setOpen(false);
          let axiosUrl = "";
          process.env.REACT_APP_VERSION === "S1" &&
               (axiosUrl =
                    process.env.REACT_APP_SECURITY_S1 + "UserForget/");
          process.env.REACT_APP_VERSION === "S2" &&
               (axiosUrl =
                    process.env.REACT_APP_SECURITY_S2 + "UserForget/");
          if (usernameByUid) {
               axios({
                    method: "PATCH",
                    url: axiosUrl,
                    data: { username: userId, password: '' },
                    headers: { Authorization: "Bearer " + jwtToken },
               })
                    .then((res) => {
                         if (res.status === 200) {
                              !sameUser && setMsgAlert({
                                   status: true,
                                   severity: "success",
                                   title: "Deleted",
                                   msg: "This User is Deleted Successfully",
                              });
                              !sameUser && navigate("/manage/users/", { replace: true });
                              sameUser && navigate("/", { replace: true });
                         } else {
                              setMsgAlert({
                                   status: true,
                                   severity: "error",
                                   title: "Request sent Failed",
                                   msg: "Unable to Delete user",
                              });
                         }
                    })
                    .catch(function (error) {
                         if (error.response) {
                              // Request made and server responded
                              console.log(1);
                              console.log(error.response.data.message);
                         } else if (error.request) {
                              // The request was made but no response was received
                              console.log(2);
                              console.log(error.request);
                         } else {
                              // Something happened in setting up the request that triggered an Error
                              console.log(3);
                              console.log("Error", error.message);
                         }
                         setMsgAlert({
                              status: true,
                              severity: "error",
                              title: "Request sent Failed",
                              msg: "Unable to Delete user",
                         });
                    });
          }
          else {
               setMsgAlert({
                    status: true,
                    severity: "error",
                    title: "Forgotten User",
                    msg: "Unable is already forgotten",
               });
          }
          setTimeout(() => {
               setMsgAlert({
                    status: false,
                    severity: "",
                    title: "",
                    msg: "",
               })
          }, 5000);
     };

     const handleAccountProfileDetails = () => {
          console.log("If request is good... callbackFunction handleAccountProfileDetails executed ");
          setRefreshUser(true)
     };

     const handleAccountProfile = () => {
          console.log("If request is good... callbackFunction handleAccountProfile executed");
          setRefreshUser(false)
     };

     useEffect(() => {
          if(localStorage.getItem("jwtToken") == null || localStorage.getItem("jwtToken") == undefined) {
               navigate("/login");
          }
          getAuthenticate(defaultUser)
     }, [])

     return (
          <>
               <Box
                    component="main"
                    sx={{
                         flexGrow: 1,
                         py: 8
                    }}
                    style={{overflow: 'auto', height: '60vh'}}
               >
                    <Container maxWidth="lg">
                         <Card sx={{ p: 2 }}>
                              <Typography
                                   sx={{ mb: 3 }}
                                   variant="h4"
                              >
                                   Account Details
                                   <Divider />
                              </Typography>
                              <Grid
                                   container
                                   spacing={3}
                              >
                                   <Grid
                                        item
                                        lg={4}
                                        md={6}
                                        xs={12}
                                   >
                                        <AccountProfile adminToken={jwtToken} details={refreshUser} callbackFunction={handleAccountProfile} />
                                   </Grid>
                                   <Grid
                                        item
                                        lg={8}
                                        md={6}
                                        xs={12}
                                   >
                                        <AccountProfileDetails adminToken={jwtToken} userDetails={userDetails} callbackFunction={handleAccountProfileDetails} />
                                   </Grid>
                              </Grid>
                         </Card>
                         <Card sx={{ mt: 1, p: 2 }}>
                              <Typography
                                   sx={{ mb: 3 }}
                                   variant="h4"
                              >
                                   Services
                                   <Divider />
                              </Typography>
                              <Grid container>
                                   <Grid
                                        lg={12}
                                        md={12}
                                        xs={12}
                                        item
                                   >
                                        <ServicesAssigned />
                                   </Grid>
                              </Grid>
                         </Card>
                         <Card sx={{ mt: 1, p: 2 }}>
                              <div>
                                   <Button variant="outlined" onClick={handleClickOpen} color="danger">Close Account</Button>
                                   <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                   >
                                        <DialogTitle id="alert-dialog-title">Account Closure</DialogTitle>
                                        <DialogContent>
                                             <DialogContentText id="alert-dialog-description">Are You Sure You want to close your account</DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                             <Button onClick={handleClose}>Disagree</Button>
                                             <Button onClick={handleDeleteAccount} autoFocus>
                                                  Agree
                                             </Button>
                                        </DialogActions>
                                   </Dialog>
                              </div>
                              {msgAlert.status && (
                                   <Alert severity={msgAlert.severity} style={{ width: "100%" }}>
                                        <AlertTitle>{msgAlert.title}</AlertTitle>
                                        {msgAlert.msg}
                                   </Alert>
                              )}
                         </Card>
                    </Container>
               </Box>
          </>
     )
}

export default ViewUser