import React, { useState } from "react";
import {
	Container,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Divider,
	Skeleton,
	Box,
	Checkbox
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchInList from "./SearchInList";
import { useEffect } from "react";
import { getSelectedDomainData } from "../../redux/certification/action";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "calc(100vh - 150px)",
		overflow: "hidden",
		overflowY: "scroll",
	},
	item: {
		display: "flex",
		justifyContent: "start",
		alignItems: "center",
		padding: "15px 0px",
	},
	icons: {},
	path: {
		paddingLeft: "10px",
		fontSize: "16px",
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	formControl: { width: "100%", height: "" },
}));

function ListingCheckbox(props) {
	const classes = useStyles();
	const [searchText, setSearchText] = useState();
	const [checkedItems, setCheckedItems] = useState([]);
	var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
	var showSkeleton = false;
	var radioValue = "";

	// rerender whwn dependency var changes
	// useEffect(() => {

	// 	console.log("skeleton is", props.skeleton)
	// 	console.log("items is", props.items)

	// }, [props.items, props.skeleton])

	// Show Skeleton make var true when data is not there or api is fetching data
	(props.skeleton === undefined || props.skeleton === true) &&
		(showSkeleton = true);

	// show skelenton n number of times
	const radioSkeletons = [];
	for (let i = 1; i <= 20; i++) {
		radioSkeletons.push(i);
	}

	const handlesearchText = (e) => {
		e.preventDefault();
		setSearchText(e.target.value);
	};


	var newSelectedListingArray = []
	const handleCheckChange = (e, domain, roleType) => {
		console.log("selected domain is ==>", domain, " | and status is =>", roleType);
		e.preventDefault();
		newSelectedListingArray = checkedItems
		const { value, checked } = e.target
		if (checked) {
			newSelectedListingArray.push({ ...domain, role: roleType })
			setCheckedItems([...newSelectedListingArray])
		}
		else {
			newSelectedListingArray = newSelectedListingArray.filter(fil => fil.uid !== value)
			setCheckedItems([...newSelectedListingArray])
		}
		props.handleRadioChange(newSelectedListingArray)
	};


	return (
		<Container className={combinedClasses}>
			<FormControl className={classes.formControl}>
				<div className="searchcontainer">
					<SearchInList searchText={handlesearchText} text={searchText} />
				</div>

				<Divider style={{ margin: "15px 0" }} />

				<FormLabel id="demo-service-radio-buttons-group-label">{props.listingName}</FormLabel>

				{/* <RadioGroup
          aria-labelledby="demo-service-radio-buttons-group-label"
          defaultValue="female"
          name="service-radio-buttons-group"
          onChange={(event, value) => {
            props.handleRadioChange(value);
            radioValue = value;
          }}
        >
        </RadioGroup> */}
				{props.items?.length > 0 &&
					!showSkeleton &&
					props.items
						.filter((filterVal) => {
							if (searchText === undefined || searchText?.length === 0) {
								return filterVal;
							} else if (
								filterVal.name
									.toLowerCase()
									.includes(searchText.toLowerCase())
							) {
								return filterVal;
							}
						})
						.map((loop) => {
							return (
								<>
									<FormControlLabel
										value={loop.uid}
										key={loop.uid}
										control={<Checkbox
											name={loop.name}
											checked={checkedItems.length > 0 && checkedItems.filter(fil => fil.uid === loop.uid).length > 0}
											onChange={e => handleCheckChange(e, loop, loop?.type ? loop.type : "proxy")}
										/>}
										label={
											loop.meta.organisationInstance ? (
												<Box>
													<Box
														variant="h5"
														component="h5"
														style={{
															fontSize: "15px",
															fontWeight: "500",
															color: "#343434",
														}}
													>
														{loop.name}
													</Box>
													<Box
														variant="small"
														component="small"
														style={{
															fontSize: "14px",
															fontWeight: "500",
															color: "#5787a1",
														}}
													>
														{loop.meta.organisationInstance}
													</Box>
												</Box>
											) : loop.name
										}
									/>
								</>
							);
						})}
				{(props.items?.length === 0 ||
					props.items?.length === undefined ||
					props.items?.length === null ||
					showSkeleton) &&
					radioSkeletons.map((loopSkeleton, index) => (
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								paddingTop: "10px",
							}}
							key={"skeletonKey" + index}
						>
							<Skeleton
								variant="circular"
								width={20}
								height={20}
								sx={{ marginRight: "7px" }}
							/>
							<Skeleton
								sx={{ flexGrow: "1" }}
								height={20}
								variant="rectangular"
							/>
						</Box>
					))}
			</FormControl>
		</Container>
	);
}

export default ListingCheckbox;
