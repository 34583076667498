import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Skeleton,
  Stack,
  Box,
  Typography,
  Card,
  Chip,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import Searcharea from "../../utils/Searcharea";
import axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 150px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  item: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "15px 0px",
  },
  icons: {},
  path: {
    paddingLeft: "10px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  formControl: { width: "100%", height: "" },
}));

function Feedbacklist(props) {
  const domainStatus = [
    "Feedback-Created",
    "Feedback-InProcess",
    "Feedback-Rework",
    "Published",
    "Approved",
    "Registered",
  ];

  const classes = useStyles();
  const [allItems, setAllItems] = useState([]);
  const [searchText, setSearchText] = useState();

  const [activeChip, setActiveChip] = useState("Feedback-Created");
  const [applyFilter, setApplyFilter] = useState(false);
  let { userId } = useParams();

  const handleChipChange = (passed) => {
    passed === "" && setApplyFilter(false);
    setActiveChip(passed);
    props.handleChipChange(passed);
  };

  var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
  var showSkeleton = false;
  var radioValue = "";

  (props.skeleton === undefined || props.skeleton === true) &&
    (showSkeleton = true);

  const radioSkeletons = [];
  for (let i = 1; i <= 20; i++) {
    radioSkeletons.push(i);
  }

  useEffect(() => {
    setAllItems(props.serviceItems)
  }, [props.serviceItems])

  const searchTextResult = () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + " BusinessObjectsBasicByNameLike/" + searchText + "/" + props.currentOrganisation + "/" + props.currentVersion + "/");
    process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + " BusinessObjectsBasicByNameLike/" + searchText + "/" + props.currentOrganisation + "/" + props.currentVersion + "/");

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(
        (response) => {
          response.data.length > 0 && setAllItems(response.data)
          showSkeleton = false
        },
        (error) => console.log(error)
      )
      .catch(err => setAllItems(props.serviceItems))
  }

  const handlesearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    showSkeleton = true
    if (e.target.value.length > 3) {
      searchTextResult()
    }
    else if (e.target.value.length === 0) {
      setAllItems(props.serviceItems)
    }
  };

  return (
    <Container className={combinedClasses}>
      <FormControl className={classes.formControl}>
        {applyFilter ? (
          <Box
            style={{
              padding: "10px",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography varinat="small">Apply filter: </Typography>
              <Button
                variant="text"
                size="small"
                onClick={() => setApplyFilter(false)}
              >
                Hide
              </Button>
            </Box>
            <Divider style={{ margin: "6px 0" }} />
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              {domainStatus.map((cur) => {
                return (
                  <Chip
                    size="small"
                    style={{ margin: "10px 5px" }}
                    variant={activeChip == cur ? "filled" : "outlined"}
                    color="primary"
                    label={cur}
                    key={cur}
                    onClick={() => handleChipChange(cur)}
                  />
                );
              })}
              {activeChip !== "" && (
                <Chip
                  size="small"
                  style={{ margin: "10px 5px" }}
                  variant="filled"
                  color="primary"
                  label="Clear"
                  onClick={() => handleChipChange("")}
                  onDelete={() => handleChipChange("")}
                />
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <div className="searchcontainer">
              <Searcharea searchText={handlesearchText} text={searchText} />
            </div>

            <Divider style={{ margin: "15px 0" }} />

            <FormLabel
              id="demo-service-radio-buttons-group-label"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography>Business Object</Typography>
              <IconButton
                aria-label="filter"
                size="small"
                color={activeChip !== "" ? "danger" : "primary"}
                onClick={() => setApplyFilter(true)}
              >
                <FilterListIcon size="small" />
              </IconButton>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-service-radio-buttons-group-label"
              defaultValue="female"
              name="service-radio-buttons-group"
              onChange={(event, value) => {
                props.handleRadioChange(value);
                radioValue = value;
              }}
            >
              {allItems?.length > 0 &&
                !showSkeleton &&
                allItems
                  .map((loop) => {
                    return (
                      <FormControlLabel
                        value={loop.bianId}
                        key={loop.bianId}
                        control={<Radio />}
                        label={
                          loop?.meta?.organisationInstance ? (
                            <Box>
                              <Box
                                variant="h5"
                                component="h5"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#343434",
                                }}
                              >
                                {loop.name}
                              </Box>
                              <Box
                                variant="small"
                                component="small"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  color: "#5787a1",
                                }}
                              >
                                {loop?.meta?.organisationInstance}
                              </Box>
                            </Box>
                          ) : (
                            loop.name
                          )
                        }
                      />
                    );
                  })}
              {(allItems?.length === 0 ||
                allItems?.length === undefined ||
                allItems?.length === null ||
                showSkeleton) &&
                radioSkeletons.map((loopSkeleton, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                    key={"skeletonKey" + index}
                  >
                    <Skeleton
                      variant="circular"
                      width={20}
                      height={20}
                      sx={{ marginRight: "7px" }}
                    />
                    <Skeleton
                      sx={{ flexGrow: "1" }}
                      height={20}
                      variant="rectangular"
                    />
                  </Box>
                ))}
            </RadioGroup>
          </Box>
        )}
      </FormControl>
    </Container>
  );
}

export default Feedbacklist;
