import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthenticationBar from './AuthenticationBar'
import Unapprove from './Unapprove'
import { withLogged } from '../utils/withAuth'

function Authentication() {
     return (
          <>
               <AuthenticationBar />
               <Routes>
                    <Route exact="true" path="registered" element={<Unapprove />} />
               </Routes>
          </>
     )
}

export default withLogged(Authentication)