import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
function Copyright() {
     return (
          <Typography variant="body2" color="textSecondary" align="center">
               {'Copyright © '}
               <Link color="inherit" to="/">
                    <Button>BIAN</Button>
               </Link>{' '}
               {new Date().getFullYear()}
               {'.'}
          </Typography>
     );
}
const useStyles = makeStyles((theme) => ({
     paper: {
          marginTop: theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
     },
     avatar: {
          margin: theme.spacing(1),
          backgroundColor: theme.palette.secondary.main,
     },
     form: {
          width: '100%', // Fix IE 11 issue.
          marginTop: theme.spacing(1),
     },
     submit: {
          margin: theme.spacing(3, 0, 2),
     },
}));
export default function ForgotUsername() {
     const classes = useStyles();
     return (
          <Container component="main" maxWidth="xs">
               <CssBaseline />
               <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                         <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                         Forgot Username
                    </Typography>
                    <form className={classes.form} noValidate>
                         <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email Address"
                              name="email"
                              autoComplete="email"
                              autoFocus
                         />
                         <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="otp"
                              label="6 Digit OTP"
                              type="otp"
                              id="otp"
                              autoComplete="current-otp"
                         />
                         <Button
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              className={classes.submit}
                         >
                              Verify
                         </Button>
                         <Grid container>
                              <Grid item>
                                   <Link to="/register" variant="body2">
                                        <Button size="small">{"Don't have an account? Sign Up"}</Button>
                                   </Link>
                              </Grid>
                         </Grid>
                    </form>
               </div>
               <Box mt={8}>
                    <Copyright />
               </Box>
          </Container>
     );
}