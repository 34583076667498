import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Card,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  LinearProgress,
  Alert,
  AlertTitle,
  CardHeader,
  CardContent,
  Grid,
  FormGroup,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import PhoneIcon from "@mui/icons-material/Phone";
import axios from "axios";

import { useNavigate as useHistory, useParams } from "react-router-dom";

function RoleAdd() {
  const [role, setRole] = useState({
    uid: "",
    name: "",
    displayName: "",
    description: "",
  });

  const [edit, setEdit] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  let history = useHistory();
  const { roleId } = useParams();

  const handleInputChange = (event) => {
    setRole({ ...role, [event.target.name]: event.target.value });
  };

  const getOrganisationData = async () => {
    var orgData = {
      uid: role.uid,
      name: role.name,
      displayName: role.displayName,
      description: role.description,
    };
    return orgData;
  };

  const addRole = async () => {
    const userData = await getOrganisationData();
    console.log("userData is ", userData);
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "Role");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "Role");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    };
    await axios
      .post(axiosUrl, userData, { headers: headers })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        history("/manage/role");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(" error.response :", error.response);
          console.log(" error.response.data :", error.response.data);
          console.log(" error.response.status :", error.response.status);
          console.log(" error.response.headers :", error.response.headers);
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.statusText +
              ", Unable to Add User"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add Role " + error.message);
        }
      });
  };

  const editRole = async () => {
    const userData = await getOrganisationData();
    console.log("userData is ", userData);

    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "RoleUpdate");
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "RoleUpdate");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("jwtToken"),
    };
    await axios
      .put(axiosUrl, userData, { headers: headers })
      .then((response) => {
        // If request is good...
        console.log(response.data);
        setError(false);
        history("/organisation");
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(" error.response :", error.response);
          console.log(" error.response.data :", error.response.data);
          console.log(" error.response.status :", error.response.status);
          console.log(" error.response.headers :", error.response.headers);
          setError(true);
          setErrorText(
            error.response.status +
              " " +
              error.response.statusText +
              ", Unable to Add Role"
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          console.log("Error", error.message);
          setError(true);
          setErrorText("Unable to Add Role " + error.message);
        }
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    edit ? editRole() : addRole();
  };

  const setRoleData = (organisationDetail) => {
    return {
      uid:
        organisationDetail.uid !== "string" &&
        organisationDetail.uid !== null &&
        organisationDetail.uid !== undefined
          ? organisationDetail.uid
          : "",
      name:
        organisationDetail.name !== "string" &&
        organisationDetail.name !== null &&
        organisationDetail.name !== undefined
          ? organisationDetail.name
          : "",
      displayName:
        organisationDetail.displayName !== "string" &&
        organisationDetail.displayName !== null &&
        organisationDetail.displayName !== undefined
          ? organisationDetail.displayName
          : "",
      description:
        organisationDetail.description !== "string" &&
        organisationDetail.description !== null &&
        organisationDetail.description !== undefined
          ? organisationDetail.description
          : "",
    };
  };

  const loadRole = async (uid) => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S1 + "RoleByUid/" + uid);
    process.env.REACT_APP_VERSION === "S2" &&
      (axiosUrl = process.env.REACT_APP_SECURITY_S2 + "RoleByUid/" + uid);

    await axios
      .get(axiosUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then((response) => {
        // If request is good...
        console.log("If request is good... ", response.data);
        setError(false);
        setRole(setRoleData(response.data));
      })
      .catch((error) => {
        console.log("error " + error);
        setError(true);
        setErrorText(
          "Unable to retrieve Organisations information From Server"
        );
      });
  };

  useEffect(() => {
    if (roleId) {
      setEdit(true);
      loadRole(roleId);
    }
  }, []);

  return (
    <Container sx={{ pt: 4 }} style={{overflow: 'auto', height: '65vh'}}>
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Card>
          <CardHeader subheader="* Fill the required fields" title="Add Role" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="name"
                  label="Name"
                  name="name"
                  autoFocus
                  value={role.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="displayName"
                  label="Role Display Name"
                  name="displayName"
                  autoFocus
                  value={role.displayName}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  required
                  id="description"
                  label="Description"
                  name="description"
                  value={role.description}
                  onChange={handleInputChange}
                />
              </Grid>
              {edit && (
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    id="uid"
                    label="Role ID"
                    name="uid"
                    value={role.uid}
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              type="submit"
            >
              {edit ? "Edit" : "Add"}
            </Button>
          </Box>
        </Card>
      </form>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )}
    </Container>
  );
}

export default RoleAdd;
