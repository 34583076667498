import React, { useEffect, useState } from "react";
import Servicelist from "./Servicelist";
import Feedbacklist from "./Feedbacklist";
import MainContent from "./MainContent";
import { makeStyles } from "@mui/styles";
import "./service.css";
import axios from "axios";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux'
import withAppInsights from './../../../AppInsights';

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  left_bar: {
    margin: "20px 10px 0 20px",
    borderRadius: "10px",
    width: "290px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
  },
  right_bar: {
    margin: "20px 20px 0 10px",
    borderRadius: "10px",
    width: "290px",
    [theme.breakpoints.down("md")]: {
      width: "100px",
    },
  },

  maincontent: {
    width: "calc(100% - 620px)",
    [theme.breakpoints.down("md")]: {
      width: "calc(100% - 240px)",
    },
  },
}));

function Service(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [serviceItems, setserviceItems] = useState("");
  const [feedItems, setfeedItems] = useState("");
  const [skeletonSer, setSkeletonSer] = useState(false);
  const [skeletonFer, setSkeletonFeed] = useState(false);
  const [suid, setsuid] = useState("");
  const [fuid, setfuid] = useState("");
  const [fchip, setfchip] = useState("");
  const [domainchip, setDomainchip] = useState("");


  // BIAN v10.0.0

  // Service
  useEffect(() => {
    if (props.serviceOrg.organisation !== undefined) {
      setSkeletonSer(true);

      let axiosUrl = "";
      if (domainchip == 'All') {
        process.env.REACT_APP_VERSION === "S1" &&
          (axiosUrl =
            process.env.REACT_APP_MODEL_ACCESS_S1 +
            "ServiceDomainsBasic/" + "/" +
            props.serviceOrg.organisation +
            "/" +
            props.serviceOrg.version);
        process.env.REACT_APP_VERSION === "S2" &&
          (axiosUrl =
            process.env.REACT_APP_MODEL_ACCESS_S2 +
            "ServiceDomainsBasic/" + "/" +
            props.serviceOrg.organisation +
            "/" +
            props.serviceOrg.version);
      } else {
        process.env.REACT_APP_VERSION === "S1" &&
          (axiosUrl =
            process.env.REACT_APP_MODEL_ACCESS_S1 +
            "ServiceDomainsBasic/" + domainchip + "/" +
            props.serviceOrg.organisation +
            "/" +
            props.serviceOrg.version);
        process.env.REACT_APP_VERSION === "S2" &&
          (axiosUrl =
            process.env.REACT_APP_MODEL_ACCESS_S2 +
            "ServiceDomainsBasic/" + domainchip + "/" +
            props.serviceOrg.organisation +
            "/" +
            props.serviceOrg.version);
      }


      axios
        .get(axiosUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        })
        .then(
          (response) => {
            var newOrgService = [];
            response.data.length > 0 &&
              response.data.map((item) =>
                newOrgService.push({
                  name: item.name,
                  id: item.id,
                  bianId: item.bianId,
                  uid: item.uid,
                  status: item.status,
                  meta: item.meta,
                  extendedDefinition: item?.extendedDefinition
                })
              );
            dispatch({ type: "ALL_FEEDBACK_DOMAIN", payload: newOrgService })

            setserviceItems(newOrgService);
            setSkeletonSer(false);
          },
          (error) => console.log(error)
        );
    }
  }, [props.serviceOrg, domainchip]);

  // Feedback
  useEffect(() => {
    if (props.feedOrg.organisation !== undefined) {
      setSkeletonFeed(true);

      let axiosUrl = "";
      if (fchip !== "") {
        process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainsBasicByStatusLike/" + fchip + "/" + props.feedOrg.organisation + "/" + props.feedOrg.version);
        process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainsBasicByStatusLike/" + fchip + "/" + props.feedOrg.organisation + "/" + props.feedOrg.version);
      } else {
        process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainsBasic/" + props.feedOrg.organisation + "/" + props.feedOrg.version);
        process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainsBasic/" + props.feedOrg.organisation + "/" + props.feedOrg.version);
      }

      axios
        .get(axiosUrl, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          },
        })
        .then(
          (response) => {
            var newOrgService = [];
            response.data.length > 0 &&
              response.data.map((item) =>
                newOrgService.push({
                  name: item.name,
                  id: item.id,
                  bianId: item.bianId,
                  uid: item.uid,
                  status: item.status,
                  meta: item.meta,
                })
              );
            setfeedItems(newOrgService);
            setSkeletonFeed(false);
          },
          (error) => console.log(error)
        );
    }
  }, [props.feedOrg, fchip]);

  function handleServiceRadioChange(uid) {
    console.log("uid handleServiceRadioChange from child is =>" + uid);
    setsuid(uid);
    dispatch({ type: "BUSINESS_SCENARIO_SERVICE_SELECTED", payload: uid })

    localStorage.setItem("serviceRadioId", uid);
  }

  function handleFeedRadioChange(uid) {
    console.log("uid handleFeedRadioChange from child is =>" + uid);
    setfuid(uid);
    localStorage.setItem("serviceFeedRadioId", uid);
  }

  function handleFeedChipChange(chip) {
    console.log("chip handleFeedChipChange from child is =>" + chip);
    setfchip(chip);
  }

  function handleFeedChipChangeDomain(chip) {
    setDomainchip(chip);
  }

  // useEffect(() => {
  //   dispatch({ type: "SELECT_TAB", payload: 'business-scenario' })
  // }, [])

  return (
    <>
      <div className={classes.maincontainer}>
        <div className={classes.left_bar}>
          <Box>
            {/* <Link to={"/dashboard/create-new/sercice-domain"}>
              <Button variant="outlined" startIcon={<AddIcon />} sx={{ border: "1px dashed grey", height: "100%", width: "100%", borderRadius: "5px", mb: 1 }}>Create</Button>
            </Link> */}
          </Box>
          <Servicelist
            skeleton={skeletonSer}
            serviceItems={serviceItems}
            handleRadioChange={handleServiceRadioChange}
            handleChipChange={handleFeedChipChangeDomain}
          />
        </div>
        <div className={classes.maincontent}>
          <MainContent settabAllVal={props.tabAllVal} organisation={props.serviceOrg.organisation} version={props.serviceOrg.version} serviceUid={suid} feedUid={fuid} />
        </div>
        <div className={classes.right_bar}>
          <Box>
            {/* <Link to={"/dashboard/create-new/sercice-domain"}>
              <Button variant="outlined" startIcon={<AddIcon />} sx={{ border: "1px dashed grey", height: "100%", width: "100%", borderRadius: "5px", mb: 1 }}>Create</Button>
            </Link> */}
          </Box>
          <Feedbacklist
            skeleton={skeletonFer}
            serviceItems={feedItems}
            handleRadioChange={handleFeedRadioChange}
            handleChipChange={handleFeedChipChange}
          />
        </div>
      </div>
    </>
  );
}

export default withAppInsights(Service);
