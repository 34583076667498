import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Skeleton,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Searcharea from "../../utils/Searcharea";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 150px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  item: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    padding: "15px 0px",
  },
  icons: {},
  path: {
    paddingLeft: "10px",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  formControl: { width: "100%", height: "" },
}));

function Servicelist(props) {
  const classes = useStyles();
  const [searchText, setSearchText] = useState();
  const [allItems, setAllItems] = useState([]);

  var combinedClasses = classes.container + " remove_scrollbar style_sidebar";
  var showSkeleton = false;
  var radioValue = "";

  // rerender whwn dependency var changes
  // useEffect(() => {

  // 	console.log("skeleton is", props.skeleton)
  // 	console.log("serviceItems is", allItems)

  // }, [allItems, props.skeleton])

  // Show Skeleton make var true when data is not there or api is fetching data
  (props.skeleton === undefined || props.skeleton === true) &&
    (showSkeleton = true);

  // show skelenton n number of times
  const radioSkeletons = [];
  for (let i = 1; i <= 20; i++) {
    radioSkeletons.push(i);
  }

  useEffect(() => {
    setAllItems(props.serviceItems)
  }, [props.serviceItems])

  const searchTextResult = () => {
    let axiosUrl = "";
    process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + " BusinessObjectsBasicByNameLike/" + searchText + "/" + props.currentOrganisation + "/" + props.currentVersion);
    process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + " BusinessObjectsBasicByNameLike/" + searchText + "/" + props.currentOrganisation + "/" + props.currentVersion);

    axios
      .get(axiosUrl, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        },
      })
      .then(
        (response) => {
          response.data.length > 0 && setAllItems(response.data)
          showSkeleton = false
        },
        (error) => console.log(error)
      )
      .catch(err => setAllItems(props.serviceItems))
  }

  const handlesearchText = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    showSkeleton = true
    if (e.target.value.length > 3) {
      searchTextResult()
    }
    else if (e.target.value.length === 0) {
      setAllItems(props.serviceItems)
    }
  };

  return (
    <Container className={combinedClasses}>
      <FormControl className={classes.formControl}>
        <div className="searchcontainer">
          <Searcharea searchText={handlesearchText} text={searchText} />
        </div>

        <Divider style={{ margin: "15px 0" }} />

        <FormLabel id="demo-service-radio-buttons-group-label">
          Business Objects
        </FormLabel>

        <RadioGroup
          aria-labelledby="demo-service-radio-buttons-group-label"
          defaultValue="female"
          name="service-radio-buttons-group"
          onChange={(event, value) => {
            props.handleRadioChange(value);
            radioValue = value;
          }}
        >
          {allItems?.length > 0 &&
            !showSkeleton &&
            allItems
              .map((loop) => {
                return (
                  <>
                    <FormControlLabel
                      value={loop.bianId}
                      key={loop.bianId}
                      control={<Radio />}
                      label={
                        loop?.meta?.organisationInstance ? (
                          <Box>
                            <Box
                              variant="h5"
                              component="h5"
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#343434",
                              }}
                            >
                              {loop.name}
                            </Box>
                            <Box
                              variant="small"
                              component="small"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#5787a1",
                              }}
                            >
                              {loop?.meta?.organisationInstance}
                            </Box>
                          </Box>
                        ) : (
                          loop.name
                        )
                      }
                    />
                    {/* {loop?.meta?.organisation && (
                      <Box variant="small" component="small">
                        {loop.meta.organisation}
                      </Box>
                    )} */}
                  </>
                );
              })}
          {(allItems?.length === 0 ||
            allItems?.length === undefined ||
            allItems?.length === null ||
            showSkeleton) &&
            radioSkeletons.map((loopSkeleton, index) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "10px",
                }}
                key={"skeletonKey" + index}
              >
                <Skeleton
                  variant="circular"
                  width={20}
                  height={20}
                  sx={{ marginRight: "7px" }}
                />
                <Skeleton
                  sx={{ flexGrow: "1" }}
                  height={20}
                  variant="rectangular"
                />
              </Box>
            ))}
        </RadioGroup>
      </FormControl>
    </Container>
  );
}

export default Servicelist;
