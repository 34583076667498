import React, { useEffect, useState } from 'react'
import axios from 'axios';
import certificate_test from '../../format/certificate_test.json'
import { Box, Button, Divider, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ListingCheckbox from '../../components/common/ListingCheckbox';
import OptionList from '../../components/common/OptionList';
import ShowGroupCard from '../../components/utils/ShowGroupCard';
import { selectCourseForCertification, selectDomainForCertification } from '../../redux/certification/action';
import { getOrganisation } from '../../redux/organisation/action';
import MultipleImageUploadModal from './MultipleImageUploadModal';
import ServiceDomainCard from './ServiceDomainCard';
import { randomMUIColorGenerator, randomNumberGenerator } from '../../utility/random';


const useStyles = makeStyles((theme) => ({
    maincontainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    left_bar: {
        margin: "0px 10px 0 20px",
        borderRadius: "10px",
        width: "300px",
        [theme.breakpoints.down("md")]: {
            width: "100px",
        },
    },
    right_bar: {
        margin: "20px 20px 0 10px",
        borderRadius: "10px",
        width: "290px",
        [theme.breakpoints.down("md")]: {
            width: "100px",
        },
    },

    maincontent: {
        width: "calc(100% - 620px)",
        [theme.breakpoints.down("md")]: {
            width: "calc(100% - 240px)",
        },
    },
}));

function CertTaker() {
    const classes = useStyles();
    const [serviceItems, setserviceItems] = useState("");
    const [skeletonSer, setSkeletonSer] = useState(false);
    const [suid, setSuid] = useState([]);
    const [openUpload, setOpenUpload] = useState(false);
    const [selection, setSelection] = useState(false);
    const [listOfCardItem, setListOfCardItem] = useState([])
    const [docs, setDocs] = useState([])
    let navigate = useNavigate();

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getOrganisation())
    }, [dispatch]);
    var orgList = useSelector(state => state.organisationData)

    const fetchList = (org, ver) => {
        let axiosUrl = "";
        process.env.REACT_APP_VERSION === "S1" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S1 + "ServiceDomainsBasic/" + org + "/" + ver);
        process.env.REACT_APP_VERSION === "S2" && (axiosUrl = process.env.REACT_APP_MODEL_ACCESS_S2 + "ServiceDomainsBasic/" + org + "/" + ver);

        axios
            .get(axiosUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                },
            })
            .then(
                (response) => {
                    response.data.length > 0 && setserviceItems(response.data);
                    setSkeletonSer(false);
                },
            );
    }

    function handleServiceRadioChange(slist) {
        setSuid(slist);
    }

    function handleOnchnge(passedvalue) {
        fetchList(passedvalue.organisation, passedvalue.version)
    }

    function handleOnRolechnge(passedvalue) {
        const list = suid.map(obj => obj.uid === passedvalue.uid ? passedvalue : obj);
        setSuid(list);
    }

    function clickToProceed() {
        dispatch(selectDomainForCertification(suid))
        setOpenUpload(true)
    }

    const handleCloseOfUpload = () => {
        setOpenUpload(false)
    };

    const handleProceedOfUpload = fil => {
        setDocs(fil)
        setOpenUpload(false)
        setSelection(true)
        // navigate('/certification/certificate_taker/select_certificate/')
    };

    const handleSkipOfUpload = () => {
        setOpenUpload(false)
        setSelection(true)
        // navigate('/certification/certificate_taker/select_certificate/')
    };

    function navigateToTestScreen(course) {
        createCertifiaction()
        dispatch(selectCourseForCertification(course))
        navigate('/certification/certificate_taker/certificate/' + course)
    }

    useEffect(() => {
        var loopOfLink = []
        if (certificate_test.length > 0 && (listOfCardItem.length !== (certificate_test.length))) {
            certificate_test.forEach(function (mapItem, index) {

                loopOfLink.push({
                    bgcolor: randomMUIColorGenerator("500"),
                    bgColorName: "teal",
                    avatarText: 'P' + (index + 1),
                    title: mapItem.test,
                    subheader: mapItem.name,
                    showCardAction: false,
                    cardType: "redux-func-call",
                    cardTypeAction: navigateToTestScreen,
                    to: mapItem.test.trim().replaceAll(" ", "-").toLowerCase(),
                    toText: "Take Test",
                    showCardBody: true,
                    description: mapItem.description
                })
            })
        }

        loopOfLink.length > 0 && setListOfCardItem(loopOfLink)

    }, [listOfCardItem])

    const createCertifiaction = async () => {
        var certificationObj = {
            "uid": "BIAN_CERTIFICATION_UID_OF_" + suid.length + "_SELECTED_DOMAIN_WITH_RANDOM_NUMBER_" + String(randomNumberGenerator(6)),
            "name": "BIAN_CERTIFICATION_NAME_" + suid.length,
            "displayName": "BIAN_CERTIFICATION_DNAME_" + suid.length,
            "description": "BIAN_CERTIFICATION_DESCRIPTION_" + suid.length,
            "serviceDomainBasics": [...suid],
            "documents": [...docs],
            "topics": [],
            "status": "string"
        }
    }

    return <>
        {selection ? <>
            <ShowGroupCard itemList={listOfCardItem} xs={12} md={6} lg={3} suid={suid} />
        </> : <>
            <div className={classes.maincontainer}>
                {orgList.organisationData.length > 0 && <div className={classes.left_bar}>
                    <Box>
                        <OptionList listOption={orgList.organisationData} handleOnchnge={handleOnchnge} />
                    </Box>
                    <ListingCheckbox
                        skeleton={skeletonSer}
                        items={serviceItems}
                        handleRadioChange={handleServiceRadioChange}
                        listingName="Service Domain"
                    />
                </div>}
                {suid.length > 0 && <>
                    <Box sx={{ padding: '10px', width: "100%" }}>
                        <Typography style={{ fontSize: "14px" }}>Selected Service Domain's</Typography>
                        <Box sx={{ display: "flex", justifyContent: "start", gap: "10px", alignItems: "start", width: "100%", flexWrap: "wrap" }}>
                            {suid.length > 0 && suid.map((card, index) => <ServiceDomainCard card={card} key={index} handleOnchnge={handleOnRolechnge} />)}
                        </Box>
                        {/* <Divider sx={{ marginTop: "10px", width: "100%", display: "fllex", justifyContent: "center" }} /> */}
                        <Box sx={{ marginTop: "30px", width: "100%", display: "fllex", justifyContent: "center" }}>
                            <Button variant="contained" color="success" style={{ display: "flex", color: "white", alignItems: 'center', boxShadow: '0 4px 24px 0 rgba(34,41,47,.1)' }} onClick={clickToProceed}>
                                <Typography style={{ fontSize: "14px" }}>
                                    Proceed
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </>}
            </div>
            {/* Upload */}
            <MultipleImageUploadModal open={openUpload} close={handleCloseOfUpload} proceed={handleProceedOfUpload} skip={handleSkipOfUpload} />
        </>}
    </>
}

export default CertTaker