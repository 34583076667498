import React from 'react'
import NavigationBar from '../../components/main/NavigationBar'
import { Route, Routes } from 'react-router';
import CertTest from './CertTest';
import CertHome from './CertHome';
import "./index.css"
import { withLogged } from '../../components/utils/withAuth';
import CertTaker from './CertTaker';
import CertAssesser from './CertAssesser';
import CertSelection from './CertSelection';


function Certification() {


  return (
    <>
      <NavigationBar />
      <Routes>
        <Route path='/' element={<CertHome />} />
        <Route path='/certificate_assessr/' element={<CertAssesser />} />
        <Route path='/certificate_assessr/certificate/:certTestId' element={<CertTest />} />
        <Route path='/certificate_taker/' element={<CertTaker />} />
        <Route path='/certificate_taker/select_certificate/' element={<CertSelection />} />
        <Route path='/certificate_taker/certificate/:certTestId' element={<CertTest />} />
      </Routes>
    </>
  )
}

export default withLogged(Certification)