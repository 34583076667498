import React, { useEffect, useState } from 'react'
import ShowGroupCard from '../../components/utils/ShowGroupCard'
import certificate_test from '../../format/certificate_test.json'
import { randomMUIColorGenerator } from '../../utility/random';
import { useDispatch, useSelector } from 'react-redux';
import { selectCourseForCertification } from '../../redux/certification/action';
import { useNavigate } from 'react-router';



function CertSelection() {
    const [listOfCardItem, setListOfCardItem] = useState([])
    const dispatch = useDispatch()
    let navigate = useNavigate();

    function openUploadModal(course) {
        dispatch(selectCourseForCertification(course))
        navigate('/certification/certificate_taker/certificate/' + course)
    }

    useEffect(() => {
        var loopOfLink = []
        if (certificate_test.length > 0 && (listOfCardItem.length !== (certificate_test.length))) {
            certificate_test.forEach(function (mapItem, index) {

                loopOfLink.push({
                    bgcolor: randomMUIColorGenerator("500"),
                    bgColorName: "teal",
                    avatarText: 'P' + (index + 1),
                    title: mapItem.test,
                    subheader: mapItem.name,
                    showCardAction: false,
                    cardType: "redux-func-call",
                    cardTypeAction: openUploadModal,
                    to: mapItem.test.trim().replaceAll(" ", "-").toLowerCase(),
                    toText: "Take Test",
                    showCardBody: true,
                    description: mapItem.description
                })
            })
        }

        loopOfLink.length > 0 && setListOfCardItem(loopOfLink)

    }, [listOfCardItem])

    return (
        <div>
            <ShowGroupCard itemList={listOfCardItem} xs={12} md={6} lg={3} />
        </div>
    )
}

export default CertSelection